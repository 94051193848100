.number-code {
  font-size: 20px;

  .name {
    color: #454a52;
  }

  .code {
    color: #195fff;
    font-family: 'Roboto-Bold', sans-serif;
    padding-left: 10px;
  }
}

.table-perform {
  width: 100%;
  border: 1px solid #dfe6ea;
  tr {
    background-color: #fff;
    &:first-child {
      background: #e8e8e8;
    }
    td {
      padding: 8px 15px;
      vertical-align: middle;
      &:last-child {
        text-align: right;
      }
    }
  }
}
