@charset "UTF-8";
/* PARENT BOX */
/* CHILD BOX */
:root {
  --blue: #195fff;
  --blue-hover: rgba(0, 0, 0, 0.14);
  --navigation: 80px;
  --sidebar: 240px;
  --sidebar-collapsed: 65px;
  --text: #262626; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

strong,
b {
  font-weight: bold; }

i,
cite,
em,
var,
address,
dfn {
  font-style: italic; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1;
  background: #eff3f5;
  font-family: "Roboto-Regular", sans-serif;
  line-height: 1.4;
  -webkit-overflow-scrolling: touch; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a,
button,
input,
select,
textarea,
input[type='checkbox'] {
  outline: none; }
  a:focus, a:hover,
  button:focus,
  button:hover,
  input:focus,
  input:hover,
  select:focus,
  select:hover,
  textarea:focus,
  textarea:hover,
  input[type='checkbox']:focus,
  input[type='checkbox']:hover {
    outline: none; }

input[type='text'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  background-color: transparent !important; }

*:focus,
:focus {
  outline: none;
  outline-offset: 0;
  -moz-outline-radius: 0; }

*:focus,
.pt-control input:focus ~ .pt-control-indicator {
  outline: 0;
  outline-offset: 0; }

* {
  -webkit-overflow-scrolling: touch; }

.expanded {
  min-width: 100%; }

/* font roboto full */
@font-face {
  font-family: 'Roboto-Medium';
  src: url("../fonts/family/Roboto-Medium.eot"), url("../fonts/family/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/family/Roboto-Medium.woff2") format("woff2"), url("../fonts/family/Roboto-Medium.woff") format("woff"), url("../fonts/family/Roboto-Medium.ttf") format("truetype"), url("../fonts/family/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Italic';
  src: url("../fonts/family/Roboto-Italic.eot"), url("../fonts/family/Roboto-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/family/Roboto-Italic.woff2") format("woff2"), url("../fonts/family/Roboto-Italic.woff") format("woff"), url("../fonts/family/Roboto-Italic.ttf") format("truetype"), url("../fonts/family/Roboto-Italic.svg#Roboto-Italic") format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Roboto-Regular';
  src: url("../fonts/family/Roboto-Regular.eot"), url("../fonts/family/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/family/Roboto-Regular.woff2") format("woff2"), url("../fonts/family/Roboto-Regular.woff") format("woff"), url("../fonts/family/Roboto-Regular.ttf") format("truetype"), url("../fonts/family/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Bold';
  src: url("../fonts/family/Roboto-Bold.eot"), url("../fonts/family/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/family/Roboto-Bold.woff2") format("woff2"), url("../fonts/family/Roboto-Bold.woff") format("woff"), url("../fonts/family/Roboto-Bold.ttf") format("truetype"), url("../fonts/family/Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Thin';
  src: url("../fonts/family/Roboto-Thin.eot");
  src: url("../fonts/family/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/family/Roboto-Thin.woff2") format("woff2"), url("../fonts/family/Roboto-Thin.woff") format("woff"), url("../fonts/family/Roboto-Thin.ttf") format("truetype"), url("../fonts/family/Roboto-Thin.svg#Roboto-Thin") format("svg");
  font-weight: 100;
  font-style: normal; }

/* fonts icomoon */
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.eot?96rguf");
  src: url("../fonts/icomoon/icomoon.eot?96rguf#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon.ttf?96rguf") format("truetype"), url("../fonts/icomoon/icomoon.woff?96rguf") format("woff"), url("../fonts/icomoon/icomoon.svg?96rguf#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-files-image:before {
  content: '\ea25'; }

.icon-check-user:before {
  content: '\ea23'; }

.icon-new-calendar:before {
  content: '\ea24'; }

.icon-gan-tag:before {
  content: '\e9f4'; }

.icon-ban-tay:before {
  content: '\e9ef'; }

.icon-but:before {
  content: '\e9f1'; }

.icon-paper-plane:before {
  content: '\ea22'; }

.icon-revoke:before {
  content: '\ea1c'; }

.icon-ttl-tiepnhan:before {
  content: '\ea1d'; }

.icon-thoigianluu:before {
  content: '\ea1e'; }

.icon-phuongphapluu:before {
  content: '\ea1f'; }

.icon-fly:before {
  content: '\ea20'; }

.icon-ho-so-cong-viec:before {
  content: '\ea21'; }

.icon-right-quote:before {
  content: '\e9f7'; }

.icon-revoke:before {
  content: '\ea1c'; }

.icon-hang-tags:before {
  content: '\e991'; }

.icon-list:before {
  content: '\ea19'; }

.icon-calendar-edit:before {
  content: '\ea1a'; }

.icon-board:before {
  content: '\ea1b'; }

.icon-right-fill:before {
  content: '\e9f8'; }

.icon-add:before {
  content: '\e9f9'; }

.icon-bell:before {
  content: '\e9fa'; }

.icon-bell-time:before {
  content: '\e9fb'; }

.icon-bin:before {
  content: '\e9fc'; }

.icon-calendar-new:before {
  content: '\e9fd'; }

.icon-calendar-time:before {
  content: '\e9fe'; }

.icon-case:before {
  content: '\e9ff'; }

.icon-check:before {
  content: '\ea00'; }

.icon-communication:before {
  content: '\ea01'; }

.icon-cube:before {
  content: '\ea02'; }

.icon-eye:before {
  content: '\ea03'; }

.icon-eye-block:before {
  content: '\ea04'; }

.icon-file:before {
  content: '\ea05'; }

.icon-file-statistics:before {
  content: '\ea06'; }

.icon-film-time:before {
  content: '\ea07'; }

.icon-flag-line:before {
  content: '\ea08'; }

.icon-give-task:before {
  content: '\ea09'; }

.icon-graph:before {
  content: '\ea0a'; }

.icon-group-file:before {
  content: '\ea0b'; }

.icon-pen:before {
  content: '\ea0c'; }

.icon-recycle:before {
  content: '\ea0d'; }

.icon-refresh:before {
  content: '\ea0e'; }

.icon-tag:before {
  content: '\ea0f'; }

.icon-time-divisin:before {
  content: '\ea10'; }

.icon-time-fail:before {
  content: '\ea11'; }

.icon-time-fail-light:before {
  content: '\ea12'; }

.icon-time-range:before {
  content: '\ea13'; }

.icon-time-range-light:before {
  content: '\ea14'; }

.icon-time-skip:before {
  content: '\ea15'; }

.icon-time-up:before {
  content: '\ea16'; }

.icon-user-add:before {
  content: '\ea17'; }

.icon-zoom-out-arrow:before {
  content: '\ea18'; }

.icon-add-user:before {
  content: '\e9df'; }

.icon-cloud:before {
  content: '\e9e0'; }

.icon-document:before {
  content: '\e9e1'; }

.icon-close-light:before {
  content: '\e9e2'; }

.icon-trinh-van-ban-ky-duyet:before {
  content: '\e9e3'; }

.icon-danh-sach-tap-trung:before {
  content: '\e9e4'; }

.icon-quan-ly-quy-trinh:before {
  content: '\e9e5'; }

.icon-danh-muc-quy-trinh:before {
  content: '\e9e6'; }

.icon-attachment-file:before {
  content: '\e9b8'; }

.icon-refreshing:before {
  content: '\e9a1'; }

.icon-chat:before {
  content: '\e9b4';
  color: #fff; }

.icon-edit-line:before {
  content: '\e9b3'; }

.icon-back:before {
  content: '\e998'; }

.icon-Save-DocumentCabinet:before {
  content: '\e9b0'; }

.icon-upload:before {
  content: '\e9af';
  color: #fff; }

.icon-play:before {
  content: '\e9ae';
  color: #195fff; }

.icon-quote-left:before {
  content: '\e9ad';
  color: #ea5744; }

.icon-decentralization-users:before {
  content: '\e9a3';
  color: #8a9da7; }

.icon-Back:before {
  content: '\e9b1';
  color: #195fff; }

.icon-Danh_Muc_Vai_Tro:before {
  content: '\e9aa';
  color: #8a9da7; }

.icon-Drapdrop:before {
  content: '\e9ab';
  color: #b5b5b5; }

.icon-Hethong:before {
  content: '\e9ac';
  color: #fff; }

.icon-decentralization:before {
  content: '\e9a4';
  color: #8a9da7; }

.icon-departments:before {
  content: '\e9a5';
  color: #8a9da7; }

.icon-flag:before {
  content: '\e9a6';
  color: #8a9da7; }

.icon-move-drap:before {
  content: '\e9a7'; }

.icon-position-user:before {
  content: '\e9a8';
  color: #8a9da7; }

.icon-save-note:before {
  content: '\e9a9';
  color: #8a9da7; }

.icon-forward:before {
  content: '\e99f';
  color: #555; }

.icon-listing:before {
  content: '\e9a0';
  color: #175bf5; }

.icon-visible:before {
  content: '\e9a2';
  color: #555; }

.icon-Cacdonvitructhuoc:before {
  content: '\e99c';
  color: #b6b8ba; }

.icon-Phoihop:before {
  content: '\e99d';
  color: #b6b8ba; }

.icon-Xemdebiet:before {
  content: '\e99e';
  color: #b6b8ba; }

.icon-Uy_Quyen:before {
  content: '\e99b';
  color: #8a9da7; }

.icon-close-line:before {
  content: '\e99a';
  color: #d2d2d2; }

.icon-arrow-dropdown:before {
  content: '\e999';
  color: #9fa2a5; }

.icon-arrow-dropup:before {
  content: '\e9b2';
  color: #9fa2a5; }

.icon-move:before {
  content: '\e996';
  color: #fff; }

.icon-trash:before {
  content: '\e997';
  color: #fff; }

.icon-close:before {
  content: '\e995';
  color: #d7d7d7; }

.icon-multifile-disable:before {
  content: '\e994';
  color: #8a9da7; }

.icon-lock:before {
  content: '\e992';
  color: #265fe1; }

.icon-profile:before {
  content: '\e993';
  color: #265fe1; }

.icon-thu_hoi_cong_van:before {
  content: '\e990';
  color: #555; }

.icon-gui_bo_sung:before {
  content: '\e991';
  color: #fff; }

.icon-search-data:before {
  content: '\e98f';
  color: #8a9da7; }

.icon-note-date-center:before {
  content: '\e98a';
  color: #bcbcbc; }

.icon-arrow-next:before {
  content: '\e98b';
  color: #fff; }

.icon-arrow-prev:before {
  content: '\e98c';
  color: #fff; }

.icon-chart:before {
  content: '\e98d';
  color: #195fff; }

.icon-double-file:before {
  content: '\e98e';
  color: #195fff; }

.icon-logout:before {
  content: '\e989';
  color: #195fff; }

.icon-Thong_Tin_GopY:before {
  content: '\e988'; }

.icon-CVDTKySo:before {
  content: '\e97e';
  color: #8a9da7; }

.icon-CVNBKyso:before {
  content: '\e97f';
  color: #8a9da7; }

.icon-DongYKysoSim:before {
  content: '\e980';
  color: #195fff; }

.icon-DongYKysoToken:before {
  content: '\e981';
  color: #195fff; }

.icon-GopY:before {
  content: '\e982';
  color: #195fff; }

.icon-TaoCVDiKyso:before {
  content: '\e983';
  color: #8a9da7; }

.icon-TaoCVDTKyso:before {
  content: '\e984';
  color: #8a9da7; }

.icon-TaoCVNBKyso:before {
  content: '\e985';
  color: #8a9da7; }

.icon-XemDSVBKyso:before {
  content: '\e986';
  color: #8a9da7; }

.icon-Xemtruoc:before {
  content: '\e987';
  color: #6a7489; }

.icon-Plus:before {
  content: '\e97b';
  color: #6a7489; }

.icon-Btn_Bao_Cao:before {
  content: '\e97c';
  color: #fff; }

.icon-Thong_Tin_Giao_Viec:before {
  content: '\e97a';
  color: #175bf5; }

.icon-Bao_Cao:before {
  content: '\e948';
  color: #175bf5; }

.icon-Cach_Thuc_Hien:before {
  content: '\e949';
  color: #a5a5a5; }

.icon-Chua_Giao:before {
  content: '\e96b';
  color: #7f7f7f; }

.icon-Chua_Thuc_Hien:before {
  content: '\e96c';
  color: #7f7f7f; }

.icon-Da_Giao:before {
  content: '\e96d';
  color: #7f7f7f; }

.icon-Dang_Thuc_Hien:before {
  content: '\e96e';
  color: #7f7f7f; }

.icon-Da_Thuc_Hien:before {
  content: '\e96f';
  color: #7f7f7f; }

.icon-File_Cong_Van_Loi:before {
  content: '\e970';
  color: #7f7f7f; }

.icon-File_Dinh_Kem:before {
  content: '\e971';
  color: #a5a5a5; }

.icon-File_Tham_Khao:before {
  content: '\e972';
  color: #a5a5a5; }

.icon-Giao_Viec:before {
  content: '\e973';
  color: #595959; }

.icon-Giao_Viec_Moi:before {
  content: '\e974';
  color: #7f7f7f; }

.icon-Hoan_Thanh:before {
  content: '\e975';
  color: #929292; }

.icon-Ket_Qua_Bao_Cao:before {
  content: '\e976';
  color: #a5a5a5; }

.icon-Tieu_De:before {
  content: '\e977';
  color: #a5a5a5; }

.icon-Tinh_Trang:before {
  content: '\e978';
  color: #a5a5a5; }

.icon-Van_Ban_Tra_Loi:before {
  content: '\e979';
  color: #a5a5a5; }

.icon-Send-Email:before {
  content: '\e947';
  color: #cbcbcb; }

.icon-Chidaobosung:before {
  content: '\e946';
  color: #595959; }

.icon-content:before {
  content: '\e968';
  color: #b8b8b8; }

.icon-setting:before {
  content: '\e969';
  color: #b8b8b8; }

.icon-hisory:before {
  content: '\e96a';
  color: #175bf5; }

.icon-zoom-in:before {
  content: '\e965'; }

.icon-zoom-out:before {
  content: '\e966'; }

.icon-expand-arrows:before {
  content: '\e967'; }

.icon-down-arrow:before {
  content: '\e962'; }

.icon-arrow-up:before {
  content: '\e963'; }

.icon-arrow-down:before {
  content: '\e964'; }

.icon-Xemthuchien:before {
  content: '\e95b';
  color: #595959; }

.icon-Chidao:before {
  content: '\e95c';
  color: #595959; }

.icon-Chuyenchidao:before {
  content: '\e95d';
  color: #595959; }

.icon-dexuatchidao:before {
  content: '\e95e';
  color: #595959; }

.icon-Gmail:before {
  content: '\e95f';
  color: #595959; }

.icon-Lichsuchidao:before {
  content: '\e960';
  color: #595959; }

.icon-Luututailieu:before {
  content: '\e961';
  color: #595959; }

.icon-save:before {
  content: '\e95a'; }

.icon-Loading:before {
  content: '\e958';
  color: #7f7f7f; }

.icon-More:before {
  content: '\e959'; }

.icon-calendar:before {
  content: '\e903';
  color: #7f7f7f; }

.icon-Nhap_cong_van_noi_bo:before {
  content: '\e902';
  color: #7f7f7f; }

.icon-Cong_van:before {
  content: '\e904';
  color: #7f7f7f; }

.icon-Cong_van_noi_bo:before {
  content: '\e907';
  color: #7f7f7f; }

.icon-Cong_van_chua_xu_ly:before {
  content: '\e908';
  color: #7f7f7f; }

.icon-Cong_van_dang_xu_ly:before {
  content: '\e909';
  color: #7f7f7f; }

.icon-Cong_viec:before {
  content: '\e90a';
  color: #7f7f7f; }

.icon-Nhap_cong_viec .path1:before {
  content: '\e90b';
  color: #7f7f7f; }

.icon-Nhap_cong_viec .path2:before {
  content: '\e90c';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Nhap_cong_viec .path3:before {
  content: '\e90d';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Nhap_cong_viec .path4:before {
  content: '\e90e';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Nhap_cong_viec .path5:before {
  content: '\e90f';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Nhap_cong_viec .path6:before {
  content: '\e910';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-Cong_viec_chua_xu_ly:before {
  content: '\e911';
  color: #7f7f7f; }

.icon-Cong_viec_dang_xu_ly:before {
  content: '\e912';
  color: #7f7f7f; }

.icon-Cong_viec_da_xu_ly:before {
  content: '\e913';
  color: #7f7f7f; }

.icon-Ky_so:before {
  content: '\e914';
  color: #7f7f7f; }

.icon-Nhap_ky_so .path1:before {
  content: '\e915';
  color: #7f7f7f; }

.icon-Nhap_ky_so .path2:before {
  content: '\e916';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Nhap_ky_so .path3:before {
  content: '\e917';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Nhap_ky_so .path4:before {
  content: '\e918';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-Nhap_ky_so .path5:before {
  content: '\e919';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-Ky_so_cong_van_di .path1:before {
  content: '\e91a';
  color: #7f7f7f; }

.icon-Ky_so_cong_van_di .path2:before {
  content: '\e91b';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Ky_so_cong_van_di .path3:before {
  content: '\e91c';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-Ky_so_cong_van_di .path4:before {
  content: '\e91d';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-Ky_so_cong_van_di .path5:before {
  content: '\e91e';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Ky_so_cong_van_noi_bo .path1:before {
  content: '\e91f';
  color: #7f7f7f; }

.icon-Ky_so_cong_van_noi_bo .path2:before {
  content: '\e920';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Ky_so_cong_van_noi_bo .path3:before {
  content: '\e921';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-Ky_so_cong_van_noi_bo .path4:before {
  content: '\e922';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-Ky_so_cong_van_noi_bo .path5:before {
  content: '\e923';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Ky_so_cong_van_noi_bo .path6:before {
  content: '\e924';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Ky_so_cong_van_noi_bo .path7:before {
  content: '\e925';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Ky_so_cong_van_noi_bo .path8:before {
  content: '\e926';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Ky_so_dau_thau .path1:before {
  content: '\e927';
  color: #7f7f7f; }

.icon-Ky_so_dau_thau .path2:before {
  content: '\e928';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Ky_so_dau_thau .path3:before {
  content: '\e929';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-Ky_so_dau_thau .path4:before {
  content: '\e92a';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-Ky_so_dau_thau .path5:before {
  content: '\e92b';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Ky_so_dau_thau .path6:before {
  content: '\e92c';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Ky_so_token .path1:before {
  content: '\e92d';
  color: #7f7f7f; }

.icon-Ky_so_token .path2:before {
  content: '\e92e';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Ky_so_token .path3:before {
  content: '\e92f';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-Ky_so_token .path4:before {
  content: '\e930';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-Ky_so_token .path5:before {
  content: '\e931';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Ky_so_di_dong .path1:before {
  content: '\e932';
  color: #7f7f7f; }

.icon-Ky_so_di_dong .path2:before {
  content: '\e933';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Ky_so_di_dong .path3:before {
  content: '\e934';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-Ky_so_di_dong .path4:before {
  content: '\e935';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-Ky_so_di_dong .path5:before {
  content: '\e936';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Chi_dao .path1:before {
  content: '\e937';
  color: #7f7f7f; }

.icon-Chi_dao .path2:before {
  content: '\e938';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Chi_dao .path3:before {
  content: '\e939';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Chi_dao .path4:before {
  content: '\e93a';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-Chi_dao .path5:before {
  content: '\e93b';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-Chuyen_chi_dao .path1:before {
  content: '\e93c';
  color: #7f7f7f; }

.icon-Chuyen_chi_dao .path2:before {
  content: '\e93d';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Chuyen_chi_dao .path3:before {
  content: '\e93e';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-Chuyen_chi_dao .path4:before {
  content: '\e93f';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-De_xuat_chi_dao .path1:before {
  content: '\e940';
  color: #7f7f7f; }

.icon-De_xuat_chi_dao .path2:before {
  content: '\e941';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-De_xuat_chi_dao .path3:before {
  content: '\e942';
  margin-left: -1.0986328125em;
  color: #7f7f7f; }

.icon-De_xuat_chi_dao .path4:before {
  content: '\e943';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-De_xuat_chi_dao .path5:before {
  content: '\e944';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-De_xuat_chi_dao .path6:before {
  content: '\e945';
  margin-left: -1.0986328125em;
  color: #cbcbcb; }

.icon-Xem_thuc_hien:before {
  content: '\e94a';
  color: #7f7f7f; }

.icon-Luu_tu_tai_lieu:before {
  content: '\e94b';
  color: #7f7f7f; }

.icon-Gui_email:before {
  content: '\e94c';
  color: #7f7f7f; }

.icon-Giao_viec:before {
  content: '\e94d';
  color: #7f7f7f; }

.icon-Ban_Bao_Cao:before {
  content: '\e94e';
  color: #7f7f7f; }

.icon-Danh_Ba:before {
  content: '\e950';
  color: #7f7f7f; }

.icon-In_So_Cong_Van:before {
  content: '\e951';
  color: #7f7f7f; }

.icon-Lich_Lam_Viec:before {
  content: '\e953';
  color: #7f7f7f; }

.icon-Thong_Bao_Chung:before {
  content: '\e954';
  color: #8a9da7; }

.icon-Tong_Hop:before {
  content: '\e955';
  color: #7f7f7f; }

.icon-Tu_Tai_Lieu:before {
  content: '\e957';
  color: #7f7f7f; }

.icon-Nhap_cong_van_den:before {
  content: '\e900';
  color: #7f7f7f; }

.icon-Nhap_cong_van_di:before {
  content: '\e901';
  color: #7f7f7f; }

.icon-Cong_van_den:before {
  content: '\e905';
  color: #8a9da7; }

.icon-Cong_van_di:before {
  content: '\e906';
  color: #8a9da7; }

.icon-right-quote:before {
  content: '\e9f7'; }

.icon-time:before {
  content: '\e9f6'; }

.icon-diagram:before {
  content: '\e9eb';
  color: #555; }

.icon-edit:before {
  content: '\e9ec'; }

.icon-Huy:before {
  content: '\e9ed';
  color: #6a7489; }

.icon-icon-yeu-cau-gop-y:before {
  content: '\e9ee';
  color: #175bf5; }

.icon-ThongNhat-1:before {
  content: '\e9ef';
  color: #b6b8ba; }

.icon-ThongNhat:before {
  content: '\e9f0';
  color: #b6b8ba; }

.icon-ThongTinGopY-1:before {
  content: '\e9f1';
  color: #b6b8ba; }

.icon-ThongTinGopY:before {
  content: '\e9f2';
  color: #b6b8ba; }

.icon-xac-nhan-lai:before {
  content: '\e9f3';
  color: #6a7489; }

.icon-XacNhan-1:before {
  content: '\e9f4';
  color: #b6b8ba; }

.icon-XacNhan:before {
  content: '\e9f5';
  color: #b6b8ba; }

.icon-upload1:before {
  content: '\e9e7'; }

.icon-quy-trinh-chung:before {
  content: '\e9e8'; }

.icon-quy-trinh-ca-nhan:before {
  content: '\e9e9'; }

.icon-search:before {
  content: '\e9ea';
  color: #8a9da7; }

.icon-bubble-tro-chuyen:before {
  content: '\e9db'; }

.icon-list-check:before {
  content: '\e9dc'; }

.icon-menu-o-vuong:before {
  content: '\e9dd'; }

.icon-bubble-cuoc-tro-chuyen:before {
  content: '\e9de'; }

.icon-bieu-do-tang:before {
  content: '\e9da'; }

.icon-s-delete:before {
  content: '\e9d5'; }

.icon-s-edit:before {
  content: '\e9d6'; }

.icon-s-list:before {
  content: '\e9d7'; }

.icon-s-pen:before {
  content: '\e9d8'; }

.icon-s-plus:before {
  content: '\e9d9'; }

.icon-revoke:before {
  content: '\e9d4'; }

.icon-recalled:before {
  content: '\e9d3'; }

.icon-folder:before {
  content: '\e9d2'; }

.icon-speech-bubble:before {
  content: '\e9d1'; }

.icon-wrench:before {
  content: '\e9d0'; }

.icon-round-delete-button1:before {
  content: '\e9cd'; }

.icon-rounded-add-button1:before {
  content: '\e9cf'; }

.icon-minimize:before {
  content: '\e9cc';
  color: #52555c; }

.icon-Giao-viec-moi:before {
  content: '\e9cb';
  color: #979797; }

.icon-Group-8:before {
  content: '\e9ca';
  color: #195fff; }

.icon-Cong_van_den1:before {
  content: '\e9c7';
  color: #5ba20d; }

.icon-Cong_viec_no_cong_van:before {
  content: '\e9c8';
  color: #8a9da7; }

.icon-Nhap_cong_van_noi_bo1:before {
  content: '\e9c9';
  color: #195fff; }

.icon-man-chutri:before {
  content: '\e9c5';
  color: #195fff; }

.icon-man-phoihop:before {
  content: '\e9c6';
  color: #777; }

.icon-upload-file:before {
  content: '\e9c4';
  color: #fff; }

.icon-Bao_Luu:before {
  content: '\e9c3';
  color: #fb804d; }

.icon-Du_An:before {
  content: '\e9c1';
  color: #8a9da7; }

.icon-Tao_Du_An:before {
  content: '\e9c2';
  color: #175bf5; }

.icon-caution-sign:before {
  content: '\e9c0';
  color: #e1d103; }

.icon-check-sign-in-a-rounded-black-square:before {
  content: '\e9bf'; }

.icon-square:before {
  content: '\e9be'; }

.icon-rounded-add-button:before {
  content: '\e9bc'; }

.icon-round-delete-button:before {
  content: '\e9bd'; }

.icon-attach:before {
  content: '\e9b5'; }

.icon-AD_password:before {
  content: '\e956'; }

.icon-Trang_Chu:before {
  content: '\e952'; }

.icon-CV_EVN:before {
  content: '\e9b9'; }

.icon-attention-warning:before {
  content: '\e9ba'; }

.icon-Kyso_Congvandi:before {
  content: '\e9bb'; }

.icon-settings:before {
  content: '\e9b7'; }

.icon-notification:before {
  content: '\e97d'; }

.icon-app-mobile:before {
  content: '\e94f'; }

.icon-organization:before {
  content: '\e9b6'; }

.icon-new-feature:before {
  content: '\e9ce'; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/family/Roboto-Thin.eot");
  src: url("../fonts/family/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/family/Roboto-Thin.woff2") format("woff2"), url("../fonts/family/Roboto-Thin.woff") format("woff"), url("../fonts/family/Roboto-Thin.ttf") format("truetype"), url("../fonts/family/Roboto-Thin.svg#Roboto-Thin") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/family/Roboto-Regular.eot"), url("../fonts/family/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/family/Roboto-Regular.woff2") format("woff2"), url("../fonts/family/Roboto-Regular.woff") format("woff"), url("../fonts/family/Roboto-Regular.ttf") format("truetype"), url("../fonts/family/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/family/Roboto-Medium.eot"), url("../fonts/family/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/family/Roboto-Medium.woff2") format("woff2"), url("../fonts/family/Roboto-Medium.woff") format("woff"), url("../fonts/family/Roboto-Medium.ttf") format("truetype"), url("../fonts/family/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/family/Roboto-Bold.eot"), url("../fonts/family/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/family/Roboto-Bold.woff2") format("woff2"), url("../fonts/family/Roboto-Bold.woff") format("woff"), url("../fonts/family/Roboto-Bold.ttf") format("truetype"), url("../fonts/family/Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/family/Roboto-Black.eot"), url("../fonts/family/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/family/Roboto-Black.woff") format("woff"), url("../fonts/family/Roboto-Black.ttf") format("truetype"), url("../fonts/family/Roboto-Black.svg#Roboto-Black") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-display: block;
  font-family: 'icon2';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/icomoon-v2/icon2.eot?4shcu0");
  src: url("../fonts/icomoon-v2/icon2.eot?4shcu0#iefix") format("embedded-opentype"), url("../fonts/icomoon-v2/icon2.ttf?4shcu0") format("truetype"), url("../fonts/icomoon-v2/icon2.woff?4shcu0") format("woff"), url("../fonts/icomoon-v2/icon2.svg?4shcu0#icomoon") format("svg"); }

[class^='icon2-'],
[class*=' icon2-'] {
  font-family: 'icon2' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none; }

.icon2-check:before {
  content: '\e942'; }

.icon2-dragdrop:before {
  content: '\e943'; }

.icon2-node:before {
  content: '\e941'; }

.icon2-left:before {
  content: '\e940'; }

.icon2-timeline:before {
  content: '\e93f'; }

.icon2-call-type:before {
  content: '\e93c'; }

.icon2-chat-type:before {
  content: '\e93d'; }

.icon2-share:before {
  content: '\e93e'; }

.icon2-perform:before {
  content: '\e93b'; }

.icon2-digital-vertical:before {
  content: '\e939'; }

.icon2-digital:before {
  content: '\e93a'; }

.icon2-hand:before {
  content: '\e938'; }

.icon2-filter:before {
  content: '\e937'; }

.icon2-clipboard:before {
  content: '\e932'; }

.icon2-tags-dup:before {
  content: '\e933'; }

.icon2-export:before {
  content: '\e934'; }

.icon2-chart-histogram:before {
  content: '\e935'; }

.icon2-d-office:before {
  content: '\e936'; }

.icon2-direct-inbox:before {
  content: '\e92c'; }

.icon2-edit-2:before {
  content: '\e92d'; }

.icon2-layer:before {
  content: '\e92e'; }

.icon2-sms-tracking:before {
  content: '\e92f'; }

.icon2-timer-pause:before {
  content: '\e930'; }

.icon2-timer-start:before {
  content: '\e931'; }

.icon2-chat:before {
  content: '\e920'; }

.icon2-ios:before {
  content: '\e921'; }

.icon2-windows:before {
  content: '\e922'; }

.icon2-chuong:before {
  content: '\e923'; }

.icon2-tai-lieu-ky:before {
  content: '\e924'; }

.icon2-tai-lieu-ky-gui-di:before {
  content: '\e925'; }

.icon2-tai-lieu-hoan-thanh-da-ky:before {
  content: '\e926'; }

.icon2-tai-lieu-hoan-thanh:before {
  content: '\e927'; }

.icon2-tai-lieu-gui-den:before {
  content: '\e928'; }

.icon2-them-tai-lieu:before {
  content: '\e929'; }

.icon2-ky-van-ban:before {
  content: '\e92a'; }

.icon2-ds-thong-ke:before {
  content: '\e92b'; }

.icon2-cai-dat:before {
  content: '\e91a'; }

.icon2-cong-viec:before {
  content: '\e91b'; }

.icon2-check-list:before {
  content: '\e91c'; }

.icon2-ky-so:before {
  content: '\e91d'; }

.icon2-tai-lieu:before {
  content: '\e91e'; }

.icon2-dashboard:before {
  content: '\e91f'; }

.icon2-download:before {
  content: '\e919'; }

.icon2-knoll:before {
  content: '\e917'; }

.icon2-nhom-thuong-giao-viec:before {
  content: '\e918'; }

.icon2-bell:before {
  content: '\e916'; }

.icon2-color-blob:before {
  content: '\e915'; }

.icon2-file-send:before {
  content: '\e914'; }

.icon2-e-office-label:before {
  content: '\e913'; }

.icon2-give-task:before {
  content: '\e912'; }

.icon2-pie-chart:before {
  content: '\e90c'; }

.icon2-eye:before {
  content: '\e911'; }

.icon2-tag:before {
  content: '\e90b'; }

.icon2-rounded-plus:before {
  content: '\e90d'; }

.icon2-documents:before {
  content: '\e90e'; }

.icon2-user:before {
  content: '\e90f'; }

.icon2-excel:before {
  content: '\e910'; }

.icon2-arrow-dart-left:before {
  content: '\e900'; }

.icon2-arrow-dart-right:before {
  content: '\e901'; }

.icon2-arrow-double-left:before {
  content: '\e902'; }

.icon2-arrow-double-right:before {
  content: '\e903'; }

.icon2-date-frames:before {
  content: '\e904'; }

.icon2-search:before {
  content: '\e905'; }

.icon2-menu:before {
  content: '\e906'; }

.icon2-arrow-left:before {
  content: '\e907'; }

.icon2-arrow-right:before {
  content: '\e908'; }

.icon2-arrow-up:before {
  content: '\e909'; }

.icon2-arrow-down:before {
  content: '\e90a'; }

.clearfix, .detail-container-sign-number .box-feedback:after {
  clear: both;
  content: '';
  display: block; }

.clearfix-after::after {
  clear: both;
  content: '';
  display: table; }

.m0 {
  margin: 0 !important; }

.mt0 {
  margin-top: 0 !important; }

.mr0 {
  margin-right: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.ml0 {
  margin-left: 0 !important; }

.mv0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.mh0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important; }

.m5 {
  margin: 5px !important; }

.mt5 {
  margin-top: 5px !important; }

.mr5 {
  margin-right: 5px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.ml5 {
  margin-left: 5px !important; }

.mv5 {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.mh5 {
  margin-bottom: 5px !important;
  margin-top: 5px !important; }

.m10 {
  margin: 10px !important; }

.mt10 {
  margin-top: 10px !important; }

.mr10 {
  margin-right: 10px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.ml10 {
  margin-left: 10px !important; }

.mv10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.mh10 {
  margin-bottom: 10px !important;
  margin-top: 10px !important; }

.m15 {
  margin: 15px !important; }

.mt15 {
  margin-top: 15px !important; }

.mr15 {
  margin-right: 15px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.ml15 {
  margin-left: 15px !important; }

.mv15 {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.mh15 {
  margin-bottom: 15px !important;
  margin-top: 15px !important; }

.m20 {
  margin: 20px !important; }

.mt20 {
  margin-top: 20px !important; }

.mr20 {
  margin-right: 20px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.ml20 {
  margin-left: 20px !important; }

.mv20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.mh20 {
  margin-bottom: 20px !important;
  margin-top: 20px !important; }

.m25 {
  margin: 25px !important; }

.mt25 {
  margin-top: 25px !important; }

.mr25 {
  margin-right: 25px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.ml25 {
  margin-left: 25px !important; }

.mv25 {
  margin-left: 25px !important;
  margin-right: 25px !important; }

.mh25 {
  margin-bottom: 25px !important;
  margin-top: 25px !important; }

.m30 {
  margin: 30px !important; }

.mt30 {
  margin-top: 30px !important; }

.mr30 {
  margin-right: 30px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.ml30 {
  margin-left: 30px !important; }

.mv30 {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.mh30 {
  margin-bottom: 30px !important;
  margin-top: 30px !important; }

.m35 {
  margin: 35px !important; }

.mt35 {
  margin-top: 35px !important; }

.mr35 {
  margin-right: 35px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.ml35 {
  margin-left: 35px !important; }

.mv35 {
  margin-left: 35px !important;
  margin-right: 35px !important; }

.mh35 {
  margin-bottom: 35px !important;
  margin-top: 35px !important; }

.m40 {
  margin: 40px !important; }

.mt40 {
  margin-top: 40px !important; }

.mr40 {
  margin-right: 40px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.ml40 {
  margin-left: 40px !important; }

.mv40 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.mh40 {
  margin-bottom: 40px !important;
  margin-top: 40px !important; }

.m45 {
  margin: 45px !important; }

.mt45 {
  margin-top: 45px !important; }

.mr45 {
  margin-right: 45px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.ml45 {
  margin-left: 45px !important; }

.mv45 {
  margin-left: 45px !important;
  margin-right: 45px !important; }

.mh45 {
  margin-bottom: 45px !important;
  margin-top: 45px !important; }

.m50 {
  margin: 50px !important; }

.mt50 {
  margin-top: 50px !important; }

.mr50 {
  margin-right: 50px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.ml50 {
  margin-left: 50px !important; }

.mv50 {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.mh50 {
  margin-bottom: 50px !important;
  margin-top: 50px !important; }

.m55 {
  margin: 55px !important; }

.mt55 {
  margin-top: 55px !important; }

.mr55 {
  margin-right: 55px !important; }

.mb55 {
  margin-bottom: 55px !important; }

.ml55 {
  margin-left: 55px !important; }

.mv55 {
  margin-left: 55px !important;
  margin-right: 55px !important; }

.mh55 {
  margin-bottom: 55px !important;
  margin-top: 55px !important; }

.m60 {
  margin: 60px !important; }

.mt60 {
  margin-top: 60px !important; }

.mr60 {
  margin-right: 60px !important; }

.mb60 {
  margin-bottom: 60px !important; }

.ml60 {
  margin-left: 60px !important; }

.mv60 {
  margin-left: 60px !important;
  margin-right: 60px !important; }

.mh60 {
  margin-bottom: 60px !important;
  margin-top: 60px !important; }

.m65 {
  margin: 65px !important; }

.mt65 {
  margin-top: 65px !important; }

.mr65 {
  margin-right: 65px !important; }

.mb65 {
  margin-bottom: 65px !important; }

.ml65 {
  margin-left: 65px !important; }

.mv65 {
  margin-left: 65px !important;
  margin-right: 65px !important; }

.mh65 {
  margin-bottom: 65px !important;
  margin-top: 65px !important; }

.p0 {
  padding: 0 !important; }

.pt0 {
  padding-top: 0 !important; }

.pr0 {
  padding-right: 0 !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pl0 {
  padding-left: 0 !important; }

.pv0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.ph0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important; }

.p5 {
  padding: 5px !important; }

.pt5 {
  padding-top: 5px !important; }

.pr5 {
  padding-right: 5px !important; }

.pb5 {
  padding-bottom: 5px !important; }

.pl5 {
  padding-left: 5px !important; }

.pv5 {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.ph5 {
  padding-bottom: 5px !important;
  padding-top: 5px !important; }

.p10 {
  padding: 10px !important; }

.pt10 {
  padding-top: 10px !important; }

.pr10 {
  padding-right: 10px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pl10 {
  padding-left: 10px !important; }

.pv10 {
  padding-left: 10px !important;
  padding-right: 10px !important; }

.ph10 {
  padding-bottom: 10px !important;
  padding-top: 10px !important; }

.p15 {
  padding: 15px !important; }

.pt15 {
  padding-top: 15px !important; }

.pr15 {
  padding-right: 15px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pl15 {
  padding-left: 15px !important; }

.pv15 {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.ph15 {
  padding-bottom: 15px !important;
  padding-top: 15px !important; }

.p20 {
  padding: 20px !important; }

.pt20 {
  padding-top: 20px !important; }

.pr20 {
  padding-right: 20px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pl20 {
  padding-left: 20px !important; }

.pv20 {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.ph20 {
  padding-bottom: 20px !important;
  padding-top: 20px !important; }

.p25 {
  padding: 25px !important; }

.pt25 {
  padding-top: 25px !important; }

.pr25 {
  padding-right: 25px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.pl25 {
  padding-left: 25px !important; }

.pv25 {
  padding-left: 25px !important;
  padding-right: 25px !important; }

.ph25 {
  padding-bottom: 25px !important;
  padding-top: 25px !important; }

.p30 {
  padding: 30px !important; }

.pt30 {
  padding-top: 30px !important; }

.pr30 {
  padding-right: 30px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.pl30 {
  padding-left: 30px !important; }

.pv30 {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.ph30 {
  padding-bottom: 30px !important;
  padding-top: 30px !important; }

.p35 {
  padding: 35px !important; }

.pt35 {
  padding-top: 35px !important; }

.pr35 {
  padding-right: 35px !important; }

.pb35 {
  padding-bottom: 35px !important; }

.pl35 {
  padding-left: 35px !important; }

.pv35 {
  padding-left: 35px !important;
  padding-right: 35px !important; }

.ph35 {
  padding-bottom: 35px !important;
  padding-top: 35px !important; }

.p40 {
  padding: 40px !important; }

.pt40 {
  padding-top: 40px !important; }

.pr40 {
  padding-right: 40px !important; }

.pb40 {
  padding-bottom: 40px !important; }

.pl40 {
  padding-left: 40px !important; }

.pv40 {
  padding-left: 40px !important;
  padding-right: 40px !important; }

.ph40 {
  padding-bottom: 40px !important;
  padding-top: 40px !important; }

.p45 {
  padding: 45px !important; }

.pt45 {
  padding-top: 45px !important; }

.pr45 {
  padding-right: 45px !important; }

.pb45 {
  padding-bottom: 45px !important; }

.pl45 {
  padding-left: 45px !important; }

.pv45 {
  padding-left: 45px !important;
  padding-right: 45px !important; }

.ph45 {
  padding-bottom: 45px !important;
  padding-top: 45px !important; }

.p50 {
  padding: 50px !important; }

.pt50 {
  padding-top: 50px !important; }

.pr50 {
  padding-right: 50px !important; }

.pb50 {
  padding-bottom: 50px !important; }

.pl50 {
  padding-left: 50px !important; }

.pv50 {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.ph50 {
  padding-bottom: 50px !important;
  padding-top: 50px !important; }

.p55 {
  padding: 55px !important; }

.pt55 {
  padding-top: 55px !important; }

.pr55 {
  padding-right: 55px !important; }

.pb55 {
  padding-bottom: 55px !important; }

.pl55 {
  padding-left: 55px !important; }

.pv55 {
  padding-left: 55px !important;
  padding-right: 55px !important; }

.ph55 {
  padding-bottom: 55px !important;
  padding-top: 55px !important; }

.p60 {
  padding: 60px !important; }

.pt60 {
  padding-top: 60px !important; }

.pr60 {
  padding-right: 60px !important; }

.pb60 {
  padding-bottom: 60px !important; }

.pl60 {
  padding-left: 60px !important; }

.pv60 {
  padding-left: 60px !important;
  padding-right: 60px !important; }

.ph60 {
  padding-bottom: 60px !important;
  padding-top: 60px !important; }

.p65 {
  padding: 65px !important; }

.pt65 {
  padding-top: 65px !important; }

.pr65 {
  padding-right: 65px !important; }

.pb65 {
  padding-bottom: 65px !important; }

.pl65 {
  padding-left: 65px !important; }

.pv65 {
  padding-left: 65px !important;
  padding-right: 65px !important; }

.ph65 {
  padding-bottom: 65px !important;
  padding-top: 65px !important; }

.p2 {
  padding: 2px !important; }

.pt2 {
  padding-top: 2px !important; }

.pr2 {
  padding-right: 2px !important; }

.pb2 {
  padding-bottom: 2px !important; }

.pl2 {
  padding-left: 2px !important; }

.pv2 {
  padding-left: 2px !important;
  padding-right: 2px !important; }

.ph2 {
  padding-bottom: 2px !important;
  padding-top: 2px !important; }

.p4 {
  padding: 4px !important; }

.pt4 {
  padding-top: 4px !important; }

.pr4 {
  padding-right: 4px !important; }

.pb4 {
  padding-bottom: 4px !important; }

.pl4 {
  padding-left: 4px !important; }

.pv4 {
  padding-left: 4px !important;
  padding-right: 4px !important; }

.ph4 {
  padding-bottom: 4px !important;
  padding-top: 4px !important; }

.p6 {
  padding: 6px !important; }

.pt6 {
  padding-top: 6px !important; }

.pr6 {
  padding-right: 6px !important; }

.pb6 {
  padding-bottom: 6px !important; }

.pl6 {
  padding-left: 6px !important; }

.pv6 {
  padding-left: 6px !important;
  padding-right: 6px !important; }

.ph6 {
  padding-bottom: 6px !important;
  padding-top: 6px !important; }

.p8 {
  padding: 8px !important; }

.pt8 {
  padding-top: 8px !important; }

.pr8 {
  padding-right: 8px !important; }

.pb8 {
  padding-bottom: 8px !important; }

.pl8 {
  padding-left: 8px !important; }

.pv8 {
  padding-left: 8px !important;
  padding-right: 8px !important; }

.ph8 {
  padding-bottom: 8px !important;
  padding-top: 8px !important; }

.p10 {
  padding: 10px !important; }

.pt10 {
  padding-top: 10px !important; }

.pr10 {
  padding-right: 10px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pl10 {
  padding-left: 10px !important; }

.pv10 {
  padding-left: 10px !important;
  padding-right: 10px !important; }

.ph10 {
  padding-bottom: 10px !important;
  padding-top: 10px !important; }

.p12 {
  padding: 12px !important; }

.pt12 {
  padding-top: 12px !important; }

.pr12 {
  padding-right: 12px !important; }

.pb12 {
  padding-bottom: 12px !important; }

.pl12 {
  padding-left: 12px !important; }

.pv12 {
  padding-left: 12px !important;
  padding-right: 12px !important; }

.ph12 {
  padding-bottom: 12px !important;
  padding-top: 12px !important; }

.p14 {
  padding: 14px !important; }

.pt14 {
  padding-top: 14px !important; }

.pr14 {
  padding-right: 14px !important; }

.pb14 {
  padding-bottom: 14px !important; }

.pl14 {
  padding-left: 14px !important; }

.pv14 {
  padding-left: 14px !important;
  padding-right: 14px !important; }

.ph14 {
  padding-bottom: 14px !important;
  padding-top: 14px !important; }

.p16 {
  padding: 16px !important; }

.pt16 {
  padding-top: 16px !important; }

.pr16 {
  padding-right: 16px !important; }

.pb16 {
  padding-bottom: 16px !important; }

.pl16 {
  padding-left: 16px !important; }

.pv16 {
  padding-left: 16px !important;
  padding-right: 16px !important; }

.ph16 {
  padding-bottom: 16px !important;
  padding-top: 16px !important; }

.p18 {
  padding: 18px !important; }

.pt18 {
  padding-top: 18px !important; }

.pr18 {
  padding-right: 18px !important; }

.pb18 {
  padding-bottom: 18px !important; }

.pl18 {
  padding-left: 18px !important; }

.pv18 {
  padding-left: 18px !important;
  padding-right: 18px !important; }

.ph18 {
  padding-bottom: 18px !important;
  padding-top: 18px !important; }

.p20 {
  padding: 20px !important; }

.pt20 {
  padding-top: 20px !important; }

.pr20 {
  padding-right: 20px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pl20 {
  padding-left: 20px !important; }

.pv20 {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.ph20 {
  padding-bottom: 20px !important;
  padding-top: 20px !important; }

.p22 {
  padding: 22px !important; }

.pt22 {
  padding-top: 22px !important; }

.pr22 {
  padding-right: 22px !important; }

.pb22 {
  padding-bottom: 22px !important; }

.pl22 {
  padding-left: 22px !important; }

.pv22 {
  padding-left: 22px !important;
  padding-right: 22px !important; }

.ph22 {
  padding-bottom: 22px !important;
  padding-top: 22px !important; }

.p24 {
  padding: 24px !important; }

.pt24 {
  padding-top: 24px !important; }

.pr24 {
  padding-right: 24px !important; }

.pb24 {
  padding-bottom: 24px !important; }

.pl24 {
  padding-left: 24px !important; }

.pv24 {
  padding-left: 24px !important;
  padding-right: 24px !important; }

.ph24 {
  padding-bottom: 24px !important;
  padding-top: 24px !important; }

.m0 {
  margin: 0 !important; }

.mt0 {
  margin-top: 0 !important; }

.mr0 {
  margin-right: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.ml0 {
  margin-left: 0 !important; }

.mv0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.mh0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important; }

.m2 {
  margin: 2px !important; }

.mt2 {
  margin-top: 2px !important; }

.mr2 {
  margin-right: 2px !important; }

.mb2 {
  margin-bottom: 2px !important; }

.ml2 {
  margin-left: 2px !important; }

.mv2 {
  margin-left: 2px !important;
  margin-right: 2px !important; }

.mh2 {
  margin-bottom: 2px !important;
  margin-top: 2px !important; }

.m4 {
  margin: 4px !important; }

.mt4 {
  margin-top: 4px !important; }

.mr4 {
  margin-right: 4px !important; }

.mb4 {
  margin-bottom: 4px !important; }

.ml4 {
  margin-left: 4px !important; }

.mv4 {
  margin-left: 4px !important;
  margin-right: 4px !important; }

.mh4 {
  margin-bottom: 4px !important;
  margin-top: 4px !important; }

.m6 {
  margin: 6px !important; }

.mt6 {
  margin-top: 6px !important; }

.mr6 {
  margin-right: 6px !important; }

.mb6 {
  margin-bottom: 6px !important; }

.ml6 {
  margin-left: 6px !important; }

.mv6 {
  margin-left: 6px !important;
  margin-right: 6px !important; }

.mh6 {
  margin-bottom: 6px !important;
  margin-top: 6px !important; }

.m8 {
  margin: 8px !important; }

.mt8 {
  margin-top: 8px !important; }

.mr8 {
  margin-right: 8px !important; }

.mb8 {
  margin-bottom: 8px !important; }

.ml8 {
  margin-left: 8px !important; }

.mv8 {
  margin-left: 8px !important;
  margin-right: 8px !important; }

.mh8 {
  margin-bottom: 8px !important;
  margin-top: 8px !important; }

.m10 {
  margin: 10px !important; }

.mt10 {
  margin-top: 10px !important; }

.mr10 {
  margin-right: 10px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.ml10 {
  margin-left: 10px !important; }

.mv10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.mh10 {
  margin-bottom: 10px !important;
  margin-top: 10px !important; }

.m12 {
  margin: 12px !important; }

.mt12 {
  margin-top: 12px !important; }

.mr12 {
  margin-right: 12px !important; }

.mb12 {
  margin-bottom: 12px !important; }

.ml12 {
  margin-left: 12px !important; }

.mv12 {
  margin-left: 12px !important;
  margin-right: 12px !important; }

.mh12 {
  margin-bottom: 12px !important;
  margin-top: 12px !important; }

.m14 {
  margin: 14px !important; }

.mt14 {
  margin-top: 14px !important; }

.mr14 {
  margin-right: 14px !important; }

.mb14 {
  margin-bottom: 14px !important; }

.ml14 {
  margin-left: 14px !important; }

.mv14 {
  margin-left: 14px !important;
  margin-right: 14px !important; }

.mh14 {
  margin-bottom: 14px !important;
  margin-top: 14px !important; }

.m16 {
  margin: 16px !important; }

.mt16 {
  margin-top: 16px !important; }

.mr16 {
  margin-right: 16px !important; }

.mb16 {
  margin-bottom: 16px !important; }

.ml16 {
  margin-left: 16px !important; }

.mv16 {
  margin-left: 16px !important;
  margin-right: 16px !important; }

.mh16 {
  margin-bottom: 16px !important;
  margin-top: 16px !important; }

.m18 {
  margin: 18px !important; }

.mt18 {
  margin-top: 18px !important; }

.mr18 {
  margin-right: 18px !important; }

.mb18 {
  margin-bottom: 18px !important; }

.ml18 {
  margin-left: 18px !important; }

.mv18 {
  margin-left: 18px !important;
  margin-right: 18px !important; }

.mh18 {
  margin-bottom: 18px !important;
  margin-top: 18px !important; }

.m20 {
  margin: 20px !important; }

.mt20 {
  margin-top: 20px !important; }

.mr20 {
  margin-right: 20px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.ml20 {
  margin-left: 20px !important; }

.mv20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.mh20 {
  margin-bottom: 20px !important;
  margin-top: 20px !important; }

.m22 {
  margin: 22px !important; }

.mt22 {
  margin-top: 22px !important; }

.mr22 {
  margin-right: 22px !important; }

.mb22 {
  margin-bottom: 22px !important; }

.ml22 {
  margin-left: 22px !important; }

.mv22 {
  margin-left: 22px !important;
  margin-right: 22px !important; }

.mh22 {
  margin-bottom: 22px !important;
  margin-top: 22px !important; }

.m24 {
  margin: 24px !important; }

.mt24 {
  margin-top: 24px !important; }

.mr24 {
  margin-right: 24px !important; }

.mb24 {
  margin-bottom: 24px !important; }

.ml24 {
  margin-left: 24px !important; }

.mv24 {
  margin-left: 24px !important;
  margin-right: 24px !important; }

.mh24 {
  margin-bottom: 24px !important;
  margin-top: 24px !important; }

.p14 {
  padding: 14px; }

.min-height-0 {
  min-height: 0 !important; }

.min-height-50 {
  min-height: 50px !important; }

.min-height-100 {
  min-height: 100px !important; }

.min-height-150 {
  min-height: 150px !important; }

.min-height-200 {
  min-height: 200px !important; }

.min-height-250 {
  min-height: 250px !important; }

.min-height-300 {
  min-height: 300px !important; }

.min-height-350 {
  min-height: 350px !important; }

.min-height-400 {
  min-height: 400px !important; }

.min-height-450 {
  min-height: 450px !important; }

.min-height-500 {
  min-height: 500px !important; }

.height-0 {
  height: 0 !important; }

.width-0 {
  width: 0 !important; }

.height-10 {
  height: 10px !important; }

.width-10 {
  width: 10px !important; }

.height-20 {
  height: 20px !important; }

.width-20 {
  width: 20px !important; }

.height-30 {
  height: 30px !important; }

.width-30 {
  width: 30px !important; }

.height-40 {
  height: 40px !important; }

.width-40 {
  width: 40px !important; }

.height-50 {
  height: 50px !important; }

.width-50 {
  width: 50px !important; }

.height-60 {
  height: 60px !important; }

.width-60 {
  width: 60px !important; }

.height-70 {
  height: 70px !important; }

.width-70 {
  width: 70px !important; }

.height-80 {
  height: 80px !important; }

.width-80 {
  width: 80px !important; }

.height-90 {
  height: 90px !important; }

.width-90 {
  width: 90px !important; }

.height-100 {
  height: 100px !important; }

.width-100 {
  width: 100px !important; }

.height-110 {
  height: 110px !important; }

.width-110 {
  width: 110px !important; }

.height-120 {
  height: 120px !important; }

.width-120 {
  width: 120px !important; }

.height-130 {
  height: 130px !important; }

.width-130 {
  width: 130px !important; }

.height-140 {
  height: 140px !important; }

.width-140 {
  width: 140px !important; }

.height-150 {
  height: 150px !important; }

.width-150 {
  width: 150px !important; }

.height-160 {
  height: 160px !important; }

.width-160 {
  width: 160px !important; }

.height-170 {
  height: 170px !important; }

.width-170 {
  width: 170px !important; }

.height-180 {
  height: 180px !important; }

.width-180 {
  width: 180px !important; }

.height-190 {
  height: 190px !important; }

.width-190 {
  width: 190px !important; }

.height-200 {
  height: 200px !important; }

.width-200 {
  width: 200px !important; }

.width-70 {
  width: 70px !important; }

.noborder-bottom {
  border-bottom: 0 none transparent !important; }
  .noborder-bottom .heading-title {
    text-align: left; }

.z-index-99 {
  z-index: 99; }

.no-wrap {
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-center {
  text-align: center !important; }

.text-right {
  text-align: right !important; }

.element-flex-start {
  display: flex;
  justify-content: flex-start; }

.element-center {
  display: flex;
  justify-content: center; }

.element-flex-end {
  display: flex;
  justify-content: flex-end; }

.element-space-between {
  display: flex;
  justify-content: space-between; }

.element-space-around {
  display: flex;
  justify-content: space-around; }

.align-flex-start {
  align-items: flex-start;
  display: flex; }

.align-center {
  align-items: center;
  display: flex; }

.align-flex-end {
  align-items: flex-end;
  display: flex; }

.border-top {
  border-top: 1px solid #e4ecf0; }

.border-left {
  border-left: 1px solid #e4ecf0; }

.border-bottom {
  border-bottom: 1px solid #e4ecf0; }

.border-right {
  border-right: 1px solid #e4ecf0; }

.min-width-20 {
  min-width: 20px !important; }

.max-width-20 {
  max-width: 20px !important; }

.pl-20 {
  padding-left: 20px !important; }

.min-width-40 {
  min-width: 40px !important; }

.max-width-40 {
  max-width: 40px !important; }

.pl-40 {
  padding-left: 40px !important; }

.min-width-60 {
  min-width: 60px !important; }

.max-width-60 {
  max-width: 60px !important; }

.pl-60 {
  padding-left: 60px !important; }

.min-width-80 {
  min-width: 80px !important; }

.max-width-80 {
  max-width: 80px !important; }

.pl-80 {
  padding-left: 80px !important; }

.min-width-100 {
  min-width: 100px !important; }

.max-width-100 {
  max-width: 100px !important; }

.pl-100 {
  padding-left: 100px !important; }

.min-width-120 {
  min-width: 120px !important; }

.max-width-120 {
  max-width: 120px !important; }

.pl-120 {
  padding-left: 120px !important; }

.min-width-140 {
  min-width: 140px !important; }

.max-width-140 {
  max-width: 140px !important; }

.pl-140 {
  padding-left: 140px !important; }

.min-width-160 {
  min-width: 160px !important; }

.max-width-160 {
  max-width: 160px !important; }

.pl-160 {
  padding-left: 160px !important; }

.min-width-180 {
  min-width: 180px !important; }

.max-width-180 {
  max-width: 180px !important; }

.pl-180 {
  padding-left: 180px !important; }

.min-width-200 {
  min-width: 200px !important; }

.max-width-200 {
  max-width: 200px !important; }

.pl-200 {
  padding-left: 200px !important; }

.min-width-220 {
  min-width: 220px !important; }

.max-width-220 {
  max-width: 220px !important; }

.pl-220 {
  padding-left: 220px !important; }

.min-width-240 {
  min-width: 240px !important; }

.max-width-240 {
  max-width: 240px !important; }

.pl-240 {
  padding-left: 240px !important; }

.min-width-260 {
  min-width: 260px !important; }

.max-width-260 {
  max-width: 260px !important; }

.pl-260 {
  padding-left: 260px !important; }

.min-width-280 {
  min-width: 280px !important; }

.max-width-280 {
  max-width: 280px !important; }

.pl-280 {
  padding-left: 280px !important; }

.min-width-300 {
  min-width: 300px !important; }

.max-width-300 {
  max-width: 300px !important; }

.pl-300 {
  padding-left: 300px !important; }

.min-width-320 {
  min-width: 320px !important; }

.max-width-320 {
  max-width: 320px !important; }

.pl-320 {
  padding-left: 320px !important; }

.min-width-340 {
  min-width: 340px !important; }

.max-width-340 {
  max-width: 340px !important; }

.pl-340 {
  padding-left: 340px !important; }

.min-width-360 {
  min-width: 360px !important; }

.max-width-360 {
  max-width: 360px !important; }

.pl-360 {
  padding-left: 360px !important; }

.min-width-380 {
  min-width: 380px !important; }

.max-width-380 {
  max-width: 380px !important; }

.pl-380 {
  padding-left: 380px !important; }

.min-width-400 {
  min-width: 400px !important; }

.max-width-400 {
  max-width: 400px !important; }

.pl-400 {
  padding-left: 400px !important; }

.min-width-420 {
  min-width: 420px !important; }

.max-width-420 {
  max-width: 420px !important; }

.pl-420 {
  padding-left: 420px !important; }

.min-width-440 {
  min-width: 440px !important; }

.max-width-440 {
  max-width: 440px !important; }

.pl-440 {
  padding-left: 440px !important; }

.min-width-460 {
  min-width: 460px !important; }

.max-width-460 {
  max-width: 460px !important; }

.pl-460 {
  padding-left: 460px !important; }

.min-width-480 {
  min-width: 480px !important; }

.max-width-480 {
  max-width: 480px !important; }

.pl-480 {
  padding-left: 480px !important; }

.min-width-500 {
  min-width: 500px !important; }

.max-width-500 {
  max-width: 500px !important; }

.pl-500 {
  padding-left: 500px !important; }

.font-size-9 {
  font-size: 9px !important; }

.font-size-10 {
  font-size: 10px !important; }

.font-size-11 {
  font-size: 11px !important; }

.font-size-12 {
  font-size: 12px !important; }

.font-size-13 {
  font-size: 13px !important; }

.font-size-14 {
  font-size: 14px !important; }

.font-size-15 {
  font-size: 15px !important; }

.font-size-16 {
  font-size: 16px !important; }

.font-size-17 {
  font-size: 17px !important; }

.font-size-18 {
  font-size: 18px !important; }

.font-size-19 {
  font-size: 19px !important; }

.font-size-20 {
  font-size: 20px !important; }

.font-size-21 {
  font-size: 21px !important; }

.font-size-22 {
  font-size: 22px !important; }

.font-size-23 {
  font-size: 23px !important; }

.font-size-24 {
  font-size: 24px !important; }

.font-size-25 {
  font-size: 25px !important; }

.font-size-26 {
  font-size: 26px !important; }

.font-size-27 {
  font-size: 27px !important; }

.font-size-28 {
  font-size: 28px !important; }

.font-size-29 {
  font-size: 29px !important; }

.font-size-30 {
  font-size: 30px !important; }

.font-size-31 {
  font-size: 31px !important; }

.font-size-32 {
  font-size: 32px !important; }

.font-size-33 {
  font-size: 33px !important; }

.font-size-34 {
  font-size: 34px !important; }

.font-size-35 {
  font-size: 35px !important; }

.font-size-36 {
  font-size: 36px !important; }

.font-size-37 {
  font-size: 37px !important; }

.font-size-38 {
  font-size: 38px !important; }

.font-size-39 {
  font-size: 39px !important; }

.font-size-40 {
  font-size: 40px !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-grow-2 {
  flex-grow: 2 !important; }

.flex-grow-3 {
  flex-grow: 3 !important; }

.flex-grow-4 {
  flex-grow: 4 !important; }

.flex-grow-5 {
  flex-grow: 5 !important; }

.flex-grow-6 {
  flex-grow: 6 !important; }

.flex-grow-7 {
  flex-grow: 7 !important; }

.flex-grow-8 {
  flex-grow: 8 !important; }

.flex-grow-9 {
  flex-grow: 9 !important; }

.flex-grow-10 {
  flex-grow: 10 !important; }

.flex-grow-11 {
  flex-grow: 11 !important; }

.flex-grow-12 {
  flex-grow: 12 !important; }

.flex-grow-13 {
  flex-grow: 13 !important; }

.flex-grow-14 {
  flex-grow: 14 !important; }

.flex-grow-15 {
  flex-grow: 15 !important; }

.flex-grow-16 {
  flex-grow: 16 !important; }

.flex-grow-17 {
  flex-grow: 17 !important; }

.flex-grow-18 {
  flex-grow: 18 !important; }

.flex-grow-19 {
  flex-grow: 19 !important; }

.flex-grow-20 {
  flex-grow: 20 !important; }

.flex-basis-0 {
  flex-basis: 0% !important; }

.flex-basis-1 {
  flex-basis: 1% !important; }

.flex-basis-2 {
  flex-basis: 2% !important; }

.flex-basis-3 {
  flex-basis: 3% !important; }

.flex-basis-4 {
  flex-basis: 4% !important; }

.flex-basis-5 {
  flex-basis: 5% !important; }

.flex-basis-6 {
  flex-basis: 6% !important; }

.flex-basis-7 {
  flex-basis: 7% !important; }

.flex-basis-8 {
  flex-basis: 8% !important; }

.flex-basis-9 {
  flex-basis: 9% !important; }

.flex-basis-10 {
  flex-basis: 10% !important; }

.flex-basis-11 {
  flex-basis: 11% !important; }

.flex-basis-12 {
  flex-basis: 12% !important; }

.flex-basis-13 {
  flex-basis: 13% !important; }

.flex-basis-14 {
  flex-basis: 14% !important; }

.flex-basis-15 {
  flex-basis: 15% !important; }

.flex-basis-16 {
  flex-basis: 16% !important; }

.flex-basis-17 {
  flex-basis: 17% !important; }

.flex-basis-18 {
  flex-basis: 18% !important; }

.flex-basis-19 {
  flex-basis: 19% !important; }

.flex-basis-20 {
  flex-basis: 20% !important; }

.flex-basis-21 {
  flex-basis: 21% !important; }

.flex-basis-22 {
  flex-basis: 22% !important; }

.flex-basis-23 {
  flex-basis: 23% !important; }

.flex-basis-24 {
  flex-basis: 24% !important; }

.flex-basis-25 {
  flex-basis: 25% !important; }

.flex-basis-26 {
  flex-basis: 26% !important; }

.flex-basis-27 {
  flex-basis: 27% !important; }

.flex-basis-28 {
  flex-basis: 28% !important; }

.flex-basis-29 {
  flex-basis: 29% !important; }

.flex-basis-30 {
  flex-basis: 30% !important; }

.flex-basis-31 {
  flex-basis: 31% !important; }

.flex-basis-32 {
  flex-basis: 32% !important; }

.flex-basis-33 {
  flex-basis: 33% !important; }

.flex-basis-34 {
  flex-basis: 34% !important; }

.flex-basis-35 {
  flex-basis: 35% !important; }

.flex-basis-36 {
  flex-basis: 36% !important; }

.flex-basis-37 {
  flex-basis: 37% !important; }

.flex-basis-38 {
  flex-basis: 38% !important; }

.flex-basis-39 {
  flex-basis: 39% !important; }

.flex-basis-40 {
  flex-basis: 40% !important; }

.flex-basis-41 {
  flex-basis: 41% !important; }

.flex-basis-42 {
  flex-basis: 42% !important; }

.flex-basis-43 {
  flex-basis: 43% !important; }

.flex-basis-44 {
  flex-basis: 44% !important; }

.flex-basis-45 {
  flex-basis: 45% !important; }

.flex-basis-46 {
  flex-basis: 46% !important; }

.flex-basis-47 {
  flex-basis: 47% !important; }

.flex-basis-48 {
  flex-basis: 48% !important; }

.flex-basis-49 {
  flex-basis: 49% !important; }

.flex-basis-50 {
  flex-basis: 50% !important; }

.flex-basis-51 {
  flex-basis: 51% !important; }

.flex-basis-52 {
  flex-basis: 52% !important; }

.flex-basis-53 {
  flex-basis: 53% !important; }

.flex-basis-54 {
  flex-basis: 54% !important; }

.flex-basis-55 {
  flex-basis: 55% !important; }

.flex-basis-56 {
  flex-basis: 56% !important; }

.flex-basis-57 {
  flex-basis: 57% !important; }

.flex-basis-58 {
  flex-basis: 58% !important; }

.flex-basis-59 {
  flex-basis: 59% !important; }

.flex-basis-60 {
  flex-basis: 60% !important; }

.flex-basis-61 {
  flex-basis: 61% !important; }

.flex-basis-62 {
  flex-basis: 62% !important; }

.flex-basis-63 {
  flex-basis: 63% !important; }

.flex-basis-64 {
  flex-basis: 64% !important; }

.flex-basis-65 {
  flex-basis: 65% !important; }

.flex-basis-66 {
  flex-basis: 66% !important; }

.flex-basis-67 {
  flex-basis: 67% !important; }

.flex-basis-68 {
  flex-basis: 68% !important; }

.flex-basis-69 {
  flex-basis: 69% !important; }

.flex-basis-70 {
  flex-basis: 70% !important; }

.flex-basis-71 {
  flex-basis: 71% !important; }

.flex-basis-72 {
  flex-basis: 72% !important; }

.flex-basis-73 {
  flex-basis: 73% !important; }

.flex-basis-74 {
  flex-basis: 74% !important; }

.flex-basis-75 {
  flex-basis: 75% !important; }

.flex-basis-76 {
  flex-basis: 76% !important; }

.flex-basis-77 {
  flex-basis: 77% !important; }

.flex-basis-78 {
  flex-basis: 78% !important; }

.flex-basis-79 {
  flex-basis: 79% !important; }

.flex-basis-80 {
  flex-basis: 80% !important; }

.flex-basis-81 {
  flex-basis: 81% !important; }

.flex-basis-82 {
  flex-basis: 82% !important; }

.flex-basis-83 {
  flex-basis: 83% !important; }

.flex-basis-84 {
  flex-basis: 84% !important; }

.flex-basis-85 {
  flex-basis: 85% !important; }

.flex-basis-86 {
  flex-basis: 86% !important; }

.flex-basis-87 {
  flex-basis: 87% !important; }

.flex-basis-88 {
  flex-basis: 88% !important; }

.flex-basis-89 {
  flex-basis: 89% !important; }

.flex-basis-90 {
  flex-basis: 90% !important; }

.flex-basis-91 {
  flex-basis: 91% !important; }

.flex-basis-92 {
  flex-basis: 92% !important; }

.flex-basis-93 {
  flex-basis: 93% !important; }

.flex-basis-94 {
  flex-basis: 94% !important; }

.flex-basis-95 {
  flex-basis: 95% !important; }

.flex-basis-96 {
  flex-basis: 96% !important; }

.flex-basis-97 {
  flex-basis: 97% !important; }

.flex-basis-98 {
  flex-basis: 98% !important; }

.flex-basis-99 {
  flex-basis: 99% !important; }

.flex-basis-100 {
  flex-basis: 100% !important; }

.flex-form {
  display: flex; }
  .flex-form > :last-child {
    flex: 1; }

.has-new {
  position: relative; }
  .has-new::after {
    background: #db3737;
    content: '';
    height: 8px;
    position: absolute;
    right: -12px;
    top: -4px;
    width: 8px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%; }

.fix-border-bottom > .pt-tab-list {
  margin-bottom: -1px; }

.font-roboto {
  font-family: "Roboto", sans-serif; }

.border-none {
  border: 0 !important; }

.uppercase {
  text-transform: uppercase; }

.text-success {
  color: #0f9960; }

.text-primary {
  color: #195fff; }

.text-black {
  color: #000; }

.text-yellow {
  color: #fc7e00; }

.vertical-align-baseline {
  vertical-align: baseline; }

.vertical-align-bottom {
  vertical-align: bottom; }

.vertical-align-middle {
  vertical-align: middle; }

.vertical-align-sub {
  vertical-align: sub; }

.vertical-align-text-bottom {
  vertical-align: text-bottom; }

.vertical-align-text-top {
  vertical-align: text-top; }

.vertical-align-top {
  vertical-align: top; }

.line-clamp-2 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis; }

.line-clamp-3 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis; }

.white-space-nowrap {
  white-space: nowrap; }

.white-space-pre {
  white-space: pre; }

.white-space-pre-wrap {
  white-space: pre-wrap; }

.white-space-break-spaces {
  white-space: break-spaces; }

.white-space-pre-line {
  white-space: pre-line; }

@media only screen and (max-width: 768px) {
  .tablet-width-full {
    flex: 1;
    flex-basis: 100%; } }

.border-radius-4 {
  border-radius: 4px; }

.border-radius-8 {
  border-radius: 8px; }

.border-radius-10 {
  border-radius: 10px; }

.rotate-45 {
  transform: rotate(45deg); }

.rotate-90 {
  transform: rotate(90deg); }

.rotate-120 {
  transform: rotate(120deg); }

.rotate-180 {
  transform: rotate(180deg); }

.rotate-270 {
  transform: rotate(270deg); }

.rotate-360 {
  transform: rotate(360deg); }

.cursor-pointer {
  cursor: pointer; }

.cursor-default {
  cursor: default; }

.cursor-not-allowed {
  cursor: not-allowed; }

.cursor-move {
  cursor: move; }

.cursor-progress {
  cursor: progress; }

.btn-action {
  text-decoration: none;
  background-color: #195fff !important;
  border: 1px solid #0344d7;
  -webkit-box-shadow: 0 2px 1px 0 #b0c9ff;
  background-image: none !important;
  box-shadow: none !important;
  color: #fff;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  min-height: 40px;
  min-width: 164px;
  line-height: 40px;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  white-space: nowrap;
  text-align: center; }
  .btn-action .pt-icon {
    line-height: 40px;
    padding-left: 10px; }
    .btn-action .pt-icon:before {
      color: #fff;
      font-size: 18px; }
  .btn-action .name-btn {
    font-family: "Roboto-medium", sans-serif;
    font-size: 12px;
    color: #fff;
    letter-spacing: 0;
    text-align: left;
    text-transform: uppercase; }
  .btn-action.btn-addnew {
    min-width: 110px; }
  .btn-action:hover {
    text-decoration: none; }
  .btn-action:not(.btn-disabled):hover {
    background: #0344d7 !important; }
  .btn-action.btn-action-header {
    min-width: 110px;
    padding: 0 12px;
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center; }

.btn-edit-blue,
a.btn-edit-blue {
  background-color: #195fff;
  border: 1px solid #0344d7;
  box-shadow: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  height: 35px;
  line-height: 1.4;
  min-width: 120px;
  padding: 4px 10px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  white-space: nowrap; }
  .btn-edit-blue .cpc-icon-left,
  a.btn-edit-blue .cpc-icon-left {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    font-size: 20px;
    margin-right: 6px;
    margin-top: -2px;
    vertical-align: top; }
  .btn-edit-blue .text-content,
  a.btn-edit-blue .text-content {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    margin-top: 2px;
    vertical-align: top; }
  @media only screen and (min-width: 1024px) {
    .btn-edit-blue:hover,
    a.btn-edit-blue:hover {
      background-color: #0046e5; } }

.buttton-action-footer {
  border-top: 1px solid rgba(151, 151, 151, 0.21);
  text-align: center;
  padding: 15px 0px; }
  .buttton-action-footer .button-default .pt-icon {
    font-size: 10px; }
  .buttton-action-footer .pt-button {
    border: 1px solid #b3b9c5;
    box-shadow: 0 2px 1px 0 rgba(205, 212, 228, 0.27);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-family: "Roboto-medium", sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    margin-right: 20px;
    padding: 4px 0 4px 12px !important;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap; }
    .buttton-action-footer .pt-button:last-child {
      margin-right: 0; }
    .buttton-action-footer .pt-button:hover {
      box-shadow: none;
      -webkit-box-shadow: none; }
  .buttton-action-footer .btn-edit {
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    background: #fff;
    color: #6a7489;
    white-space: nowrap; }
    .buttton-action-footer .btn-edit .pt-icon {
      color: #6a7489; }
    .buttton-action-footer .btn-edit:last-child {
      margin-right: 0; }
  .buttton-action-footer .btn-cancel,
  .buttton-action-footer .btn-cancle {
    background: #fff;
    color: #6a7489;
    min-width: 105px;
    padding: 4px 12px 4px 10px !important;
    text-align: center;
    white-space: nowrap; }
    .buttton-action-footer .btn-cancel .pt-icon,
    .buttton-action-footer .btn-cancle .pt-icon {
      color: #6a7489; }
    .buttton-action-footer .btn-cancel .icon-back,
    .buttton-action-footer .btn-cancle .icon-back {
      margin-left: 0;
      margin-right: 6px; }
    .buttton-action-footer .btn-cancel:last-child,
    .buttton-action-footer .btn-cancle:last-child {
      margin-right: 0; }
    .buttton-action-footer .btn-cancel .text-content,
    .buttton-action-footer .btn-cancle .text-content {
      text-transform: uppercase;
      color: #6a7489;
      font-size: 12px; }
    .buttton-action-footer .btn-cancel:hover,
    .buttton-action-footer .btn-cancle:hover {
      background-color: #eff3f5; }
  .buttton-action-footer .btn-blue-color {
    background: #195fff;
    border-color: #195fff;
    color: #fff;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    white-space: nowrap; }
    .buttton-action-footer .btn-blue-color .pt-icon:before {
      color: #fff; }
    .buttton-action-footer .btn-blue-color:not(.btn-disabled):hover {
      background: #0344d7;
      color: #fff; }
    .buttton-action-footer .btn-blue-color:last-child {
      margin-right: 0; }
  .buttton-action-footer .btn-save {
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    background: #195fff;
    color: #fff;
    white-space: nowrap; }
    .buttton-action-footer .btn-save .pt-icon:before {
      color: #fff; }
    .buttton-action-footer .btn-save:not(.btn-disabled):hover {
      background: #0344d7;
      color: #fff; }
    .buttton-action-footer .btn-save:last-child {
      margin-right: 0; }
  .buttton-action-footer .btn-view {
    text-align: center;
    padding: 4px 15px !important;
    background: #fff;
    color: #6a7489;
    white-space: nowrap; }
    .buttton-action-footer .btn-view .pt-icon {
      color: #6a7489; }
    .buttton-action-footer .btn-view:last-child {
      margin-right: 0; }
  .buttton-action-footer .pt-icon {
    margin-left: 12px;
    margin-right: 12px; }
  .buttton-action-footer .icon-Giao_viec:before {
    color: #fff;
    font-size: 20px;
    position: relative;
    top: 4px;
    left: -5px; }
  .buttton-action-footer .btn-cancel-steering {
    border-color: #ea5744;
    color: #ea5744;
    margin-left: 30px; }
    .buttton-action-footer .btn-cancel-steering .pt-icon {
      color: #ea5744;
      margin-top: -2px; }
  .buttton-action-footer .btn-confirm-comment {
    border-color: #0344d7;
    color: #0344d7;
    margin-left: 30px; }
    .buttton-action-footer .btn-confirm-comment .pt-icon {
      color: #0344d7;
      margin-top: -2px; }

.button-gv .btn-action:not(:last-child) {
  margin-right: 15px; }

.button-gv .name-btn {
  margin-right: 8px; }

.button-gv .icon-Tu_Tai_Lieu:before,
.button-gv .pt-icon-tick:before {
  color: #fff;
  font-size: 13px; }

.btn-icon-collapse {
  background: none transparent;
  box-shadow: none;
  border: 0 none transparent;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  padding: 10px;
  width: 32px;
  cursor: pointer; }

.btn-open-aside-nav-rpw {
  background-color: #fff;
  border: 0 none transparent;
  box-shadow: none;
  color: #195fff;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  font-family: "Roboto-medium", sans-serif;
  font-size: 14px;
  height: 50px;
  margin: 0;
  padding: 10px 45px 10px 15px;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  width: 100%; }
  .btn-open-aside-nav-rpw .icon-right {
    font-size: 16px;
    position: absolute;
    right: 22px;
    top: 15px; }
    .btn-open-aside-nav-rpw .icon-right:before {
      color: #969696; }

.pt-button-back {
  background-color: #eff3f5;
  box-shadow: 0 2px 1px 0 rgba(206, 213, 229, 0.27);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  min-width: 102px;
  min-height: 30px; }
  .pt-button-back .text-content {
    text-transform: uppercase;
    color: #6a7489;
    font-size: 12px; }

.btn-add-small {
  background-color: #eff3f5;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  color: #6a7489;
  font-family: "Roboto-medium", sans-serif;
  font-size: 10px;
  line-height: 1.4;
  min-width: 102px;
  min-height: 26px;
  padding: 2px 6px; }
  .btn-add-small .text-content {
    color: #6a7489; }

.pt-viewdetail {
  background-color: #fff !important;
  background-image: none !important;
  color: #6a7489 !important;
  box-shadow: 0 2px 1px 0 rgba(206, 213, 229, 0.27);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-family: "Roboto-medium", sans-serif;
  font-size: 12px;
  min-width: 140px;
  height: 40px;
  text-transform: uppercase;
  white-space: nowrap; }
  .pt-viewdetail .icon-Xemthuchien {
    font-size: 11px;
    margin-left: 8px;
    margin-right: 0; }
    .pt-viewdetail .icon-Xemthuchien:before {
      color: #6a7489; }

.buttton-actionfooter-fixtop {
  position: fixed;
  top: 100px;
  right: 0;
  z-index: 1;
  border-top: 0 !important; }
  .buttton-actionfooter-fixtop .pt-button {
    display: block;
    margin: 0 0 5px 0;
    min-width: 40px;
    padding: 4px 5px !important;
    text-align: center; }
    .buttton-actionfooter-fixtop .pt-button .pt-icon {
      margin: 0; }
    .buttton-actionfooter-fixtop .pt-button .text-content {
      display: none; }
  .buttton-actionfooter-fixtop .btn-blue-color {
    width: 100%; }
    .buttton-actionfooter-fixtop .btn-blue-color .icon-save:before {
      color: #fff; }
    .buttton-actionfooter-fixtop .btn-blue-color .pt-icon:before {
      color: #fff; }

.btn-more {
  background: #fff;
  border: 1px solid #d2d2d2;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  min-width: 83px;
  height: 28px;
  box-shadow: none; }
  .btn-more .text-content {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 12px;
    color: #6a7489;
    text-align: center; }

.button-link-chat {
  padding-top: 5px;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  border: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  height: 36px;
  line-height: 1; }
  .button-link-chat:hover {
    background-color: rgba(0, 0, 0, 0.14); }
  .button-link-chat.digital {
    width: 72px; }
    .button-link-chat.digital .icon2-digital {
      font-size: 22px; }

.btn-success {
  background: #6bc779 !important;
  color: #fff !important;
  white-space: nowrap;
  border-radius: 3px;
  min-width: 100px;
  min-height: 38px;
  margin-right: 10px;
  text-transform: uppercase;
  font-family: "Roboto-medium", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  text-align: center;
  border: none !important; }
  .btn-success .pt-icon:before {
    color: #fff; }

.text-heading-page .pt-button {
  border: 1px solid #b3b9c5;
  box-shadow: 0 2px 1px 0 rgba(205, 212, 228, 0.27);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-family: "Roboto-medium", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  margin-right: 20px;
  padding: 4px 0 4px 12px !important;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap; }
  .text-heading-page .pt-button:last-child {
    margin-right: 0; }
  .text-heading-page .pt-button:hover {
    box-shadow: none;
    -webkit-box-shadow: none; }

.text-heading-page .btn-cancel {
  background: #fff;
  color: #6a7489;
  min-width: 105px;
  padding: 4px 12px 4px 10px !important;
  text-align: center;
  white-space: nowrap; }
  .text-heading-page .btn-cancel .pt-icon {
    color: #6a7489; }
  .text-heading-page .btn-cancel .icon-back {
    margin-left: 0;
    margin-right: 6px; }
  .text-heading-page .btn-cancel .text-content {
    text-transform: uppercase;
    color: #6a7489;
    font-size: 12px; }

.btn-opinion-sign {
  padding: 0 10px !important;
  min-width: 125px; }
  .btn-opinion-sign .pt-icon {
    margin-left: 5px !important;
    vertical-align: text-top; }

.text-uppercase {
  text-transform: uppercase; }

.btn-disabled {
  outline: none;
  background-color: #ced9e0 !important;
  border-color: #ced9e0;
  cursor: not-allowed !important; }
  .btn-disabled > span {
    color: #6a7489 !important; }
    .btn-disabled > span::before {
      color: #6a7489 !important; }

.btn-minwidth-auto {
  min-width: auto !important;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 37px;
  line-height: 37px; }
  .btn-minwidth-auto .pt-icon {
    min-height: 36px;
    line-height: 36px; }

table.pt-table tbody tr:first-child td {
  box-shadow: none; }

.table-container {
  background: #fff;
  border: 1px solid #dfe6ea;
  padding-bottom: 20px;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  position: relative;
  z-index: 1; }
  .table-container .info-alertMistake {
    color: #0344d7;
    font-weight: bold; }
  .table-container .view-file-attach {
    font-size: 22px;
    color: #195fff;
    cursor: pointer; }
    .table-container .view-file-attach:hover {
      color: #195fff; }
  .table-container .icon-type-work:before {
    font-size: 20px; }
  .table-container .icon-type-work.icon-attention-warning:before {
    font-size: 16px; }
  .table-container .style-icon-DocumentCabinet {
    font-size: 17px; }
    .table-container .style-icon-DocumentCabinet:before {
      color: #7f7f7f; }
  .table-container .text-center {
    text-align: center !important; }
  .table-container .text-url {
    color: #195fff; }
  .table-container .font-medium {
    font-family: 'Roboto-Medium'; }
  .table-container .table-scroll {
    width: 100%;
    overflow-x: auto; }
    .table-container .table-scroll .tr_tbody {
      color: #0344d7; }
  .table-container .break-word-content {
    min-width: 250px;
    word-break: keep-all; }
  .table-container .no-border-bt {
    border-bottom: none !important; }
  .table-container .table-scroll-hidden {
    overflow: hidden;
    width: auto; }
    .table-container .table-scroll-hidden .pt-table {
      width: 100%;
      min-width: auto; }
  .table-container .colum-default-width {
    min-width: 200px; }
  .table-container .colum-adress-send {
    width: 250px; }
  .table-container .colum-date-send,
  .table-container .colum-date-update,
  .table-container .colum-number-documentary {
    width: 100px; }
  .table-container .colum-establishment {
    width: 180px; }
  .table-container .colum-receive-number {
    min-width: 50px; }
  .table-container .colum-first-icon {
    width: 15px; }
  .table-container .colum-stt-first {
    width: 10px; }
  .table-container .colum-Leadership {
    width: 180px; }
  .table-container .column-content-alert-mistake {
    min-width: 300px; }
  .table-container .colum-administrator {
    width: 200px; }
  .table-container .colum-assign {
    min-width: 140px;
    width: 24%; }
  .table-container .colum-leader,
  .table-container .colum-organ-enact,
  .table-container .colum-reception-room {
    width: 200px; }
  .table-container .colum-command {
    width: 300px; }
  .table-container .colum-number-copies {
    width: 100px; }
  .table-container .colum-action {
    width: 5px; }
  .table-container .colum-role {
    width: 20px; }
  .table-container .colum-count-deparkment {
    width: 100px;
    text-align: center !important; }
  .table-container .colum-date-assign {
    width: 90px; }
  .table-container .colum-datetime {
    width: 82px; }
  .table-container .colum-name-document {
    min-width: 300px; }
  .table-container .colum-role-savedocument {
    min-width: 100px; }
  .table-container .colum-datetime {
    position: relative;
    min-width: 82px;
    white-space: normal !important; }
    .table-container .colum-datetime .pt-icon-chevron-down,
    .table-container .colum-datetime .pt-icon-chevron-up {
      top: calc(50% - 5px);
      position: absolute;
      right: 0; }
  .table-container .colum-file {
    width: 55px;
    text-align: center; }
  .table-container .colum-days-remaining {
    position: relative;
    min-width: 80px;
    white-space: normal !important; }
    .table-container .colum-days-remaining .pt-icon-chevron-down,
    .table-container .colum-days-remaining .pt-icon-chevron-up {
      top: calc(50% - 5px);
      position: absolute;
      right: 0; }
  .table-container .colum-days-remaining {
    width: 70px; }
  .table-container .colum-number-symbol {
    min-width: 70px; }
  .table-container .colum-the-signer {
    width: 150px; }
  .table-container .colum-date,
  .table-container .colum-date-documentary,
  .table-container .colum-department-constitutive,
  .table-container .colum-status {
    width: 100px; }
  .table-container table {
    width: 100%; }
  .table-container .data-emty {
    cursor: default;
    padding: 72px 20px;
    font-family: "Roboto-medium", sans-serif;
    font-size: 20px; }
  .table-container .pt-table .pt-popover-target {
    font-size: 28px;
    line-height: 1; }
    .table-container .pt-table .pt-popover-target .status-no-process, .table-container .pt-table .pt-popover-target .no-process, .table-container .pt-table .pt-popover-target .lddv-chua-chi-dao, .table-container .pt-table .pt-popover-target .lddv-chua-giao, .table-container .pt-table .pt-popover-target .ldpb-chuaduyet, .table-container .pt-table .pt-popover-target .thu-ky-chuaduyet, .table-container .pt-table .pt-popover-target .lddv-chuaduyet,
    .table-container .pt-table .pt-popover-target .status-process,
    .table-container .pt-table .pt-popover-target .process,
    .table-container .pt-table .pt-popover-target .dang-gop-y {
      margin-top: 0; }
  .table-container .pt-table tr {
    border-bottom: 1px solid rgba(210, 210, 210, 0.5); }
    .table-container .pt-table tr:nth-child(even) {
      background: #f8f9f9; }
    .table-container .pt-table tr:focus {
      -webkit-transform: translateX(-3px);
      transform: translateX(-3px);
      -webkit-box-shadow: 0 8px 21px 0 rgba(199, 199, 199, 0.21);
      box-shadow: 0 8px 21px 0 rgba(199, 199, 199, 0.21); }
    .table-container .pt-table tr .status-complete:before {
      font-size: 18px;
      position: relative;
      top: 2px; }
    .table-container .pt-table tr a {
      text-decoration: none; }
    .table-container .pt-table tr .status-complete {
      font-size: 18px; }
      .table-container .pt-table tr .status-complete .icon-Giao-viec-moi {
        font-size: 22px; }
      .table-container .pt-table tr .status-complete:hover .icon-Hoan_Thanh:before,
      .table-container .pt-table tr .status-complete:hover .icon-Giao-viec-moi:before {
        color: #6bc779; }
    .table-container .pt-table tr .pt-checkbox {
      padding-left: 15px;
      margin-bottom: 0; }
  .table-container .pt-table .style-qh {
    background: #f76262; }
  .table-container .pt-table .style-gqh {
    background: #f7ca18; }
  .table-container .pt-table .style-pbct {
    color: #195fff; }
  .table-container .pt-table .style-color {
    color: #454a52 !important; }
  .table-container .pt-table .deadline-date {
    -webkit-border-radius: 13px;
    -moz-border-radius: 13px;
    border-radius: 13px;
    color: #fff;
    padding: 2px 16px; }
  .table-container .pt-table td {
    cursor: pointer;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    color: #454a52;
    letter-spacing: 0;
    line-height: 1.4;
    padding: 8px 5px 5px;
    text-align: left;
    vertical-align: top; }
    .table-container .pt-table td .check-star {
      color: #ffdc79;
      display: -moz-inline-stack;
      display: inline-block;
      zoom: 1;
      *display: inline;
      vertical-align: top; }
      .table-container .pt-table td .check-star::before {
        font-size: 16px; }
    .table-container .pt-table td .icon-More:hover:before {
      color: #195fff; }
    .table-container .pt-table td .assign-content {
      font-style: italic; }
    .table-container .pt-table td:first-child {
      text-align: center;
      padding-left: 10px; }
    .table-container .pt-table td:last-child {
      padding-right: 10px; }
    .table-container .pt-table td.text-nowrap {
      white-space: nowrap; }
      .table-container .pt-table td.text-nowrap.text-nowrap-primary {
        color: #195fff; }
  .table-container .pt-table .count-department {
    padding-left: 5px; }
  .table-container .pt-table thead tr {
    background: #195fff; }
  .table-container .pt-table thead th {
    min-height: 30px;
    white-space: nowrap;
    padding: 10px 8px;
    vertical-align: middle; }
    .table-container .pt-table thead th.white-space-normal {
      white-space: normal; }
    .table-container .pt-table thead th .text-sort {
      color: #fff;
      font-family: "Roboto-medium", sans-serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 1.6;
      letter-spacing: 0;
      text-align: left;
      text-transform: uppercase; }
    .table-container .pt-table thead th:first-child {
      text-align: center; }
    .table-container .pt-table thead th:last-child {
      padding-right: 20px; }
    .table-container .pt-table thead th .pt-icon {
      color: #fff;
      padding-left: 7px;
      cursor: pointer; }
    .table-container .pt-table thead th .pt-checkbox {
      margin-top: 6px; }
  .table-container.table-subcollap-container {
    padding-bottom: 10px; }
    .table-container.table-subcollap-container .pt-table thead th:first-child {
      padding-left: 40px;
      text-align: left; }
    .table-container.table-subcollap-container .pt-table .subtable-title {
      color: #454a52;
      font-family: "Roboto-Bold", sans-serif;
      font-size: 14px; }
    .table-container.table-subcollap-container .pt-table td.subtable-content {
      border: 0 none transparent;
      padding: 0; }
    .table-container.table-subcollap-container .pt-table .subtable-content tbody tr:first-child td {
      box-shadow: none; }
    .table-container.table-subcollap-container .pt-table td.collapse-heading-content {
      background-color: #f8f9f9;
      box-shadow: none;
      color: #454a52;
      font-family: "Roboto-Bold", sans-serif;
      font-size: 14px;
      padding: 8px 40px 8px 20px;
      position: relative;
      text-align: left; }
      .table-container.table-subcollap-container .pt-table td.collapse-heading-content .btn-icon-collapse {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        margin: auto;
        position: absolute;
        right: 10px; }
        .table-container.table-subcollap-container .pt-table td.collapse-heading-content .btn-icon-collapse .pt-icon {
          color: #195fff; }
          .table-container.table-subcollap-container .pt-table td.collapse-heading-content .btn-icon-collapse .pt-icon:before {
            color: #195fff; }
    .table-container.table-subcollap-container .pt-table td.sub-collapse-heading-content {
      padding-left: 40px; }
    .table-container.table-subcollap-container .footer-list {
      margin-top: 10px; }
    .table-container.table-subcollap-container .subtable-content .pt-table th:first-child,
    .table-container.table-subcollap-container td:first-child {
      padding-left: 40px;
      text-align: left; }
  .table-container .text-center-col {
    text-align: center !important; }
  .table-container .status-job {
    margin: 0; }

.table-container .cursor-default td {
  cursor: default; }

.table-multiple-header .pt-table thead tr th {
  border: 1px solid #fff;
  border-left: none;
  text-align: center; }

.table-multiple-header .pt-table thead tr:nth-child(2) {
  background: #195fff; }

.pt-popover-content-sizing .pt-popover-content {
  padding: 0 !important; }

.menu-popover .item {
  padding: 10px 15px;
  display: block;
  text-decoration: none;
  color: #454a52;
  cursor: pointer; }
  .menu-popover .item .name_action {
    font-family: "Roboto-medium", sans-serif;
    font-size: 12px; }
  .menu-popover .item .pt-icon {
    padding-right: 10px; }
  .menu-popover .item:hover {
    background-color: #195fff;
    color: #fff; }
    .menu-popover .item:hover .pt-icon::before {
      color: #fff !important; }
    .menu-popover .item:hover .icon,
    .menu-popover .item:hover *[class^='icon-'],
    .menu-popover .item:hover *[class*=' icon-'],
    .menu-popover .item:hover *[class^='pt-icon-'],
    .menu-popover .item:hover *[class*=' pt-icon-'] {
      color: #fff; }
      .menu-popover .item:hover .icon::before,
      .menu-popover .item:hover *[class^='icon-']::before,
      .menu-popover .item:hover *[class*=' icon-']::before,
      .menu-popover .item:hover *[class^='pt-icon-']::before,
      .menu-popover .item:hover *[class*=' pt-icon-']::before {
        color: #fff !important; }

.style-qh {
  color: #f76262; }

.style-pbct {
  color: #195fff; }

.style-gqh {
  color: #f7ca18; }

.style-qh-fix {
  margin: 5px; }
  .style-qh-fix::before {
    font-size: 20px !important; }

.pt-popover-table {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0 6px 19px 0 rgba(16, 22, 26, 0.12);
  min-width: 568px; }

.group-table-DepartmentPerform .pt-table {
  width: 100%;
  z-index: 0; }

.department-perform-table {
  overflow: auto;
  margin-bottom: 12px; }
  .department-perform-table .table-container {
    z-index: 0; }
  .department-perform-table .pt-table {
    width: 100%; }
  .department-perform-table .heading-table {
    border-bottom: 1px solid #dfe6ea; }
    .department-perform-table .heading-table th {
      font-family: "Roboto-Bold", sans-serif; }
    .department-perform-table .heading-table td,
    .department-perform-table .heading-table th {
      font-size: 12px;
      letter-spacing: 0;
      min-width: 66px; }
      .department-perform-table .heading-table td .pt-control,
      .department-perform-table .heading-table th .pt-control {
        margin-top: 0; }
      .department-perform-table .heading-table td:nth-child(1),
      .department-perform-table .heading-table th:nth-child(1) {
        min-width: 160px; }
      .department-perform-table .heading-table td:nth-child(2),
      .department-perform-table .heading-table th:nth-child(2) {
        min-width: 66px; }
    .department-perform-table .heading-table .head-table-checkall-content {
      white-space: nowrap; }
      .department-perform-table .heading-table .head-table-checkall-content .checkall,
      .department-perform-table .heading-table .head-table-checkall-content .text-content {
        display: -moz-inline-stack;
        display: inline-block;
        zoom: 1;
        *display: inline;
        vertical-align: middle; }
      .department-perform-table .heading-table .head-table-checkall-content .checkall {
        margin-bottom: 0; }
  .department-perform-table tbody td {
    padding: 8px 10px 6px;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    color: #454b52;
    letter-spacing: 0; }
    .department-perform-table tbody td:first-child {
      padding-left: 15px;
      text-align: left !important;
      width: 48%; }
  .department-perform-table tbody tr:nth-child(even) {
    background: #ebebeb;
    border: 1px solid #dfe6ea; }
  .department-perform-table tbody .item-checkbox,
  .department-perform-table tbody .item-radio {
    display: inline;
    margin-top: 0;
    margin-bottom: 0;
    min-height: 16px;
    padding-left: 10px; }
  .department-perform-table .pt-control .pt-control-indicator {
    -webkit-box-shadow: inset 0 0 0 1px #717171;
    box-shadow: inset 0 0 0 1px #717171; }

.table-status {
  width: 100%;
  display: table; }
  .table-status .heading-table {
    display: table-row;
    height: 63px;
    vertical-align: middle; }
    .table-status .heading-table p {
      font-family: "Roboto-medium", sans-serif;
      font-size: 12px;
      color: #4c4e53;
      letter-spacing: 0;
      text-align: left;
      text-transform: uppercase; }
  .table-status .row-table {
    display: table-row;
    height: 46px; }
    .table-status .row-table p {
      font-family: "Roboto-Regular", sans-serif;
      font-size: 13px;
      color: #454a52;
      letter-spacing: 0;
      text-align: left; }
  .table-status .colum-stt {
    width: 10px; }
  .table-status .colum-datetime {
    width: 130px; }
  .table-status .colum-status {
    min-width: 100px;
    max-width: 140px; }
  .table-status .cell-table {
    white-space: nowrap;
    padding: 0 20px;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    border-bottom: 1px solid rgba(227, 228, 232, 0.34); }
    .table-status .cell-table .btn-edit {
      color: #6a7489;
      background: #fff;
      white-space: nowrap; }
  .table-status .text-center {
    text-align: center !important; }
  .table-status .column-department {
    min-width: 100px; }
    .table-status .column-department .department-name {
      font-family: "Roboto-medium", sans-serif; }
    .table-status .column-department .department-comment {
      font-size: 13px; }

.click-dropdown {
  cursor: pointer; }

.read-more {
  text-align: center;
  padding: 10px 0; }
  .read-more a {
    text-decoration: none; }
    .read-more a:hover .pt-icon:before {
      color: #195fff; }
    .read-more a:hover .text {
      color: #195fff; }
  .read-more .pt-icon:before {
    color: #454a52; }
  .read-more .text {
    padding-left: 5px;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    color: #454a52; }

.unprocessor {
  text-transform: uppercase; }

.lddv-chua-chi-dao {
  background: white !important; }

.lddv-chua-giao {
  background: white !important; }

.status-job {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  min-width: 115px;
  padding: 0px 5px;
  min-height: 18px;
  line-height: 18px;
  font-family: "Roboto-Bold", sans-serif;
  font-size: 9px;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  right: 0;
  float: left; }

/* table custome */
.collapse-block .collapse-heading-content {
  border-bottom: 1px solid rgba(102, 102, 102, 0.2);
  position: relative; }
  .collapse-block .collapse-heading-content .subtable-title,
  .collapse-block .collapse-heading-content .table-title {
    background-color: #f8f9f9;
    color: #454a52;
    font-family: "Roboto-Bold", sans-serif;
    font-size: 14px;
    padding: 12px 40px 12px 20px; }
  .collapse-block .collapse-heading-content .subtable-title {
    padding-left: 40px; }
  .collapse-block .collapse-heading-content .btn-icon-collapse {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: auto;
    position: absolute;
    right: 10px; }
    .collapse-block .collapse-heading-content .btn-icon-collapse .pt-icon {
      color: #195fff; }
      .collapse-block .collapse-heading-content .btn-icon-collapse .pt-icon:before {
        color: #195fff; }

.table-head-container {
  border-bottom: 1px solid rgba(102, 102, 102, 0.2);
  display: table;
  width: 100%;
  background: #195fff; }
  .table-head-container .head-label {
    font-family: "Roboto-medium", sans-serif;
    font-size: 12px;
    color: #fff;
    letter-spacing: 0;
    text-align: left;
    font-weight: 500 !important; }
  .table-head-container .table-cols-content {
    color: #454a52;
    font-family: "Roboto-medium", sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 31px;
    min-height: 54px;
    padding: 12px 10px 6px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap; }
    .table-head-container .table-cols-content:last-child {
      padding-right: 20px; }
    .table-head-container .table-cols-content .pt-icon {
      color: #fff;
      padding-left: 7px;
      cursor: pointer; }
    .table-head-container .table-cols-content .pt-checkbox {
      margin-top: 6px; }

.table-display {
  display: table;
  table-layout: fixed;
  width: 100%; }
  .table-display .table-cols-content {
    color: #454a52;
    display: table-cell;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 1.4;
    padding: 12px;
    text-align: left;
    vertical-align: top; }
    .table-display .table-cols-content:first-child {
      padding-left: 40px; }
    .table-display .table-cols-content .icon-More:before {
      font-size: 20px; }
  .table-display .width-col-1 {
    width: 1%; }
  .table-display .width-col-2 {
    width: 2%; }
  .table-display .width-col-3 {
    width: 3%; }
  .table-display .width-col-5 {
    width: 5%; }
  .table-display .width-col-10 {
    width: 10%; }
  .table-display .width-col-15 {
    width: 15%; }
  .table-display .width-col-20 {
    width: 20%; }
  .table-display .width-col-25 {
    width: 25%; }
  .table-display .width-col-30 {
    width: 30%; }
  .table-display .width-col-35 {
    width: 35%; }
  .table-display .width-col-40 {
    width: 40%; }
  .table-display .width-col-45 {
    width: 45%; }
  .table-display .width-col-50 {
    width: 50%; }
  .table-display .width-col-55 {
    width: 55%; }
  .table-display .width-col-60px {
    width: 60px; }
  .table-display .table-rows {
    border-bottom: 1px solid #e3e4e8;
    cursor: pointer;
    display: table;
    width: 100%; }
    .table-display .table-rows.table-row-nolink {
      cursor: default; }
    .table-display .table-rows:nth-child(even) {
      background: #f8f9f9; }

/* table custome .end */
.pt-interactive tbody tr .error-table-hover {
  cursor: default; }
  .pt-interactive tbody tr .error-table-hover .error-page-container {
    cursor: default; }

.pt-interactive tbody tr:hover .error-table-hover {
  background: #fff !important; }

.no-scroll-DepartmentPerform .heading-table td:nth-child(1),
.no-scroll-DepartmentPerform .heading-table th:nth-child(1) {
  min-width: calc(100% - 80px); }

.table-link {
  display: block;
  color: #454a52;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 8px 5px 8px; }
  .table-link:hover {
    color: #454a52; }

.fix-padding {
  padding-right: 4px !important;
  padding-left: 4px !important; }
  .fix-padding .pt-select select {
    padding: 0 10px 0 5px; }

.pointer {
  cursor: pointer; }

.custom-checkbox .pt-checkbox {
  margin-top: 0px !important; }

.custom-checkbox .pt-control-indicator {
  color: #195fff !important; }

.custom-checkbox .pt-control-indicator:before {
  background: transparent !important; }

.table-middle tr td,
.table-middle tr th {
  vertical-align: middle !important;
  cursor: default !important; }

.table-middle tr td.pointer {
  cursor: pointer !important; }

.pt-table.middle tr th,
.pt-table.middle tr td {
  vertical-align: middle; }

.tr-default td {
  cursor: default !important; }

.new-table-style {
  display: table;
  background: #fff; }
  .new-table-style .row {
    display: table;
    width: 100%;
    margin: 0; }
    .new-table-style .row .cell {
      display: table-cell;
      padding: 10px;
      font-size: 13px; }
      .new-table-style .row .cell:not(:empty) {
        border-bottom: 1px solid #e3e4e8; }
    .new-table-style .row.parent {
      border-top: 1px solid #e3e4e8;
      border-bottom: 1px solid #e3e4e8;
      background: #f8f9f9; }
      .new-table-style .row.parent .cell {
        border: none; }
    .new-table-style .row.child:last-child .cell {
      border: none; }
    .new-table-style .row.child:hover .cell {
      background: #f8f9f9; }
    .new-table-style .row *[class^='icon-']::before,
    .new-table-style .row *[class*='icon-']::before {
      font-size: 14px;
      color: #2667fc; }
    .new-table-style .row i[class^='icon-round'] {
      position: relative;
      top: 2px; }

.cell.cell-width-0 {
  width: 0 !important; }

.margin-collapse-0 {
  margin-left: 0 !important;
  width: 100 !important; }

.cell.cell-width-1 {
  width: 1% !important; }

.margin-collapse-1 {
  margin-left: 1% !important;
  width: 99% !important; }

.cell.cell-width-2 {
  width: 2% !important; }

.margin-collapse-2 {
  margin-left: 2% !important;
  width: 98% !important; }

.cell.cell-width-3 {
  width: 3% !important; }

.margin-collapse-3 {
  margin-left: 3% !important;
  width: 97% !important; }

.cell.cell-width-4 {
  width: 4% !important; }

.margin-collapse-4 {
  margin-left: 4% !important;
  width: 96% !important; }

.cell.cell-width-5 {
  width: 5% !important; }

.margin-collapse-5 {
  margin-left: 5% !important;
  width: 95% !important; }

.cell.cell-width-6 {
  width: 6% !important; }

.margin-collapse-6 {
  margin-left: 6% !important;
  width: 94% !important; }

.cell.cell-width-7 {
  width: 7% !important; }

.margin-collapse-7 {
  margin-left: 7% !important;
  width: 93% !important; }

.cell.cell-width-8 {
  width: 8% !important; }

.margin-collapse-8 {
  margin-left: 8% !important;
  width: 92% !important; }

.cell.cell-width-9 {
  width: 9% !important; }

.margin-collapse-9 {
  margin-left: 9% !important;
  width: 91% !important; }

.cell.cell-width-10 {
  width: 10% !important; }

.margin-collapse-10 {
  margin-left: 10% !important;
  width: 90% !important; }

.cell.cell-width-11 {
  width: 11% !important; }

.margin-collapse-11 {
  margin-left: 11% !important;
  width: 89% !important; }

.cell.cell-width-12 {
  width: 12% !important; }

.margin-collapse-12 {
  margin-left: 12% !important;
  width: 88% !important; }

.cell.cell-width-13 {
  width: 13% !important; }

.margin-collapse-13 {
  margin-left: 13% !important;
  width: 87% !important; }

.cell.cell-width-14 {
  width: 14% !important; }

.margin-collapse-14 {
  margin-left: 14% !important;
  width: 86% !important; }

.cell.cell-width-15 {
  width: 15% !important; }

.margin-collapse-15 {
  margin-left: 15% !important;
  width: 85% !important; }

.cell.cell-width-16 {
  width: 16% !important; }

.margin-collapse-16 {
  margin-left: 16% !important;
  width: 84% !important; }

.cell.cell-width-17 {
  width: 17% !important; }

.margin-collapse-17 {
  margin-left: 17% !important;
  width: 83% !important; }

.cell.cell-width-18 {
  width: 18% !important; }

.margin-collapse-18 {
  margin-left: 18% !important;
  width: 82% !important; }

.cell.cell-width-19 {
  width: 19% !important; }

.margin-collapse-19 {
  margin-left: 19% !important;
  width: 81% !important; }

.cell.cell-width-20 {
  width: 20% !important; }

.margin-collapse-20 {
  margin-left: 20% !important;
  width: 80% !important; }

.cell.cell-width-21 {
  width: 21% !important; }

.margin-collapse-21 {
  margin-left: 21% !important;
  width: 79% !important; }

.cell.cell-width-22 {
  width: 22% !important; }

.margin-collapse-22 {
  margin-left: 22% !important;
  width: 78% !important; }

.cell.cell-width-23 {
  width: 23% !important; }

.margin-collapse-23 {
  margin-left: 23% !important;
  width: 77% !important; }

.cell.cell-width-24 {
  width: 24% !important; }

.margin-collapse-24 {
  margin-left: 24% !important;
  width: 76% !important; }

.cell.cell-width-25 {
  width: 25% !important; }

.margin-collapse-25 {
  margin-left: 25% !important;
  width: 75% !important; }

.cell.cell-width-26 {
  width: 26% !important; }

.margin-collapse-26 {
  margin-left: 26% !important;
  width: 74% !important; }

.cell.cell-width-27 {
  width: 27% !important; }

.margin-collapse-27 {
  margin-left: 27% !important;
  width: 73% !important; }

.cell.cell-width-28 {
  width: 28% !important; }

.margin-collapse-28 {
  margin-left: 28% !important;
  width: 72% !important; }

.cell.cell-width-29 {
  width: 29% !important; }

.margin-collapse-29 {
  margin-left: 29% !important;
  width: 71% !important; }

.cell.cell-width-30 {
  width: 30% !important; }

.margin-collapse-30 {
  margin-left: 30% !important;
  width: 70% !important; }

.cell.cell-width-31 {
  width: 31% !important; }

.margin-collapse-31 {
  margin-left: 31% !important;
  width: 69% !important; }

.cell.cell-width-32 {
  width: 32% !important; }

.margin-collapse-32 {
  margin-left: 32% !important;
  width: 68% !important; }

.cell.cell-width-33 {
  width: 33% !important; }

.margin-collapse-33 {
  margin-left: 33% !important;
  width: 67% !important; }

.cell.cell-width-34 {
  width: 34% !important; }

.margin-collapse-34 {
  margin-left: 34% !important;
  width: 66% !important; }

.cell.cell-width-35 {
  width: 35% !important; }

.margin-collapse-35 {
  margin-left: 35% !important;
  width: 65% !important; }

.cell.cell-width-36 {
  width: 36% !important; }

.margin-collapse-36 {
  margin-left: 36% !important;
  width: 64% !important; }

.cell.cell-width-37 {
  width: 37% !important; }

.margin-collapse-37 {
  margin-left: 37% !important;
  width: 63% !important; }

.cell.cell-width-38 {
  width: 38% !important; }

.margin-collapse-38 {
  margin-left: 38% !important;
  width: 62% !important; }

.cell.cell-width-39 {
  width: 39% !important; }

.margin-collapse-39 {
  margin-left: 39% !important;
  width: 61% !important; }

.cell.cell-width-40 {
  width: 40% !important; }

.margin-collapse-40 {
  margin-left: 40% !important;
  width: 60% !important; }

.cell.cell-width-41 {
  width: 41% !important; }

.margin-collapse-41 {
  margin-left: 41% !important;
  width: 59% !important; }

.cell.cell-width-42 {
  width: 42% !important; }

.margin-collapse-42 {
  margin-left: 42% !important;
  width: 58% !important; }

.cell.cell-width-43 {
  width: 43% !important; }

.margin-collapse-43 {
  margin-left: 43% !important;
  width: 57% !important; }

.cell.cell-width-44 {
  width: 44% !important; }

.margin-collapse-44 {
  margin-left: 44% !important;
  width: 56% !important; }

.cell.cell-width-45 {
  width: 45% !important; }

.margin-collapse-45 {
  margin-left: 45% !important;
  width: 55% !important; }

.cell.cell-width-46 {
  width: 46% !important; }

.margin-collapse-46 {
  margin-left: 46% !important;
  width: 54% !important; }

.cell.cell-width-47 {
  width: 47% !important; }

.margin-collapse-47 {
  margin-left: 47% !important;
  width: 53% !important; }

.cell.cell-width-48 {
  width: 48% !important; }

.margin-collapse-48 {
  margin-left: 48% !important;
  width: 52% !important; }

.cell.cell-width-49 {
  width: 49% !important; }

.margin-collapse-49 {
  margin-left: 49% !important;
  width: 51% !important; }

.cell.cell-width-50 {
  width: 50% !important; }

.margin-collapse-50 {
  margin-left: 50% !important;
  width: 50% !important; }

.cell.cell-width-51 {
  width: 51% !important; }

.margin-collapse-51 {
  margin-left: 51% !important;
  width: 49% !important; }

.cell.cell-width-52 {
  width: 52% !important; }

.margin-collapse-52 {
  margin-left: 52% !important;
  width: 48% !important; }

.cell.cell-width-53 {
  width: 53% !important; }

.margin-collapse-53 {
  margin-left: 53% !important;
  width: 47% !important; }

.cell.cell-width-54 {
  width: 54% !important; }

.margin-collapse-54 {
  margin-left: 54% !important;
  width: 46% !important; }

.cell.cell-width-55 {
  width: 55% !important; }

.margin-collapse-55 {
  margin-left: 55% !important;
  width: 45% !important; }

.cell.cell-width-56 {
  width: 56% !important; }

.margin-collapse-56 {
  margin-left: 56% !important;
  width: 44% !important; }

.cell.cell-width-57 {
  width: 57% !important; }

.margin-collapse-57 {
  margin-left: 57% !important;
  width: 43% !important; }

.cell.cell-width-58 {
  width: 58% !important; }

.margin-collapse-58 {
  margin-left: 58% !important;
  width: 42% !important; }

.cell.cell-width-59 {
  width: 59% !important; }

.margin-collapse-59 {
  margin-left: 59% !important;
  width: 41% !important; }

.cell.cell-width-60 {
  width: 60% !important; }

.margin-collapse-60 {
  margin-left: 60% !important;
  width: 40% !important; }

.cell.cell-width-61 {
  width: 61% !important; }

.margin-collapse-61 {
  margin-left: 61% !important;
  width: 39% !important; }

.cell.cell-width-62 {
  width: 62% !important; }

.margin-collapse-62 {
  margin-left: 62% !important;
  width: 38% !important; }

.cell.cell-width-63 {
  width: 63% !important; }

.margin-collapse-63 {
  margin-left: 63% !important;
  width: 37% !important; }

.cell.cell-width-64 {
  width: 64% !important; }

.margin-collapse-64 {
  margin-left: 64% !important;
  width: 36% !important; }

.cell.cell-width-65 {
  width: 65% !important; }

.margin-collapse-65 {
  margin-left: 65% !important;
  width: 35% !important; }

.cell.cell-width-66 {
  width: 66% !important; }

.margin-collapse-66 {
  margin-left: 66% !important;
  width: 34% !important; }

.cell.cell-width-67 {
  width: 67% !important; }

.margin-collapse-67 {
  margin-left: 67% !important;
  width: 33% !important; }

.cell.cell-width-68 {
  width: 68% !important; }

.margin-collapse-68 {
  margin-left: 68% !important;
  width: 32% !important; }

.cell.cell-width-69 {
  width: 69% !important; }

.margin-collapse-69 {
  margin-left: 69% !important;
  width: 31% !important; }

.cell.cell-width-70 {
  width: 70% !important; }

.margin-collapse-70 {
  margin-left: 70% !important;
  width: 30% !important; }

.cell.cell-width-71 {
  width: 71% !important; }

.margin-collapse-71 {
  margin-left: 71% !important;
  width: 29% !important; }

.cell.cell-width-72 {
  width: 72% !important; }

.margin-collapse-72 {
  margin-left: 72% !important;
  width: 28% !important; }

.cell.cell-width-73 {
  width: 73% !important; }

.margin-collapse-73 {
  margin-left: 73% !important;
  width: 27% !important; }

.cell.cell-width-74 {
  width: 74% !important; }

.margin-collapse-74 {
  margin-left: 74% !important;
  width: 26% !important; }

.cell.cell-width-75 {
  width: 75% !important; }

.margin-collapse-75 {
  margin-left: 75% !important;
  width: 25% !important; }

.cell.cell-width-76 {
  width: 76% !important; }

.margin-collapse-76 {
  margin-left: 76% !important;
  width: 24% !important; }

.cell.cell-width-77 {
  width: 77% !important; }

.margin-collapse-77 {
  margin-left: 77% !important;
  width: 23% !important; }

.cell.cell-width-78 {
  width: 78% !important; }

.margin-collapse-78 {
  margin-left: 78% !important;
  width: 22% !important; }

.cell.cell-width-79 {
  width: 79% !important; }

.margin-collapse-79 {
  margin-left: 79% !important;
  width: 21% !important; }

.cell.cell-width-80 {
  width: 80% !important; }

.margin-collapse-80 {
  margin-left: 80% !important;
  width: 20% !important; }

.cell.cell-width-81 {
  width: 81% !important; }

.margin-collapse-81 {
  margin-left: 81% !important;
  width: 19% !important; }

.cell.cell-width-82 {
  width: 82% !important; }

.margin-collapse-82 {
  margin-left: 82% !important;
  width: 18% !important; }

.cell.cell-width-83 {
  width: 83% !important; }

.margin-collapse-83 {
  margin-left: 83% !important;
  width: 17% !important; }

.cell.cell-width-84 {
  width: 84% !important; }

.margin-collapse-84 {
  margin-left: 84% !important;
  width: 16% !important; }

.cell.cell-width-85 {
  width: 85% !important; }

.margin-collapse-85 {
  margin-left: 85% !important;
  width: 15% !important; }

.cell.cell-width-86 {
  width: 86% !important; }

.margin-collapse-86 {
  margin-left: 86% !important;
  width: 14% !important; }

.cell.cell-width-87 {
  width: 87% !important; }

.margin-collapse-87 {
  margin-left: 87% !important;
  width: 13% !important; }

.cell.cell-width-88 {
  width: 88% !important; }

.margin-collapse-88 {
  margin-left: 88% !important;
  width: 12% !important; }

.cell.cell-width-89 {
  width: 89% !important; }

.margin-collapse-89 {
  margin-left: 89% !important;
  width: 11% !important; }

.cell.cell-width-90 {
  width: 90% !important; }

.margin-collapse-90 {
  margin-left: 90% !important;
  width: 10% !important; }

.cell.cell-width-91 {
  width: 91% !important; }

.margin-collapse-91 {
  margin-left: 91% !important;
  width: 9% !important; }

.cell.cell-width-92 {
  width: 92% !important; }

.margin-collapse-92 {
  margin-left: 92% !important;
  width: 8% !important; }

.cell.cell-width-93 {
  width: 93% !important; }

.margin-collapse-93 {
  margin-left: 93% !important;
  width: 7% !important; }

.cell.cell-width-94 {
  width: 94% !important; }

.margin-collapse-94 {
  margin-left: 94% !important;
  width: 6% !important; }

.cell.cell-width-95 {
  width: 95% !important; }

.margin-collapse-95 {
  margin-left: 95% !important;
  width: 5% !important; }

.cell.cell-width-96 {
  width: 96% !important; }

.margin-collapse-96 {
  margin-left: 96% !important;
  width: 4% !important; }

.cell.cell-width-97 {
  width: 97% !important; }

.margin-collapse-97 {
  margin-left: 97% !important;
  width: 3% !important; }

.cell.cell-width-98 {
  width: 98% !important; }

.margin-collapse-98 {
  margin-left: 98% !important;
  width: 2% !important; }

.cell.cell-width-99 {
  width: 99% !important; }

.margin-collapse-99 {
  margin-left: 99% !important;
  width: 1% !important; }

.cell.cell-width-100 {
  width: 100% !important; }

.margin-collapse-100 {
  margin-left: 100% !important;
  width: 0% !important; }

.table-scroll-fixed {
  max-height: calc(100vh - 240px);
  max-width: 100%;
  overflow: auto;
  position: relative; }
  .table-scroll-fixed table {
    max-width: 100%; }
  .table-scroll-fixed thead th {
    background-color: #195fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 1; }
    .table-scroll-fixed thead th.table-scroll-fixed--col {
      left: 0;
      z-index: 3; }
    .table-scroll-fixed thead th.table-scroll-fixed--col2 {
      left: 60px;
      min-width: 280px;
      width: 280px; }
      @media only screen and (max-width: 500px) {
        .table-scroll-fixed thead th.table-scroll-fixed--col2 {
          left: auto;
          z-index: 2; } }
  .table-scroll-fixed tbody tr:nth-child(2n + 2) .table-scroll-fixed--col {
    background-color: #f0f4f8; }
  .table-scroll-fixed tbody .table-scroll-fixed--col {
    background: #fff;
    left: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2; }
    .table-scroll-fixed tbody .table-scroll-fixed--col .text-content {
      word-break: break-word; }
  .table-scroll-fixed tbody .table-scroll-fixed--col2 {
    left: 60px;
    min-width: 280px;
    width: 280px; }
    @media only screen and (max-width: 500px) {
      .table-scroll-fixed tbody .table-scroll-fixed--col2 {
        left: auto;
        z-index: 1; } }

.seach-form .dropdown-tree-filter {
  min-width: 270px; }

.seach-form .group-select-inline {
  min-width: 100%;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline; }
  .seach-form .group-select-inline .pt-select-inline {
    width: calc(100% - 100px); }

.seach-form .btn-search {
  background: #6bc779;
  color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  min-width: 38px;
  height: 38px;
  margin-right: 14px;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  box-shadow: none; }
  .seach-form .btn-search:last-child {
    margin-right: 0; }
  .seach-form .btn-search .pt-icon-search {
    margin: 0 5px; }
    .seach-form .btn-search .pt-icon-search:before {
      color: #fff; }
  .seach-form .btn-search:hover {
    border: none;
    background: #15b32d; }

.seach-form .search {
  background: #fff;
  border: 1px solid #e0e1e4;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  margin: 0;
  min-width: 357px;
  min-height: 38px;
  padding: 4px 10px;
  color: #000; }
  .seach-form .search.placeholder {
    color: #bbb; }
  .seach-form .search:-moz-placeholder {
    color: #bbb; }
  .seach-form .search::-webkit-input-placeholder {
    color: #bbb; }
  .seach-form .search:-ms-input-placeholder {
    color: #bbb; }
  .seach-form .search:focus {
    border-color: rgba(25, 95, 255, 0.3); }

.seach-form .btn-reload,
.seach-form .btn-toogle {
  min-width: 38px;
  height: 38px;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  background: #fff;
  border: 1px solid #dfe1e4;
  -webkit-box-shadow: 0 2px 1px 0 #f9faff;
  -moz-box-shadow: 0 2px 1px 0 #f9faff;
  box-shadow: 0 2px 1px 0 #f9faff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-right: 14px; }
  .seach-form .btn-reload:hover,
  .seach-form .btn-toogle:hover {
    border: none;
    background: #6bc779; }
    .seach-form .btn-reload:hover span:before,
    .seach-form .btn-toogle:hover span:before {
      color: #fff; }
  .seach-form .btn-reload .pt-icon,
  .seach-form .btn-toogle .pt-icon {
    margin-right: 0; }

.seach-form .btn-reload-group,
.seach-form .search-keyword {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline; }
  .seach-form .btn-reload-group .btn-toogle,
  .seach-form .search-keyword .btn-toogle {
    margin-right: 0; }

.seach-form .search-keyword {
  margin-right: 14px;
  padding: 0 !important; }

.seach-form .search-header-radio-group-content .radio-inline {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px; }

.search-filter-select {
  float: right;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline; }
  .search-filter-select .group-select-sub {
    width: 20%; }
  .search-filter-select .search {
    min-width: 270px; }

.search-filter-date {
  text-align: right;
  padding-left: 10px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: middle; }
  .search-filter-date .group-datetime {
    float: left;
    padding-right: 10px;
    text-align: right; }
  .search-filter-date .daterangepicker-group {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    width: 62%; }

.search-filter-select-department {
  min-width: 50%;
  float: left; }

.form-search-basic .search-work-department {
  padding-top: 9px; }

.form-search-basic .search-header-group {
  padding-bottom: 17px;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px; }
  .form-search-basic .search-header-group .search-header-group-sub {
    padding-bottom: 17px;
    padding-right: 10px; }
  .form-search-basic .search-header-group .pt-label-inline {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .form-search-basic .search-header-group .pt-label-inline .text-label {
      font-family: 'Roboto-medium';
      font-size: 12px;
      color: #81868e;
      letter-spacing: 0;
      text-align: left;
      margin-right: 20px; }

.form-search-basic .head-check-content {
  margin-top: 8px;
  margin-bottom: 0; }

.search-advanced {
  padding: 0 10px; }
  .search-advanced .pt-label {
    font-family: "Roboto-medium", sans-serif;
    font-size: 12px;
    color: #81868e;
    letter-spacing: 0;
    text-align: left;
    margin: 0 0 10px; }
  .search-advanced label.pt-label .pt-input,
  .search-advanced label.pt-label .pt-select select {
    min-height: 38px;
    border: 1px solid #e0e1e4;
    box-shadow: none; }
  .search-advanced label.pt-label .pt-input,
  .search-advanced label.pt-label .pt-select {
    margin-top: 10px; }
  .search-advanced .pt-popover-target .pt-control-group,
  .search-advanced .pt-popover-target .pt-control-group > :first-child,
  .search-advanced .pt-popover-target .pt-select {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0; }
  .search-advanced .group-datetime {
    width: 30.4%;
    margin: 0 0 10px; }
    .search-advanced .group-datetime .label-datepicker {
      margin: 0 0 10px; }
    .search-advanced .group-datetime .react-datepicker-wrapper {
      display: -moz-inline-stack;
      display: inline-block;
      zoom: 1;
      *display: inline;
      float: left;
      width: 50%; }
  .search-advanced .row {
    margin-right: 0;
    margin-left: 0; }
  .search-advanced .group-select {
    width: 19.6%; }
  .search-advanced .full-with-right {
    width: 39.3%; }
  .search-advanced .pt-input {
    background-color: #fff;
    background-image: none;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    color: #4a4a4a;
    letter-spacing: 0;
    text-align: left;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0; }
  .search-advanced .pt-select {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0; }
    .search-advanced .pt-select select {
      background-color: #fff;
      background-image: none;
      font-family: "Roboto-Regular", sans-serif;
      font-size: 13px;
      color: #4a4a4a;
      letter-spacing: 0;
      text-align: left;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0; }
    .search-advanced .pt-select:after {
      content: '\E697';
      color: #9fa2a5;
      font-size: 12px;
      padding-left: 11px;
      font-family: 'Icons20', sans-serif;
      position: relative;
      line-height: 0.5;
      right: 10px;
      float: right;
      top: -22px; }
  .search-advanced .select-senddocument {
    width: 34.8%; }

.daterangepicker-group .pt-input {
  min-height: 38px;
  border: 1px solid #e0e1e4;
  -webkit-box-shadow: none;
  box-shadow: none; }

.daterangepicker-group .pt-popover-target {
  width: 50%; }

.statistic-work {
  padding-left: 15px;
  position: relative; }
  .statistic-work .active-filter-gqh {
    border-top: 3px solid #f7ca18; }
  .statistic-work .active-filter-qh {
    border-top: 3px solid #f54d3d; }
  .statistic-work .item {
    min-width: 50%; }
  .statistic-work .item-status {
    text-align: left;
    cursor: pointer; }
    .statistic-work .item-status .number {
      font-family: "Roboto-medium", sans-serif;
      font-size: 33px;
      padding-right: 15px;
      display: -moz-inline-stack;
      display: inline-block;
      zoom: 1;
      *display: inline;
      vertical-align: middle; }
    .statistic-work .item-status .number-past {
      color: #f54d3d; }
    .statistic-work .item-status .number-near {
      color: #f7ca18; }
    .statistic-work .item-status .near-deadline,
    .statistic-work .item-status .past-deadline {
      font-family: "Roboto-medium", sans-serif;
      font-size: 13px;
      color: #212121;
      line-height: 18px;
      position: absolute;
      top: 8px; }

.search-container {
  background: #f9faff;
  border: 1px solid #dfe7ea;
  border-bottom: 0px;
  -webkit-box-shadow: 0 8px 21px 0 rgba(199, 199, 199, 0.21);
  -moz-box-shadow: 0 8px 21px 0 rgba(199, 199, 199, 0.21);
  box-shadow: 0 8px 21px 0 rgba(199, 199, 199, 0.21);
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  position: relative;
  z-index: 10; }
  .search-container .search-header-left-content {
    padding-top: 10px; }

.search-InternalDocument .group-datetime {
  width: 30.3%; }

.search-ReceiveDocument .group-datetime,
.search-work .group-datetime,
.search-DocumentarySearch .group-datetime {
  width: 50%; }

.search-ReceiveDocument .group-select,
.search-work .group-select,
.search-DocumentarySearch .group-select {
  width: 25%; }

.search-ReceiveDocument .group-select-sub,
.search-work .group-select-sub,
.search-DocumentarySearch .group-select-sub {
  width: 15%; }

.search-ReceiveDocument .select-filter {
  width: 30%; }

.search-ReceiveDocument .search-advanced .group-datetime {
  width: 25%; }

.search-work .search-header-right-content {
  padding: 10px 0px;
  padding-right: 10px; }

.search-work .search-ListFilterAssigned .search-header-group {
  float: right; }
  .search-work .search-ListFilterAssigned .search-header-group .search-filter-date {
    float: left; }

.search-work .seach-form {
  padding: 0 !important; }

.search-work .search-advanced .group-datetime {
  width: 25%; }

.search-container .select-filter-header {
  display: inline; }

.search-container .select-filter {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  margin-right: 10px; }

.search-container .search-header-group {
  padding-bottom: 0;
  padding-top: 0;
  width: 100%; }

.search-container .seach-form {
  padding: 10px 0px; }

.search-ReceiveDocument-other .group-datetime {
  width: 30% !important; }

.search-ReceiveDocument-other .input-element-other {
  width: 20%; }
  .search-ReceiveDocument-other .input-element-other .pt-input {
    min-width: 100%; }

.search-work-other .group-datetime {
  width: 27.3% !important; }

.search-work-other .group-select {
  width: 15.2% !important; }

.search-work-other .input-element-other {
  width: 15%; }
  .search-work-other .input-element-other .pt-input {
    min-width: 100%; }

.filter-search-list {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline; }

.search-advanced-listSignNumberProjects .item {
  width: 25% !important; }

.search-container.list-filter .dropdown-filter {
  width: 268px; }

.search-container.list-filter .text-filter {
  flex: 1;
  margin-left: 10px; }

.row-picker-range {
  display: flex;
  margin: 0 -5px; }
  .row-picker-range .col-picker-range {
    width: 50%;
    padding: 0 5px; }
    .row-picker-range .col-picker-range .react-datepicker-wrapper {
      overflow: hidden;
      border-radius: 3px;
      background-color: #fff;
      border: 1px solid #dfe1e4;
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.05); }

.form-input-search {
  background-color: #fff;
  position: relative;
  border: 1px solid #dfe1e4;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.05); }

.pt-intent-reload {
  transition: all 0.3s ease;
  border: 1px solid #dfe1e4;
  background-color: #fff; }

.table-container-full {
  background: #fff; }
  .table-container-full .pt-table {
    background: #fff; }

body {
  font-family: "Roboto-Regular", sans-serif;
  color: #454a52; }

html {
  scroll-behavior: smooth; }

* {
  min-height: 0;
  min-width: 0; }

*:focus {
  outline: 0;
  outline-width: 0;
  border-color: none; }

.pt-control input:disabled ~ .pt-control-indicator,
.pt-file-upload input:disabled + .pt-file-upload-input,
.pt-file-upload input.pt-disabled + .pt-file-upload-input,
.pt-input:disabled,
.pt-input.pt-disabled,
.pt-select select:disabled,
.pt-select .select.disabled,
.pt-file-upload input.pt-disabled + .pt-file-upload-input:after,
.pt-file-upload input:disabled + .pt-file-upload-input:after,
textarea:disabled,
input:disabled {
  background-color: rgba(206, 217, 224, 0.5) !important;
  pointer-events: none !important; }

.pt-button:disabled, .pt-button.pt-disabled {
  border-color: #d2d2d2 !important;
  background-color: rgba(206, 217, 224, 0.5) !important;
  color: #6a7489 !important; }
  .pt-button:disabled:hover, .pt-button.pt-disabled:hover {
    background-color: rgba(206, 217, 224, 0.5) !important; }

.pt-button:not([class*='pt-intent-']):disabled,
.pt-button:not([class*='pt-intent-']).pt-disabled {
  opacity: 1 !important;
  box-shadow: none !important; }
  .pt-button:not([class*='pt-intent-']):disabled span,
  .pt-button:not([class*='pt-intent-']).pt-disabled span {
    color: #6a7489 !important; }
  .pt-button:not([class*='pt-intent-']):disabled .pt-icon:before,
  .pt-button:not([class*='pt-intent-']):disabled .pt-icon-pt-icon:before,
  .pt-button:not([class*='pt-intent-']).pt-disabled .pt-icon:before,
  .pt-button:not([class*='pt-intent-']).pt-disabled .pt-icon-pt-icon:before {
    color: #6a7489 !important; }

select::-moz-focus-inner {
  border-color: none; }

button::-moz-focus-inner {
  border: 0; }

.start-xs {
  text-align: left; }

.end-xs {
  text-align: right; }

textarea,
input {
  background-color: #fff;
  color: #000; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0.5;
  font-family: "Roboto-Regular", sans-serif; }
  ::-webkit-input-placeholder.placeholder {
    color: #d2d2d2; }
  ::-webkit-input-placeholder:-moz-placeholder {
    color: #d2d2d2; }
  ::-webkit-input-placeholder::-webkit-input-placeholder {
    color: #d2d2d2; }
  ::-webkit-input-placeholder:-ms-input-placeholder {
    color: #d2d2d2; }

::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0.5;
  font-family: "Roboto-Regular", sans-serif; }
  ::-moz-placeholder.placeholder {
    color: #d2d2d2; }
  ::-moz-placeholder:-moz-placeholder {
    color: #d2d2d2; }
  ::-moz-placeholder::-webkit-input-placeholder {
    color: #d2d2d2; }
  ::-moz-placeholder:-ms-input-placeholder {
    color: #d2d2d2; }

:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0.5;
  font-family: "Roboto-Regular", sans-serif; }
  :-ms-input-placeholder.placeholder {
    color: #d2d2d2; }
  :-ms-input-placeholder:-moz-placeholder {
    color: #d2d2d2; }
  :-ms-input-placeholder::-webkit-input-placeholder {
    color: #d2d2d2; }
  :-ms-input-placeholder:-ms-input-placeholder {
    color: #d2d2d2; }

:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0.5;
  font-family: "Roboto-Regular", sans-serif; }
  :-moz-placeholder.placeholder {
    color: #d2d2d2; }
  :-moz-placeholder:-moz-placeholder {
    color: #d2d2d2; }
  :-moz-placeholder::-webkit-input-placeholder {
    color: #d2d2d2; }
  :-moz-placeholder:-ms-input-placeholder {
    color: #d2d2d2; }

textarea::-webkit-input-placeholder {
  opacity: 0.5;
  font-family: "Roboto-Regular", sans-serif; }
  textarea::-webkit-input-placeholder.placeholder {
    color: #d2d2d2; }
  textarea::-webkit-input-placeholder:-moz-placeholder {
    color: #d2d2d2; }
  textarea::-webkit-input-placeholder::-webkit-input-placeholder {
    color: #d2d2d2; }
  textarea::-webkit-input-placeholder:-ms-input-placeholder {
    color: #d2d2d2; }

textarea:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0.5;
  font-family: "Roboto-Regular", sans-serif; }
  textarea:-moz-placeholder.placeholder {
    color: #d2d2d2; }
  textarea:-moz-placeholder:-moz-placeholder {
    color: #d2d2d2; }
  textarea:-moz-placeholder::-webkit-input-placeholder {
    color: #d2d2d2; }
  textarea:-moz-placeholder:-ms-input-placeholder {
    color: #d2d2d2; }

textarea::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0.5;
  font-family: "Roboto-Regular", sans-serif; }
  textarea::-moz-placeholder.placeholder {
    color: #d2d2d2; }
  textarea::-moz-placeholder:-moz-placeholder {
    color: #d2d2d2; }
  textarea::-moz-placeholder::-webkit-input-placeholder {
    color: #d2d2d2; }
  textarea::-moz-placeholder:-ms-input-placeholder {
    color: #d2d2d2; }

textarea:-ms-input-placeholder {
  opacity: 0.5;
  font-family: "Roboto-Regular", sans-serif; }
  textarea:-ms-input-placeholder.placeholder {
    color: #d2d2d2; }
  textarea:-ms-input-placeholder:-moz-placeholder {
    color: #d2d2d2; }
  textarea:-ms-input-placeholder::-webkit-input-placeholder {
    color: #d2d2d2; }
  textarea:-ms-input-placeholder:-ms-input-placeholder {
    color: #d2d2d2; }

.logo-link {
  cursor: pointer;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  height: 34px;
  overflow: hidden;
  text-indent: -1983px;
  max-width: 170px;
  width: 170px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center left; }
  @media only screen and (max-width: 1365px) {
    .logo-link {
      width: 160px; } }
  @media only screen and (max-width: 575px) {
    .logo-link {
      width: 140px; } }
  @media only screen and (max-width: 375px) {
    .logo-link {
      width: 120px; } }

.dashboard-page-error {
  width: 100% !important; }
  .dashboard-page-error .content-wrapper {
    padding: 0;
    margin: 0; }

.heading-page {
  text-align: left;
  margin-bottom: 12px;
  position: relative; }
  .heading-page .heading-inline {
    display: inline-block !important; }
  .heading-page .text-heading-page {
    color: #195fff;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    text-align: left;
    white-space: nowrap;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    margin-top: 7px; }
    .heading-page .text-heading-page .text-heading {
      display: -moz-inline-stack;
      display: inline-block;
      zoom: 1;
      *display: inline;
      vertical-align: middle;
      font-family: "Roboto-Regular", sans-serif; }
    .heading-page .text-heading-page .icon-heading {
      display: -moz-inline-stack;
      display: inline-block;
      zoom: 1;
      *display: inline;
      margin-right: 15px;
      vertical-align: middle; }
      .heading-page .text-heading-page .icon-heading::before {
        font-size: 25px;
        color: #195fff; }
    .heading-page .text-heading-page.text-white {
      color: #fff; }
      .heading-page .text-heading-page.text-white .icon-heading {
        color: #fff; }
        .heading-page .text-heading-page.text-white .icon-heading::before {
          color: #fff; }
  .heading-page:after, .heading-page:before {
    content: '';
    display: table; }
  .heading-page:after {
    clear: both; }

.heading-icon-20 {
  color: #195fff;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  text-align: left;
  white-space: nowrap; }
  .heading-icon-20 .icon-left {
    color: #195fff;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    font-size: 41px;
    margin-right: 20px;
    vertical-align: middle; }
    .heading-icon-20 .icon-left:before {
      color: #195fff; }
  .heading-icon-20 .heading-text {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: middle; }

.heading-icon-17 {
  color: #212121;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 17px;
  letter-spacing: 0;
  text-align: left;
  white-space: nowrap; }
  .heading-icon-17 .icon-left {
    color: #195fff;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    font-size: 22px;
    margin-right: 20px;
    vertical-align: middle; }
    .heading-icon-17 .icon-left:before {
      color: #195fff; }
  .heading-icon-17 .icon-Cong_viec {
    font-size: 25px; }
  .heading-icon-17 .heading-text {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: middle; }

.heading-14 {
  color: #212121;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  text-align: left;
  white-space: nowrap; }
  .heading-14 .heading-text,
  .heading-14 .right-number {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline; }
  .heading-14 .right-number {
    color: #195fff;
    font-family: "Roboto-Bold", sans-serif;
    font-size: 28px;
    margin-left: 20px; }

.pagination {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  width: 100%; }
  .pagination a,
  .pagination .item-arrow {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    color: #454a52;
    text-align: left;
    padding: 5px 9px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #cecece;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin-right: 10px;
    cursor: pointer; }
    .pagination a:hover:not(.active),
    .pagination .item-arrow:hover:not(.active) {
      background-color: #195fff;
      border-color: #195fff;
      color: #fff; }
  .pagination span {
    color: #dbdadb; }
  .pagination li {
    display: inline; }
    .pagination li.active a {
      background-color: #195fff;
      color: #fff;
      border: 1px solid #195fff; }
    .pagination li.disabled a,
    .pagination li.disabled a:hover {
      background-color: #fff;
      border-color: rgba(206, 206, 206, 0.5);
      color: rgba(69, 74, 82, 0.5);
      cursor: default; }

.note-list-workPage {
  margin-top: 10px; }
  .note-list-workPage .list-note {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    width: 25%; }
  .note-list-workPage .note-issues .name-note {
    margin-left: 5px;
    display: block; }

.list-modules-note .icon-modules:before {
  font-size: 22px;
  padding-right: 5px; }

.list-modules-note .icon-modules.icon-attention-warning {
  padding-right: 3px; }

.list-modules-note .icon-modules.icon-attention-warning:before {
  font-size: 18px; }

.note-issues {
  position: absolute; }
  .note-issues .name-note {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 10px;
    color: #384f5a;
    letter-spacing: 0;
    text-align: left;
    text-transform: uppercase;
    margin-left: 5px; }
  .note-issues .list-note {
    margin-top: 9px; }
    .note-issues .list-note .note-item {
      font-family: "Roboto-Regular", sans-serif;
      font-size: 13px;
      color: #454a52;
      letter-spacing: 0;
      text-align: left; }
      .note-issues .list-note .note-item > .note-icon {
        display: inline-block;
        width: 30px;
        padding: 6px; }
    .note-issues .list-note .square-note {
      font-family: "Roboto-Regular", sans-serif;
      font-size: 13px;
      color: #454a52;
      letter-spacing: 0;
      text-align: left; }
    .note-issues .list-note .style-icon-DocumentCabinet {
      display: -moz-inline-stack;
      display: inline-block;
      zoom: 1;
      *display: inline;
      margin-top: 4px; }
      .note-issues .list-note .style-icon-DocumentCabinet:before {
        font-size: 20px;
        color: #7f7f7f;
        margin-left: 6px;
        padding-right: 5px; }
    .note-issues .list-note .pt-icon-symbol-square:before {
      font-size: 30px; }
    .note-issues .list-note .text-qh {
      position: relative;
      top: -5px; }
    .note-issues .list-note .pt-icon-star {
      margin-right: 11px;
      color: #f8d46e; }
      .note-issues .list-note .pt-icon-star:before {
        top: 4px;
        font-size: 16px; }
  .note-issues .list-modules-note .note-item {
    padding-bottom: 4px; }

.note-issues-none {
  position: static; }

.note-work {
  top: 5px; }

.footer-list {
  padding: 0px 20px 0;
  position: relative; }

.status-no-process, .no-process, .lddv-chua-chi-dao, .lddv-chua-giao, .ldpb-chuaduyet, .thu-ky-chuaduyet, .lddv-chuaduyet {
  background: #fcf4e7;
  color: #de8f10;
  border: 1px solid #f1d493;
  margin-top: 12px; }

.status-no-approval, .no-approval, .khong-duyet {
  background: #fed9db;
  border: 1px solid #ffacb1;
  color: #f60a18; }

.status-process, .process, .dang-gop-y {
  background: #e8efff;
  color: #195fff;
  border: 1px solid #adc6ff;
  margin-top: 12px; }

.status-done, .done, .da-gop-y, .khong-y-kien {
  background: #d9fcff;
  border: 1px solid #a6f1fe;
  color: #0ca0c5;
  margin-top: 12px; }

.status-approved, .approved {
  background: #e5f5df;
  border: 1px solid #9ee782;
  color: #5ba83e; }

.status-reversed, .reversed {
  background: #fde7df;
  border: 1px solid #f3b296;
  color: #ec6f3b; }

.status-no-accept, .no-accept {
  background: #fff0f0;
  border: 1px solid #ff6f6f;
  color: #ff6f6f; }

.status-black, .black {
  background: #f9f9f9;
  border: 1px solid #c3c3c3;
  color: #212121; }

.no-border-bottom {
  border-bottom: 0 none transparent !important; }

.not-found-content {
  width: 100%;
  color: #8a9da7;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 17px;
  line-height: 1.4;
  padding: 90px 30px;
  text-align: center; }
  .not-found-content .description {
    padding-top: 10px; }
  .not-found-content .thumb-icon-content {
    font-size: 46px;
    line-height: 1; }

.error-page-container {
  padding: 100px 20px;
  height: 100%;
  text-align: center;
  margin: 0 auto; }
  .error-page-container.small {
    padding: 60px 20px; }
  .error-page-container .img-thumb-error {
    height: auto;
    margin: 0 auto 30px;
    max-width: 100%; }
  .error-page-container .text-link {
    color: #195fff;
    text-decoration: underline;
    cursor: pointer; }
    @media only screen and (min-width: 992px) {
      .error-page-container .text-link:hover {
        text-decoration: none; } }
  .error-page-container .title-error {
    color: #212121;
    font-family: "Roboto-medium", sans-serif;
    font-size: 60px;
    line-height: 1; }
  .error-page-container .description {
    color: #212121;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    line-height: 1.6;
    padding-top: 24px; }
  .error-page-container .description-note {
    color: #8a9da7;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 16px;
    line-height: 1.6; }

.section-box-info .list-items {
  padding: 20px; }
  .section-box-info .list-items .item-value:not(:last-child) {
    margin-bottom: 10px; }
  .section-box-info .list-items .label-text {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 14px;
    color: #212121;
    letter-spacing: 0;
    text-align: left; }
  .section-box-info .list-items .content {
    text-align: justify;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    color: #212121;
    letter-spacing: 0;
    text-align: left; }

.section-box-info .content-file-info .name-file {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  color: #212121;
  letter-spacing: 0;
  text-align: left;
  padding-left: 10px; }

.label-text-detail {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 14px;
  color: #212121;
  letter-spacing: 0;
  text-align: left; }

.loader-line {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd; }
  .loader-line:before {
    display: block;
    position: absolute;
    content: '';
    left: -200px;
    width: 200px;
    height: 4px;
    background-color: #195fff;
    animation: loading 1s linear infinite; }

@keyframes loading {
  from {
    left: -200px;
    width: 30%; }
  50% {
    width: 30%; }
  70% {
    width: 70%; }
  80% {
    left: 50%; }
  95% {
    left: 120%; }
  to {
    left: 100%; } }

.table-loading:hover .colum-loading {
  background: none !important; }

.table-loading .loading-content {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: wave;
  animation-timing-function: linear;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: wave;
  -webkit-animation-timing-function: linear;
  -moz-animation-duration: 1s;
  -moz-animation-fill-mode: forwards;
  -moz-animation-iteration-count: infinite;
  -moz-animation-name: wave;
  -moz-animation-timing-function: linear;
  background: #dddddd;
  background-image: -webkit-gradient(linear, left center, right center, from(#dddddd), color-stop(0.2, #f5f5f5), color-stop(0.4, #e5e5e5), to(#dddddd));
  background-image: -webkit-linear-gradient(left, #dddddd 0%, #f5f5f5 20%, #e5e5e5 40%, #dddddd 100%);
  background-image: -moz-gradient(linear, left center, right center, from(#dddddd), color-stop(0.2, #f5f5f5), color-stop(0.4, #e5e5e5), to(#dddddd));
  background-image: -moz-linear-gradient(left, #dddddd 0%, #f5f5f5 20%, #e5e5e5 40%, #dddddd 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  position: relative;
  height: 90px;
  padding: 10px;
  width: 33.3333333333333%;
  float: left; }
  .table-loading .loading-content span {
    display: block;
    background: #fff;
    height: 6px;
    left: 0;
    position: absolute;
    right: 0; }
    .table-loading .loading-content span.shape1 {
      height: 90px;
      left: 0;
      right: auto;
      top: 0;
      width: 10px; }
    .table-loading .loading-content span.shape2 {
      height: 10px;
      left: 60%;
      top: 0; }
    .table-loading .loading-content span.shape3 {
      left: 10px;
      top: 10px; }
    .table-loading .loading-content span.shape4 {
      height: 12px;
      left: 65%;
      top: 14px; }
    .table-loading .loading-content span.shape5 {
      left: 10px;
      top: 26px; }
    .table-loading .loading-content span.shape6 {
      height: 10px;
      left: 0;
      top: 32px; }
    .table-loading .loading-content span.shape7 {
      left: 10px;
      top: 42px;
      height: 6px; }
    .table-loading .loading-content span.shape8 {
      left: 85%;
      top: 48px;
      height: 10px; }
    .table-loading .loading-content span.shape9 {
      height: 6px;
      left: 10px;
      top: 58px; }
    .table-loading .loading-content span.shape10 {
      left: 90%;
      top: 64px;
      height: 10px; }
    .table-loading .loading-content span.shape11 {
      left: 10px;
      top: 74px;
      height: 6px; }
    .table-loading .loading-content span.shape12 {
      height: 10px;
      left: 83%;
      top: 80px; }

.table-loading-system {
  padding: 100px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px; }
  .table-loading-system .loading-content {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: wave;
    animation-timing-function: linear;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: wave;
    -webkit-animation-timing-function: linear;
    -moz-animation-duration: 1s;
    -moz-animation-fill-mode: forwards;
    -moz-animation-iteration-count: infinite;
    -moz-animation-name: wave;
    -moz-animation-timing-function: linear;
    background: #dddddd;
    background-image: -webkit-gradient(linear, left center, right center, from(#dddddd), color-stop(0.2, #f5f5f5), color-stop(0.4, #e5e5e5), to(#dddddd));
    background-image: -webkit-linear-gradient(left, #dddddd 0%, #f5f5f5 20%, #e5e5e5 40%, #dddddd 100%);
    background-image: -moz-gradient(linear, left center, right center, from(#dddddd), color-stop(0.2, #f5f5f5), color-stop(0.4, #e5e5e5), to(#dddddd));
    background-image: -moz-linear-gradient(left, #dddddd 0%, #f5f5f5 20%, #e5e5e5 40%, #dddddd 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    position: relative;
    height: 90px;
    padding: 10px;
    width: 33.3333333333333%;
    float: left; }
    .table-loading-system .loading-content span {
      display: block;
      background: #fff;
      height: 6px;
      left: 0;
      position: absolute;
      right: 0; }
      .table-loading-system .loading-content span.shape1 {
        height: 90px;
        left: 0;
        right: auto;
        top: 0;
        width: 10px; }
      .table-loading-system .loading-content span.shape2 {
        height: 10px;
        left: 60%;
        top: 0; }
      .table-loading-system .loading-content span.shape3 {
        left: 10px;
        top: 10px; }
      .table-loading-system .loading-content span.shape4 {
        height: 12px;
        left: 65%;
        top: 14px; }
      .table-loading-system .loading-content span.shape5 {
        left: 10px;
        top: 26px; }
      .table-loading-system .loading-content span.shape6 {
        height: 10px;
        left: 0;
        top: 32px; }
      .table-loading-system .loading-content span.shape7 {
        left: 10px;
        top: 42px;
        height: 6px; }
      .table-loading-system .loading-content span.shape8 {
        left: 85%;
        top: 48px;
        height: 10px; }
      .table-loading-system .loading-content span.shape9 {
        height: 6px;
        left: 10px;
        top: 58px; }
      .table-loading-system .loading-content span.shape10 {
        left: 90%;
        top: 64px;
        height: 10px; }
      .table-loading-system .loading-content span.shape11 {
        left: 10px;
        top: 74px;
        height: 6px; }
      .table-loading-system .loading-content span.shape12 {
        height: 10px;
        left: 83%;
        top: 80px; }

@-webkit-keyframes wave {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.timeline-wrapper {
  padding: 20px;
  margin: 0 auto; }
  .timeline-wrapper .animated-background-item {
    margin-bottom: 10px;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 10px;
    position: relative; }
    .timeline-wrapper .animated-background-item:first-child {
      width: 50%; }

.loading-input {
  background-color: #6db3f2;
  background: url("../loading_circle.gif") !important;
  background-position: center right 8px !important;
  background-repeat: no-repeat !important; }

.loading-view {
  background-color: #6db3f2;
  background: url("../loading_circle.gif") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  font-size: 32px; }

.textarea-content {
  white-space: pre-line !important; }

.loading-line-detail {
  background-color: #fff;
  margin-bottom: 10px; }
  .loading-line-detail .timeline-wrapper {
    padding: 10px 0; }

.pt-dialog-body .container-popup .div-warning-popup {
  color: red; }
  .pt-dialog-body .container-popup .div-warning-popup .span-warining-popup {
    font-size: 15px;
    font-weight: 600; }
  .pt-dialog-body .container-popup .div-warning-popup .span-messenger-popup {
    font-size: 13px; }

.pt-dialog-body .container-popup .pt-label .span-label {
  font-size: 15px;
  padding: 10px 0px; }

.pt-dialog-body .container-popup .pt-label .required-input {
  margin-left: 3px;
  color: #f00; }

.pt-dialog-body .pt-form-helper-text {
  font-size: 13px;
  color: red; }

.breadcrumb-path {
  padding-bottom: 6px; }
  .breadcrumb-path .pt-breadcrumb {
    font-size: 13px;
    font-family: "Roboto-Regular", sans-serif; }
    .breadcrumb-path .pt-breadcrumb:hover {
      color: #185fff; }
  .breadcrumb-path .pt-breadcrumb-current {
    color: #185fff; }

.text-tree-push-blue,
a.text-tree-push-blue {
  color: #195fff;
  text-decoration: none; }

.viewfile-digitizing-container .click-renderPagination {
  cursor: pointer;
  padding-right: 15px; }

.viewfile-digitizing-container .paper-viewfile {
  padding: 10px 20px; }
  .viewfile-digitizing-container .paper-viewfile .btn-pager {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline; }

.viewfile-digitizing {
  width: 100% !important;
  height: auto !important;
  margin: 0 auto; }

.pt-form-signature {
  overflow: auto;
  margin-top: 10px;
  text-align: right;
  margin-right: 12px;
  margin-left: 12px; }

.info-file {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  color: #212121;
  letter-spacing: 0;
  text-align: left;
  margin-top: 10px !important;
  margin-left: 10px !important; }

.link-file {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center left !important;
  background-size: 25px !important;
  padding: 10px 0px 10px 35px;
  background-size: 32px;
  color: #454a52;
  font-size: 14px;
  font-family: "Roboto-Regular", sans-serif; }
  .link-file:hover {
    color: #195fff; }
  .link-file.read-only {
    cursor: default; }
    .link-file.read-only:hover {
      text-decoration: unset;
      color: inherit; }

.download-file {
  text-decoration: none;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  color: #1352e0;
  letter-spacing: 0;
  text-align: left;
  margin-top: 20px; }

@media print {
  body * {
    visibility: hidden; }
  .section-to-print,
  .section-to-print * {
    visibility: visible; }
  .section-to-print {
    display: block;
    background-color: white;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .print-fireFox .print-container {
    min-width: 100%;
    font-size: 13px; }
    .print-fireFox .print-container .print-p {
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      padding-bottom: 20px; }
    .print-fireFox .print-container .print-table {
      min-width: 100%;
      border-collapse: separate;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc; }
    .print-fireFox .print-container .print-thead .print-tr .print-th {
      border-top: 2px solid #ccc !important;
      border-right: 1px solid #ccc !important;
      border-left: 1px solid #ccc !important;
      padding: 8px 10px;
      font-size: 16px; }
    .print-fireFox .print-container .print-tbody .print-tr .print-td {
      border-top: 1px solid #ccc !important;
      border-right: 1px solid #ccc !important;
      border-left: 1px solid #ccc !important;
      border-bottom: 2px solid #ccc !important;
      padding: 8px 10px; }
  .print-chrome-safari .print-container {
    min-width: 100%;
    font-size: 13px; }
    .print-chrome-safari .print-container .print-p {
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      padding-bottom: 20px; }
    .print-chrome-safari .print-container .print-table {
      min-width: 100%;
      border: 1px solid #ccc;
      border-collapse: collapse; }
    .print-chrome-safari .print-container .print-thead .print-tr .print-th {
      border: 1px solid #ccc;
      padding: 8px 10px;
      font-size: 16px; }
    .print-chrome-safari .print-container .print-tbody .print-tr .print-td {
      border: 1px solid #ccc;
      padding: 8px 10px; }
  .print-brief {
    min-width: 700px;
    word-break: break-word; }
  .print-default {
    min-width: 170px;
    word-break: break-word; }
  .print-dateDocument {
    min-width: 92px;
    max-width: 92px; }
  .print-receive {
    min-width: 300px;
    word-break: break-word; }
  @page {
    size: A4 landscape; } }

.word-break {
  word-break: break-word;
  min-width: 160px; }

.section-to-not-print {
  height: 0;
  visibility: hidden;
  display: none; }

.migrated-file-buttons {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  cursor: pointer;
  margin-left: 8px;
  vertical-align: sub; }

.footer-three-dot {
  background: transparent;
  transition: background-color 0.3s;
  border: 1px solid #cecece;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-right: 10px;
  padding: 5px 9px;
  cursor: pointer; }
  .footer-three-dot > .icon-Group-8 {
    font-size: 13px;
    color: #000; }

.disabled {
  pointer-events: none;
  opacity: 0.6; }

.gerenal-list-utilities {
  cursor: default !important; }

.over-time {
  color: #195fff !important;
  cursor: pointer; }
  .over-time .hover-over-time:hover {
    text-decoration: underline;
    cursor: pointer; }

.iframe-container {
  width: 100%;
  height: 100%;
  margin: 0px auto; }
  .iframe-container .embed-container {
    height: 100vh;
    width: 100%;
    padding-bottom: 50%;
    position: relative; }
    .iframe-container .embed-container iframe {
      min-width: 100%;
      width: 10px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }

.table-container .status-urgency td {
  color: #f70808; }

.table-container .status-urgency .pt-popover-target {
  color: #454a52; }

.table-container .status-urgency-hight td {
  color: #f70808;
  font-family: "Roboto-medium", sans-serif; }

.table-container .status-urgency-hight .pt-popover-target {
  color: #454a52; }

.table-container .status-urgency-nomarl td {
  color: none;
  font-family: "Roboto-Regular", sans-serif; }

.table-container .status-urgency-nomarl .pt-popover-target {
  color: #454a52; }

.sign-number-history-datetime {
  width: 147px; }

.wrapper-panel-collapse {
  margin-bottom: 12px; }
  .wrapper-panel-collapse .heading-group-panel {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 14px;
    color: #454a52;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 13px; }
  .wrapper-panel-collapse .panel-heading {
    margin-bottom: -1px;
    cursor: pointer;
    border: 1px solid #e3e4e8;
    padding: 10px 20px;
    background: #f8f9f9;
    margin-top: 3px; }
    .wrapper-panel-collapse .panel-heading .panel-title {
      color: #384f5a;
      font-family: "Roboto-medium", sans-serif;
      font-weight: 500 !important;
      font-size: 12px;
      line-height: 1.6;
      letter-spacing: 0;
      text-align: left;
      text-transform: uppercase; }
      .wrapper-panel-collapse .panel-heading .panel-title .icon-dropdown-panel {
        margin-right: 8px; }
        .wrapper-panel-collapse .panel-heading .panel-title .icon-dropdown-panel::before {
          color: #384f5a;
          font-size: 6px; }
  .wrapper-panel-collapse .panel-body {
    border: 1px solid #e3e4e8;
    padding: 9px 0 0 10px; }
    .wrapper-panel-collapse .panel-body .table-status .heading-table {
      height: 40px; }

.system-checkbox {
  min-height: 0 !important; }

.icon-list-project {
  text-align: right !important; }

.select-project .search-filter-select {
  float: none; }

.select-project .select-project-system {
  min-width: 170px; }

.select-project .search-system-module {
  padding-right: 0; }

.select-project .group-select-inline {
  min-width: auto; }

.select-project .search-system-tree {
  margin-left: 30px; }

.wapper-checkbox-group-two-columns {
  margin-bottom: 12px;
  border: 1px solid #dfe6ea; }
  .wapper-checkbox-group-two-columns .col-second {
    width: 95px !important; }
  .wapper-checkbox-group-two-columns .box-panel-heading .text-content {
    vertical-align: initial; }
  .wapper-checkbox-group-two-columns .text-content {
    display: inline-block;
    width: 80px;
    vertical-align: top;
    font-size: 12px; }
    .wapper-checkbox-group-two-columns .text-content .item-checkbox {
      display: inline;
      cursor: pointer; }
  .wapper-checkbox-group-two-columns .panel-title {
    display: inline-block;
    width: calc(100% - 177px);
    min-width: 140px;
    cursor: pointer; }
  .wapper-checkbox-group-two-columns .box-panel-heading .panel-heading,
  .wapper-checkbox-group-two-columns .box-panel-heading .panel-title {
    background-color: #fff;
    border: none;
    font-size: 12px;
    color: #182026;
    font-family: "Roboto-Bold", sans-serif; }
  .wapper-checkbox-group-two-columns .pt-control.pt-disabled {
    opacity: 0.6;
    cursor: not-allowed; }
  .wapper-checkbox-group-two-columns .panel-item .panel-heading {
    cursor: default; }
  .wapper-checkbox-group-two-columns .loading-container {
    padding: 0 !important; }

.fixtopMenuRight, .fixtopMenuRightHasSideBar {
  position: fixed;
  top: 53px;
  right: 0;
  background: transparent !important;
  box-shadow: none !important;
  padding-left: 12px !important;
  padding-right: 20px !important;
  float: none !important;
  z-index: 2 !important; }
  .fixtopMenuRight .full-box-content, .fixtopMenuRightHasSideBar .full-box-content {
    background: #fff;
    -webkit-box-shadow: 0 8px 21px 0 rgba(199, 199, 199, 0.21) !important;
    box-shadow: 0 8px 21px 0 rgba(199, 199, 199, 0.21) !important;
    border-radius: 0 4px 4px 4px; }

.fixtopMenuRightHasSideBar {
  padding-left: 5px !important; }

.button-back-fixed {
  -webkit-transition: all 0.5s ease;
  -moz-transition: position 10s;
  -ms-transition: position 10s;
  -o-transition: position 10s;
  transition: all 0.5s ease; }

.fixed-button-back {
  position: fixed;
  top: 120px;
  z-index: 1;
  left: 84px;
  animation: smoothScroll 1s forwards; }

.fixed-button-hasSidebar {
  left: 280px;
  position: fixed;
  top: 120px;
  z-index: 1;
  animation: smoothScroll 1s forwards; }
  .fixed-button-hasSidebar .body-view-file-mobile {
    margin-top: 51px; }

.fixed-viewfile-hasSidebar .action-top {
  position: fixed;
  top: 55px;
  z-index: 1;
  width: calc(70% - 210px); }

.fixed-viewfile-hasSidebar .body-view-file-mobile {
  margin-top: 60px; }

.fixed-viewfile .action-top {
  position: fixed;
  top: 55px;
  z-index: 1;
  width: calc(70% - 73px); }

.fixed-viewfile .body-view-file-mobile {
  margin-top: 60px; }

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px); }
  100% {
    transform: translateY(0px); } }

.loading-view-work {
  background-color: #6db3f2;
  background: url("../loading_circle.gif") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  height: 20px;
  width: 20px; }

.pt-tab-color-cabinet {
  color: #777 !important; }

li.active-select > .rc-tree-select-tree-node-content-wrapper .rc-tree-select-tree-title {
  color: #195fff !important;
  cursor: pointer !important;
  opacity: 1 !important; }

li.disable-select > .rc-tree-select-tree-node-content-wrapper .rc-tree-select-tree-title {
  color: rgba(151, 154, 156, 0.88);
  cursor: default; }

.text-abridgment {
  font-size: 15px !important;
  font-weight: 600 !important; }

.font-20 {
  font-size: 20px !important; }

.font-13 {
  font-size: 13px !important; }
  .font-13:before {
    font-size: 13px !important; }

.text-bold {
  font-weight: bold !important; }

.text-normal {
  text-transform: none; }

.center {
  text-align: center !important; }

.column-3 {
  width: 3%; }

.column-5 {
  width: 5%; }

.column-7 {
  width: 7%; }

.column-10 {
  width: 10%; }

.column-35 {
  width: 35%; }

.column-13 {
  width: 13%; }

.column-15 {
  width: 15%; }

.column-27 {
  width: 27%; }

.column-20 {
  width: 20%; }

.column-30 {
  width: 30%; }

.column-2 {
  width: 2%; }

.column-9 {
  width: 9%;
  min-width: 105px; }

.column-35 {
  width: 35%; }

.column-45 {
  width: 45%; }

.column-50 {
  width: 50%; }

.column-18 {
  width: 18%; }

.column-32 {
  width: 32%; }

.column-60px {
  width: 60px; }

.column-130px {
  min-width: 130px; }

.react-datepicker__input-container .gridview-filter {
  height: 30px !important; }

.gridview-filter-select {
  width: 100%;
  background: #fff;
  border-radius: 3px;
  min-height: 30px;
  font-family: 'Roboto-Regular';
  font-size: 13px;
  color: #454a52;
  letter-spacing: 0;
  text-align: left;
  position: relative; }

.gridview-filter-select::after {
  content: '\E697';
  color: #9fa2a5;
  font-size: 14px;
  padding-left: 11px;
  font-family: 'Icons20', sans-serif;
  position: absolute;
  line-height: 0;
  right: 7px;
  top: 15px; }

.daterangepicker-gridview {
  position: relative; }
  .daterangepicker-gridview .react-datepicker-popper {
    transform: none !important;
    top: 35px !important;
    margin-top: 10px; }
  .daterangepicker-gridview .react-datepicker-popper .react-datepicker__triangle {
    border: 8px solid transparent;
    border-top: none;
    border-bottom-color: #aeaeae !important; }
  .daterangepicker-gridview .react-datepicker-popper .react-datepicker__triangle::before {
    content: '';
    position: absolute;
    border: 8px solid transparent;
    border-top: none;
    border-bottom-color: #f0f0f0 !important;
    top: 1px;
    left: -8px;
    z-index: 1; }
  .daterangepicker-gridview .react-datepicker-popper .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
    left: 50px !important;
    right: auto !important; }
  .daterangepicker-gridview .react-datepicker__input-container .react-datepicker__close-icon {
    top: 12px !important; }

.react-datepicker-popper {
  z-index: 21; }

.position-gridview {
  position: relative; }
  .position-gridview .pt-close-gridview {
    position: absolute;
    top: 17px;
    right: 15px;
    cursor: pointer; }
  .position-gridview .pt-close-gridview-search {
    position: absolute;
    top: 12px;
    right: 45px;
    cursor: pointer; }
  .position-gridview .pt-close-gridview-cqbh {
    position: absolute;
    top: 45px;
    right: 10px;
    cursor: pointer; }
  .position-gridview .icon-close:before {
    color: #a5a7ac; }

.form-min-height-auto .item-gridview-filter form {
  min-height: auto; }

.item-gridview-filter {
  overflow-y: hidden !important; }
  .item-gridview-filter form {
    min-height: 450px; }
  .item-gridview-filter .pt-table {
    background: #fff;
    border: 1px solid #dfe6ea; }
    .item-gridview-filter .pt-table:before {
      content: '';
      position: absolute;
      height: 37px;
      width: calc(100% + 1px);
      bottom: -38px;
      background: #fff;
      border: 1px solid #dfe6ea;
      border-top: 0;
      left: 0px; }
    .item-gridview-filter .pt-table td {
      padding: 7px 5px;
      vertical-align: middle; }
    .item-gridview-filter .pt-table .note-issues {
      position: relative;
      text-align: left; }
    .item-gridview-filter .pt-table .list-item-center {
      text-align: center !important; }
  .item-gridview-filter .custom-grid-filter {
    font-size: 13px;
    box-shadow: none;
    border: 1px solid #e3e4e8; }
  .item-gridview-filter .padding-right-input-grid-filter {
    padding-right: 26px; }
  .item-gridview-filter .fix-table-before {
    width: calc(100% - 1px) !important; }
    .item-gridview-filter .fix-table-before:before {
      content: '';
      height: 0;
      border: 0; }

.daterangepicker-gridview-custom .react-datepicker__day {
  font-weight: normal; }

.item-gridview-filter-TTL form {
  min-height: 360px;
  margin-right: 1px; }

.table-container .footer-gridview .style-qh {
  background: none; }

.table-container .footer-gridview .style-gqh {
  background: none; }

.mg-bt-20 {
  margin-bottom: 20px;
  border-bottom: 1px solid #dfe7ea; }

.input-search-filter {
  padding-right: 65px !important; }

.item-wrap-table {
  padding-bottom: 0;
  border: 0; }

.icon-Group-8 {
  font-size: 8px !important; }

.overflow-hidden-54px {
  height: 54px !important;
  overflow: hidden !important; }

.overflow-hidden-90px {
  height: 90px !important;
  overflow: hidden !important; }

.overflow-hidden-36px {
  height: 36px !important;
  overflow: hidden !important; }

.overflow-hidden-72px {
  height: 72px !important;
  overflow: hidden !important; }

.overflow-hidden-66px {
  height: 66px !important;
  overflow: hidden !important; }

.gridview-filter-select .select-tree-filter {
  width: 100%;
  min-width: 160px !important;
  color: #182026;
  font-weight: 100; }
  .gridview-filter-select .select-tree-filter .rc-tree-select-selection {
    min-height: 30px !important; }
  .gridview-filter-select .select-tree-filter .rc-tree-select-arrow {
    top: 0 !important;
    display: none !important; }
  .gridview-filter-select .select-tree-filter .rc-tree-select-selection__rendered {
    margin-top: -4px !important; }

.gridview-filter-select .input-select input {
  height: 30px;
  min-width: 85px; }

.dropdown-select-tree {
  width: 160px !important; }

.align-item-center {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.align-items-base {
  align-items: baseline; }

.main-form .form-block2 {
  margin-bottom: 20px;
  margin-top: 10px;
  box-shadow: 0 7px 5px -6px #777;
  background: #f5f5f5; }

.form-block-2 .form-subblock {
  margin-bottom: 20px !important;
  box-shadow: 0 7px 5px -6px #777;
  background: #f5f5f5; }
  .form-block-2 .form-subblock .color-blue {
    color: #185fff; }

.font-size-14 {
  font-size: 14px !important; }
  .font-size-14 .d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }

.position-relative {
  position: relative !important; }
  .position-relative .position-absolute {
    position: absolute;
    top: 11px;
    right: 10px;
    cursor: pointer; }

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.d-block {
  display: block; }

.d-inline {
  display: inline-block; }

.justify-start {
  justify-content: flex-start; }

.justify-center {
  justify-content: center; }

.item-center {
  align-items: center; }

.justify-end {
  justify-content: flex-end; }

.icon-close.darker-color::before {
  color: #a5a7ac; }

.text-warning {
  color: #db3737;
  font-style: italic;
  font-size: 14px; }

.text-warning-bootstrap {
  color: #ffc107 !important; }

.text-primary-bootstrap {
  color: #007bff !important; }

.icon-Group-8::before {
  color: #454a52 !important; }

.border-right {
  border-right: 1px solid rgba(151, 151, 151, 0.21); }

.border-left {
  border-left: 1px solid rgba(151, 151, 151, 0.21); }

.border-top {
  border-top: 1px solid rgba(151, 151, 151, 0.21); }

.border-bottom {
  border-bottom: 1px solid rgba(151, 151, 151, 0.21); }

.vh-65 {
  height: 65vh; }

[class^='icon-'].icon-blue,
[class*=' icon-'].icon-blue {
  color: #1b60ff; }

.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.flex-column-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.font-size-18 {
  font-size: 18px !important; }

.pl80 {
  padding-left: 80px !important; }

.animate-spin {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.d-inline-block {
  display: inline-block !important; }

.d--inline-block {
  display: inline-block; }

.flipY {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.text-danger {
  color: #c23030 !important; }

.w-50 {
  width: 50%; }

.border-secondary {
  border: 1px solid #d2d2d2; }

.w-150px {
  width: 150px; }

.w-145px {
  width: 145px; }

.w-115px {
  width: 115px; }

.bg-white {
  background-color: #fff; }

.text-center {
  text-align: center; }

.border-left-width-0 {
  border-left-width: 0 !important; }

.border-radius-lelf-20 {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px; }

.border-radius-right-20 {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px; }

.bg-primary {
  background-color: #195fff; }

.border-primary {
  border: 1px solid #195fff; }

.text-white {
  color: #fff; }

.transition-all-300 {
  transition: all 0.3s ease, box-shadow 0.5s ease; }

.box-shadow-primary {
  box-shadow: 0 0px 2px 2px rgba(25, 95, 254, 0.3); }

.z-index-1 {
  z-index: 1; }

.w-100 {
  width: 100% !important; }

.ml65 {
  margin-right: 65px !important; }

.top-0 {
  top: 0; }

.left-0 {
  left: 0; }

.right-70px {
  right: 70px; }

.position-absolute {
  position: absolute; }

.border-right-radius-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }
  .border-right-radius-0 .Select-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.height-39px .Select-control {
  height: 39px; }

.select-custom-absolute-icon .Select-arrow-zone {
  position: absolute;
  top: 10px;
  left: 9px; }

.select-custom-absolute-icon .Select-placeholder {
  padding-left: 32px; }

.select-custom-absolute-icon .Select-input {
  padding-left: 32px; }

.select-custom-absolute-icon .Select-value {
  padding-left: 32px !important; }

.select-custom-input .Select-multi-value-wrapper {
  display: block; }
  .select-custom-input .Select-multi-value-wrapper .Select-placeholder {
    top: unset; }
  .select-custom-input .Select-multi-value-wrapper .Select-value {
    top: unset; }
  .select-custom-input .Select-multi-value-wrapper .Select-input {
    display: block !important;
    font-size: 13px;
    padding-top: 3px; }
  .select-custom-input .Select-multi-value-wrapper .Select-input > input {
    width: 100% !important; }

.select-custom-input .Select-value {
  font-size: 13px;
  padding-right: 35px !important; }

.select-menu-outer-z-index-3 .Select-menu-outer {
  z-index: 3; }

.select-hide-clear-btn .Select-clear-zone {
  display: none; }

.z-index-1 {
  z-index: 1; }

.font-weight-bold {
  font-weight: bold; }

.text-fresh-red {
  color: #cc1d1d !important; }
  .text-fresh-red .pt-form-helper-text {
    color: #cc1d1d !important; }

.border-top-secondary-2px {
  border-top: 2px solid #f1f1f1; }

.border-bottom-secondary-2px {
  border-bottom: 2px solid #f1f1f1; }

.has-action {
  cursor: pointer; }

.default-color-icon::before {
  color: inherit !important; }

.size-icon-6::before {
  font-size: 6px !important; }

.size-icon-7::before {
  font-size: 7px !important; }

.size-icon-8::before {
  font-size: 8px !important; }

.size-icon-9::before {
  font-size: 9px !important; }

.size-icon-10::before {
  font-size: 10px !important; }

.size-icon-11::before {
  font-size: 11px !important; }

.size-icon-12::before {
  font-size: 12px !important; }

.size-icon-13::before {
  font-size: 13px !important; }

.size-icon-14::before {
  font-size: 14px !important; }

.size-icon-15::before {
  font-size: 15px !important; }

.size-icon-16::before {
  font-size: 16px !important; }

.size-icon-17::before {
  font-size: 17px !important; }

.size-icon-18::before {
  font-size: 18px !important; }

.size-icon-19::before {
  font-size: 19px !important; }

.size-icon-20::before {
  font-size: 20px !important; }

.size-icon-21::before {
  font-size: 21px !important; }

.size-icon-22::before {
  font-size: 22px !important; }

.size-icon-23::before {
  font-size: 23px !important; }

.size-icon-24::before {
  font-size: 24px !important; }

.size-icon-25::before {
  font-size: 25px !important; }

.size-icon-26::before {
  font-size: 26px !important; }

.size-icon-27::before {
  font-size: 27px !important; }

.size-icon-28::before {
  font-size: 28px !important; }

.size-icon-29::before {
  font-size: 29px !important; }

.size-icon-30::before {
  font-size: 30px !important; }

.size-icon-31::before {
  font-size: 31px !important; }

.size-icon-32::before {
  font-size: 32px !important; }

.size-icon-33::before {
  font-size: 33px !important; }

.size-icon-34::before {
  font-size: 34px !important; }

.size-icon-35::before {
  font-size: 35px !important; }

.size-icon-36::before {
  font-size: 36px !important; }

.size-icon-37::before {
  font-size: 37px !important; }

.size-icon-38::before {
  font-size: 38px !important; }

.size-icon-39::before {
  font-size: 39px !important; }

.size-icon-40::before {
  font-size: 40px !important; }

.no-border {
  border: 0 !important; }

.read-on {
  color: #185fff;
  cursor: pointer;
  font-weight: bold; }

.checkbox-inline {
  display: inline-block;
  font-size: 0.001px; }

.avatar-background--box {
  max-width: 36px;
  margin: 0 auto; }

.avatar-background {
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #e7e7e7; }

.opacity-05 {
  opacity: 0.6; }

.font-weight-300 {
  font-weight: 300; }

.font-weight-400 {
  font-weight: 400; }

.font-weight-500 {
  font-weight: 500; }

.font-weight-600 {
  font-weight: 600; }

.font-weight-700 {
  font-weight: 700; }

.fs10 {
  font-size: 10px; }

.fs11 {
  font-size: 11px; }

.fs12 {
  font-size: 12px; }

.fs13 {
  font-size: 13px; }

.fs14 {
  font-size: 14px; }

.fs15 {
  font-size: 15px; }

.fs16 {
  font-size: 16px; }

.fs17 {
  font-size: 17px; }

.fs18 {
  font-size: 18px; }

.small-file-icon {
  background-size: 13px !important;
  padding: 5px 0 5px 24px; }

.asterisk::after {
  content: ' *';
  color: #db3737; }

div.pt-popover-target {
  width: 100%; }

.flex-wrap {
  flex-wrap: wrap; }

.head-check-all .pt-checkbox {
  display: inline-block;
  vertical-align: middle;
  margin: 0 !important;
  margin-left: 10px !important; }

.detail-label {
  font-weight: 500;
  color: #738a95;
  font-size: 13px; }

.detail-text {
  font-weight: 500;
  color: #31434b;
  font-size: 13px; }

.cpc-timeline {
  position: relative; }
  .cpc-timeline::after {
    content: '';
    position: absolute;
    width: 1px;
    background-color: #dfe6ea;
    top: 0;
    bottom: 0; }
  .cpc-timeline .timeline_item {
    margin-bottom: 15px;
    margin-left: 15px;
    position: relative;
    font-size: 13px; }
    .cpc-timeline .timeline_item::after {
      content: '';
      position: absolute;
      width: 7px;
      height: 7px;
      top: 8px;
      left: -18px;
      z-index: 1;
      background-color: #195fff;
      border-radius: 10px; }
    .cpc-timeline .timeline_item .phong_ban {
      color: #212121;
      font-weight: 600; }
    .cpc-timeline .timeline_item .time {
      font-weight: 400; }
    .cpc-timeline .timeline_item .avatar-user {
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      display: block;
      height: 37px;
      min-width: 37px;
      width: 37px; }
    .cpc-timeline .timeline_item .name {
      color: #212121;
      font-weight: 600; }
    .cpc-timeline .timeline_item .chuc_vu {
      color: #738a95;
      font-weight: 400; }

.cpc-input-label {
  font-size: 13px;
  font-weight: 500;
  color: #262626;
  margin-bottom: 5px; }

.gap-5 {
  gap: 5px; }

.dropdown-300 {
  max-height: 300px;
  overflow: auto; }

.circle-border {
  display: inline-flex;
  height: 15px;
  width: 15px;
  align-items: center;
  background: #43b976;
  justify-content: center;
  border-radius: 10px; }
  .circle-border .checkmark {
    transform: rotate(45deg);
    height: 10px;
    width: 6px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    margin-bottom: 20%; }

.do-collapse-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 13px;
  position: relative; }
  .do-collapse-title .chuc_vu {
    font-weight: 600;
    color: #212121;
    font-family: "Roboto", sans-serif; }
  .do-collapse-title .vai_tro {
    color: #195fff; }
    .do-collapse-title .vai_tro.red {
      color: #d24848 !important; }
  .do-collapse-title .thoi_gian {
    color: #738a95; }

.border {
  border: 1px solid #dfe6ea; }

.cpc-login-form .main-title {
  font-family: "Roboto-medium", sans-serif;
  font-weight: normal; }

.cpc-login-page {
  padding-bottom: 100px; }
  .cpc-login-page .cpc-footer {
    left: 0; }

.form-padding-box {
  padding: 20px; }

.form-container .label-inline-input {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline; }

.form-container .center-checkbox {
  margin-top: 35px; }

.form-container .note-highline {
  color: #ea5744;
  font-size: 13px;
  font-style: italic;
  padding-bottom: 10px;
  padding-top: 5px; }
  .form-container .note-highline.note-qoute-left {
    padding-left: 22px;
    position: relative; }
    .form-container .note-highline.note-qoute-left:before {
      content: '\e9ad';
      font-family: 'icomoon' !important;
      font-size: 11px;
      left: 0;
      position: absolute;
      top: 5px; }

.form-container .blod-select {
  color: #195fff;
  font-family: "Roboto-Bold", sans-serif; }

.form-container .symbol-documentary {
  color: #6a7489;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  font-family: "Roboto-medium", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  padding-top: 10px;
  text-align: center; }

.form-container .pt-label {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 14px;
  color: #454a52;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 0; }

.form-container .pt-select {
  width: 100%; }

.form-container .note-input {
  opacity: 0.74;
  font-family: "Roboto-Italic", sans-serif;
  font-size: 13px;
  color: #454a52;
  letter-spacing: 0;
  text-align: left;
  margin-top: 7px;
  position: relative;
  top: 7px; }

.form-container .pt-form-group {
  margin: 0 0 12px;
  display: block; }
  .form-container .pt-form-group .pt-label {
    line-height: 1.6;
    margin-bottom: 5px; }
  .form-container .pt-form-group .icon-remove-file {
    font-size: 15px;
    line-height: 40px;
    padding-left: 7px;
    cursor: pointer;
    position: absolute;
    right: 90px; }
    .form-container .pt-form-group .icon-remove-file:before {
      color: #185fff; }
    .form-container .pt-form-group .icon-remove-file:hover:before {
      color: red; }
    .form-container .pt-form-group .icon-remove-file.icon-remove-file-input {
      top: 0px; }
  .form-container .pt-form-group .pt-form-helper-text {
    text-align: left; }
    .form-container .pt-form-group .pt-form-helper-text .pt-form-helper-text-content {
      padding-left: 32.5%; }
  .form-container .pt-form-group .pt-report-work {
    padding-left: 0; }
  .form-container .pt-form-group .pt-form-helper-content {
    padding-top: 4px;
    padding-left: 0; }
  .form-container .pt-form-group .pt-form-helper-infomation {
    color: #454a52; }
    .form-container .pt-form-group .pt-form-helper-infomation .size-file {
      font-weight: bold; }
  .form-container .pt-form-group .col-form-item .pt-label {
    margin-bottom: 0; }
  .form-container .pt-form-group .checkbox-tree-section .loading-container {
    padding: 0; }

.form-container .pt-file-upload,
.form-container .pt-input,
.form-container .pt-select select,
.form-container .pt-select .select {
  width: 100%;
  background: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  min-height: 39px;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  color: #454a52;
  letter-spacing: 0;
  text-align: left; }

.form-container .pt-file-upload {
  vertical-align: top;
  overflow: hidden;
  margin-left: -1px; }

.form-container .upload-have-close {
  width: 100%;
  position: relative; }

.form-container .pt-intent-danger {
  position: relative; }
  .form-container .pt-intent-danger .pt-form-content {
    margin-bottom: 0; }
    .form-container .pt-intent-danger .pt-form-content .select-tree {
      width: 100%; }
  .form-container .pt-intent-danger:last-child {
    margin-bottom: 0; }

.form-container textarea.pt-input {
  width: 100% !important; }

.form-container .pt-popover-target {
  width: 100%; }

.form-container .pt-icon-calendar::before {
  position: absolute;
  right: 0;
  top: 6px;
  color: #a5a7ab;
  font-size: 13px; }

.form-container .pt-select::after {
  content: '\E697';
  color: #9fa2a5;
  font-size: 14px;
  padding-left: 11px;
  font-family: 'Icons20', sans-serif;
  position: relative;
  line-height: 0;
  right: 10px;
  float: right;
  top: -19px; }

.form-container .pt-select select,
.form-container .pt-select .select,
.form-container input[type='email'],
.form-container input[type='file'],
.form-container input[type='number'],
.form-container input[type='password'],
.form-container input[type='phone'],
.form-container input[type='text'],
.form-container textarea {
  box-shadow: none;
  border: 1px solid #d2d2d2; }
  .form-container .pt-select select:focus,
  .form-container .pt-select .select:focus,
  .form-container input[type='email']:focus,
  .form-container input[type='file']:focus,
  .form-container input[type='number']:focus,
  .form-container input[type='password']:focus,
  .form-container input[type='phone']:focus,
  .form-container input[type='text']:focus,
  .form-container textarea:focus {
    box-shadow: none;
    border-color: rgba(25, 95, 255, 0.5); }

.form-container .pt-file-upload-input {
  min-height: 39px;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  color: #bebebe;
  letter-spacing: 0;
  text-align: left;
  padding: 5px 15px;
  left: 1px; }
  .form-container .pt-file-upload-input::after {
    min-height: 39px;
    color: #fff;
    background: #195fff;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    text-align: center;
    line-height: 18px;
    padding: 10px 0;
    content: 'Chọn file';
    margin: 0; }

.form-container .label-item {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 14px;
  color: #212121;
  letter-spacing: 0;
  text-align: left; }

.form-container .info {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  color: #212121;
  line-height: 18px;
  text-align: left; }

.form-container .required-input {
  margin-left: 3px;
  color: #f00; }

.form-container .text-noted-medium {
  opacity: 0.74;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  color: rgba(69, 74, 82, 0.74);
  letter-spacing: 0;
  text-align: left;
  margin-top: 5px; }

.form-container .btn-add-file {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  height: 39px;
  background: #fff; }
  .form-container .btn-add-file span {
    font-family: Roboto-Bold;
    font-size: 12px;
    color: #6a7489;
    letter-spacing: 0;
    text-align: left;
    text-transform: uppercase; }
  .form-container .btn-add-file .icon-bc {
    margin-left: 10px; }
  .form-container .btn-add-file.blue {
    background: #195fff;
    color: #fff; }
    .form-container .btn-add-file.blue span {
      color: #fff; }
    .form-container .btn-add-file.blue .icon-bc:before {
      color: #fff; }

.form-container .selectbox-group {
  margin-bottom: 12px; }
  .form-container .selectbox-group .heading-group-select {
    margin-bottom: 0; }
    .form-container .selectbox-group .heading-group-select:after, .form-container .selectbox-group .heading-group-select:before {
      content: '';
      display: table; }
    .form-container .selectbox-group .heading-group-select:after {
      clear: both; }
  .form-container .selectbox-group .pt-collapse .pt-collapse-body {
    padding-bottom: 4px;
    padding-top: 4px; }
  .form-container .selectbox-group .container-checkbox {
    margin-top: 8px; }
  .form-container .selectbox-group .container-list-other-units {
    margin-top: 10px; }

.form-container .pt-inline .pt-label {
  margin-top: 10px; }

.form-container .pt-inline .note-deadline {
  padding-top: 12px; }

.form-container.container-detail .pt-form-group .pt-label {
  line-height: 1.6;
  padding-top: 8px; }

.form-container.form-send-mail .pt-form-group .pt-label {
  line-height: 1.6;
  padding-top: 8px; }

.form-container.form-send-mail .pt-form-group .pt-form-content {
  font-size: 13px; }

.form-container .disabled-checkbox .pt-disabled .name_checkbox {
  color: rgba(33, 33, 33, 0.4); }

.form-container .disabled-checkbox .name_checkbox-unit-department {
  color: #7f7f7f; }

.form-container .box-DepartmentPerform .loading-container {
  padding: 0; }

.form-container .table-forward {
  width: 100%;
  border: 1px solid #d2d2d2; }
  .form-container .table-forward .thead-forward {
    font-weight: bold;
    font-size: 13px;
    background: #eff3f5; }
    .form-container .table-forward .thead-forward .tr-forward .th-forward {
      text-align: left;
      padding: 8px; }
  .form-container .table-forward .tbody-forward {
    font-size: 13px; }
    .form-container .table-forward .tbody-forward tr:nth-child(even) {
      background: #eff3f5; }
    .form-container .table-forward .tbody-forward tr:nth-child(odd) {
      background: #fff; }
    .form-container .table-forward .tbody-forward .tr-forward .td-forward {
      text-align: left;
      padding: 3px 8px 3px 8px; }
      .form-container .table-forward .tbody-forward .tr-forward .td-forward .radio-forward {
        min-height: 0px;
        padding-left: 0;
        margin-bottom: 20px; }
        .form-container .table-forward .tbody-forward .tr-forward .td-forward .radio-forward .pt-control-indicator {
          width: 16px;
          height: 16px; }

.form-container-popup .pt-label,
.form-container-popup .pt-file-upload,
.form-container-popup .pt-input,
.form-container-popup .pt-select select {
  color: #333; }

.pt-control.pt-checkbox input:checked ~ .pt-control-indicator::before,
.pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator::before {
  background: #195fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.pt-control-indicator {
  font-size: 10px;
  background: #fff !important; }
  .pt-control-indicator:before {
    background: #195fff !important;
    outline: none; }
  .pt-control-indicator:hover {
    background: none;
    outline: none; }
  .pt-control-indicator:focus {
    background: none;
    outline: none; }

.pt-form-helper-text {
  font-family: "Roboto-Regular", sans-serif; }

.selectbox-group {
  margin-bottom: 20px; }
  .selectbox-group .container-checkbox {
    background: #fff;
    border: 1px solid #d2d2d2;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    /*.pt-checkbox .pt-control-indicator {
      top: 4px;
    }*/ }
    .selectbox-group .container-checkbox .row {
      margin: 0; }
    .selectbox-group .container-checkbox .loading-container {
      padding: 0; }
  .selectbox-group .item-checkbox {
    padding: 6px 10px;
    min-height: 18px;
    line-height: 18px; }
  .selectbox-group .pt-checkbox {
    margin: 0; }
  .selectbox-group .name_checkbox {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    color: #212121;
    letter-spacing: 0;
    text-align: left; }
  .selectbox-group .drop-down-checbox::before {
    color: #9fa2a5;
    font-size: 12px;
    padding-left: 4px;
    position: relative;
    top: -1px; }

.selectbox-inline .pt-label {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 12px;
  color: #212121;
  letter-spacing: 0;
  text-align: left; }

.selectbox-inline .pt-select select {
  background-color: #fff;
  background-image: none;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  color: #4a4a4a;
  letter-spacing: 0;
  text-align: left;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  min-height: 30px; }

.selectbox-inline .pt-select:after {
  content: '\E697';
  color: #9fa2a5;
  font-size: 12px;
  padding-left: 11px;
  font-family: 'Icons20', sans-serif;
  position: relative;
  line-height: 0;
  right: 10px;
  float: right;
  top: -15px; }

.heading-group-select {
  margin-bottom: 13px; }
  .heading-group-select .name_checkbox {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    color: #212121;
    letter-spacing: 0;
    text-align: left; }
  .heading-group-select .name_group {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 14px;
    color: #454a52;
    letter-spacing: 0;
    text-align: left; }
  .heading-group-select .name_group_unit {
    cursor: pointer;
    padding-right: 7px; }

.cpc-login-form .pt-input-group.pt-large .pt-icon {
  line-height: 1; }

.input-icon-datepicker {
  background-color: #fff;
  background-image: url("../calendar.svg");
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center right 10px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  cursor: pointer; }

.add-receive-document .form-container .file-box {
  margin-bottom: 0; }

.select2-custom-iconsearch .Select-arrow-zone {
  line-height: 1;
  font-family: 'Icons20';
  font-size: inherit;
  font-weight: 400;
  font-style: normal;
  color: #8a9da7; }
  .select2-custom-iconsearch .Select-arrow-zone:before {
    content: '\E64B'; }

.Select-placeholder {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  color: #bebebe;
  letter-spacing: 0;
  text-align: left; }

.rc-tree-select-tree-title {
  font-size: 14px;
  font-family: "Roboto-Regular", sans-serif;
  cursor: pointer; }
  .rc-tree-select-tree-title:hover {
    font-family: "Roboto-medium", sans-serif; }

.rc-tree-select-tree-node-selected {
  background-color: transparent;
  border: none;
  opacity: 1;
  color: #185fff;
  font-family: "Roboto-medium", sans-serif; }

.dropdown-tree .rc-tree-select-selection {
  background: #fff;
  border: 1px solid #e0e1e4;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  margin: 0;
  min-height: 38px;
  padding: 4px 0px; }
  .dropdown-tree .rc-tree-select-selection:active {
    border-color: rgba(25, 95, 255, 0.5); }
  .dropdown-tree .rc-tree-select-selection:hover {
    border-color: rgba(25, 95, 255, 0.5);
    -webkit-box-shadow: 0 0 1px #185fff;
    box-shadow: 0 0 1px #185fff; }

.dropdown-tree .rc-tree-select-selection--single .rc-tree-select-selection-selected-value {
  text-align: left; }

.dropdown-tree .rc-tree-select-arrow {
  top: 5px; }

.dropdown-tree .rc-tree-select-dropdown-search .rc-tree-select-search__field {
  padding: 10px !important; }

.dropdown-tree .rc-tree-select-search__field__placeholder {
  left: 10px;
  top: 50%;
  transform: translateY(-50%); }

.dropdown-tree .rc-tree-select-arrow b {
  display: none; }

.dropdown-tree .rc-tree-select-arrow:after {
  content: '\E697';
  color: #9fa2a5;
  font-size: 14px;
  padding-left: 11px;
  font-family: 'Icons20', sans-serif;
  position: relative;
  line-height: 0;
  right: 10px;
  float: right;
  top: 15px; }

.dropdown-tree.input-tree .rc-tree-select-selection {
  border-color: #d2d2d2; }

.check-dropdown-tree .rc-tree-select-tree-node-content-wrapper {
  pointer-events: none; }

.check-dropdown-tree .rc-tree-select-tree-switcher {
  display: none !important; }

.react-datepicker-wrapper {
  display: block; }

.progress-bar-upload {
  margin-top: 5px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline; }

.progress-upload-content {
  clear: both;
  padding-right: 50px;
  position: relative; }
  .progress-upload-content .percent-content {
    color: #195fff;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    font-family: "Roboto-medium", sans-serif;
    font-size: 13px;
    position: absolute;
    right: 0;
    top: 4px; }

.dropdown-tree-filter {
  min-width: 270px; }

.code-sign-number {
  color: #0344d7; }

.checkbox-group-two-columns {
  margin-top: 5px; }
  .checkbox-group-two-columns .pt-control.pt-disabled {
    opacity: 0.6; }
  .checkbox-group-two-columns .col-third {
    width: 112px; }

.box-parent-unit {
  margin-bottom: 12px; }

.pt-select .select {
  background-repeat: no-repeat !important;
  background-size: 25px !important;
  background-position-x: 10px !important;
  background-position-y: 6px !important;
  cursor: pointer;
  padding: 0px 25px 0px 35px; }
  .pt-select .select .file-name {
    line-height: 39px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap; }
    .pt-select .select .file-name .name {
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis; }
    .pt-select .select .file-name .extention {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto; }
  .pt-select .select.focus {
    border-color: rgba(25, 95, 255, 0.5) !important; }

.custom-dropdown {
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  width: 100%;
  top: 100%;
  left: 0;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
  .custom-dropdown .custom-dropdown-item {
    cursor: pointer;
    padding: 5px 25px 5px 35px;
    font-size: 14px;
    background-repeat: no-repeat;
    background-size: 25px;
    background-position-x: 10px;
    background-position-y: 2px; }
    .custom-dropdown .custom-dropdown-item:hover {
      background-color: #195fff !important;
      color: #fff; }
    .custom-dropdown .custom-dropdown-item.choosen {
      background-color: #d2d2d2; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.recaptcha {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 20px; }

.progress-circle {
  margin: 0 auto;
  position: relative;
  padding: 0;
  width: 69px;
  height: 69px;
  background-color: #eee;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%; }
  .progress-circle .number-content {
    color: #656565;
    font-family: "Roboto-Regular", sans-serif;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: auto;
    position: absolute;
    width: 100%;
    line-height: 1;
    text-align: center;
    display: block;
    z-index: 2; }
    .progress-circle .number-content .number-top {
      display: block;
      font-family: "Roboto-Bold", sans-serif;
      font-size: 18px; }
    .progress-circle .number-content .number-bottom {
      border-top: 1px solid #afafaf;
      display: -moz-inline-stack;
      display: inline-block;
      zoom: 1;
      *display: inline;
      font-size: 10px;
      margin-top: 4px;
      padding-top: 4px;
      min-width: 24px; }
  .progress-circle .value-bar {
    position: absolute;
    clip: rect(0, 35px, 69px, 0);
    width: 69px;
    height: 69px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 3px solid #0790fa;
    box-sizing: border-box; }
  .progress-circle .left-half-clipper {
    /* a round circle */
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 69px;
    height: 69px;
    position: absolute;
    /* needed for clipping */
    clip: rect(0, 69px, 69px, 35px);
    /* clips the whole left half*/ }
  .progress-circle.over50 .left-half-clipper {
    clip: rect(auto, auto, auto, auto); }
  .progress-circle.over50 .first50-bar {
    clip: rect(0, 69px, 69px, 35px);
    background-color: #0790fa;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 69px;
    height: 69px;
    position: absolute; }
  .progress-circle.progress-orange.over50 .first50-bar {
    background-color: #f6b026; }
  .progress-circle.progress-orange .value-bar {
    border-color: #f6b026; }
  .progress-circle.progress-chrysoprase.over50 .first50-bar {
    background-color: #19bc9c; }
  .progress-circle.progress-chrysoprase .value-bar {
    border-color: #19bc9c; }
  .progress-circle.progress-lightblue.over50 .first50-bar {
    background-color: #0790fa; }
  .progress-circle.progress-lightblue .value-bar {
    border-color: #0790fa; }
  .progress-circle.progress-red.over50 .first50-bar {
    background-color: #e74c3b; }
  .progress-circle.progress-red .value-bar {
    border-color: #e74c3b; }
  .progress-circle:after {
    border: none;
    position: absolute;
    top: 3px;
    left: 3px;
    text-align: center;
    display: block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 63px;
    height: 63px;
    background-color: white;
    content: ' '; }
  .progress-circle:not(.over50) .first50-bar {
    display: none; }

/* when p>50, don't clip left half*/
/* Progress bar rotation position */
.progress-circle.percent0 .value-bar {
  display: none; }

.progress-circle.percent1 .value-bar {
  transform: rotate(4deg); }

.progress-circle.percent2 .value-bar {
  transform: rotate(7deg); }

.progress-circle.percent3 .value-bar {
  transform: rotate(11deg); }

.progress-circle.percent4 .value-bar {
  transform: rotate(14deg); }

.progress-circle.percent5 .value-bar {
  transform: rotate(18deg); }

.progress-circle.percent6 .value-bar {
  transform: rotate(22deg); }

.progress-circle.percent7 .value-bar {
  transform: rotate(25deg); }

.progress-circle.percent8 .value-bar {
  transform: rotate(29deg); }

.progress-circle.percent9 .value-bar {
  transform: rotate(32deg); }

.progress-circle.percent10 .value-bar {
  transform: rotate(36deg); }

.progress-circle.percent11 .value-bar {
  transform: rotate(40deg); }

.progress-circle.percent12 .value-bar {
  transform: rotate(43deg); }

.progress-circle.percent13 .value-bar {
  transform: rotate(47deg); }

.progress-circle.percent14 .value-bar {
  transform: rotate(50deg); }

.progress-circle.percent15 .value-bar {
  transform: rotate(54deg); }

.progress-circle.percent16 .value-bar {
  transform: rotate(58deg); }

.progress-circle.percent17 .value-bar {
  transform: rotate(61deg); }

.progress-circle.percent18 .value-bar {
  transform: rotate(65deg); }

.progress-circle.percent19 .value-bar {
  transform: rotate(68deg); }

.progress-circle.percent20 .value-bar {
  transform: rotate(72deg); }

.progress-circle.percent21 .value-bar {
  transform: rotate(76deg); }

.progress-circle.percent22 .value-bar {
  transform: rotate(79deg); }

.progress-circle.percent23 .value-bar {
  transform: rotate(83deg); }

.progress-circle.percent24 .value-bar {
  transform: rotate(86deg); }

.progress-circle.percent25 .value-bar {
  transform: rotate(90deg); }

.progress-circle.percent26 .value-bar {
  transform: rotate(94deg); }

.progress-circle.percent27 .value-bar {
  transform: rotate(97deg); }

.progress-circle.percent28 .value-bar {
  transform: rotate(101deg); }

.progress-circle.percent29 .value-bar {
  transform: rotate(104deg); }

.progress-circle.percent30 .value-bar {
  transform: rotate(108deg); }

.progress-circle.percent31 .value-bar {
  transform: rotate(112deg); }

.progress-circle.percent32 .value-bar {
  transform: rotate(115deg); }

.progress-circle.percent33 .value-bar {
  transform: rotate(119deg); }

.progress-circle.percent34 .value-bar {
  transform: rotate(122deg); }

.progress-circle.percent35 .value-bar {
  transform: rotate(126deg); }

.progress-circle.percent36 .value-bar {
  transform: rotate(130deg); }

.progress-circle.percent37 .value-bar {
  transform: rotate(133deg); }

.progress-circle.percent38 .value-bar {
  transform: rotate(137deg); }

.progress-circle.percent39 .value-bar {
  transform: rotate(140deg); }

.progress-circle.percent40 .value-bar {
  transform: rotate(144deg); }

.progress-circle.percent41 .value-bar {
  transform: rotate(148deg); }

.progress-circle.percent42 .value-bar {
  transform: rotate(151deg); }

.progress-circle.percent43 .value-bar {
  transform: rotate(155deg); }

.progress-circle.percent44 .value-bar {
  transform: rotate(158deg); }

.progress-circle.percent45 .value-bar {
  transform: rotate(162deg); }

.progress-circle.percent46 .value-bar {
  transform: rotate(166deg); }

.progress-circle.percent47 .value-bar {
  transform: rotate(169deg); }

.progress-circle.percent48 .value-bar {
  transform: rotate(173deg); }

.progress-circle.percent49 .value-bar {
  transform: rotate(176deg); }

.progress-circle.percent50 .value-bar {
  transform: rotate(180deg); }

.progress-circle.percent51 .value-bar {
  transform: rotate(184deg); }

.progress-circle.percent52 .value-bar {
  transform: rotate(187deg); }

.progress-circle.percent53 .value-bar {
  transform: rotate(191deg); }

.progress-circle.percent54 .value-bar {
  transform: rotate(194deg); }

.progress-circle.percent55 .value-bar {
  transform: rotate(198deg); }

.progress-circle.percent56 .value-bar {
  transform: rotate(202deg); }

.progress-circle.percent57 .value-bar {
  transform: rotate(205deg); }

.progress-circle.percent58 .value-bar {
  transform: rotate(209deg); }

.progress-circle.percent59 .value-bar {
  transform: rotate(212deg); }

.progress-circle.percent60 .value-bar {
  transform: rotate(216deg); }

.progress-circle.percent61 .value-bar {
  transform: rotate(220deg); }

.progress-circle.percent62 .value-bar {
  transform: rotate(223deg); }

.progress-circle.percent63 .value-bar {
  transform: rotate(227deg); }

.progress-circle.percent64 .value-bar {
  transform: rotate(230deg); }

.progress-circle.percent65 .value-bar {
  transform: rotate(234deg); }

.progress-circle.percent66 .value-bar {
  transform: rotate(238deg); }

.progress-circle.percent67 .value-bar {
  transform: rotate(241deg); }

.progress-circle.percent68 .value-bar {
  transform: rotate(245deg); }

.progress-circle.percent69 .value-bar {
  transform: rotate(248deg); }

.progress-circle.percent70 .value-bar {
  transform: rotate(252deg); }

.progress-circle.percent71 .value-bar {
  transform: rotate(256deg); }

.progress-circle.percent72 .value-bar {
  transform: rotate(259deg); }

.progress-circle.percent73 .value-bar {
  transform: rotate(263deg); }

.progress-circle.percent74 .value-bar {
  transform: rotate(266deg); }

.progress-circle.percent75 .value-bar {
  transform: rotate(270deg); }

.progress-circle.percent76 .value-bar {
  transform: rotate(274deg); }

.progress-circle.percent77 .value-bar {
  transform: rotate(277deg); }

.progress-circle.percent78 .value-bar {
  transform: rotate(281deg); }

.progress-circle.percent79 .value-bar {
  transform: rotate(284deg); }

.progress-circle.percent80 .value-bar {
  transform: rotate(288deg); }

.progress-circle.percent81 .value-bar {
  transform: rotate(292deg); }

.progress-circle.percent82 .value-bar {
  transform: rotate(295deg); }

.progress-circle.percent83 .value-bar {
  transform: rotate(299deg); }

.progress-circle.percent84 .value-bar {
  transform: rotate(302deg); }

.progress-circle.percent85 .value-bar {
  transform: rotate(306deg); }

.progress-circle.percent86 .value-bar {
  transform: rotate(310deg); }

.progress-circle.percent87 .value-bar {
  transform: rotate(313deg); }

.progress-circle.percent88 .value-bar {
  transform: rotate(317deg); }

.progress-circle.percent89 .value-bar {
  transform: rotate(320deg); }

.progress-circle.percent90 .value-bar {
  transform: rotate(324deg); }

.progress-circle.percent91 .value-bar {
  transform: rotate(328deg); }

.progress-circle.percent92 .value-bar {
  transform: rotate(331deg); }

.progress-circle.percent93 .value-bar {
  transform: rotate(335deg); }

.progress-circle.percent94 .value-bar {
  transform: rotate(338deg); }

.progress-circle.percent95 .value-bar {
  transform: rotate(342deg); }

.progress-circle.percent96 .value-bar {
  transform: rotate(346deg); }

.progress-circle.percent97 .value-bar {
  transform: rotate(349deg); }

.progress-circle.percent98 .value-bar {
  transform: rotate(353deg); }

.progress-circle.percent99 .value-bar {
  transform: rotate(356deg); }

.progress-circle.percent100 .value-bar {
  transform: rotate(360deg); }

.message-popup-delete {
  font-family: "Roboto-Regular", sans-serif; }

.pt-dialog {
  top: 150px;
  width: 350px;
  max-width: 95%;
  background: #fff;
  font-family: "Roboto-Regular", sans-serif;
  padding-bottom: 5px; }
  .pt-dialog .pt-dialog-header {
    min-height: 50px;
    font-family: "Roboto-Bold", sans-serif;
    position: relative;
    z-index: 1; }
    .pt-dialog .pt-dialog-header .pt-icon-small-cross::before {
      color: #dadcdd; }
  .pt-dialog .pt-dialog-body {
    line-height: 1.5; }
    .pt-dialog .pt-dialog-body .error {
      font-family: "Roboto-Bold", sans-serif;
      color: #db3737;
      margin-top: 10px;
      margin-bottom: 10px; }
  .pt-dialog .popup-warning-body {
    color: #d24848; }
  .pt-dialog .pt-dialog-footer {
    margin: 0px;
    padding: 15px 20px 0 20px;
    border-top: 1px solid #f0f0f0; }
  .pt-dialog .pt-dialog-footer-group-button {
    text-align: center; }
    .pt-dialog .pt-dialog-footer-group-button .group-button--content button {
      min-width: 74px; }
      .pt-dialog .pt-dialog-footer-group-button .group-button--content button span {
        font-size: 13px; }
  .pt-dialog .pt-dialog-footer-actions {
    justify-content: center;
    margin: 0 -5px; }
    .pt-dialog .pt-dialog-footer-actions .box-button {
      margin: 0 auto;
      justify-content: center; }
      .pt-dialog .pt-dialog-footer-actions .box-button button {
        min-width: 80px;
        height: 35px;
        margin: 0px;
        margin: 0 5px 10px 5px; }
      .pt-dialog .pt-dialog-footer-actions .box-button .button-exit {
        background: #fff;
        color: #888; }
      .pt-dialog .pt-dialog-footer-actions .box-button .button-exit:hover {
        background: #cecece;
        color: #000; }
      .pt-dialog .pt-dialog-footer-actions .box-button .button-blue {
        background: #195fff;
        color: #fff; }
      .pt-dialog .pt-dialog-footer-actions .box-button .button-blue:hover {
        background: #0344d7; }
  .pt-dialog .pt-dialog-close-button {
    font-size: 22px; }
  .pt-dialog.pt-dialog-button {
    padding-bottom: 0px; }

.pt-toast-container.pt-toast-container-top {
  top: 80px; }
  .pt-toast-container.pt-toast-container-top .pt-toast .pt-toast-message {
    padding-left: 30px;
    font-size: 13px;
    font-family: "Roboto-Regular", sans-serif; }

body.pt-overlay-open {
  z-index: 18;
  overflow: auto; }

.size-container-dialog {
  min-width: 488px;
  max-width: 100%; }
  .size-container-dialog.size-container-dialog--width-auto {
    min-width: auto; }
  .size-container-dialog.size-container-dialog--width-limit {
    width: 100%;
    max-width: 800px; }

.wrapper-content-dialog {
  margin: 0 0 17px;
  padding: 20px; }

.dialog-chonphieu {
  border-radius: 0px;
  padding: 0px; }
  .dialog-chonphieu .dialog-chonphieu-body .dialog-chonphieu-search {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    padding: 15px 20px;
    border-bottom: 1px solid #e2e2e2; }
  .dialog-chonphieu .dialog-chonphieu-body .pt-input {
    width: 100%;
    border-radius: 0px;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    border: none;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1); }
  .dialog-chonphieu .dialog-chonphieu-body ul {
    margin-bottom: 15px; }
    .dialog-chonphieu .dialog-chonphieu-body ul li {
      display: block;
      padding: 12px 20px;
      border-top: 1px solid #e2e2e2;
      position: relative;
      padding-right: 50px; }
      .dialog-chonphieu .dialog-chonphieu-body ul li:first-child {
        border-top: none; }
      .dialog-chonphieu .dialog-chonphieu-body ul li h4 {
        font-weight: 600;
        font-size: 13px;
        margin-bottom: 5px; }
      .dialog-chonphieu .dialog-chonphieu-body ul li p {
        font-size: 13px; }
      .dialog-chonphieu .dialog-chonphieu-body ul li:hover {
        cursor: pointer; }
      .dialog-chonphieu .dialog-chonphieu-body ul li.active:after {
        content: '\E948';
        display: inline-block;
        font-family: 'icomoon';
        position: absolute;
        top: 50%;
        margin-top: -11px;
        right: 15px;
        color: #195fff;
        width: 22px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        font-size: 14px; }

.pt-button.button-icon-left {
  padding-right: 15px !important; }
  .pt-button.button-icon-left .pt-icon {
    margin-left: 5px; }

.color-green {
  color: #08b424; }

.color-icon-green {
  color: #08b424; }
  .color-icon-green:before {
    color: #08b424; }

.color-orange-lv1 {
  color: #ed8525 !important; }

.color-red {
  color: #d24848; }

.color-red-important {
  color: #d24848 !important; }
  .color-red-important:before {
    color: #d24848 !important; }

.color-red-dark {
  color: #c42d56; }

.color-orange {
  color: #ff951a; }

.color-black {
  color: #31434b !important; }

.color-text {
  color: #212121; }

.color-gray {
  color: #777777; }

.color-blue {
  color: #185fff; }

.color-blue-important {
  color: #185fff !important; }
  .color-blue-important:before {
    color: #185fff !important; }

.color-yellow {
  color: #fc7e00; }

.color-danger {
  color: #d93448; }

.color-white {
  color: #fff; }

.icon-color-primary::before {
  color: #195fff; }

.icon-color-danger::before {
  color: #d93448; }

.icon-color-gray::before {
  color: #8a9da7; }

.icon-color-yellow::before {
  color: #fc7e00; }

.font-style-italic {
  font-style: italic; }

.word-break-word {
  word-break: break-word; }

.text-11-600 {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 11px;
  line-height: 15px; }

.text-12-500 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px; }

.text-13-500 {
  font-family: "Roboto-medium", sans-serif;
  font-size: 13px;
  line-height: 18px; }

.text-13-600 {
  font-family: "Roboto-Bold", sans-serif;
  font-size: 13px; }

.text-14-500 {
  font-family: "Roboto-medium", sans-serif;
  font-size: 14px;
  line-height: 18px; }

.d-flex {
  display: flex; }
  .d-flex.inline {
    display: inline-flex !important; }
  .d-flex.wrap {
    flex-wrap: wrap; }
  .d-flex.justify-space-between {
    justify-content: space-between; }
  .d-flex.align-items-flex-start {
    align-items: flex-start; }
  .d-flex.align-items-center {
    align-items: center; }
  .d-flex.align-items-flex-end {
    align-items: flex-end; }
  .d-flex-wrap {
    flex-wrap: wrap; }
  .d-flex.gap-5 {
    gap: 5px; }
  .d-flex.gap-10 {
    gap: 10px; }
  .d-flex.gap-15 {
    gap: 15px; }
  .d-flex.gap-20 {
    gap: 20px; }
  .d-flex.gap-25 {
    gap: 25px; }

.justify-content-center {
  justify-content: center; }

.justify-content-flex-end {
  justify-content: flex-end; }

.justify-content-space-between {
  justify-content: space-between; }

.gap-10 {
  gap: 10px; }

.gap-15 {
  gap: 15px; }

.gap-20 {
  gap: 20px; }

.gap-25 {
  gap: 25px; }

.gap-30 {
  gap: 30px; }

.icon-size-10::before {
  font-size: 10px !important; }

.icon-size-11::before {
  font-size: 11px !important; }

.icon-size-14::before {
  font-size: 14px !important; }

.icon-size-15::before {
  font-size: 15px !important; }

.icon-size-16::before {
  font-size: 16px !important; }

.icon-size-17::before {
  font-size: 17px !important; }

.icon-size-18::before {
  font-size: 18px !important; }

.icon-size-19::before {
  font-size: 19px !important; }

.icon-size-20::before {
  font-size: 20px !important; }

/*----------------------------header----------------------*/
.user-submenu-content .user-titles {
  list-style: none;
  margin: 0;
  padding: 0; }
  .user-submenu-content .user-titles .text-link {
    color: #454a52;
    font-family: "Roboto-Regular", sans-serif;
    display: block;
    padding: 2px 10px; }
    .user-submenu-content .user-titles .text-link:hover {
      color: #195fff;
      text-decoration: underline; }
  .user-submenu-content .user-titles .active .text-link {
    color: #0344d7;
    cursor: default;
    font-family: "Roboto-Bold", sans-serif; }
    .user-submenu-content .user-titles .active .text-link:hover {
      color: #0344d7;
      text-decoration: none; }
  .user-submenu-content .user-titles .ut-item {
    position: relative;
    padding: 0px 8px; }

.user-submenu-content .userinfo-content {
  cursor: default;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  padding: 6px 10px; }
  .user-submenu-content .userinfo-content .label-text {
    font-family: "Roboto-medium", sans-serif;
    margin-bottom: 4px; }

.user-submenu-content .item:last-child {
  border-top: 1px solid #e4ecf0; }

.cpc-menu {
  position: absolute;
  top: 100%;
  right: 0;
  display: none; }
  .cpc-menu .pt-menu {
    border: 1px solid #ededed;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    overflow: hidden;
    padding: 10px 0;
    min-width: 218px; }
  .cpc-menu .title-username .username {
    color: #0344d7;
    display: block;
    font-family: "Roboto-Bold", sans-serif;
    font-size: 14px;
    padding: 6px 10px;
    white-space: nowrap;
    cursor: pointer; }
    .cpc-menu .title-username .username .icon-profile:before {
      color: #0344d7;
      margin-right: 6px; }
    .cpc-menu .title-username .username:hover {
      background-color: rgba(0, 0, 0, 0.1);
      text-decoration: none; }
  .cpc-menu .icon-logout,
  .cpc-menu .icon-profile {
    font-size: 16px;
    position: relative;
    top: 2px; }
  .cpc-menu .btn-logout {
    padding: 7px 10px; }
  .cpc-menu .pt-menu-item {
    font-size: 13px;
    color: #212121;
    font-family: "Roboto-Regular", sans-serif; }
    .cpc-menu .pt-menu-item .name {
      padding-left: 6px; }

.header {
  background-color: #195fff;
  min-height: 45px;
  position: fixed;
  width: 100%;
  z-index: 19; }
  .header .logo {
    padding: 10px 15px;
    line-height: 0; }
    @media only screen and (max-width: 1200px) {
      .header .logo {
        padding: 8px 15px; } }
  .header .block-menu {
    padding-left: 10px; }
    .header .block-menu .list-menu {
      width: 100%;
      margin: 0;
      line-height: 26px; }
      .header .block-menu .list-menu .top-icon {
        position: relative;
        display: -moz-inline-stack;
        display: inline-block;
        zoom: 1;
        *display: inline;
        height: 24px;
        line-height: 1;
        margin-bottom: 4px;
        vertical-align: top; }
        .header .block-menu .list-menu .top-icon:before {
          font-size: 20px;
          color: #fff; }
        .header .block-menu .list-menu .top-icon.icon-Tu_Tai_Lieu:before {
          font-size: 17px; }
      .header .block-menu .list-menu .menu-link {
        color: #fff;
        cursor: pointer;
        display: block;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 12px;
        height: 100%;
        letter-spacing: 0;
        line-height: 1.4;
        padding: 5px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        width: 100%; }
        .header .block-menu .list-menu .menu-link .text-content {
          display: block; }
        .header .block-menu .list-menu .menu-link .status-new {
          position: absolute;
          top: 0;
          right: 0;
          width: 8px;
          height: 8px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          background: #db3737; }
      .header .block-menu .list-menu .item {
        position: relative;
        float: left;
        cursor: pointer;
        min-width: 100px;
        text-align: center; }
        .header .block-menu .list-menu .item:hover {
          background-color: rgba(0, 0, 0, 0.14); }
          .header .block-menu .list-menu .item:hover .menu-link,
          .header .block-menu .list-menu .item:hover .top-icon {
            color: #fff; }
            .header .block-menu .list-menu .item:hover .menu-link:before,
            .header .block-menu .list-menu .item:hover .top-icon:before {
              color: #fff; }
      .header .block-menu .list-menu .active {
        background: rgba(0, 0, 0, 0.14);
        color: #fff; }
  .header .info-user {
    float: right;
    padding: 10px 15px 10px 0;
    cursor: pointer; }
    .header .info-user:hover .cpc-menu {
      display: block; }
    .header .info-user .dropdown {
      display: block;
      line-height: 35px;
      padding-right: 20px;
      position: relative;
      white-space: nowrap; }
      .header .info-user .dropdown .user-avatar-content {
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
        display: -moz-inline-stack;
        display: inline-block;
        zoom: 1;
        *display: inline;
        float: left;
        width: 37px;
        height: 37px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        margin-right: 10px;
        margin-top: -2px;
        border: 1px solid #4a82ff;
        overflow: hidden; }
        .header .info-user .dropdown .user-avatar-content .user-image {
          opacity: 0; }
      .header .info-user .dropdown .name-user {
        white-space: nowrap;
        width: calc(100% - 30px);
        font-size: 13px;
        line-height: 1.3em; }
      .header .info-user .dropdown .dropdown-toggle {
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 20px;
        color: #fff;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        text-align: left;
        text-decoration: none; }
      .header .info-user .dropdown:after {
        content: '\e999';
        color: #cbdbff;
        font-size: 7px;
        font-family: 'icomoon';
        position: absolute;
        top: 0;
        right: 5px; }
      .header .info-user .dropdown .user-menu .dropdown-toggle {
        display: block;
        overflow: hidden; }

.header-right-container .info-user .user-menu {
  padding-left: 10px; }
  .header-right-container .info-user .user-menu .dots-chuc-danh {
    top: 6px;
    left: 40px; }

.dots-chuc-danh {
  position: absolute;
  top: 50%;
  left: 0px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #f00;
  margin-top: -5px; }

.header-container {
  position: relative; }
  .header-container .logo-mobilemenu-container,
  .header-container .nav-container {
    float: left; }
    .header-container .logo-mobilemenu-container:before, .header-container .logo-mobilemenu-container:after,
    .header-container .nav-container:before,
    .header-container .nav-container:after {
      content: '';
      display: table; }
    .header-container .logo-mobilemenu-container:after,
    .header-container .nav-container:after {
      clear: both; }
  .header-container .header-right-container {
    float: right; }
  .header-container .button-link-chat {
    float: right;
    margin-right: 10px; }
    .header-container .button-link-chat .name-link {
      display: -moz-inline-stack;
      display: inline-block;
      zoom: 1;
      *display: inline;
      font-size: 13px;
      line-height: 1.4;
      margin-top: 1px;
      padding-left: 10px;
      vertical-align: top;
      font-family: "Roboto-Thin", sans-serif; }
    .header-container .button-link-chat .icon-chat {
      font-size: 20px;
      margin-right: 5px;
      vertical-align: middle; }
  .header-container:before, .header-container:after {
    content: '';
    display: table; }
  .header-container:after {
    clear: both; }

.right-header-group {
  float: right;
  height: 55px;
  position: relative;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 1200px) {
    .right-header-group {
      margin-right: 60px;
      height: 50px; } }

@media only screen and (max-width: 1200px) {
  .nav-container.control-header {
    right: -336px;
    -webkit-transition: right 0.3s ease-in-out;
    -o-transition: right 0.3s ease-in-out;
    transition: right 0.3s ease-in-out;
    position: absolute;
    z-index: 1010;
    top: 100%;
    width: 336px; } }
  @media only screen and (max-width: 1200px) and (max-width: 767px) {
    .nav-container.control-header {
      top: 0;
      height: 100%;
      position: fixed;
      background: #fff; }
      .nav-container.control-header .menu {
        margin-top: 50px;
        padding-bottom: 20px; }
      .nav-container.control-header .title {
        width: 100%;
        display: none !important; }
      .nav-container.control-header .hidden-element {
        display: block !important; }
      .nav-container.control-header .icon-sidebar:before {
        text-align: left; }
      .nav-container.control-header .name-menu {
        display: inline-block !important; } }

@media only screen and (max-width: 1200px) {
  .nav-container.control-header-open {
    right: 0;
    display: block;
    margin-right: -15px;
    background-color: #fff; } }

@media only screen and (max-width: 992px) {
  .nav-container.control-header-open {
    overflow: auto; } }

@media only screen and (max-width: 767px) {
  .nav-container.control-header-open {
    max-width: 100% !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 100% !important;
    top: 0; }
    .nav-container.control-header-open .block-menu {
      margin: 0 !important; }
      .nav-container.control-header-open .block-menu .icon-close-menu {
        position: fixed;
        width: 100%;
        left: 0;
        text-align: right;
        z-index: 1;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 10px; }
      .nav-container.control-header-open .block-menu .pt-icon-cross::before {
        font-family: 'Icons20';
        font-size: 30px;
        color: #d2d2d2;
        cursor: pointer; } }

@media only screen and (max-width: 600px) {
  .nav-container.control-header-open .pt-icon-cross::before {
    font-size: 25px; } }

.page-container {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  right: 0;
  top: 0;
  width: 100%; }

.content-wrapper {
  padding: 65px 20px 20px; }

.left-content {
  float: right;
  min-height: 100%;
  width: -moz-calc(100% - 260px);
  width: -webkit-calc(100% - 260px);
  width: calc(100% - 260px); }

.page-container.sidebar-collapsed {
  -webkit-transition: all 10ms linear 10ms;
  -moz-transition: all 10ms linear 10ms;
  -o-transition: all 10ms linear 10ms;
  transition: all 10ms linear 10ms; }
  .page-container.sidebar-collapsed .left-content {
    float: right;
    width: -moz-calc(100% - 65px);
    width: -webkit-calc(100% - 65px);
    width: calc(100% - 65px); }
  .page-container.sidebar-collapsed .sidebar-menu .hidden-element,
  .page-container.sidebar-collapsed .sidebar-menu .title {
    display: none;
    -webkit-transform: translateZ(0); }
  .page-container.sidebar-collapsed .treeview-item {
    border-bottom: 1px solid #dfdfdf; }
    .page-container.sidebar-collapsed .treeview-item .icon-sidebar {
      position: inherit;
      top: 0;
      vertical-align: -webkit-baseline-middle; }
    .page-container.sidebar-collapsed .treeview-item .link-url {
      padding: 0;
      text-align: center; }

.page-container.sidebar-collapsed-back {
  -webkit-transition: all 10ms linear;
  -moz-transition: all 10ms linear;
  -o-transition: all 10ms linear;
  transition: all 10ms linear; }
  .page-container.sidebar-collapsed-back .left-content {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    float: right;
    width: -moz-calc(100% - 240px);
    width: -webkit-calc(100% - 240px);
    width: calc(100% - 240px); }

.page-container.sidebar-collapsed .sidebar-menu {
  width: 65px;
  -webkit-transition: all 10ms ease-in-out 10ms;
  -moz-transition: all 10ms ease-in-out 10ms;
  -o-transition: all 10ms ease-in-out 10ms;
  transition: all 10ms ease-in-out 10ms; }

.page-container.sidebar-collapsed-back .sidebar-menu {
  width: 240px;
  -webkit-transition: all 10ms ease-in-out;
  -moz-transition: all 10ms ease-in-out;
  -o-transition: all 10ms ease-in-out;
  transition: all 10ms ease-in-out; }

.page-container .treeview-item .name-menu {
  margin-left: 30px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline; }

.page-container .treeview-item .pt-popover-target {
  width: 100%; }

.page-container .treeview-item .pt-popover-open {
  font-family: "Roboto-medium", sans-serif;
  font-size: 13px; }

.page-container .treeview-item .item-menu {
  cursor: pointer;
  position: relative; }
  .page-container .treeview-item .item-menu:hover {
    background: #eff3f5; }

.page-container .treeview-item .active {
  background: #eff3f5; }

.page-container .treeview-item .link-url {
  color: #384f5a;
  display: block;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 40px;
  padding-left: 10px;
  text-align: left;
  text-decoration: none; }

.page-container .treeview-item .hidden-dowload-app {
  display: none; }

.page-container .treeview-item .icon-sidebar {
  height: 25px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 25px; }
  .page-container .treeview-item .icon-sidebar:before {
    color: #7f7f7f;
    font-size: 20px; }

.page-container .treeview-item .icon-Van_Ban_Tra_Loi:before,
.page-container .treeview-item .icon-decentralization:before {
  font-size: 20px; }

.page-container .treeview-item .icon-Danger:before,
.page-container .treeview-item .icon-Tu_Tai_Lieu:before {
  font-size: 15px; }

.page-container .treeview-item .icon-give-task::before {
  font-size: 18px; }

.page-container .treeview-item .icon-attention-warning:before {
  font-size: 16px !important; }

.page-container .treeview-item .icon-tag {
  transform: scale(0.8); }

.page-container .treeview-item .pt-tag-count {
  bottom: 9px;
  color: #fff;
  float: right;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  margin-right: 10px;
  min-width: 30px;
  padding: 1px 8px;
  position: relative;
  text-align: center;
  top: 11px; }

.page-container .treeview-item .pt-tag-new-feature {
  color: #db3737 !important;
  font-size: 32px !important;
  top: 3px !important; }

.page-container .treeview-item .pt-tag-new-feature-collaped {
  color: #db3737 !important;
  font-size: 21px !important;
  position: absolute !important;
  right: -8px !important;
  top: 2px !important; }

.sidebar-menu {
  background: #fff;
  bottom: 0;
  float: left;
  height: 100%;
  left: 0;
  margin-top: 55px;
  position: fixed;
  top: 0;
  width: 260px;
  z-index: 20;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); }
  @media only screen and (max-width: 1200px) {
    .sidebar-menu {
      margin-top: 50px; } }
  .sidebar-menu .arrow-sidebar {
    border-bottom: 1px solid #dfdfdf;
    padding: 12px 0 10px;
    text-align: center; }
    .sidebar-menu .arrow-sidebar span {
      cursor: pointer; }
  .sidebar-menu .arrow-sidebar-border {
    border: none; }
  .sidebar-menu .pt-icon-chevron-right {
    text-center: center; }
  .sidebar-menu .pt-icon-chevron-left {
    float: right;
    padding: 3px 12px; }
  .sidebar-menu .menu {
    padding-bottom: 89px; }
  .sidebar-menu .title {
    border-bottom: 1px solid rgba(227, 228, 232, 0.34);
    display: block;
    padding: 19px 0 12px 10px;
    text-decoration: none; }
  .sidebar-menu .title span {
    color: #3f555f;
    font-family: "Roboto-medium", sans-serif;
    font-size: 14px;
    letter-spacing: 0.16px;
    text-align: left; }
  .sidebar-menu .icon-XemDSVBKyso:before {
    font-size: 17px !important; }

.tooltip-sidebar .pt-popover-content span {
  color: #fff;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  padding: 7px;
  text-align: left; }

.pt-tooltip.pt-intent-primary .pt-popover-content {
  background: #195fff; }

.pt-tooltip.pt-intent-primary .pt-popover-arrow-fill {
  fill: #195fff; }

.new-feature-badge {
  float: right;
  margin-right: 10px;
  transform: translateY(-5px); }
  .new-feature-badge .icon-new-feature::before {
    font-size: 30px; }

.sidebar-collapsed .sidebar-block__arrow {
  justify-content: center; }
  .sidebar-collapsed .sidebar-block__arrow .icon {
    transform: rotate(180deg); }

.sidebar-collapsed .menu > li > .pt-popover-target {
  display: none; }

.sidebar-collapsed .sidebar-item-box .name-menu .icon-sidebar {
  text-align: center; }

.sidebar-collapsed .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.sidebar-block__arrow {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  line-height: 100%;
  min-height: 40px;
  padding: 0 10px; }
  .sidebar-block__arrow .icon {
    color: #195fff;
    cursor: pointer;
    display: block;
    height: 20px;
    line-height: 20px;
    text-align: center;
    transition: 0.5s all;
    width: 20px; }
  @media screen and (max-width: 767px) {
    .sidebar-block__arrow .sidebar__arrow {
      display: none; } }
  .sidebar-block__arrow .sidebar-arrow-close {
    display: none;
    margin-top: 5px; }
    @media screen and (max-width: 767px) {
      .sidebar-block__arrow .sidebar-arrow-close {
        display: block; } }
    .sidebar-block__arrow .sidebar-arrow-close span {
      color: #d2d2d2;
      display: block;
      height: 30px;
      line-height: 30px;
      text-align: center;
      width: 30px; }
      .sidebar-block__arrow .sidebar-arrow-close span::before {
        color: #d2d2d2; }

@media screen and (max-width: 767px) {
  .sidebar-block .menu {
    margin-top: 0 !important; } }

.sidebar-block .menu .pt-popover-target {
  width: 100%; }

.sidebar-block .menu li {
  list-style: none; }
  .sidebar-block .menu li.active .sidebar-item-box {
    background: whitesmoke; }
  .sidebar-block .menu li ul li:hover .sidebar-item-box {
    background: whitesmoke; }

.sidebar-block .menu a {
  text-decoration: none; }

.sidebar-block .menu > li .name {
  color: #3f555f;
  font-family: "Roboto-medium", sans-serif;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 22px; }

.sidebar-block .menu > li > ul {
  border-top: 1px solid #f0f3f5; }
  .sidebar-block .menu > li > ul > li .name {
    color: #333333;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 14px;
    line-height: 22px; }

.sidebar-block .menu .sidebar-item-box {
  align-items: center;
  display: flex;
  min-height: 40px;
  padding: 5px 20px; }
  .sidebar-block .menu .sidebar-item-box > * {
    flex: 1; }
  .sidebar-block .menu .sidebar-item-box .name-menu {
    align-items: center;
    display: flex !important;
    gap: 5px; }
    .sidebar-block .menu .sidebar-item-box .name-menu > * {
      min-width: 0; }
    .sidebar-block .menu .sidebar-item-box .name-menu .icon-sidebar {
      color: #8a9da7 !important;
      min-width: 20px;
      width: 20px; }

/*----------------------------footer---------------*/
.footer-container {
  display: none;
  background: #fff;
  border: 1px solid #e4ecf0;
  min-height: 76px;
  text-align: center;
  width: 100%; }
  .footer-container .info-footer {
    margin: 16px; }
    .footer-container .info-footer p {
      font-family: "Roboto-Regular", sans-serif;
      font-size: 10px;
      color: rgba(56, 79, 90, 0.46);
      letter-spacing: 0;
      line-height: 21px; }

/* IE 6 */
* html footer {
  position: absolute;
  top: expression( ( 0-(footer.offsetHeight)+ ( document.documentElement.clientHeight ? document.documentElement.clientHeight: document.body.clientHeight )+ ( ignoreMe = document.documentElement.scrollTop ? document.documentElement.scrollTop: document.body.scrollTop ) )+'px' ); }

.cpc-preload .cpc-logo {
  position: absolute;
  top: 45%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  background-image: url(../logo-preload.png);
  background-size: 80px 80px;
  background-repeat: no-repeat;
  width: 88px;
  height: 88px; }

.cpc-preload .cpc-loading-text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 45%;
  margin-top: 100px;
  font-size: 14px;
  color: #212121; }

.cpc-preload .cpc-spinner {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 180px;
  height: 180px;
  margin-top: -90px;
  margin-left: -90px;
  border: 2px solid #425ff3;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out; }

.cpc-preload .cpc-spinner-2 {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 180px;
  height: 180px;
  margin-top: -90px;
  margin-left: -90px;
  border: 2px solid #425ff3;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 2s infinite ease-in-out;
  animation: sk-scaleout 2s infinite ease-in-out; }

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0.45); }
  100% {
    -webkit-transform: scale(1);
    opacity: 0; } }

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0.45);
    transform: scale(0.45); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

.spinner-loading.small {
  width: 30px !important;
  display: block !important; }

.spinner-loading.xs {
  width: 20px !important;
  display: block !important; }

.navigation {
  background-color: var(--blue);
  font-family: "Roboto", sans-serif;
  height: 100vh;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: var(--navigation);
  z-index: 3; }
  .navigation__logo {
    margin-bottom: 25px;
    padding: 12px 5px; }
    .navigation__logo a {
      background-position: center center;
      background-repeat: no-repeat;
      display: block;
      height: 36px;
      margin: 0 auto;
      width: 36px; }
  .navigation__menu {
    margin: 0;
    padding: 0; }
    .navigation__menu > li {
      list-style: none; }
      .navigation__menu > li:hover, .navigation__menu > li.active {
        background-color: var(--blue-hover); }
    .navigation__menu .item {
      color: white;
      cursor: pointer;
      padding: 15px 5px; }
      .navigation__menu .item .menu-link {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .navigation__menu .item .menu-link .text-content {
          font-size: 12px;
          font-weight: 300;
          margin-top: 8px;
          text-transform: uppercase;
          width: 100%; }
        .navigation__menu .item .menu-link .top-icon {
          font-size: 20px;
          position: relative; }
          .navigation__menu .item .menu-link .top-icon::before {
            color: inherit; }
          .navigation__menu .item .menu-link .top-icon .status-new {
            background-color: #d24848;
            border-radius: 50%;
            height: 8px;
            position: absolute;
            right: 0;
            top: 0;
            width: 8px; }
      .navigation__menu .item:hover {
        background-color: var(--blue-hover); }

.page-container.is-navigation .header {
  background-color: #fff; }
  .page-container.is-navigation .header .logo {
    display: none; }
  .page-container.is-navigation .header .info-user .dropdown .dropdown-toggle {
    color: var(--text); }
  .page-container.is-navigation .header .right-header-group .notification-title__icon-noti {
    color: var(--blue); }
  .page-container.is-navigation .header .right-header-group .icon-chat::before {
    color: var(--blue); }
  .page-container.is-navigation .header .right-header-group .icon2-digital::before {
    color: var(--blue); }
  @media only screen and (max-width: 1200px) {
    .page-container.is-navigation .header .menu-header-mobile .pt-icon::before {
      color: var(--blue);
      position: relative; } }
  @media only screen and (max-width: 768px) {
    .page-container.is-navigation .header .menu-header-mobile .pt-icon::before {
      font-size: 20px; } }

@media only screen and (max-width: 767px) {
  .page-container.is-navigation .sidebar-menu {
    left: var(--navigation) !important;
    width: 0 !important; }
    .page-container.is-navigation .sidebar-menu.sidebar-mobile-open {
      width: 100% !important; } }

@media only screen and (max-width: 767px) {
  .page-container.is-navigation .left-content {
    left: 0 !important;
    width: calc(100% - var(--navigation)) !important; } }

.page-container.is-navigation .menu-sidebar-mobile span.pt-icon::before {
  color: #ddd; }

.page-container.is-navigation.sidebar-collapsed-back .header {
  left: calc(var(--sidebar) + var(--navigation));
  width: calc(100% - (var(--sidebar) + var(--navigation))); }
  @media only screen and (max-width: 767px) {
    .page-container.is-navigation.sidebar-collapsed-back .header {
      left: var(--navigation);
      width: calc(100% - var(--navigation)); } }

.page-container.is-navigation.sidebar-collapsed-back .sidebar-menu {
  left: var(--navigation);
  margin-top: 0; }

.page-container.is-navigation.sidebar-collapsed-back .left-content {
  left: calc(var(--sidebar) + var(--navigation));
  width: calc(100% - (var(--sidebar) + var(--navigation))); }

.page-container.is-navigation.sidebar-collapsed .header {
  left: calc(var(--sidebar-collapsed) + var(--navigation));
  width: calc(100% - (var(--sidebar-collapsed) + var(--navigation))); }
  @media only screen and (max-width: 767px) {
    .page-container.is-navigation.sidebar-collapsed .header {
      left: var(--navigation);
      width: calc(100% - var(--navigation)); } }

.page-container.is-navigation.sidebar-collapsed .sidebar-menu {
  left: var(--navigation);
  margin-top: 0; }

.page-container.is-navigation.sidebar-collapsed .left-content {
  left: calc(var(--sidebar-collapsed) + var(--navigation));
  width: calc(100% - (var(--sidebar-collapsed) + var(--navigation))); }

.page-container.is-navigation.page-container-dashboard .sidebar-menu {
  width: 0 !important; }

.page-container.is-navigation.page-container-dashboard .dashboard-page-wapper {
  left: 0 !important;
  width: calc(100% - var(--navigation)) !important; }
  .page-container.is-navigation.page-container-dashboard .dashboard-page-wapper .dashboard-wrapper {
    padding: 30px 0 !important; }

.public-layout {
  font-family: "Roboto", sans-serif; }
  .public-layout header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #195fff;
    height: 58px;
    padding: 5px 40px; }
    @media only screen and (max-width: 992px) {
      .public-layout header {
        padding: 5px 10px; } }
    .public-layout header img {
      max-height: 40px; }
  .public-layout .content {
    padding: 0 40px; }
    @media only screen and (max-width: 992px) {
      .public-layout .content {
        padding: 0 10px; } }
  .public-layout .heading {
    font-size: 20px;
    line-height: 28px;
    font-weight: 300; }
    .public-layout .heading span {
      color: #175bf5;
      font-weight: 500; }
  .public-layout .panel-layout {
    box-shadow: 0px 8px 21px rgba(199, 199, 199, 0.209975);
    border-radius: 0px 4px 4px 4px;
    background: #ffffff; }

.tab-cus-container .pt-tab-list {
  background-color: #fafafa;
  border-bottom: 1px solid #dfe6ea;
  min-height: 48px; }
  .tab-cus-container .pt-tab-list .pt-tab {
    border-top: 3px solid transparent;
    box-shadow: none;
    color: #777;
    font-family: "Roboto-medium", sans-serif;
    font-size: 14px;
    line-height: 1.4;
    margin-right: 0;
    min-width: 150px;
    padding: 14px 15px;
    position: relative;
    text-align: center;
    text-transform: uppercase; }
    .tab-cus-container .pt-tab-list .pt-tab:not([aria-disabled='true']):hover {
      color: #195fff; }
    .tab-cus-container .pt-tab-list .pt-tab[aria-selected='true'] {
      border-top-color: #195fff;
      color: #195fff; }

.tab-cus-container .pt-tab-panel {
  margin-top: 0; }

.react-datepicker__input-container {
  width: 100%; }
  .react-datepicker__input-container .input-datepicker {
    border: 1px solid #e0e1e4;
    -webkit-box-shadow: none;
    box-shadow: none;
    box-sizing: border-box;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    height: 39px;
    padding-left: 10px;
    width: 100%; }
    .react-datepicker__input-container .input-datepicker.pointer {
      cursor: pointer; }
  .react-datepicker__input-container .react-datepicker-time__header {
    text-transform: capitalize; }
  .react-datepicker__input-container .react-datepicker__close-icon {
    left: 80px;
    position: absolute;
    top: 16px; }
    .react-datepicker__input-container .react-datepicker__close-icon::after {
      background: transparent;
      color: #a5a7aa;
      font-size: 18px;
      left: 0;
      width: 10px; }
  .react-datepicker__input-container .has-time-clear + .react-datepicker__close-icon {
    left: 115px; }
  .react-datepicker__input-container .cpc-input {
    height: 38px; }

.react-datepicker-popper.has-time {
  width: max-content; }
  .react-datepicker-popper.has-time .react-datepicker__navigation--next--with-time {
    right: 90px; }
  .react-datepicker-popper.has-time .react-datepicker__time-container {
    width: 80px; }
    .react-datepicker-popper.has-time .react-datepicker__time-container .react-datepicker-time__header {
      position: relative;
      visibility: hidden; }
      .react-datepicker-popper.has-time .react-datepicker__time-container .react-datepicker-time__header::after {
        content: 'Thời gian';
        left: 5px;
        position: absolute;
        visibility: visible; }
    .react-datepicker-popper.has-time .react-datepicker__time-container .react-datepicker__time-box {
      width: inherit; }
      .react-datepicker-popper.has-time .react-datepicker__time-container .react-datepicker__time-box .react-datepicker__time-list {
        padding-right: unset; }
        .react-datepicker-popper.has-time .react-datepicker__time-container .react-datepicker__time-box .react-datepicker__time-list-item {
          line-height: 30px;
          text-align: center; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-right: 0; }

.pt-datepicker .pt-datepicker-caption select {
  text-transform: capitalize; }

.pt-datepicker .DayPicker-Weekday {
  font-family: "Roboto-medium", sans-serif;
  font-size: 15px; }

.react-datepicker-wrapper {
  display: block; }

.react-quill-custom {
  display: inline-block;
  float: right; }
  .react-quill-custom .ql-container {
    height: 375px;
    overflow-y: auto;
    padding: 10px;
    position: relative; }

.ql-editor .ql-video {
  display: block;
  height: 315px;
  width: 560px; }

.checkbox-tree-content {
  background: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 3px; }

.rc-tree {
  margin: 0;
  padding: 10px; }

.rc-tree li {
  list-style: none;
  margin: 0;
  outline: 0;
  padding: 0;
  white-space: nowrap; }
  .rc-tree li .rc-tree-title {
    color: #454a52;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    letter-spacing: 0;
    margin-left: 8px;
    text-align: left; }

.rc-tree li .draggable {
  color: #333;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none; }

.rc-tree li.drag-over > .draggable {
  background-color: #316ac5;
  border: 1px #316ac5 solid;
  color: white;
  opacity: 0.8; }

.rc-tree li.drag-over-gap-top > .draggable {
  border-top: 2px blue solid; }

.rc-tree li.drag-over-gap-bottom > .draggable {
  border-bottom: 2px blue solid; }

.rc-tree li.filter-node > .rc-tree-node-content-wrapper {
  color: #a60000 !important;
  font-weight: bold !important; }

.rc-tree li ul {
  margin: 0;
  padding: 0 0 0 18px; }

.rc-tree li .rc-tree-node-content-wrapper {
  cursor: pointer;
  display: inline-block;
  height: 17px;
  margin: 0;
  padding: 1px 3px 0 0;
  text-decoration: none;
  vertical-align: top; }

.rc-tree li span.rc-tree-switcher,
.rc-tree li span.rc-tree-checkbox,
.rc-tree li span.rc-tree-iconEle {
  background-attachment: scroll;
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0 none;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  line-height: 16px;
  margin-right: 2px;
  outline: none;
  vertical-align: middle;
  width: 16px; }

.rc-tree li span.rc-tree-icon_loading {
  background: url("data:image/gif;base64,R0lGODlhEAAQAKIGAMLY8YSx5HOm4Mjc88/g9Ofw+v///wAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQFCgAGACwAAAAAEAAQAAADMGi6RbUwGjKIXCAA016PgRBElAVlG/RdLOO0X9nK61W39qvqiwz5Ls/rRqrggsdkAgAh+QQFCgAGACwCAAAABwAFAAADD2hqELAmiFBIYY4MAutdCQAh+QQFCgAGACwGAAAABwAFAAADD1hU1kaDOKMYCGAGEeYFCQAh+QQFCgAGACwKAAIABQAHAAADEFhUZjSkKdZqBQG0IELDQAIAIfkEBQoABgAsCgAGAAUABwAAAxBoVlRKgyjmlAIBqCDCzUoCACH5BAUKAAYALAYACgAHAAUAAAMPaGpFtYYMAgJgLogA610JACH5BAUKAAYALAIACgAHAAUAAAMPCAHWFiI4o1ghZZJB5i0JACH5BAUKAAYALAAABgAFAAcAAAMQCAFmIaEp1motpDQySMNFAgA7") no-repeat scroll 0 0 transparent;
  margin-right: 2px;
  vertical-align: top; }

.rc-tree li span.rc-tree-switcher.rc-tree-switcher-noop {
  cursor: auto; }

.rc-tree li span.rc-tree-switcher.rc-tree-switcher_open {
  font-size: 12px;
  padding-top: 3px; }
  .rc-tree li span.rc-tree-switcher.rc-tree-switcher_open:before {
    color: #7f7f7f;
    content: '\e9bd'; }

.rc-tree li span.rc-tree-switcher.rc-tree-switcher_close {
  font-size: 12px;
  padding-top: 3px; }
  .rc-tree li span.rc-tree-switcher.rc-tree-switcher_close:before {
    color: #7f7f7f;
    content: '\e9bc'; }

.rc-tree li span.rc-tree-checkbox:before {
  color: #d5d6d7;
  content: '\e9be'; }

.rc-tree li span.rc-tree-checkbox-checked {
  background-position: -14px 0; }
  .rc-tree li span.rc-tree-checkbox-checked:before {
    color: #195fff;
    content: '\e9bf'; }

.rc-tree li span.rc-tree-checkbox-indeterminate {
  background-position: -14px -28px; }

.rc-tree li span.rc-tree-checkbox-disabled {
  background-color: rgba(206, 217, 224, 0.5);
  background-position: 0 -56px;
  cursor: not-allowed; }

.rc-tree li span.rc-tree-checkbox.rc-tree-checkbox-checked.rc-tree-checkbox-disabled {
  background-position: -14px -56px; }

.rc-tree li span.rc-tree-checkbox.rc-tree-checkbox-indeterminate.rc-tree-checkbox-disabled {
  background: #ccc;
  border-radius: 3px;
  position: relative; }

.rc-tree li span.rc-tree-checkbox.rc-tree-checkbox-indeterminate.rc-tree-checkbox-disabled::after {
  border: 2px solid #fff;
  border-left: 0;
  border-top: 0;
  content: ' ';
  height: 0;
  left: 3px;
  position: absolute;
  top: 5px;
  -webkit-transform: scale(1);
  transform: scale(1);
  width: 5px; }

.rc-tree:not(.rc-tree-show-line) .rc-tree-switcher-noop {
  background: none; }

.rc-tree.rc-tree-show-line li:not(:last-child) > ul {
  background: url("data:image/gif;base64,R0lGODlhCQACAIAAAMzMzP///yH5BAEAAAEALAAAAAAJAAIAAAIEjI9pUAA7") 0 0 repeat-y; }

.rc-tree.rc-tree-show-line li:not(:last-child) > .rc-tree-switcher-noop {
  background-position: -56px -18px; }

.rc-tree.rc-tree-show-line li:last-child > .rc-tree-switcher-noop {
  background-position: -56px -36px; }

.rc-tree-child-tree {
  display: none; }

.rc-tree-child-tree-open {
  display: block; }

.rc-tree-treenode-disabled > span,
.rc-tree-treenode-disabled > a,
.rc-tree-treenode-disabled > a span {
  color: #ccc;
  cursor: not-allowed; }

.rc-tree-node-selected {
  background-color: #ffe6b0;
  border: 1px #ffb951 solid;
  opacity: 0.8; }

.rc-tree-icon__open {
  background-position: -110px -16px;
  margin-right: 2px;
  vertical-align: top; }

.rc-tree-icon__close {
  background-position: -110px 0;
  margin-right: 2px;
  vertical-align: top; }

.rc-tree-icon__docu {
  background-position: -110px -32px;
  margin-right: 2px;
  vertical-align: top; }

.custom-font-icomoon, .rc-tree li span.rc-tree-switcher.rc-tree-switcher_open, .rc-tree li span.rc-tree-switcher.rc-tree-switcher_close, .rc-tree li span.rc-tree-checkbox, .rc-tree li span.rc-tree-checkbox-checked {
  font-family: 'icomoon' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none; }

.disabled-tree-node .rc-tree-title {
  color: rgba(33, 33, 33, 0.4) !important;
  cursor: not-allowed; }

.disabled-tree-node-child > .rc-tree-title {
  color: rgba(33, 33, 33, 0.4) !important;
  cursor: not-allowed; }

.avatar-box {
  align-items: center;
  background: #fafafa;
  border: 1px dashed #e2e2e2;
  border-radius: 50%;
  display: flex;
  height: 120px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 120px; }
  .avatar-box .icon {
    font-size: 13px;
    position: absolute;
    transition: 0.2s;
    z-index: 2; }
    .avatar-box .icon:before {
      color: #999999;
      content: '\e9d9';
      display: block;
      font-family: 'icomoon';
      font-size: 23px;
      margin-bottom: 5px;
      text-align: center; }
    .avatar-box .icon.bg-opacity {
      opacity: 0;
      transition-timing-function: ease-in-out; }
  .avatar-box [type='file'] {
    bottom: 0px;
    cursor: pointer;
    height: 100%;
    left: 0px;
    opacity: 0;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 100%;
    z-index: 99; }
  .avatar-box .img-show {
    background-size: cover;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%; }
  .avatar-box .bg-gradient:before {
    background: white;
    background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%);
    content: '';
    display: inline-block;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 1; }

.employee-dialog {
  min-width: 450px;
  width: auto; }
  .employee-dialog .employee-dialog-content {
    padding: 0; }

.employee-select-tree {
  padding: 20px;
  max-height: 600px;
  overflow-y: auto; }
  .employee-select-tree .employee-select-tree-item {
    margin: 7px 0; }
    .employee-select-tree .employee-select-tree-item .rc-tree-switcher {
      transform: translateY(1px) !important;
      padding: 0 !important;
      margin-right: 5px; }
      .employee-select-tree .employee-select-tree-item .rc-tree-switcher::before {
        font-size: 16px; }
    .employee-select-tree .employee-select-tree-item .rc-tree-checkbox::before {
      font-size: 18px; }
    .employee-select-tree .employee-select-tree-item .rc-tree-title {
      font-size: 16px !important;
      line-height: 1.5; }
    .employee-select-tree .employee-select-tree-item .rc-tree-child-tree {
      padding-left: 22px; }
    .employee-select-tree .employee-select-tree-item .rc-tree-node-content-wrapper {
      height: auto; }
    .employee-select-tree .employee-select-tree-item.hideCheckbox > .rc-tree-checkbox {
      opacity: 0;
      visibility: hidden; }
    .employee-select-tree .employee-select-tree-item.hideCheckbox > .rc-tree-node-content-wrapper {
      pointer-events: none; }
    .employee-select-tree .employee-select-tree-item .display-none {
      position: absolute;
      left: -9999px;
      visibility: hidden;
      display: none; }

.dialog-footer-employee .box-button {
  display: -webkit-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center; }
  .dialog-footer-employee .box-button .btn-save-employee {
    width: 150px; }

.apply-draft-dialog {
  width: auto;
  max-width: none;
  padding: 0; }
  .apply-draft-dialog main {
    padding: 20px; }
    .apply-draft-dialog main table {
      border-collapse: collapse;
      border: 1px solid #dfe6ea; }
    .apply-draft-dialog main th,
    .apply-draft-dialog main td {
      text-align: start;
      border-bottom: 1px solid #dfe6ea;
      padding: 10px 15px;
      white-space: nowrap; }
      .apply-draft-dialog main th:nth-child(2),
      .apply-draft-dialog main td:nth-child(2) {
        width: 20vw;
        max-width: 400px; }
        .apply-draft-dialog main th:nth-child(2):not(th),
        .apply-draft-dialog main td:nth-child(2):not(th) {
          cursor: pointer; }
      .apply-draft-dialog main th:nth-child(3),
      .apply-draft-dialog main td:nth-child(3) {
        width: 10vw;
        max-width: 200px; }
    .apply-draft-dialog main th {
      font-weight: 600;
      font-size: 12px; }
    .apply-draft-dialog main td {
      font-size: 13px; }
      .apply-draft-dialog main td:nth-child(2) {
        color: #195fff;
        overflow: hidden;
        text-overflow: ellipsis; }
        .apply-draft-dialog main td:nth-child(2):hover {
          text-decoration: underline; }
    .apply-draft-dialog main thead {
      background-color: #f8f9f9; }
    .apply-draft-dialog main .icon-trash {
      cursor: pointer; }
      .apply-draft-dialog main .icon-trash::before {
        color: #000; }

.fc-day-other {
  background-color: #f6f6f6; }

.fc a {
  color: #000; }

.fc .fc-timegrid-axis {
  width: 0 !important; }

.fc .fc-timegrid .fc-scrollgrid-section.fc-scrollgrid-section-liquid {
  display: none; }

.fc .fc-timegrid .fc-scrollgrid-section.fc-scrollgrid-section-body td {
  height: 100% !important; }
  .fc .fc-timegrid .fc-scrollgrid-section.fc-scrollgrid-section-body td table {
    height: calc(100vh - 320px); }

.fc-day-other {
  background-color: #f6f6f6; }

.fc-event-title {
  font-weight: normal; }

.fc .fc-button:disabled {
  cursor: not-allowed; }

*::-webkit-scrollbar {
  width: 6px;
  height: 6px; }

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #c5cdd2; }

.cpc-select-basic {
  background: #ffffff;
  border: 1px solid #dfe6ea;
  color: #31434b;
  height: 36px;
  line-height: 36px;
  outline: none;
  width: 100%; }
  .cpc-select-basic:disabled {
    cursor: not-allowed; }

.react-calendar-timeline {
  background-color: #fff;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  overflow: hidden; }
  .react-calendar-timeline * {
    box-sizing: border-box; }
  .react-calendar-timeline .rct-outer {
    display: block;
    overflow: hidden;
    white-space: nowrap; }
  .react-calendar-timeline .rct-scroll {
    display: inline-block;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-touch-action: none;
    touch-action: none;
    vertical-align: top;
    white-space: normal; }
  .react-calendar-timeline .rct-item:hover {
    z-index: 88; }
  .react-calendar-timeline .rct-item .rct-item-content {
    border-radius: 2px;
    display: inline-block;
    height: 100%;
    left: 0px;
    overflow: hidden;
    padding: 0 6px;
    position: sticky;
    position: -webkit-sticky; }
  .react-calendar-timeline .rct-sidebar {
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    white-space: normal; }
    .react-calendar-timeline .rct-sidebar.rct-sidebar-right {
      border-left: 1px solid #f7f7f7;
      border-right: 0; }
    .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
      border-bottom: 1px solid #f7f7f7;
      box-sizing: border-box;
      margin: 0;
      overflow: hidden;
      padding: 0 15px;
      text-overflow: ellipsis;
      white-space: nowrap; }
      .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
        background: #fff; }
      .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
        background: transparent; }
  .react-calendar-timeline .rct-vertical-lines .rct-vl {
    border-left: 1px solid #f7f7f7;
    position: absolute;
    z-index: 30; }
    .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
      border-left-width: 2px; }
    .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
      background: rgba(250, 246, 225, 0.2); }
  .react-calendar-timeline .rct-horizontal-lines {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none; }
    .react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
    .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
      border-bottom: 1px solid #f7f7f7;
      box-sizing: border-box;
      z-index: 40; }
    .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
      background: #fff; }
    .react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
      background: transparent; }
  .react-calendar-timeline .rct-cursor-line {
    background: #8a9da7;
    position: absolute;
    width: 2px;
    z-index: 51; }
  .react-calendar-timeline .rct-dateHeader {
    align-items: center;
    background-color: #fff;
    border-left: 1px solid #f7f7f7;
    border-top: 1px solid #f7f7f7;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    height: 100%;
    justify-content: center; }
  .react-calendar-timeline .rct-dateHeader-primary {
    color: #31434b;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px; }
  .react-calendar-timeline .rct-header-root {
    background: #fff;
    border-bottom: 1px solid #f7f7f7; }
  .react-calendar-timeline .rct-header-root > div:first-child {
    align-items: center;
    display: flex;
    font-size: 12px;
    line-height: 18px;
    padding: 15px; }
  .react-calendar-timeline .rct-header-root > div:first-child,
  .react-calendar-timeline .rct-sidebar > div:first-child {
    border-right: 1px solid #f7f7f7;
    vertical-align: middle; }

.timeline-calendar-header-right {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1; }

.timeline-calendar {
  position: relative;
  z-index: 1; }
  .timeline-calendar-arrow {
    background-color: #fff;
    border: 1px solid #f7f7f7;
    border-radius: 4px;
    display: inline-block;
    line-height: 100%;
    overflow: hidden; }
    .timeline-calendar-arrow > .timeline-button:not(:last-child) {
      border-right: 1px solid #f7f7f7 !important; }
  .timeline-calendar .timeline-button {
    align-items: center;
    background-color: transparent;
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    color: #31434b;
    display: inline-flex;
    justify-content: center;
    outline: none !important; }
  .timeline-calendar .timeline-item {
    align-items: center;
    display: inline-flex;
    gap: 3px;
    padding: 0 15px;
    max-width: 100%; }
    .timeline-calendar .timeline-item [class^='icon2-'] {
      min-width: 13px;
      width: 13px; }
    .timeline-calendar .timeline-item .name {
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }

.cpc-login-form {
  width: 448px;
  background: #ffffff;
  border: 1px solid #dfe6ea;
  box-shadow: 0 8px 21px 0 rgba(199, 199, 199, 0.21);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 30px;
  margin: auto; }
  .cpc-login-form h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: "Roboto-medium", sans-serif;
    font-size: 22px;
    color: #212121;
    letter-spacing: 0.41px;
    line-height: 30px;
    text-align: left; }
  .cpc-login-form .pt-button {
    width: 100%;
    margin-top: 20px;
    padding-top: 14px !important;
    padding-bottom: 13px !important;
    text-transform: uppercase;
    color: #fff;
    background: #2769ff !important;
    box-shadow: 0 0 0 1px #e6e6e6;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-family: "Roboto-medium", sans-serif;
    font-size: 16px;
    line-height: 25px; }
  .cpc-login-form .pt-button:disabled {
    background: #e6e6e6 !important;
    color: #fff; }
  .cpc-login-form .pt-input-group {
    margin-top: 28px;
    padding-left: 36px; }
    .cpc-login-form .pt-input-group .pt-icon {
      font-size: 25px;
      margin-left: 0 !important; }
    .cpc-login-form .pt-input-group .cpc-control-group label {
      text-transform: uppercase;
      font-size: 12px;
      color: #212121;
      padding-top: 3px;
      margin-bottom: 5px;
      display: block; }
    .cpc-login-form .pt-input-group .cpc-control-group .cpc-form-control {
      padding: 6px 0 !important;
      font-size: 17px;
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      border-bottom: 1px solid #dddddd;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0; }
    .cpc-login-form .pt-input-group .cpc-control-group .cpc-form-control:focus {
      border-bottom: 1px solid #195fff; }
    .cpc-login-form .pt-input-group .cpc-control-group .error {
      padding: 10px 0;
      color: red; }
  .cpc-login-form .container-remember-login .remember-me {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    color: #212121;
    letter-spacing: 0;
    text-align: left;
    display: inline-block;
    margin-bottom: 0; }

.cpc-login-page {
  background-size: cover;
  background-position: bottom center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 700px; }
  .cpc-login-page h1 {
    margin: 25px auto 25px;
    width: 320px;
    height: 88px;
    font-size: 31px;
    font-weight: 500;
    color: #195fff; }
  .cpc-login-page .cpc-logo-full-text {
    background-size: contain;
    overflow: hidden;
    background-repeat: no-repeat; }
  .cpc-login-page .cpc-text-muted {
    margin-left: 5px;
    font-size: 23px;
    font-weight: 300;
    color: #195fff; }
  .cpc-login-page .cpc-footer {
    width: 100%;
    text-align: center;
    position: absolute;
    font-size: 11px;
    color: #989899;
    line-height: 20px;
    margin-top: 1%; }
  .cpc-login-page .version p {
    position: absolute;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 12px;
    right: 5px;
    bottom: 0; }

.g-recaptcha {
  margin-top: 20px;
  margin-bottom: 15px;
  transform-origin: left top;
  -webkit-transform-origin: left top; }

/*----------------------------home-page---------------*/
.dashboard-page-wapper {
  width: 100% !important; }
  .dashboard-page-wapper .dashboard-container {
    float: none;
    margin: 0 auto;
    width: 90%; }

.header .hideMenusider-homepage .menu-sidebar-mobile {
  display: none !important; }

.header .hideMenusider-homepage .logo {
  margin-left: 0px; }

.link-block-content {
  cursor: pointer;
  display: block;
  font-size: 0;
  white-space: nowrap; }
  .link-block-content .cicle-icon-content {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: block;
    float: left;
    font-size: 19px;
    height: 48px;
    margin-right: 16px;
    padding: 14px 2px;
    text-align: center;
    width: 48px; }
    .link-block-content .cicle-icon-content .icon-thumb:before {
      color: #fff; }
  .link-block-content .label-text {
    color: #212121;
    display: block;
    line-height: 1.6;
    text-transform: uppercase;
    white-space: normal;
    width: 100%; }
  .link-block-content .number-text {
    display: block;
    font-family: "Roboto-medium", sans-serif;
    font-size: 26px;
    line-height: 1;
    margin-top: 4px;
    width: 100%; }
  .link-block-content .text-content {
    display: block;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 12px;
    overflow: hidden; }
  .link-block-content.red-content .cicle-icon-content {
    background-color: #ff4858; }
  .link-block-content.red-content .number-text {
    color: #ff4858; }
  .link-block-content.chrysoprase-content .cicle-icon-content {
    background-color: #26d4be; }
  .link-block-content.chrysoprase-content .number-text {
    color: #26d4be; }
  .link-block-content.lightblue-content .cicle-icon-content {
    background-color: #5f98ff; }
  .link-block-content.lightblue-content .number-text {
    color: #5f98ff; }
  .link-block-content.orange-content .cicle-icon-content {
    background-color: #f8aa0f; }
  .link-block-content.orange-content .number-text {
    color: #f8aa0f; }
  .link-block-content.grey-content .cicle-icon-content {
    background-color: #abbac5; }
  .link-block-content.grey-content .number-text {
    color: #abbac5; }
  .link-block-content:hover {
    text-decoration: none; }
  .link-block-content:after, .link-block-content:before {
    content: '';
    display: table; }
  .link-block-content:after {
    clear: both; }

.list-task-content .item {
  padding: 10px 18px;
  position: relative; }
  .list-task-content .item:not(:first-child):before {
    background-color: #e4ecf0;
    content: '';
    height: 43px;
    left: 0;
    position: absolute;
    width: 1px;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: auto; }

.progressbar-content .description {
  color: #212121;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 12px;
  line-height: 1.6;
  padding-top: 8px;
  text-align: center;
  text-transform: uppercase; }

.statistical-list .item {
  padding-bottom: 14px;
  padding-top: 14px; }

.chart-content {
  padding-bottom: 15px;
  padding-top: 10px; }
  .chart-content img {
    height: auto;
    max-width: 100%; }

.chart-content-thongke-gopy {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px; }
  .chart-content-thongke-gopy img {
    height: auto;
    max-width: 100%; }

.chart-thongke-gopy {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center; }

.text-chart {
  font-size: 75px;
  font-weight: bold;
  padding-left: 25px;
  text-align: right; }

.name-chart {
  font-weight: bold;
  margin-left: 50px; }

.statistical-container .col-item {
  padding: 0 30px 20px; }

.statistical-container .statistical-item-left {
  border-right: 1px solid #e4ecf0; }

.statistical-detail-content .main-heading {
  padding-bottom: 15px; }

.section-block {
  background-color: #fff;
  border: 1px solid #dfe7ea;
  -webkit-box-shadow: 0 0 10px 2px rgba(200, 200, 200, 0.4);
  -moz-box-shadow: 0 0 10px 2px rgba(200, 200, 200, 0.4);
  box-shadow: 0 0 10px 2px rgba(200, 200, 200, 0.4);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }
  .section-block .heading-content {
    padding: 16px 22px; }
    .section-block .heading-content .main-heading {
      float: left; }
    .section-block .heading-content .action-right-content {
      float: right; }
    .section-block .heading-content:after, .section-block .heading-content:before {
      content: '';
      display: table; }
    .section-block .heading-content:after {
      clear: both; }
  .section-block .border-top {
    border-top: 1px solid #e4ecf0; }
  .section-block .list-task-content {
    padding: 14px 10px; }
  .section-block .public-infor-content {
    padding: 10px 18px; }
  .section-block.public-infor-section-block .heading-content {
    padding: 16px 18px; }
  .section-block .text-no-permission {
    font-size: 18px;
    padding: 40px 10px;
    text-align: center; }

.list-dot-12 .text-link {
  color: #545454; }
  .list-dot-12 .text-link:hover {
    color: #195fff; }

.list-dot-12 .item {
  color: #545454;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 12px;
  line-height: 1.6;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-top: 4px;
  position: relative; }
  .list-dot-12 .item:before {
    background-color: rgba(23, 91, 245, 0.5);
    content: '';
    height: 6px;
    left: 0;
    position: absolute;
    top: 10px;
    width: 6px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px; }

.public-infor-list-content {
  padding-top: 10px; }
  .public-infor-list-content .title {
    color: #212121;
    font-family: "Roboto-medium", sans-serif;
    font-size: 14px;
    line-height: 1.7;
    padding-bottom: 4px; }

.public-infor-content img {
  height: auto;
  max-width: 100%; }

.home-section-container-last .section-block {
  display: table;
  height: 100%;
  width: 100%; }

.home-section-container-last .chart-content {
  padding: 10px 20px 20px; }

.home-section-container-last .public-infor-section-block {
  display: block; }

.dashboard-container {
  min-height: 300px; }
  .dashboard-container .section-container {
    padding-bottom: 12px;
    padding-top: 12px; }

.recharts-responsive-container .recharts-default-legend {
  top: -10px; }

.recharts-responsive-container .recharts-xAxis .recharts-text {
  color: #212121;
  font-family: "Roboto-Bold", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  text-align: center; }

.recharts-responsive-container .recharts-legend-wrapper {
  bottom: 0 !important;
  color: #212121;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  text-align: left; }

.recharts-responsive-container .recharts-x-axis {
  font-size: 13px; }

.selectbox-inline .filter-chart {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.public-infor-content {
  overflow: hidden;
  width: 100%; }

.slider .slick-prev {
  left: 0;
  z-index: 1; }

.slider .slick-next {
  right: 0;
  z-index: 1; }

.filter-chart .item-filter {
  margin-right: 20px; }
  .filter-chart .item-filter:last-child {
    margin-right: 0; }

.filter-chart .pt-select select {
  color: #212121;
  font-family: "Roboto-medium", sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  text-align: left; }

/*----------------------------notification---------------*/
.notification-container .pt-button.btn-management {
  background: #fff;
  height: 38px;
  min-width: 164px;
  padding: 12px 0px;
  -webkit-box-shadow: 0 2px 1px 0 rgba(206, 213, 229, 0.27) !important false false false false;
  -moz-box-shadow: 0 2px 1px 0 rgba(206, 213, 229, 0.27) !important false false false false;
  box-shadow: 0 2px 1px 0 rgba(206, 213, 229, 0.27) !important false false false false;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }
  .notification-container .pt-button.btn-management:before {
    color: #195fff;
    font-size: 18px;
    left: 0;
    margin-right: 10px;
    position: relative;
    text-align: left;
    vertical-align: middle; }
  .notification-container .pt-button.btn-management:hover {
    background-color: #0046e5; }
    .notification-container .pt-button.btn-management:hover:before,
    .notification-container .pt-button.btn-management:hover .text-btn {
      color: #fff; }
  .notification-container .pt-button.btn-management .text-btn {
    color: #6a7489;
    display: inline-block;
    font-family: "Roboto-medium", sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    margin-left: 5px;
    text-align: left;
    text-transform: uppercase;
    vertical-align: middle; }

.notification-container .list-notification .item {
  border-bottom: 1px solid #dfe7ea;
  margin: 0px 20px 0px 20px;
  padding: 10px 0px;
  position: relative; }
  .notification-container .list-notification .item:last-child {
    border-bottom: none; }

.notification-container .list-notification .content {
  display: inline-block;
  padding-left: 25px;
  vertical-align: text-top;
  width: calc(100% - 120px); }

.notification-container .block-notification-content {
  height: 100%;
  position: relative; }

.notification-container .list-notification-padding {
  padding-bottom: 0 !important; }

.notification-container .list-task-notification {
  margin-left: 0;
  margin-right: 0; }
  .notification-container .list-task-notification .content-notification-box {
    border-right: 1px solid #dfe7ea;
    padding-left: 0;
    padding-right: 0; }
    .notification-container .list-task-notification .content-notification-box:last-child {
      border: none; }
    .notification-container .list-task-notification .content-notification-box .footer-list-notification {
      background: #fff;
      border-top: 1px solid #dfe7ea;
      bottom: 0;
      position: absolute;
      text-align: center;
      width: 100%; }
      .notification-container .list-task-notification .content-notification-box .footer-list-notification .pagination {
        text-align: center; }
  .notification-container .list-task-notification .heading-notification-box {
    color: #212121;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 20px 0px 20px 20px;
    text-align: left;
    text-transform: uppercase; }
  .notification-container .list-task-notification .icon-title {
    font-size: 10px;
    margin-right: 10px;
    position: absolute;
    text-align: left;
    top: 18px; }
  .notification-container .list-task-notification .title-notification {
    color: #195fff;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;
    text-decoration: none; }
    .notification-container .list-task-notification .title-notification:hover {
      color: #0046e5; }
  .notification-container .list-task-notification .date-update {
    color: #7c8593;
    float: right;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 5px;
    text-align: right; }
    .notification-container .list-task-notification .date-update .date {
      margin-right: 5px; }

/* form-AddAdminNotificationPage */
.form-AddAdminNotificationPage .form-container-AddAdminNotificationPage {
  background: #fff;
  box-shadow: 0 8px 21px 0 rgba(199, 199, 199, 0.21);
  text-align: left; }
  .form-AddAdminNotificationPage .form-container-AddAdminNotificationPage .pt-checkbox {
    min-height: 0;
    padding-left: 20px; }
  .form-AddAdminNotificationPage .form-container-AddAdminNotificationPage .checkbox-notification {
    padding-left: 57px; }
  .form-AddAdminNotificationPage .form-container-AddAdminNotificationPage .pt-form-content .react-quill-custom .loading-container {
    padding: 0; }
    .form-AddAdminNotificationPage .form-container-AddAdminNotificationPage .pt-form-content .react-quill-custom .loading-container .timeline-wrapper {
      padding: 20px 0; }

.form-AddAdminNotificationPage .form-inline-group .pt-input,
.form-AddAdminNotificationPage .form-inline-group .radio-group,
.form-AddAdminNotificationPage .form-inline-group .pt-select,
.form-AddAdminNotificationPage .react-quill-custom {
  width: calc(100% - 107px); }

.form-AddAdminNotificationPage .form-inline-group .pt-select {
  display: inline-block; }

.form-AddAdminNotificationPage .dropdown-tree-filter {
  margin-left: 64px; }

.form-AddAdminNotificationPage .name_checkbox {
  color: #212121;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  text-align: left; }

.form-AddAdminNotificationPage .text-heading {
  margin-left: 20px; }

/* detail page notification */
.container-detail-notification {
  background: #fff;
  padding: 30px;
  text-align: initial; }
  .container-detail-notification .title {
    color: #212121;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 20px;
    text-align: justify; }
  .container-detail-notification .content-wrapper-news {
    margin-top: 20px;
    overflow: auto; }
  .container-detail-notification .info-post {
    position: relative; }
    .container-detail-notification .info-post .avatar {
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
      display: inline-block;
      float: left;
      height: 37px;
      left: 0;
      overflow: hidden;
      position: absolute;
      width: 37px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%; }
    .container-detail-notification .info-post .name_user {
      color: #212121;
      display: inline-block;
      font-family: "Roboto-Regular", sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      margin-left: 45px;
      text-align: left;
      vertical-align: -webkit-baseline-middle;
      white-space: nowrap; }
    .container-detail-notification .info-post .date-update {
      color: #212121;
      font-family: "Roboto-Regular", sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 23px;
      margin-top: 5px;
      text-align: left; }
      .container-detail-notification .info-post .date-update .date {
        margin-right: 5px; }

.page-ListAdminNotification .search-container {
  border: 0;
  padding: 0 10px; }
  .page-ListAdminNotification .search-container .name_checkbox {
    font-family: "Roboto-medium", sans-serif;
    font-size: 14px; }

.page-ListAdminNotification .admin-notification-search .end-xs {
  text-align: right; }

.page-ListAdminNotification .table-container {
  border-radius: 4px; }

.notification-title {
  align-items: center;
  cursor: pointer;
  display: flex; }
  .notification-title__icon-noti-wrapper {
    line-height: 0;
    margin-left: 20px;
    margin-right: 20px;
    position: relative; }
  .notification-title__dot-noti {
    background: #d0021b;
    border-radius: 50%;
    content: '';
    height: 10px;
    position: absolute;
    right: 0;
    top: 0;
    width: 10px; }
  .notification-title__dot-noti--header-title {
    right: -20px;
    top: calc(50% - 6px); }
  .notification-title__icon-noti {
    color: #fff;
    font-size: 23px !important; }
  .notification-title__text {
    color: #333333 !important;
    font-size: 16px;
    font-weight: bold; }
  .notification-title__toggle-icon {
    font-size: 6px;
    margin-left: 5px; }
  .notification-title.noti-home-icon.pt-tether-enabled .notification-title__icon-noti-wrapper {
    background: #1552dc !important; }
  .notification-title.noti-home-icon.pt-tether-enabled .notification-title__icon-noti {
    color: #fff !important; }
  .notification-title.noti-home-icon .notification-title__icon-noti-wrapper {
    background-color: #fff;
    border-radius: 50%;
    height: 47px;
    line-height: 50px;
    margin: 0;
    text-align: center;
    width: 47px; }
  .notification-title.noti-home-icon .notification-title__icon-noti {
    color: #1552dc; }
    .notification-title.noti-home-icon .notification-title__icon-noti::before {
      font-size: 16px !important; }

.notification-tabs {
  display: flex;
  flex-direction: column;
  transition: max-width 0.3s ease;
  width: 400px; }
  .notification-tabs__content-manage {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 15px; }
  .notification-tabs__content-manage-icon {
    font-size: 18px;
    margin-right: 5px; }
    .notification-tabs__content-manage-icon::before {
      color: #195fff !important; }
  .notification-tabs__content-manage-text {
    font-family: "Roboto-medium", sans-serif;
    font-size: 14px;
    font-weight: 500; }
  .notification-tabs__content-manage-arrow-icon {
    font-size: 12px;
    margin-left: auto; }
    .notification-tabs__content-manage-arrow-icon::before {
      color: #888888; }
  .notification-tabs__header {
    background: #0648df;
    background: linear-gradient(45deg, #0648df 0%, #195fff 100%);
    display: flex;
    position: relative; }
  .notification-tabs__header-item {
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    flex: 0 0 50%;
    font-family: "Roboto-Bold", sans-serif;
    font-size: 13px;
    padding: 14px 0;
    text-align: center;
    transition: all 0.3s ease;
    user-select: none;
    width: 100%; }
    .notification-tabs__header-item:hover {
      color: #fff; }
    .notification-tabs__header-item--active {
      color: #fff; }
  .notification-tabs__header-item-text {
    display: inline-block;
    position: relative; }
  .notification-tabs__header-underline {
    bottom: 0;
    height: 3px;
    position: absolute;
    transition: all 0.3s ease;
    width: 50%; }
    .notification-tabs__header-underline::after {
      background: #fff;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      content: '';
      height: 100%;
      left: 35%;
      position: absolute;
      width: 30%; }
  .notification-tabs__body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.3s ease; }
  .notification-tabs__body--is-loading {
    max-height: 80px;
    min-height: 80px; }
  .notification-tabs__loading {
    display: block !important;
    margin: auto;
    padding: 20px 0; }
  .notification-tabs__list-noti {
    display: flex;
    flex-direction: column; }
  .notification-tabs__list-noti-item {
    background: #fff;
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    padding: 15px;
    transition: all 0.2s ease; }
    .notification-tabs__list-noti-item:hover {
      background: #f5f5f5; }
    .notification-tabs__list-noti-item > div:first-child {
      flex: 0 0 auto; }

.noti-content-list {
  margin-left: 10px; }
  .noti-content-list__title {
    color: #333333;
    font-family: "Roboto-Bold", sans-serif;
    font-size: 15px;
    margin-bottom: 5px; }
  .noti-content-list__decription {
    color: #999999;
    font-size: 13px;
    height: auto;
    margin-bottom: 5px;
    padding: 0;
    white-space: pre-line;
    word-break: break-word; }
  .noti-content-list__time {
    align-items: center;
    color: #999999;
    display: flex;
    font-size: 13px; }
    .noti-content-list__time .icon-time {
      font-size: 12px;
      margin-right: 6px; }

.truncate-text__content {
  overflow: hidden;
  text-overflow: ellipsis; }
  .truncate-text__content img {
    max-width: 100%; }

.truncate-text__read-more {
  color: #106ba3;
  cursor: pointer; }
  .truncate-text__read-more:hover {
    text-decoration: underline; }

.fade-in {
  -webkit-animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.button-icon-left button {
  align-items: center;
  color: #fff;
  display: flex;
  flex-flow: row-reverse;
  min-width: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .button-icon-left button span {
    color: #fff; }
  .button-icon-left button .pt-icon-standard {
    color: #fff;
    margin-right: 10px !important; }

.dashboard-wrapper {
  background: #fff;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding: 80px 0 30px; }
  @media screen and (max-width: 768px) {
    .dashboard-wrapper {
      padding: 80px 0 30px; } }
  .dashboard-wrapper .dashboard-container {
    margin: 0 auto;
    max-width: 820px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%; }
    .dashboard-wrapper .dashboard-container.dashboard-container-new {
      max-width: 1400px; }
  .dashboard-wrapper .home-row {
    font-size: 0.0001px; }
    .dashboard-wrapper .home-row .home-col {
      box-sizing: border-box;
      display: inline-block;
      vertical-align: top;
      width: 100%; }
      @media screen and (max-width: 768px) {
        .dashboard-wrapper .home-row .home-col {
          width: 100%; }
          .dashboard-wrapper .home-row .home-col .home-left--block {
            padding-left: 10px !important;
            padding-right: 10px !important; }
          .dashboard-wrapper .home-row .home-col.home-col--right {
            padding-left: 10px !important;
            padding-right: 10px !important;
            width: 100% !important; }
            .dashboard-wrapper .home-row .home-col.home-col--right ul li {
              display: inline-block !important;
              width: 20%; } }
  .dashboard-wrapper .home-row--items {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px; }
    .dashboard-wrapper .home-row--items .home-col--items {
      flex: 1;
      max-width: 50%;
      min-width: 50%;
      padding: 0 10px; }
  .dashboard-wrapper .home-col--box {
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 10px 20px rgba(51, 63, 86, 0.08);
    display: flex;
    font-family: Roboto-Regular;
    padding: 10px; }
    .dashboard-wrapper .home-col--box i.icon {
      background: #ff4858;
      border-radius: 10px;
      color: #fff;
      font-size: 18px;
      font-style: normal;
      height: 38px;
      line-height: 38px;
      text-align: center;
      width: 38px; }
      .dashboard-wrapper .home-col--box i.icon:before {
        color: #fff; }
    .dashboard-wrapper .home-col--box .info {
      padding-left: 10px; }
      .dashboard-wrapper .home-col--box .info span {
        font-size: 18px;
        font-style: normal;
        font-weight: 700; }
      .dashboard-wrapper .home-col--box .info p {
        font-size: 13px;
        font-style: normal;
        font-weight: 500; }
  .dashboard-wrapper h2 {
    color: #405976;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.2em;
    margin-bottom: 12px;
    text-transform: uppercase; }
    .dashboard-wrapper h2.home-row--title.color-light {
      color: #fff; }

.slider-block {
  border-radius: 12px;
  overflow: hidden; }
  .slider-block .slider-item {
    border-radius: 12px;
    overflow: hidden;
    width: 100%; }
    .slider-block .slider-item .slider-item-image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 22%;
      width: 100%; }
  .slider-block .slick-dots {
    bottom: 10px;
    position: absolute; }
    .slider-block .slick-dots li button::before {
      color: #c4c4c4;
      font-size: 14px;
      opacity: 1; }
    .slider-block .slick-dots li.slick-active button:before {
      color: #3478f3; }

@media screen and (max-width: 768px) {
  .col-mobile {
    flex-basis: 100% !important;
    max-width: 100% !important; } }

.wrap-header__info {
  align-items: center;
  display: flex;
  justify-content: space-between; }
  .wrap-header__info .wrap-info {
    align-items: center;
    display: flex; }
    .wrap-header__info .wrap-info__avatar {
      align-items: center;
      background-color: #fff;
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
      border: 1px solid #fff;
      border-radius: 50%;
      color: #454a52;
      display: flex;
      font-size: 24px;
      height: 74px;
      justify-content: center;
      width: 74px; }
    .wrap-header__info .wrap-info__description {
      color: #454a52;
      margin-left: 17px; }
      .wrap-header__info .wrap-info__description__large {
        font-size: 24px; }
      .wrap-header__info .wrap-info__description .opacity-07 {
        opacity: 0.7; }

.wrap-content__homepage {
  font-size: 14px; }
  .wrap-content__homepage .card_title {
    color: #195fff;
    font-weight: 500;
    text-transform: uppercase; }
  .wrap-content__homepage .home__title__small {
    color: #195fff;
    font-size: 16px;
    font-weight: 500; }
  .wrap-content__homepage .new-job__block {
    padding-top: 15px; }
  @media screen and (max-width: 767px) {
    .wrap-content__homepage .row {
      margin-left: -0.5rem;
      margin-right: -0.5rem; } }

.card__content__left {
  flex: 1; }

.chart__total {
  fill: #195fff;
  font-size: 24px;
  font-weight: 700; }

.home__tag {
  border-radius: 6px;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  padding: 7px 10px; }
  .home__tag__text::before {
    content: 'o';
    margin-bottom: 4px;
    margin-right: 7px; }

.job-card__block {
  font-family: "Roboto", sans-serif; }
  .job-card__block:not(:first-child) {
    border-top: 1px solid #dce4ef; }
  .job-card__block .job__head {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 10px; }
    .job-card__block .job__head__des {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis; }
  .job-card__block .job__footer {
    align-items: center;
    display: flex;
    font-family: "Roboto", sans-serif;
    font-weight: 500; }
    .job-card__block .job__footer .job__priority {
      margin-left: auto; }

.home-card__block {
  padding: 10px 15px; }

.home-legend-chart li {
  cursor: pointer;
  padding: 5px 0;
  position: relative; }
  .home-legend-chart li span:nth-child(1) {
    line-height: 100%;
    vertical-align: middle; }
  .home-legend-chart li span:nth-child(2) {
    color: rgba(0, 0, 0, 0.7); }
  .home-legend-chart li.color-blue::before {
    border-color: #1552dc; }
  .home-legend-chart li.color-green::before {
    border-color: #08b424; }
  .home-legend-chart li.color-red::before {
    border-color: #cc1f1f; }
  .home-legend-chart li.color-orange::before {
    border-color: #ff951a; }

.home-work-origin {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px; }
  .home-work-origin__item {
    display: flex;
    flex: 1 auto;
    padding: 0 10px;
    width: 20%; }
    @media screen and (max-width: 600px) {
      .home-work-origin__item {
        width: 33.33%; } }
  .home-work-origin.dual .home-work-origin__item .home-card {
    align-items: center;
    display: flex;
    flex-wrap: wrap; }
    .home-work-origin.dual .home-work-origin__item .home-card__num {
      flex: 0 0 auto;
      margin-right: 8px; }
    .home-work-origin.dual .home-work-origin__item .home-card__des {
      flex: 0 0 auto; }

.home-card {
  background: rgba(179, 179, 179, 0.1);
  border: 1px solid rgba(178, 178, 178, 0.15);
  border-radius: 4px;
  padding: 10px;
  width: 100%; }
  .home-card__num {
    color: #4a4a4a; }
  .home-card__des {
    color: rgba(0, 0, 0, 0.7); }
  .home-card.color-orange {
    background: rgba(255, 149, 26, 0.1);
    border-color: rgba(255, 149, 26, 0.15); }
    .home-card.color-orange .home-card__num {
      color: #ff951a; }
  .home-card.color-blue {
    background: rgba(21, 82, 220, 0.07);
    border-color: rgba(21, 82, 220, 0.15); }
    .home-card.color-blue .home-card__num {
      color: #1552dc; }
  .home-card.color-green {
    background-color: rgba(0, 170, 72, 0.07);
    border-color: rgba(0, 170, 72, 0.15); }
    .home-card.color-green .home-card__num {
      color: #00aa48; }
  .home-card.color-red {
    background-color: rgba(204, 31, 31, 0.07);
    border-color: rgba(204, 31, 31, 0.12); }
    .home-card.color-red .home-card__num {
      color: #cc1f1f; }

.home-tabs .pt-tab-indicator-wrapper .pt-tab-indicator {
  display: none !important; }

.home-tabs .pt-tab {
  color: #8a9da7;
  font-size: 14px;
  font-weight: 600;
  position: relative; }
  .home-tabs .pt-tab:hover, .home-tabs .pt-tab[aria-selected='true'] {
    color: #424242; }
  .home-tabs .pt-tab:not(:last-child) {
    margin-right: 10px;
    padding-right: 10px; }
    .home-tabs .pt-tab:not(:last-child)::after {
      background-color: #d9d9d9;
      content: '';
      display: inline-block;
      height: 12px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0%, -50%);
      width: 1px; }

.home-tabs .pt-tab-panel {
  margin: 0; }

.home-layout-dual {
  align-items: flex-start;
  display: flex; }
  .home-layout-dual > div {
    flex: 1;
    padding-bottom: 10px; }
    .home-layout-dual > div:not(:last-child) {
      border-right: 1px solid #dce4ef;
      margin-right: 20px;
      padding-right: 20px; }

.add-receive-document {
  background: #fff; }

.ad-digitizing {
  background: #fff; }

.bottom-add-receive-document .btn-digitizing, .bottom-add-receive-document .btn-digitizing-cvd {
  background: #fff;
  border: 1px solid #d2d2d2;
  -webkit-box-shadow: 0 2px 1px 0 rgba(205, 212, 228, 0.27);
  -moz-box-shadow: 0 2px 1px 0 rgba(205, 212, 228, 0.27);
  box-shadow: 0 2px 1px 0 rgba(205, 212, 228, 0.27);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  min-width: 278px;
  height: 38px;
  font-family: "Roboto-medium", sans-serif;
  font-size: 12px;
  color: #6a7489;
  text-align: center;
  text-transform: uppercase; }

.bottom-add-receive-document .btn-digitizing-cvd {
  min-width: 140px;
  margin-left: 10px; }

.format-center {
  text-align: inherit; }

/*-------ad-digitizing-container-----------*/
.ad-digitizing-container {
  background: #fff;
  padding: 15px 20px;
  position: relative; }
  .ad-digitizing-container .btn-close {
    position: absolute;
    right: 15px;
    top: 15px; }
    .ad-digitizing-container .btn-close::before {
      color: #6a7489;
      cursor: pointer; }
  .ad-digitizing-container .heading {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 17px;
    color: #000;
    margin-bottom: 10px; }
    .ad-digitizing-container .heading .left-heading-goback {
      color: #000;
      margin-right: 5px;
      cursor: pointer; }

.list-digitizing .list-digitizing-icon-trash {
  float: right;
  display: block;
  color: #454a52; }

.list-digitizing .list-digitizing-icon-trash:hover {
  float: right;
  display: block;
  color: #ff4858; }

.list-digitizing .item {
  padding: 15px 10px 10px 10px;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid #d2d2d2;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  min-height: 100px;
  cursor: pointer;
  margin-right: 10px; }
  .list-digitizing .item:first-child {
    margin-top: 20px; }
  .list-digitizing .item:hover {
    background: #eff3f5; }
  .list-digitizing .item .date-update {
    font-family: "Roboto-medium", sans-serif;
    font-size: 12px;
    color: #454a52;
    position: absolute;
    top: -13px;
    left: 0;
    border: 1px solid #d2d2d2;
    border-radius: 26px;
    width: 170px;
    background: #fff;
    text-align: center;
    margin-left: 10px;
    line-height: 20px;
    padding: 0px 5px; }
  .list-digitizing .item .label-item {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 13px;
    color: #454a52; }
  .list-digitizing .item .info {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    color: #454a52;
    padding-left: 5px;
    line-height: 22px; }
  .list-digitizing .item .code {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 16px;
    color: #195fff; }
  .list-digitizing .item .name-file {
    background: url("../pdf_file.png");
    background-repeat: no-repeat;
    background-position: center left;
    padding-left: 22px; }
    .list-digitizing .item .name-file span {
      font-family: "Roboto-Regular", sans-serif;
      color: #454b52;
      font-size: 12px; }
  .list-digitizing .item .check-item {
    position: absolute;
    top: 8px;
    right: 0;
    margin-right: 12px; }
    .list-digitizing .item .check-item .pt-icon {
      text-align: center;
      border: 1px solid #b3b9c5;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      width: 44px;
      height: 24px; }
      .list-digitizing .item .check-item .pt-icon::before {
        color: #8a92a4;
        position: relative;
        top: 2px; }
      .list-digitizing .item .check-item .pt-icon:hover {
        border: 1px solid #195fff !important; }
        .list-digitizing .item .check-item .pt-icon:hover::before {
          color: #195fff; }
    .list-digitizing .item .check-item .active-tick {
      border: 1px solid #195fff !important; }
      .list-digitizing .item .check-item .active-tick::before {
        color: #195fff; }

.list-digitizing .active-tick {
  border: 1px solid #195fff; }

.file-box {
  margin-bottom: 20px; }
  .file-box .pt-file-upload {
    overflow: hidden; }

.form-input-receive-document .loading-container {
  height: 100%;
  background-color: #fff;
  padding: 40px 20px; }

.form-input-receive-document .box-button-shortcuts {
  padding-top: 5px; }

.authorize-page .loading-container {
  background-color: #fff; }

.detail-status-box .table-hover .status-job {
  margin-top: 0; }

.detail-status-box .table-hover .Cell {
  padding: 6px 20px 4px; }

.detail-status-box .table-hover .Heading,
.detail-status-box .table-hover .Row {
  height: 38px; }

.detail-receive-document {
  /* document view */
  /* container-form-action */
  /*view job done*/
  /* documents-cabinet */
  /* form send mail */
  /* detail-digitizing */ }
  .detail-receive-document .btn-edit-blue {
    float: right;
    margin-top: 2px; }
  .detail-receive-document .btn-view-file-detail {
    color: #195fff;
    display: none;
    font-size: 14px;
    font-family: "Roboto-medium", sans-serif;
    line-height: 1.4;
    white-space: nowrap;
    cursor: pointer; }
    .detail-receive-document .btn-view-file-detail .left-icon {
      display: inline-block;
      font-size: 29px;
      line-height: 0.5;
      margin-right: 5px;
      margin-left: -2px;
      vertical-align: top; }
      .detail-receive-document .btn-view-file-detail .left-icon:before {
        color: #195fff; }
    .detail-receive-document .btn-view-file-detail .text-content {
      display: inline-block;
      vertical-align: top; }
  .detail-receive-document .info-top {
    line-height: 30px;
    margin-bottom: 15px; }
  .detail-receive-document .number-code {
    font-size: 20px; }
    .detail-receive-document .number-code .name {
      font-family: "Roboto-Regular", sans-serif;
      color: #000; }
    .detail-receive-document .number-code .code {
      color: #195fff;
      padding-left: 10px;
      font-family: "Roboto-Bold", sans-serif; }
  .detail-receive-document .number-code-request-comment {
    font-size: 16px;
    display: flex;
    margin-left: -10px; }
    .detail-receive-document .number-code-request-comment .code-detail {
      color: #195fff;
      font-family: "Roboto-Bold", sans-serif; }
    .detail-receive-document .number-code-request-comment .name-number-code {
      font-family: "Roboto-Regular", sans-serif;
      color: #000;
      font-size: 16px;
      font-family: "Roboto-Bold", sans-serif; }
    .detail-receive-document .number-code-request-comment .name-detail {
      color: #000;
      font-size: 16px;
      font-family: "Roboto-Bold", sans-serif; }
    .detail-receive-document .number-code-request-comment .detail {
      color: #000;
      font-size: 16px; }
  .detail-receive-document .desc {
    font-size: 16px;
    font-style: italic;
    margin-bottom: 12px; }
    .detail-receive-document .desc .label {
      font-family: "Roboto-Bold", sans-serif;
      color: #212121; }
    .detail-receive-document .desc .detail {
      padding-left: 10px;
      font-family: "Roboto-Regular", sans-serif; }
    .detail-receive-document .desc .label-normal {
      font-style: normal;
      font-family: 'Roboto-Bold';
      color: #212121; }
  .detail-receive-document .work-desc {
    font-size: 14px;
    font-style: normal; }
    .detail-receive-document .work-desc .label {
      font-family: "Roboto-Bold", sans-serif;
      color: #212121; }
    .detail-receive-document .work-desc .detail {
      font-family: "Roboto-Regular", sans-serif; }
  .detail-receive-document .list-info-document {
    font-size: 14px;
    color: #212121;
    display: none; }
    .detail-receive-document .list-info-document label.pt-label {
      display: block;
      margin: 0 0 8px;
      line-height: 1.4; }
    .detail-receive-document .list-info-document ul li {
      width: 19%;
      display: inline-block;
      margin-right: 10px;
      vertical-align: top; }
    .detail-receive-document .list-info-document .label-item {
      font-family: "Roboto-Bold", sans-serif; }
    .detail-receive-document .list-info-document .pt-info {
      font-family: "Roboto-Regular", sans-serif;
      padding-left: 3px; }
  .detail-receive-document .detail-container .box-left-showdetail {
    width: 40% !important; }
  .detail-receive-document .detail-container .box-right-showdetail {
    width: 60% !important; }
  .detail-receive-document .detail-container .box-left {
    width: 70%;
    min-height: 716px;
    float: left;
    -webkit-transition: all 10ms linear;
    -moz-transition: all 10ms linear;
    -o-transition: all 10ms linear;
    transition: all 10ms linear; }
    .detail-receive-document .detail-container .box-left .row {
      margin: 0; }
    .detail-receive-document .detail-container .box-left .pd-none {
      padding-left: 0;
      padding-right: 0; }
    .detail-receive-document .detail-container .box-left .action-top {
      margin: 0;
      padding: 0;
      list-style-type: none; }
      .detail-receive-document .detail-container .box-left .action-top .button-list {
        padding-top: 13px;
        padding-bottom: 13px; }
    .detail-receive-document .detail-container .box-left .loading-container {
      height: 100%;
      background-color: #fff;
      padding: 10px 0px; }
  .detail-receive-document .detail-container .box-right {
    background-color: #fff;
    width: 30%;
    -webkit-box-shadow: 0 8px 21px 0 rgba(199, 199, 199, 0.21);
    -moz-box-shadow: 0 8px 21px 0 rgba(199, 199, 199, 0.21);
    box-shadow: 0 8px 21px 0 rgba(199, 199, 199, 0.21);
    -webkit-border-radius: 0 4px 4px 4px;
    -moz-border-radius: 0 4px 4px 4px;
    border-radius: 0 4px 4px 4px;
    float: right; }
    .detail-receive-document .detail-container .box-right .list-action .click-item {
      text-decoration: none;
      cursor: pointer;
      background: transparent;
      box-shadow: none;
      border: none;
      position: relative;
      text-align: left;
      vertical-align: middle; }
      .detail-receive-document .detail-container .box-right .list-action .click-item:disabled {
        opacity: 0.5;
        cursor: default !important; }
      .detail-receive-document .detail-container .box-right .list-action .click-item .icon-thuhoi {
        font-size: 12px !important;
        color: #787878;
        cursor: default; }
    .detail-receive-document .detail-container .box-right .list-action .title,
    .detail-receive-document .detail-container .box-right .list-action .title-sign-number {
      font-family: "Roboto-medium", sans-serif;
      text-transform: uppercase;
      font-size: 13px;
      color: #9498a2;
      letter-spacing: 0;
      text-align: left; }
    .detail-receive-document .detail-container .box-right .list-action .title {
      padding: 12px 10px 5px; }
    .detail-receive-document .detail-container .box-right .list-action .title-sign-number {
      padding: 15px 10px 10px; }
    .detail-receive-document .detail-container .box-right .list-action .item .icon-cpc {
      position: absolute;
      left: 0;
      width: 30px;
      text-align: left;
      line-height: 15px;
      font-size: 15px; }
      .detail-receive-document .detail-container .box-right .list-action .item .icon-cpc:before {
        color: #595959; }
    .detail-receive-document .detail-container .box-right .list-action .item .icon-done-work {
      font-size: 21px; }
    .detail-receive-document .detail-container .box-right .group-button-action {
      margin-left: -10px;
      margin-right: -10px;
      text-align: center; }
      .detail-receive-document .detail-container .box-right .group-button-action .button-content {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 10px; }
      .detail-receive-document .detail-container .box-right .group-button-action .button-content-2 {
        display: inline-block;
        padding-left: 0px;
        padding-right: 10px; }
        .detail-receive-document .detail-container .box-right .group-button-action .button-content-2 .display-flex {
          display: flex; }
          .detail-receive-document .detail-container .box-right .group-button-action .button-content-2 .display-flex .image-logo {
            width: 30px;
            height: 30px;
            align-items: center;
            margin-left: 10px; }
      .detail-receive-document .detail-container .box-right .group-button-action:after, .detail-receive-document .detail-container .box-right .group-button-action:before {
        content: '';
        display: table; }
      .detail-receive-document .detail-container .box-right .group-button-action:after {
        clear: both; }
    .detail-receive-document .detail-container .box-right .box-action {
      overflow: hidden;
      padding-left: 8px;
      padding-right: 8px;
      min-height: 40px;
      line-height: 1.4;
      border-bottom: 1px solid rgba(151, 151, 151, 0.1); }
      .detail-receive-document .detail-container .box-right .box-action:last-child {
        border-bottom: none; }
      .detail-receive-document .detail-container .box-right .box-action .icon-Thong_Tin_Giao_Viec::before {
        color: #5f5f5f;
        font-size: 15px; }
      .detail-receive-document .detail-container .box-right .box-action span.pt-icon-annotation {
        font-family: Icons20 !important; }
      .detail-receive-document .detail-container .box-right .box-action .icon-organization::before {
        font-size: 18px; }
      .detail-receive-document .detail-container .box-right .box-action .row {
        overflow: hidden; }
      .detail-receive-document .detail-container .box-right .box-action .icon-Gmail::before,
      .detail-receive-document .detail-container .box-right .box-action .icon-Luututailieu::before,
      .detail-receive-document .detail-container .box-right .box-action .icon-Xemthuchien::before {
        font-size: 13px; }
      .detail-receive-document .detail-container .box-right .box-action .action-name {
        font-family: "Roboto-Regular", sans-serif;
        font-size: 15px;
        color: #555555;
        display: inline-block;
        margin-left: 23px;
        vertical-align: text-bottom; }
      .detail-receive-document .detail-container .box-right .box-action .item {
        border-bottom: 1px solid #e4ecf0;
        margin-bottom: -1px;
        padding: 10px 0px 10px 10px; }
    .detail-receive-document .detail-container .box-right .full-box-content {
      background: #fff;
      padding-right: 0;
      padding-left: 0; }
      .detail-receive-document .detail-container .box-right .full-box-content .box-action .history-receivingunit-container {
        padding: 0 6px 14px; }
  .detail-receive-document .view-document {
    min-height: 56px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: static; }
    .detail-receive-document .view-document .action-top {
      background: #fff; }
    .detail-receive-document .view-document .button-list {
      padding: 18px 20px;
      text-align: center; }
      .detail-receive-document .view-document .button-list .line-right {
        padding-right: 10px;
        border-right: 1px solid rgba(61, 61, 61, 0.15); }
      .detail-receive-document .view-document .button-list input {
        width: 57px;
        height: 30px;
        font-size: 13px;
        color: #52555c;
        padding-left: 8px;
        margin-left: 5px;
        margin-right: 5px; }
      .detail-receive-document .view-document .button-list .item-btn {
        margin-right: 12px;
        display: inline-block;
        vertical-align: middle; }
        .detail-receive-document .view-document .button-list .item-btn a {
          text-decoration: none; }
        .detail-receive-document .view-document .button-list .item-btn .dowload {
          text-transform: uppercase;
          font-family: "Roboto-Regular", sans-serif;
          font-size: 13px;
          color: #52555c;
          padding-left: 8px; }
        .detail-receive-document .view-document .button-list .item-btn .page-view {
          font-family: "Roboto-Regular", sans-serif;
          font-size: 13px;
          color: #52555c; }
        .detail-receive-document .view-document .button-list .item-btn i:before {
          font-size: 20px;
          color: #52555c;
          position: relative;
          top: 3px; }
    .detail-receive-document .view-document .body-view-file {
      border: 1px solid #eeeff1;
      background: #eeeff1;
      padding: 19px;
      position: relative;
      overflow: hidden;
      display: table;
      width: 100%; }
      .detail-receive-document .view-document .body-view-file .body-view-file-content {
        position: relative;
        height: 100%;
        display: table-cell;
        vertical-align: middle; }
        .detail-receive-document .view-document .body-view-file .body-view-file-content img {
          max-height: 100%;
          max-width: 100%;
          margin: 0 auto;
          display: block; }
      .detail-receive-document .view-document .body-view-file:hover .slider {
        opacity: 1; }
      .detail-receive-document .view-document .body-view-file .slider {
        position: absolute;
        width: 10px;
        height: 100%;
        right: 0;
        top: 0;
        opacity: 0;
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s; }
        .detail-receive-document .view-document .body-view-file .slider .rangeslider-vertical {
          height: 100%;
          margin: 0;
          border: 0;
          box-shadow: unset;
          background: #fafafa; }
          .detail-receive-document .view-document .body-view-file .slider .rangeslider-vertical .rangeslider__handle {
            width: 8px;
            left: 1px;
            height: 50px;
            background-color: rgba(0, 0, 0, 0.2);
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            border: 0; }
          .detail-receive-document .view-document .body-view-file .slider .rangeslider-vertical .rangeslider__fill {
            background-color: #fafafa; }
    .detail-receive-document .view-document .body-view-file-mobile {
      position: relative;
      height: 100%;
      background: #eeeff1;
      padding-bottom: 20px; }
      .detail-receive-document .view-document .body-view-file-mobile img {
        width: 100%;
        height: auto;
        padding: 15px;
        padding-bottom: 0; }
      .detail-receive-document .view-document .body-view-file-mobile .viewfile-digitizing-container {
        padding: 15px 15px 10px 15px; }
    .detail-receive-document .view-document .cpc-error {
      display: table;
      width: 100%; }
      .detail-receive-document .view-document .cpc-error .cpc-text {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        font-family: 'Roboto-Regular';
        font-size: 14px;
        color: #f00; }
    .detail-receive-document .view-document .cpc-loading {
      display: table;
      width: 100%; }
      .detail-receive-document .view-document .cpc-loading .spinner {
        margin: auto;
        width: 70px;
        text-align: center;
        vertical-align: middle;
        display: table-cell; }
      .detail-receive-document .view-document .cpc-loading .spinner > div {
        width: 18px;
        height: 18px;
        background-color: #999;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
      .detail-receive-document .view-document .cpc-loading .spinner .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s; }
      .detail-receive-document .view-document .cpc-loading .spinner .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }
      .detail-receive-document .view-document .cpc-loading .spinner2 {
        width: 40px;
        height: 40px;
        background-color: #aaa;
        margin: 100px auto;
        -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
        animation: sk-rotateplane 1.2s infinite ease-in-out; }

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px); }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg); }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg); } }

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }
    .detail-receive-document .view-document.cpc-view-fullscreen {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 38; }
      .detail-receive-document .view-document.cpc-view-fullscreen .body-view-file {
        display: block; }
        .detail-receive-document .view-document.cpc-view-fullscreen .body-view-file .body-view-file-content {
          display: block; }
    .detail-receive-document .view-document.cpc-mobile .body-view-file-content {
      margin-bottom: 20px;
      height: auto; }
  .detail-receive-document .container-form-action {
    /* Chuyen chi dao */ }
    .detail-receive-document .container-form-action .heading {
      text-align: center;
      border-bottom: 1px solid rgba(151, 151, 151, 0.23);
      margin-top: 0 !important;
      padding: 17px 0;
      padding-left: 10px; }
      .detail-receive-document .container-form-action .heading .click-go-back {
        text-decoration: none;
        cursor: pointer; }
      .detail-receive-document .container-form-action .heading .pt-icon-arrow-left {
        color: #000;
        cursor: pointer;
        padding-top: 3px; }
      .detail-receive-document .container-form-action .heading .name-heading {
        display: inline;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 17px;
        color: #000;
        letter-spacing: 0; }
    .detail-receive-document .container-form-action .heading-item {
      padding: 17px 0 !important;
      text-align: center !important;
      border: 0; }
    .detail-receive-document .container-form-action .heading-goback {
      text-align: left;
      border-bottom: 1px solid rgba(151, 151, 151, 0.23);
      margin-top: 0 !important;
      padding: 17px 0; }
      .detail-receive-document .container-form-action .heading-goback .left-heading-goback {
        text-align: left;
        text-decoration: none;
        cursor: pointer; }
      .detail-receive-document .container-form-action .heading-goback .pt-icon-arrow-left {
        color: #000;
        cursor: pointer;
        margin-left: 20px; }
      .detail-receive-document .container-form-action .heading-goback .text-back {
        font-family: "Roboto-Regular", sans-serif;
        font-size: 13px;
        color: #454a52;
        letter-spacing: 0;
        line-height: 21px;
        text-align: left;
        padding-left: 30px; }
    .detail-receive-document .container-form-action .container-detail {
      padding: 20px 10px 10px; }
    .detail-receive-document .container-form-action .propose {
      font-family: "Roboto-Regular", sans-serif;
      font-size: 14px;
      color: #d19330;
      letter-spacing: 0;
      text-align: left;
      margin-bottom: 24px; }
      .detail-receive-document .container-form-action .propose .name {
        font-family: "Roboto-Bold", sans-serif; }
    .detail-receive-document .container-form-action .resolve-date .pt-form-group {
      margin: 0; }
    .detail-receive-document .container-form-action .resolve-date .pt-icon-calendar:before {
      top: 2px; }
    .detail-receive-document .container-form-action .resolve-date .pt-input-group {
      min-width: 128px;
      height: 38px; }
    .detail-receive-document .container-form-action .resolve-date .note-deadline,
    .detail-receive-document .container-form-action .resolve-date .pt-form-content,
    .detail-receive-document .container-form-action .resolve-date .pt-label {
      display: inline-block;
      vertical-align: top; }
    .detail-receive-document .container-form-action .resolve-date .pt-label {
      line-height: 1.4; }
    .detail-receive-document .container-form-action .resolve-date .pt-input {
      background-color: transparent;
      cursor: default;
      font-family: Roboto-Regular;
      font-size: 13px;
      letter-spacing: 0;
      text-align: left; }
    .detail-receive-document .container-form-action .resolve-date .pt-popover-target {
      z-index: 3; }
  .detail-receive-document .container-form-action-sub {
    padding-top: 0 !important; }
    .detail-receive-document .container-form-action-sub .heading.noborder-bottom {
      padding: 0px; }
    .detail-receive-document .container-form-action-sub .item-content-history {
      margin-top: 20px; }
  .detail-receive-document .buttton-action-footer .icon-Chidao:before,
  .detail-receive-document .buttton-action-footer .icon-Chidaobosung:before,
  .detail-receive-document .buttton-action-footer .icon-Tu_Tai_Lieu:before,
  .detail-receive-document .buttton-action-footer .icon-dexuatchidao:before {
    color: #fff;
    font-size: 15px; }
  .detail-receive-document .buttton-action-footer .icon-Chuyenchidao {
    margin-right: 12px;
    margin-left: 12px; }
    .detail-receive-document .buttton-action-footer .icon-Chuyenchidao::before {
      color: #fff;
      font-size: 16px; }
  .detail-receive-document .scroll-ExecutingViewBox {
    overflow: auto;
    max-height: 600px; }
    .detail-receive-document .scroll-ExecutingViewBox .item-box {
      width: 100%; }
  .detail-receive-document .view-job-done .box-level-sub {
    margin-left: 25px; }
    .detail-receive-document .view-job-done .box-level-sub a {
      text-decoration: none; }
    .detail-receive-document .view-job-done .box-level-sub .info-job {
      padding-left: 70px;
      margin-bottom: 15px; }
    .detail-receive-document .view-job-done .box-level-sub:last-child {
      margin-bottom: 0;
      margin-top: 15px; }
    .detail-receive-document .view-job-done .box-level-sub .click-dropdown {
      margin-top: 4px; }
    .detail-receive-document .view-job-done .box-level-sub .department-only-see > .name-department {
      margin-left: 0; }
    .detail-receive-document .view-job-done .box-level-sub .container-detail {
      padding-top: 0;
      border: none; }
    .detail-receive-document .view-job-done .box-level-sub:last-child {
      margin-bottom: 0; }
  .detail-receive-document .view-job-done .item-box {
    margin-bottom: 10px;
    clear: left; }
  .detail-receive-document .view-job-done .item-box-internal {
    margin-left: 0px; }
  .detail-receive-document .view-job-done .click-dropdown {
    position: absolute; }
    .detail-receive-document .view-job-done .click-dropdown::before {
      color: #195fff;
      padding-right: 15px; }
  .detail-receive-document .view-job-done .drop-down-items {
    left: 0;
    top: 5px; }
  .detail-receive-document .view-job-done .drop-down-sub,
  .detail-receive-document .view-job-done .pt-icon-chevron-down {
    margin-bottom: 7px; }
    .detail-receive-document .view-job-done .drop-down-sub .click-avatar,
    .detail-receive-document .view-job-done .pt-icon-chevron-down .click-avatar {
      cursor: pointer;
      position: relative; }
    .detail-receive-document .view-job-done .drop-down-sub .name-department,
    .detail-receive-document .view-job-done .pt-icon-chevron-down .name-department {
      font-family: "Roboto-Bold", sans-serif;
      font-size: 14px;
      color: #212121;
      letter-spacing: 0;
      text-align: left;
      padding-right: 10px;
      margin-left: 30px; }
    .detail-receive-document .view-job-done .drop-down-sub .role,
    .detail-receive-document .view-job-done .pt-icon-chevron-down .role {
      color: #195fff;
      font-family: "Roboto-medium", sans-serif;
      font-size: 13px;
      padding-left: 3px; }
  .detail-receive-document .view-job-done .box-level-sub > .container-form-action {
    padding-left: 20px;
    padding-top: 10px; }
  .detail-receive-document .view-job-done .department-only-see {
    padding-left: 30px; }
  .detail-receive-document .view-job-done .avatar-user {
    position: relative;
    min-height: 38px; }
    .detail-receive-document .view-job-done .avatar-user .click-avatar {
      cursor: pointer; }
  .detail-receive-document .view-job-done .click-avatar {
    cursor: pointer; }
  .detail-receive-document .view-job-done .user-only-see {
    margin-top: 20px; }
  .detail-receive-document .view-job-done .status-job {
    position: absolute;
    top: 0;
    margin-top: 3px; }
  .detail-receive-document .view-job-done .avatar {
    width: 37px;
    height: 37px;
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
    float: left;
    margin-left: 20px;
    position: absolute;
    left: 0; }
  .detail-receive-document .view-job-done .name_user.none-status-fullwidth {
    width: 100%; }
  .detail-receive-document .view-job-done .name_user {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 13px;
    color: #212121;
    padding-top: 8px;
    padding-left: 65px;
    width: 100%;
    white-space: pre-line;
    float: left; }
    .detail-receive-document .view-job-done .name_user .role {
      color: #195fff;
      font-family: "Roboto-medium", sans-serif;
      font-size: 13px;
      padding-left: 5px; }
  .detail-receive-document .view-job-done .info-job {
    font-size: 13px;
    color: #000;
    padding-left: 70px;
    line-height: 22px; }
    .detail-receive-document .view-job-done .info-job .title {
      font-family: "Roboto-medium", sans-serif;
      display: inline-block;
      padding-right: 5px; }
    .detail-receive-document .view-job-done .info-job .content {
      font-family: "Roboto-Regular", sans-serif; }
    .detail-receive-document .view-job-done .info-job .info-job-item {
      min-width: 100px; }
  .detail-receive-document .view-job-done .info-gop-y {
    font-size: 14px;
    color: #000;
    padding-left: 35px;
    line-height: 22px;
    margin-bottom: 15px; }
    .detail-receive-document .view-job-done .info-gop-y .title {
      font-family: "Roboto-medium", sans-serif;
      padding-left: 22px;
      padding-right: 10px;
      display: inline-block;
      font-size: 14px; }
    .detail-receive-document .view-job-done .info-gop-y .title-file-info {
      font-family: "Roboto-medium", sans-serif;
      padding-left: 35px;
      padding-right: 10px;
      display: inline-block;
      font-size: 14px; }
    .detail-receive-document .view-job-done .info-gop-y .title-trang-thai-gop-y {
      font-size: 15px;
      font-family: "Roboto-Bold", sans-serif; }
    .detail-receive-document .view-job-done .info-gop-y .content {
      font-family: "Roboto-Regular", sans-serif; }
    .detail-receive-document .view-job-done .info-gop-y .info-job-item {
      min-width: 100px; }
    .detail-receive-document .view-job-done .info-gop-y .info {
      padding-left: 36px; }
  .detail-receive-document .documents-cabinet .pt-label {
    color: #212121;
    margin-bottom: 16px;
    padding-top: 10px; }
  .detail-receive-document .documents-cabinet .checkbox-document {
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.23); }
    .detail-receive-document .documents-cabinet .checkbox-document .col-xs-5 {
      padding-right: 0; }
    .detail-receive-document .documents-cabinet .checkbox-document .col-xs-7 {
      padding-left: 0; }
  .detail-receive-document .documents-cabinet .type-document-list {
    padding-left: 20px;
    padding-top: 10px; }
  .detail-receive-document .documents-cabinet .group-save-document {
    border-left: 1px solid rgba(151, 151, 151, 0.23);
    background: #fafafa;
    padding: 20px;
    min-height: 324px; }
    .detail-receive-document .documents-cabinet .group-save-document .selectbox-group {
      margin-bottom: 0; }
  .detail-receive-document .documents-cabinet .pt-text {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    color: #454a52;
    margin-bottom: 14px; }
  .detail-receive-document .documents-cabinet .list-select-document .pt-icon-chevron-down,
  .detail-receive-document .documents-cabinet .list-select-document .pt-icon-chevron-up {
    padding: 2px 0;
    cursor: pointer; }
  .detail-receive-document .documents-cabinet .list-select-document .pt-icon-chevron-up:before {
    color: #195fff; }
  .detail-receive-document .documents-cabinet .list-select-document .name_checkbox {
    cursor: pointer;
    margin-bottom: 15px; }
    .detail-receive-document .documents-cabinet .list-select-document .name_checkbox .pt-checkbox-save-document {
      float: left;
      display: inline;
      margin-left: 10px; }
    .detail-receive-document .documents-cabinet .list-select-document .name_checkbox .pt-save-document {
      display: block; }
  .detail-receive-document .documents-cabinet .list-select-document .not-icon-chevron {
    margin-top: 10px;
    margin-left: 13px; }
  .detail-receive-document .documents-cabinet .list-select-document .list-select-sub {
    margin-top: 15px;
    margin-left: 25px; }
    .detail-receive-document .documents-cabinet .list-select-document .list-select-sub:last-child {
      margin-bottom: 15px; }
    .detail-receive-document .documents-cabinet .list-select-document .list-select-sub .pt-checkbox-save-document {
      margin: 0px 0px 0px 25px; }
    .detail-receive-document .documents-cabinet .list-select-document .list-select-sub .selectbox-group .pt-checkbox {
      float: left;
      margin-left: 10px; }
    .detail-receive-document .documents-cabinet .list-select-document .list-select-sub .pt-icon-chevron-down,
    .detail-receive-document .documents-cabinet .list-select-document .list-select-sub .pt-icon-chevron-up {
      font-size: 13px; }
  .detail-receive-document .documents-cabinet .list-select-document .dropdown-icon {
    cursor: pointer; }
  .detail-receive-document .documents-cabinet .item-info-file .label-item {
    display: inline-block;
    margin-right: 5px; }
  .detail-receive-document .documents-cabinet .item-info-file:not(:last-child) {
    margin-bottom: 19px; }
  .detail-receive-document .documents-cabinet .icon-pdf {
    padding-right: 10px;
    padding-left: 10px; }
  .detail-receive-document .documents-cabinet .form-input {
    padding: 20px; }
  .detail-receive-document .documents-cabinet .form-save-document-content {
    padding: 5px 10px 10px; }
  .detail-receive-document .form-send-mail {
    padding: 18px; }
    .detail-receive-document .form-send-mail .pt-label {
      padding-top: 12px; }
    .detail-receive-document .form-send-mail .item-info-file .label-item {
      padding-right: 45px; }
    .detail-receive-document .form-send-mail .item-info-file .icon-pdf {
      padding-right: 10px; }
  .detail-receive-document .detail-digitizing .item {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin-left: 10px;
    margin-bottom: 10px; }

.note-deadline .text {
  opacity: 0.74;
  font-family: "Roboto-Italic", sans-serif;
  font-size: 13px;
  color: #454a52;
  letter-spacing: 0;
  text-align: left;
  padding-left: 10px; }

.dispatch-code {
  margin-bottom: 15px; }
  .dispatch-code .number-code {
    padding-left: 15px;
    position: relative;
    top: 3px; }
  .dispatch-code:after {
    content: '';
    clear: both;
    display: table; }
  .dispatch-code .text-content {
    font-family: "Roboto-Regular", sans-serif; }

/* history */
.history-steering-box .heading {
  text-align: left; }
  .history-steering-box .heading .name-heading {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 17px;
    color: #000; }
  .history-steering-box .heading .icon-GopY:before,
  .history-steering-box .heading .icon-hisory:before {
    padding-right: 13px; }
  .history-steering-box .heading .icon-listing::before {
    padding-right: 13px;
    font-size: 21px; }
  .history-steering-box .heading .icon-Ban_Bao_Cao:before,
  .history-steering-box .heading .icon-Chidao:before,
  .history-steering-box .heading .icon-Thong_Tin_Giao_Viec:before {
    padding-right: 13px;
    color: #175bf5; }

.history-steering-box .box-item {
  margin-top: 10px; }

.history-steering-box .item-content-history {
  margin-top: 20px;
  position: relative;
  padding: 20px;
  background: #fff;
  border: 1px solid #e4ecf0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  font-size: 13px; }
  .history-steering-box .item-content-history .status-assignment {
    color: #195fff !important; }
    .history-steering-box .item-content-history .status-assignment .style-icon:before {
      color: #195fff !important; }
  .history-steering-box .item-content-history .remove-assignment {
    color: #ea5744 !important; }
    .history-steering-box .item-content-history .remove-assignment .pt-icon-trash:before {
      color: #ea5744 !important; }
    .history-steering-box .item-content-history .remove-assignment .title-bold {
      font-family: "Roboto-medium", sans-serif;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: left; }
  .history-steering-box .item-content-history .content-detail {
    overflow: hidden;
    margin-top: 2px;
    padding-left: 10px; }
    .history-steering-box .item-content-history .content-detail .detail-pdf {
      display: block; }
      .history-steering-box .item-content-history .content-detail .detail-pdf .icon-pdf {
        padding-right: 10px; }
      .history-steering-box .item-content-history .content-detail .detail-pdf .detail-pdf-item {
        font-size: 13px;
        font-family: Roboto-Regular;
        color: #454b52;
        display: block; }
    .history-steering-box .item-content-history .content-detail .done,
    .history-steering-box .item-content-history .content-detail .no-process,
    .history-steering-box .item-content-history .content-detail .process {
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      border-radius: 100px;
      min-width: 98px;
      min-height: 18px;
      line-height: 18px;
      font-family: Roboto-Bold !important;
      font-size: 9px;
      letter-spacing: 0;
      text-align: center;
      text-transform: uppercase;
      display: inline-block; }
    .history-steering-box .item-content-history .content-detail .process {
      background: #eef6ff;
      border: 1px solid #adc6ff;
      color: #195fff; }
    .history-steering-box .item-content-history .content-detail .done {
      background: #d9fcff;
      border: 1px solid #a6f1fe;
      color: #0ca0c5; }
    .history-steering-box .item-content-history .content-detail .no-process {
      background: #fff2d6;
      border: 1px solid #f1d493;
      color: #d19330; }
    .history-steering-box .item-content-history .content-detail .light-text {
      color: #195fff; }
  .history-steering-box .item-content-history .time-create {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 11px;
    color: #4a4a4a;
    position: absolute;
    top: -13px;
    left: 0;
    border: 1px solid #dbdbdb;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
    min-width: 110px;
    padding: 6px 11px;
    background: #fff; }
  .history-steering-box .item-content-history .avatar-user {
    position: relative;
    min-height: 37px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.16);
    padding-bottom: 12px;
    margin-bottom: 12px; }
    .history-steering-box .item-content-history .avatar-user .avatar {
      width: 37px;
      min-width: 37px;
      height: 37px;
      display: inline;
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
      display: inline-block;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      overflow: hidden;
      vertical-align: middle;
      margin-right: 14px; }
    .history-steering-box .item-content-history .avatar-user .name_user {
      font-family: "Roboto-Bold", sans-serif;
      font-size: 13px;
      color: #212121;
      letter-spacing: 0;
      text-align: left;
      display: inline-block; }
  .history-steering-box .item-content-history .info-content li {
    font-size: 13px;
    color: #040404;
    letter-spacing: 0;
    line-height: 22px;
    text-align: left; }
    .history-steering-box .item-content-history .info-content li:not(:last-child) {
      margin-bottom: 12px; }
  .history-steering-box .item-content-history .info-content .style-icon {
    display: inline;
    float: left;
    width: 25px;
    height: 25px; }
    .history-steering-box .item-content-history .info-content .style-icon::before {
      position: relative;
      top: 2px;
      font-size: 16px;
      color: #b6b8ba; }
  .history-steering-box .item-content-history .info-content .title-bold {
    font-family: "Roboto-medium", sans-serif; }
  .history-steering-box .item-content-history .info-content .detail {
    font-family: "Roboto-Regular", sans-serif; }

.history-steering-box .buttton-action-footer {
  text-align: left; }
  .history-steering-box .buttton-action-footer .pt-button {
    padding: 4px 0 4px 12px !important; }
  .history-steering-box .buttton-action-footer .pt-icon {
    margin-left: 12px;
    margin-right: 12px; }

.history-steering-showmobile .heading {
  text-align: center !important; }

/* info-detail-container */
.info-detail-container {
  padding: 10px; }
  .info-detail-container .list-info .item {
    color: #040404;
    letter-spacing: 0;
    text-align: left;
    line-height: 1.4; }
  .info-detail-container .list-info .title-bold {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 14px;
    color: #212121;
    letter-spacing: 0;
    text-align: left; }
  .info-detail-container .list-info .detail {
    text-align: justify;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    color: #212121;
    letter-spacing: 0;
    text-align: left; }

.form-report-work .tt-label {
  padding-left: 8px !important;
  padding-top: 0 !important; }

.form-report-work .name-radio {
  color: #454a52;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  text-align: left; }

.form-report-work .icon-Btn_Bao_Cao:before {
  font-size: 13px;
  margin-right: 10px; }

.form-assign-work .tt-label {
  padding-top: 0 !important; }

.form-assign-work .title-assign {
  color: #454a52;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: left; }

.form-assign-work .btn-add-document {
  background: #fff;
  min-height: 38px;
  padding: 0 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }
  .form-assign-work .btn-add-document .icon-ttl {
    color: #81868e; }
  .form-assign-work .btn-add-document .text-btn {
    color: #81868e;
    font-family: "Roboto-medium", sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    margin-right: 15px;
    text-align: left;
    text-transform: uppercase; }

.form-assign-work .info-save-document {
  color: #454a52;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  margin-top: 8px;
  text-align: left; }
  .form-assign-work .info-save-document .year-style {
    color: #195fff; }
  .form-assign-work .info-save-document .pt-icon {
    margin-left: 5px; }
    .form-assign-work .info-save-document .pt-icon:before {
      background: #d7d7d7;
      color: #fff;
      cursor: pointer;
      font-size: 10px;
      padding: 1px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%; }
    .form-assign-work .info-save-document .pt-icon:hover:before {
      background: #d24848; }

.form-assign-work .resolve-date .pt-icon-calendar:before {
  top: 2px; }

.form-assign-work .resolve-date .note-deadline {
  padding-top: 10px; }

.form-assign-work .resolve-date .pt-input {
  font-size: 13px; }

.form-assign-work .resolve-date .pt-form-helper-text {
  padding-left: 10px; }

.form-assign-work .pt-form-group .pt-checkbox {
  display: inline-block; }

.form-assign-work .documents-cabinet-assign-work {
  border-top: 1px solid rgba(151, 151, 151, 0.21);
  margin-top: 20px; }
  .form-assign-work .documents-cabinet-assign-work .pt-checkbox-save-document {
    margin: 0px 0px 0px 15px !important; }
  .form-assign-work .documents-cabinet-assign-work .section-box-cabinet {
    padding: 20px 0 10px 0; }
    .form-assign-work .documents-cabinet-assign-work .section-box-cabinet .name-checkbox > .pt-label {
      line-height: normal; }
    .form-assign-work .documents-cabinet-assign-work .section-box-cabinet .documents-cabinet .pt-label {
      padding-top: 0; }
    .form-assign-work .documents-cabinet-assign-work .section-box-cabinet .documents-cabinet .checkbox-document {
      border-bottom: 0; }
      .form-assign-work .documents-cabinet-assign-work .section-box-cabinet .documents-cabinet .checkbox-document .group-save-document {
        border-left: 0;
        padding: 10px; }
      .form-assign-work .documents-cabinet-assign-work .section-box-cabinet .documents-cabinet .checkbox-document .type-document-list {
        padding: 0; }
        .form-assign-work .documents-cabinet-assign-work .section-box-cabinet .documents-cabinet .checkbox-document .type-document-list .pt-radio {
          display: inline-block;
          margin-right: 30px; }
          .form-assign-work .documents-cabinet-assign-work .section-box-cabinet .documents-cabinet .checkbox-document .type-document-list .pt-radio:last-child {
            margin-right: 0; }

.assign-info {
  background: #fff;
  -webkit-box-shadow: 0 8px 21px 0 rgba(199, 199, 199, 0.21);
  box-shadow: 0 8px 21px 0 rgba(199, 199, 199, 0.21); }
  .assign-info .heading-title-text {
    color: #9498a2;
    font-family: 'Roboto-medium';
    font-size: 13px;
    text-transform: uppercase; }

.lay-y-kien-container .border-line {
  border: 1px solid #d2d2d2;
  margin: 15px 0 10px 0; }

.lay-y-kien-container .text-not-bold {
  font-family: 'Roboto-medium';
  font-size: 13px; }

.lay-y-kien-container .checkbox-hdtv {
  font-family: 'Roboto-Regular';
  font-size: 13px; }
  .lay-y-kien-container .checkbox-hdtv .checkbox-item {
    display: flex;
    justify-content: flex-end; }
    .lay-y-kien-container .checkbox-hdtv .checkbox-item .pt-checkbox {
      margin-top: 10px; }
    .lay-y-kien-container .checkbox-hdtv .checkbox-item .name_checkbox {
      font-size: 13px; }
  .lay-y-kien-container .checkbox-hdtv .container-checkbox {
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 10px; }

.text-about-version {
  color: #000;
  font-size: 13px; }
  .text-about-version span {
    color: #f00;
    margin-right: 5px; }
  .text-about-version button {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #195fff;
    cursor: pointer;
    display: inline;
    outline: none;
    padding: 0px;
    text-decoration: underline; }

.comment-card {
  font: 'Roboto', sans-serif;
  font-weight: 300;
  position: relative; }
  .comment-card .comment-content {
    color: #31434b;
    font-size: 13px;
    line-height: 18px;
    word-break: break-word; }
  .comment-card .comment-time {
    color: #738a95;
    font-size: 11px;
    line-height: 15px; }
  .comment-card .box-action-comment {
    position: absolute;
    right: 10px;
    top: 11px; }

.txt-link {
  color: #d93448;
  cursor: pointer;
  font-size: 11px;
  text-decoration: underline; }

.text-thuhoi {
  color: #333333;
  font-size: 13px;
  font-style: italic;
  opacity: 0.35; }

.comment-button {
  background-color: #0aaa84 !important;
  border: 1px solid #0aaa84 !important; }

.box-left-addSignNumber {
  width: 60% !important; }

.box-right-addSignNumber {
  width: 40% !important; }

.addSignNumber {
  background-image: linear-gradient(-180deg, #ffffff 91%, rgba(241, 243, 246, 0.11) 100%);
  border: 1px solid #dfe7ea;
  box-shadow: 0 8px 21px 0 rgba(199, 199, 199, 0.21);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }
  .addSignNumber .btn-add-file {
    margin-top: 10px; }
  .addSignNumber .form-container {
    padding: 30px 40px 30px 40px; }
    .addSignNumber .form-container .text-regular-label {
      font-family: "Roboto-Regular", sans-serif; }
    .addSignNumber .form-container .text-bold-label {
      font-family: "Roboto-Bold", sans-serif; }
  .addSignNumber .buttton-action-footer {
    border-top: 1px solid #dfe6ea;
    padding: 22px 85px; }
    .addSignNumber .buttton-action-footer .btn-save {
      margin-right: 0; }

.list-member-signnumber .table {
  display: table;
  width: 100%;
  border-collapse: collapse; }

.list-member-signnumber .heading-table {
  display: table-row;
  font-weight: bold;
  text-align: center; }
  .list-member-signnumber .heading-table .cell-item {
    padding: 0; }

.list-member-signnumber .row-item {
  display: table-row; }
  .list-member-signnumber .row-item .cell-item {
    padding-bottom: 13px;
    padding-left: 10px;
    padding-top: 10px; }

.list-member-signnumber .cell-item {
  display: table-cell; }
  .list-member-signnumber .cell-item .name {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 14px;
    color: #454b52;
    letter-spacing: 0;
    text-align: left;
    padding-bottom: 13px; }
  .list-member-signnumber .cell-item .pt-checkbox {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    color: #212121;
    letter-spacing: 0;
    text-align: left;
    padding: 0 0 0 25px; }
  .list-member-signnumber .cell-item .pt-radio {
    left: 5px;
    top: 4px; }
  .list-member-signnumber .cell-item .pt-control {
    display: inherit; }

.list-member-signnumber .container-table {
  display: table-footer-group;
  border: 1px solid #d2d2d2;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.list-sign-number .search-advanced .group-select,
.list-sign-number .search-advanced .group-input {
  width: 34.8%; }
  .list-sign-number .search-advanced .group-select .pt-input,
  .list-sign-number .search-advanced .group-input .pt-input {
    width: 100%; }

.list-sign-number .checkbox-sign-number {
  margin-left: 10px; }
  .list-sign-number .checkbox-sign-number .name_checkbox {
    font-family: "Roboto-medium", sans-serif;
    font-size: 14px; }

.list-sign-number .search-header-left-content {
  padding-top: 0; }

.kstt-sidebar-block .title span {
  color: #195fff; }

.list-info-sign-number ul li {
  width: 20% !important; }
  .list-info-sign-number ul li .text-status {
    text-transform: uppercase; }

.detail-container-sign-number .detail-status-box .status-job {
  margin-top: 0; }

.detail-container-sign-number .buttton-action-footer {
  margin-top: 0px; }

.detail-container-sign-number .not-found-content {
  background-color: #fff; }

.detail-container-sign-number .list-action-sign-number .section-box-info .list-items {
  padding: 10px; }

.detail-container-sign-number .box-feedback .left-icon {
  float: left;
  display: block;
  width: 20px !important;
  height: 20px !important; }

.detail-container-sign-number .box-feedback .content-detail {
  float: left;
  display: block;
  width: calc(100% - 40px);
  padding-right: 10px; }

.detail-container-sign-number .feedback-content-secretary .textarea-content {
  color: #f71c1e;
  font-weight: 600; }

.detail-container-sign-number .feedback-content-secretary .icon-copy {
  cursor: pointer; }
  .detail-container-sign-number .feedback-content-secretary .icon-copy:before {
    font-size: 20px; }

.detail-container-sign-number .detail-history-signnumber .table-status .cell-table,
.detail-container-sign-number .list-action-sign-number .table-status .cell-table {
  padding: 0 10px;
  white-space: normal;
  line-height: 22px; }

.detail-container-sign-number .detail-history-signnumber .table-status .cell-status-warning,
.detail-container-sign-number .list-action-sign-number .table-status .cell-status-warning {
  white-space: nowrap;
  padding-right: 16px; }
  .detail-container-sign-number .detail-history-signnumber .table-status .cell-status-warning .status-job,
  .detail-container-sign-number .list-action-sign-number .table-status .cell-status-warning .status-job {
    min-width: 88px; }
  .detail-container-sign-number .detail-history-signnumber .table-status .cell-status-warning .warning-icon-feedback,
  .detail-container-sign-number .list-action-sign-number .table-status .cell-status-warning .warning-icon-feedback {
    margin: 0 3px; }

.detail-container-sign-number .detail-history-signnumber .box-action,
.detail-container-sign-number .list-action-sign-number .box-action {
  border-bottom: 1px solid rgba(151, 151, 151, 0.1) !important;
  padding: 0 20px 20px 12px !important; }
  .detail-container-sign-number .detail-history-signnumber .box-action .btn-action-sign-number,
  .detail-container-sign-number .list-action-sign-number .box-action .btn-action-sign-number {
    background: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    width: 100%;
    max-width: 200px;
    min-height: 43px;
    line-height: 43px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: "Roboto-medium", sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    text-align: center;
    align-items: center; }
    .detail-container-sign-number .detail-history-signnumber .box-action .btn-action-sign-number.display-flex,
    .detail-container-sign-number .list-action-sign-number .box-action .btn-action-sign-number.display-flex {
      display: flex; }
    .detail-container-sign-number .detail-history-signnumber .box-action .btn-action-sign-number p,
    .detail-container-sign-number .detail-history-signnumber .box-action .btn-action-sign-number span,
    .detail-container-sign-number .list-action-sign-number .box-action .btn-action-sign-number p,
    .detail-container-sign-number .list-action-sign-number .box-action .btn-action-sign-number span {
      line-height: 100%; }
    .detail-container-sign-number .detail-history-signnumber .box-action .btn-action-sign-number .pt-icon,
    .detail-container-sign-number .list-action-sign-number .box-action .btn-action-sign-number .pt-icon {
      margin-right: 0;
      margin-left: 10px; }
    .detail-container-sign-number .detail-history-signnumber .box-action .btn-action-sign-number .pt-icon-cross:before,
    .detail-container-sign-number .list-action-sign-number .box-action .btn-action-sign-number .pt-icon-cross:before {
      color: #f54d3d; }
  .detail-container-sign-number .detail-history-signnumber .box-action .row .col-xs-4:last-child .btn-action-sign-number,
  .detail-container-sign-number .list-action-sign-number .box-action .row .col-xs-4:last-child .btn-action-sign-number {
    max-width: 137px; }
  .detail-container-sign-number .detail-history-signnumber .box-action .btn-action-red,
  .detail-container-sign-number .list-action-sign-number .box-action .btn-action-red {
    border: 1px solid #f54d3d;
    color: #f54d3d !important; }
  .detail-container-sign-number .detail-history-signnumber .box-action .btn-action-blue,
  .detail-container-sign-number .list-action-sign-number .box-action .btn-action-blue {
    border: 1px solid #195fff;
    color: #195fff !important; }
  .detail-container-sign-number .detail-history-signnumber .box-action .btn-action-orange,
  .detail-container-sign-number .list-action-sign-number .box-action .btn-action-orange {
    border: 1px solid #fb804d;
    color: #fb804d !important; }

.detail-container-sign-number .detail-history-signnumber .container-form-action .form-container .form-input,
.detail-container-sign-number .list-action-sign-number .container-form-action .form-container .form-input {
  padding: 14px 15px 5px 15px; }
  .detail-container-sign-number .detail-history-signnumber .container-form-action .form-container .form-input.border-top,
  .detail-container-sign-number .list-action-sign-number .container-form-action .form-container .form-input.border-top {
    border-top: 1px solid #e4ecf0; }

.detail-container-sign-number .detail-history-signnumber .container-form-action .form-container .pt-label,
.detail-container-sign-number .list-action-sign-number .container-form-action .form-container .pt-label {
  padding-top: 15px;
  font-family: "Roboto-Bold", sans-serif;
  font-size: 14px;
  color: #454a52;
  line-height: 1.5;
  padding-right: 15px; }

.detail-container-sign-number .detail-history-signnumber .container-form-action .buttton-action-footer,
.detail-container-sign-number .list-action-sign-number .container-form-action .buttton-action-footer {
  margin: 14px 0; }

.detail-container-sign-number .detail-history-signnumber .pt-icon-chevron-down:before,
.detail-container-sign-number .list-action-sign-number .pt-icon-chevron-down:before {
  color: #6a7489;
  font-size: 12px;
  padding-left: 5px; }

.detail-container-sign-number .detail-status-box {
  background-color: #fff;
  overflow-x: auto;
  width: 100%; }
  .detail-container-sign-number .detail-status-box .table-hover {
    min-width: 470px; }

.detail-container-sign-number .history-edit-document {
  padding: 10px; }

.detail-container-sign-number .show-note-box {
  padding: 20px 10px 0 10px; }

.detail-container-sign-number .history-steering-box .item-content-history {
  margin-top: 14px;
  margin-bottom: 25px;
  padding: 20px 10px 15px; }
  .detail-container-sign-number .history-steering-box .item-content-history .buttton-action-footer {
    margin-bottom: 0; }
  .detail-container-sign-number .history-steering-box .item-content-history .button-update-feedback {
    text-align: center; }
  .detail-container-sign-number .history-steering-box .item-content-history:last-child {
    margin-bottom: 10px; }

.detail-container-sign-number .history-steering-box .heading .pt-icon-chevron-up,
.detail-container-sign-number .history-steering-box .heading .pt-icon-chevron-down {
  cursor: pointer; }
  .detail-container-sign-number .history-steering-box .heading .pt-icon-chevron-up:before,
  .detail-container-sign-number .history-steering-box .heading .pt-icon-chevron-down:before {
    font-size: 13px;
    color: #6a7489;
    top: -1px;
    position: relative;
    left: 3px; }

.detail-container-sign-number .box-left .row .col-xs-12:last-child {
  margin-top: 20px; }

.box-signnumber {
  background: #fff;
  padding: 10px;
  padding-bottom: 10px; }

.member-list-box {
  border: 1px solid #dfe6ea;
  border-radius: 4px;
  max-width: 1274px;
  margin: auto; }
  .member-list-box::after {
    content: '';
    clear: both;
    display: table; }
  .member-list-box .item {
    background: #f5f5f5;
    width: 49%;
    border-radius: 3px;
    padding: 10px 20px;
    float: left;
    margin: 5px 0px;
    font-size: 13px; }
    .member-list-box .item:nth-child(Odd) {
      margin-left: 1%; }
    .member-list-box .item:nth-child(Even) {
      margin-right: 1%; }
    .member-list-box .item .name {
      font-family: 'Roboto-Bold';
      font-size: 14px; }
    .member-list-box .item .unit {
      color: #7f7f7f; }

.detail-container-sign-number .kstt-container .gui-chat .box-action {
  padding: 0 20px !important; }

.title-divid {
  border-bottom: 1px solid rgba(227, 228, 232, 0.34);
  padding: 0 0 5px 0;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .title-divid {
      padding: 0; } }
  .title-divid button {
    border: none;
    background: transparent;
    cursor: pointer; }
    .title-divid button.guichat {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -7.53px;
      line-height: 100%; }
      .title-divid button.guichat i {
        margin-right: 8px;
        font-size: 15px;
        vertical-align: middle; }
      .title-divid button.guichat span {
        font-size: 13px;
        color: #4c4e53; }

/************ listContact ******************/
.list-radio-group {
  font-size: 0; }
  .list-radio-group .pt-control .pt-control-indicator {
    box-shadow: none;
    border: 1px solid #ababab; }
  .list-radio-group .pt-control.checked,
  .list-radio-group .pt-control.pt-radio input:checked ~ {
    color: #195fff; }
    .list-radio-group .pt-control.checked .pt-control-indicator,
    .list-radio-group .pt-control.pt-radio input:checked ~ .pt-control-indicator {
      border-color: #195fff;
      box-shadow: none; }
    .list-radio-group .pt-control.checked .pt-text,
    .list-radio-group .pt-control.pt-radio input:checked ~ .pt-text {
      color: #195fff; }
  .list-radio-group .item {
    color: #212121;
    display: inline-block;
    font-family: "Roboto-medium", sans-serif;
    font-size: 14px; }
    .list-radio-group .item:not(:last-child) {
      padding-right: 32px; }

.listContact-container .search-header-left-content {
  padding-top: 0; }

.page-list-contact-container .name-radio {
  font-family: "Roboto-medium", sans-serif;
  font-size: 14px; }

.page-list-contact-container .select-filter-header {
  padding-top: 10px;
  padding-right: 10px; }

.page-list-contact-container .search-group-container .list-radio-group {
  padding-top: 14px; }
  .page-list-contact-container .search-group-container .list-radio-group .pt-radio {
    margin-bottom: 0; }

.page-list-contact-container .search-group-container .search-header-group {
  display: inline-block;
  text-align: right; }

.page-list-contact-container .search-advanced .group-select {
  width: 50%; }

/* custom forward-department select */
.checkbox-forward-department {
  margin-bottom: 12px; }
  .checkbox-forward-department .forward-department {
    border: 1px solid #b6b6b6;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 9px 0 0 18px; }
  .checkbox-forward-department .list-checkbox-level .sub-level-primary {
    position: relative; }
  .checkbox-forward-department .list-checkbox-level .name-checkbox {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    letter-spacing: 0;
    text-align: left; }
  .checkbox-forward-department .list-checkbox-level .pt-icon-chevron-down {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer; }
    .checkbox-forward-department .list-checkbox-level .pt-icon-chevron-down:before {
      font-size: 12px;
      color: #9fa2a5;
      right: 10px;
      position: relative; }
  .checkbox-forward-department .list-checkbox-level .dropdown-check-box-group {
    margin-left: 9px; }

.departments-list {
  display: table;
  width: 100%; }
  .departments-list .pt-control.pt-disabled {
    color: #384f5a; }
  .departments-list .right-action-group {
    display: inline-block;
    padding: 0 10px;
    position: absolute;
    right: 0;
    top: 4px; }
    .departments-list .right-action-group .icon-dropdown-right {
      cursor: pointer;
      display: inline-block;
      line-height: 0;
      padding: 5px 10px;
      vertical-align: middle; }
      .departments-list .right-action-group .icon-dropdown-right .icon-arrow-dropdown {
        font-size: 8px; }
        .departments-list .right-action-group .icon-dropdown-right .icon-arrow-dropdown:before {
          color: #9fa2a5; }
    .departments-list .right-action-group .icon-action {
      cursor: pointer;
      display: inline-block;
      padding: 5px;
      vertical-align: middle; }
  .departments-list .dropdown-left {
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 16px;
    line-height: 0;
    padding: 6px 5px 4px 14px;
    position: absolute;
    left: 0;
    top: 4px;
    vertical-align: middle;
    width: 32px; }
    .departments-list .dropdown-left .cpc-icon-left {
      font-size: 7px; }
      .departments-list .dropdown-left .cpc-icon-left:before {
        color: #9fa2a5; }
    .departments-list .dropdown-left .pt-icon-chevron-up {
      font-size: 18px;
      line-height: 1;
      margin-top: -4px; }
      .departments-list .dropdown-left .pt-icon-chevron-up:before {
        color: #9fa2a5; }
  .departments-list .main-content {
    font-size: 13px;
    min-width: 250px;
    padding: 5px 0 5px 14px;
    position: relative; }
    .departments-list .main-content .left-icon {
      color: #384f5a;
      display: inline-block;
      font-size: 7px;
      position: absolute;
      left: 15px;
      top: 8px; }
      .departments-list .main-content .left-icon:before {
        color: #384f5a; }
    .departments-list .main-content .text-link {
      color: #384f5a;
      display: block;
      padding-right: 34px; }
      .departments-list .main-content .text-link .text-link-drawer {
        font-weight: bold; }
    .departments-list .main-content:hover .text-link {
      color: #195fff;
      text-decoration: none; }
    .departments-list .main-content:hover .left-icon:before {
      color: #195fff; }
    .departments-list .main-content.active .text-link {
      color: #195fff;
      font-family: "Roboto-medium", sans-serif; }
    .departments-list .main-content.active .left-icon:before {
      color: #195fff; }
  .departments-list .sublist {
    padding-left: 20px; }
    .departments-list .sublist .main-content {
      padding-left: 35px; }
  .departments-list.departments-action-list .sublist {
    padding-left: 20px; }
  .departments-list.departments-action-list .main-content {
    padding-left: 45px; }

.document-cabinet-content .aside-list-content,
.decentralized-users-content .aside-list-content {
  overflow-x: auto; }

.aside-nav .head-content-aside {
  padding: 14px 120px 8px 14px; }

.aside-nav .main-heading {
  color: #212121;
  font-family: "Roboto-medium", sans-serif; }

.cols-group-content {
  display: table;
  table-layout: fixed;
  width: 100%; }
  .cols-group-content .aside-col-content,
  .cols-group-content .col-main-content {
    display: table-cell;
    vertical-align: top; }
  .cols-group-content .aside-col-content {
    border-right: 1px solid #dfe6ea;
    width: 300px;
    overflow: auto; }
    .cols-group-content .aside-col-content .btn-close {
      display: none;
      font-size: 16px;
      height: 48px;
      padding: 16px;
      position: absolute;
      top: 0;
      right: 0;
      width: 48px; }
      .cols-group-content .aside-col-content .btn-close .icon-close-line:before {
        color: #d2d2d2; }
    .cols-group-content .aside-col-content .head-content-aside {
      padding: 14px 120px 8px 14px;
      position: relative; }
      .cols-group-content .aside-col-content .head-content-aside .btn-add-small {
        position: absolute;
        right: 10px;
        top: 10px; }
  .cols-group-content .col-main-content {
    min-width: -moz-calc(100% - 300px);
    min-width: -webkit-calc(100% - 300px);
    min-width: calc(100% - 300px);
    width: 70%; }

.page-list-document-cabinet-container {
  background-color: #fff;
  border: 1px solid #dfe6ea;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }
  .page-list-document-cabinet-container .search-documentary-content {
    padding-bottom: 0 !important; }
  .page-list-document-cabinet-container .search-advanced {
    margin: 0 10px; }
  .page-list-document-cabinet-container .show-asidenav-moblie {
    border-bottom: 1px solid #dfe6ea;
    display: none; }
  .page-list-document-cabinet-container .head-action-group {
    border-bottom: 1px solid #dfe6ea;
    min-height: 60px;
    padding: 10px 15px;
    text-align: right; }
    .page-list-document-cabinet-container .head-action-group .btn-action {
      margin: 0 5px;
      min-width: 120px;
      text-align: center; }
      .page-list-document-cabinet-container .head-action-group .btn-action .pt-icon {
        display: inline-block;
        margin-left: 8px;
        vertical-align: top; }
        .page-list-document-cabinet-container .head-action-group .btn-action .pt-icon:before {
          font-size: 17px; }
      .page-list-document-cabinet-container .head-action-group .btn-action .icon-move:before {
        font-size: 15px; }
    .page-list-document-cabinet-container .head-action-group .list-button {
      float: right; }
      .page-list-document-cabinet-container .head-action-group .list-button .btn-action {
        cursor: pointer;
        height: 38px; }
    .page-list-document-cabinet-container .head-action-group .select-tab {
      width: 285px; }
  .page-list-document-cabinet-container .search-container {
    border: 0 none transparent;
    box-shadow: none; }
  .page-list-document-cabinet-container .aside-collaspe-content .text-link {
    color: #212121;
    display: block;
    font-size: 14px;
    line-height: 1.4;
    padding: 10px 35px; }
    .page-list-document-cabinet-container .aside-collaspe-content .text-link:hover {
      text-decoration: none; }
  .page-list-document-cabinet-container .aside-collaspe-content .dropdown-left {
    cursor: pointer;
    display: inline-block;
    float: left;
    line-height: 0;
    padding: 16px 5px 4px 14px;
    position: absolute;
    left: 0;
    top: 0;
    vertical-align: middle;
    width: 45px; }
    .page-list-document-cabinet-container .aside-collaspe-content .dropdown-left .icon-arrow-dropdown {
      font-size: 7px; }
      .page-list-document-cabinet-container .aside-collaspe-content .dropdown-left .icon-arrow-dropdown:before {
        color: #9fa2a5; }
    .page-list-document-cabinet-container .aside-collaspe-content .dropdown-left .pt-icon-chevron-up {
      font-size: 18px;
      line-height: 1;
      margin-top: -4px; }
      .page-list-document-cabinet-container .aside-collaspe-content .dropdown-left .pt-icon-chevron-up:before {
        color: #9fa2a5; }
  .page-list-document-cabinet-container .aside-collaspe-content .subitem .dropdown-left {
    margin-left: 15px; }
  .page-list-document-cabinet-container .aside-collaspe-content .right-action-group {
    padding: 0 10px; }
    .page-list-document-cabinet-container .aside-collaspe-content .right-action-group .icon-dropdown-right {
      cursor: pointer;
      display: inline-block;
      line-height: 0;
      padding: 5px 10px;
      vertical-align: middle; }
      .page-list-document-cabinet-container .aside-collaspe-content .right-action-group .icon-dropdown-right .icon-arrow-dropdown {
        font-size: 8px; }
        .page-list-document-cabinet-container .aside-collaspe-content .right-action-group .icon-dropdown-right .icon-arrow-dropdown:before {
          color: #9fa2a5; }
    .page-list-document-cabinet-container .aside-collaspe-content .right-action-group .icon-action {
      cursor: pointer;
      display: inline-block;
      padding: 5px;
      vertical-align: middle; }
  .page-list-document-cabinet-container .aside-collaspe-content .heading-panel {
    -webkit-transition: background-color 0.5s ease-in-out;
    -moz-transition: background-color 0.5s ease-in-out;
    -o-transition: background-color 0.5s ease-in-out;
    transition: background-color 0.5s ease-in-out;
    position: relative; }
    .page-list-document-cabinet-container .aside-collaspe-content .heading-panel .right-action-group {
      position: absolute;
      right: 0;
      text-align: right;
      top: 10px; }
    .page-list-document-cabinet-container .aside-collaspe-content .heading-panel:hover {
      background-color: #f5f5f5; }
  .page-list-document-cabinet-container .aside-collaspe-content .aside-sublink .text-link {
    padding-left: 55px; }
  .page-list-document-cabinet-container .aside-collaspe-content .aside-sublink .subitem {
    position: relative;
    -webkit-transition: background-color 0.5s ease-in-out;
    -moz-transition: background-color 0.5s ease-in-out;
    -o-transition: background-color 0.5s ease-in-out;
    transition: background-color 0.5s ease-in-out; }
    .page-list-document-cabinet-container .aside-collaspe-content .aside-sublink .subitem:hover {
      background-color: #f5f5f5; }
    .page-list-document-cabinet-container .aside-collaspe-content .aside-sublink .subitem .right-action-group {
      position: absolute;
      right: 0;
      text-align: right;
      top: 10px; }
  .page-list-document-cabinet-container .table-container {
    border-left: 0 none transparent;
    border-right: 0 none transparent;
    border-bottom: 0 none transparent;
    min-height: 180px; }
  .page-list-document-cabinet-container .table-scroll {
    overflow-x: auto; }
  .page-list-document-cabinet-container .search-advanced {
    padding-left: 0;
    padding-right: 0;
    margin-top: 15px; }
    .page-list-document-cabinet-container .search-advanced .group-datetime-50 {
      width: 50%; }
  .page-list-document-cabinet-container .pt-tab-indicator {
    background: none;
    height: 0; }

/* Detail DocumentsCabinet */
.page-DetailDocumentsCabinet .container-form-action {
  background: #fff; }

.page-DetailDocumentsCabinet .tab-cus-container {
  border: 1px solid #dfe6ea;
  width: 100%; }
  .page-DetailDocumentsCabinet .tab-cus-container .pt-tab-list {
    border: 0; }

.page-DetailDocumentsCabinet .pt-icon-annotation:before {
  color: #5f5f5f;
  font-size: 16px; }

.page-DetailDocumentsCabinet .radio-group .item-radio {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  color: #212121;
  letter-spacing: 0;
  text-align: left; }

/* form-EditDocumentsCabinet */
.form-EditDocumentsCabinet {
  padding: 18px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.21);
  padding: 18px; }
  .form-EditDocumentsCabinet .pt-label {
    padding-top: 12px; }
  .form-EditDocumentsCabinet .item-info-file .label-item {
    padding-right: 45px;
    font-family: "Roboto-Bold", sans-serif;
    font-size: 14px;
    color: #454a52;
    text-align: left;
    margin-bottom: 0; }
  .form-EditDocumentsCabinet .item-info-file .icon-pdf {
    padding-right: 10px; }
  .form-EditDocumentsCabinet .pt-form-group:last-child {
    margin-bottom: 0; }

/* form add document cabinet */
.addDocumentsCabinet {
  background: #fff;
  /* sidebar content */ }
  .addDocumentsCabinet .box-action-detail {
    border-top: 0; }
  .addDocumentsCabinet .buttton-action-footer {
    margin: 0;
    padding: 20px;
    border-top: 1px solid rgba(151, 151, 151, 0.26); }
  .addDocumentsCabinet .section-content {
    padding: 20px 30px; }
  .addDocumentsCabinet .container-box-addfile {
    padding-left: 0;
    padding-right: 0; }
  .addDocumentsCabinet .col-left-form {
    width: -moz-calc(100% - 400px);
    width: -webkit-calc(100% - 400px);
    width: calc(100% - 400px); }
  .addDocumentsCabinet .col-right-form {
    width: 400px;
    position: relative; }
  .addDocumentsCabinet .document-cabinet-content {
    border: 1px solid #d2d2d2; }
    .addDocumentsCabinet .document-cabinet-content .aside-main-content .dropdown-left {
      cursor: pointer;
      display: inline-block;
      float: left;
      line-height: 0;
      padding: 6px 0 4px 20px;
      vertical-align: middle;
      width: 33px; }
      .addDocumentsCabinet .document-cabinet-content .aside-main-content .dropdown-left .icon-arrow-dropdown {
        font-size: 8px; }
        .addDocumentsCabinet .document-cabinet-content .aside-main-content .dropdown-left .icon-arrow-dropdown:before {
          color: #9fa2a5; }
      .addDocumentsCabinet .document-cabinet-content .aside-main-content .dropdown-left .pt-icon-chevron-up {
        font-size: 18px;
        line-height: 1;
        margin-top: -4px; }
        .addDocumentsCabinet .document-cabinet-content .aside-main-content .dropdown-left .pt-icon-chevron-up:before {
          color: #9fa2a5; }
  .addDocumentsCabinet .hidden-icon-addDocumentsCabinet {
    display: none; }
  .addDocumentsCabinet .aside-col-content .btn-close {
    display: none;
    font-size: 16px;
    height: 48px;
    padding: 16px;
    position: absolute;
    top: 0;
    right: 0;
    width: 48px; }
    .addDocumentsCabinet .aside-col-content .btn-close .icon-close-line:before {
      color: #d2d2d2; }
  .addDocumentsCabinet .aside-col-content .main-heading {
    color: #212121;
    font-family: "Roboto-medium", sans-serif;
    padding: 14px 18px; }
  .addDocumentsCabinet .aside-collaspe-content .text-link {
    color: #212121;
    display: block;
    font-size: 14px;
    line-height: 1.4;
    overflow: hidden;
    padding: 10px 18px; }
    .addDocumentsCabinet .aside-collaspe-content .text-link .pt-control {
      margin-bottom: 0; }
    .addDocumentsCabinet .aside-collaspe-content .text-link:hover {
      text-decoration: none; }
  .addDocumentsCabinet .aside-collaspe-content .right-action-group {
    padding: 0 10px; }
    .addDocumentsCabinet .aside-collaspe-content .right-action-group .icon-dropdown-right {
      cursor: pointer;
      display: inline-block;
      line-height: 0;
      padding: 5px 10px;
      vertical-align: middle; }
      .addDocumentsCabinet .aside-collaspe-content .right-action-group .icon-dropdown-right .icon-arrow-dropdown {
        font-size: 8px; }
        .addDocumentsCabinet .aside-collaspe-content .right-action-group .icon-dropdown-right .icon-arrow-dropdown:before {
          color: #9fa2a5; }
    .addDocumentsCabinet .aside-collaspe-content .right-action-group .icon-action {
      cursor: pointer;
      display: inline-block;
      padding: 5px;
      vertical-align: middle; }
  .addDocumentsCabinet .aside-collaspe-content .heading-panel {
    -webkit-transition: background-color 0.5s ease-in-out;
    -moz-transition: background-color 0.5s ease-in-out;
    -o-transition: background-color 0.5s ease-in-out;
    transition: background-color 0.5s ease-in-out;
    position: relative; }
    .addDocumentsCabinet .aside-collaspe-content .heading-panel .right-action-group {
      position: absolute;
      right: 0;
      text-align: right;
      top: 10px; }
    .addDocumentsCabinet .aside-collaspe-content .heading-panel:hover {
      background-color: #f5f5f5; }
  .addDocumentsCabinet .aside-collaspe-content .aside-sublink .text-link {
    padding-left: 53px; }
  .addDocumentsCabinet .aside-collaspe-content .aside-sublink .subitem {
    -webkit-transition: background-color 0.5s ease-in-out;
    -moz-transition: background-color 0.5s ease-in-out;
    -o-transition: background-color 0.5s ease-in-out;
    transition: background-color 0.5s ease-in-out; }
    .addDocumentsCabinet .aside-collaspe-content .aside-sublink .subitem:hover {
      background-color: #f5f5f5; }
  .addDocumentsCabinet .detail-pdf-item {
    width: 89%;
    float: right;
    padding-left: 57px; }
    .addDocumentsCabinet .detail-pdf-item .name_file,
    .addDocumentsCabinet .detail-pdf-item .path-save {
      font-family: "Roboto-Regular", sans-serif;
      font-size: 14px;
      color: #212121;
      letter-spacing: 0;
      text-align: left;
      padding-left: 15px; }
    .addDocumentsCabinet .detail-pdf-item .path-save {
      display: block;
      padding-left: 30px; }
    .addDocumentsCabinet .detail-pdf-item .path-save {
      padding-left: 0;
      margin-top: 10px; }

.container-UpdateDrawers .pt-input-timesave {
  width: 34%; }

.container-UpdateDrawers .text-after-input {
  padding-left: 11px;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  color: #212121;
  letter-spacing: 0;
  text-align: left; }

/* form-inline */
.form-inline-group {
  padding-right: 20px; }
  .form-inline-group .note-input {
    margin-bottom: 5px;
    position: inherit !important; }
  .form-inline-group .input-cqbh {
    float: left; }
  .form-inline-group .pt-form-content {
    display: inline; }
  .form-inline-group .pt-input,
  .form-inline-group .radio-group,
  .form-inline-group .pt-select {
    width: 64%;
    float: right; }
  .form-inline-group .pt-textarea {
    width: 82.4% !important;
    float: right;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    padding: 15px;
    letter-spacing: 0;
    text-align: left; }
  .form-inline-group .dateinput-icontainer-box-nline {
    width: auto;
    display: inline; }
  .form-inline-group .dateinput-inline {
    width: 64%;
    float: right; }
    .form-inline-group .dateinput-inline .pt-input {
      width: 100%; }
  .form-inline-group .react-datepicker-wrapper {
    display: block; }

.detail-send-document .list-info-document .item-btn {
  width: 24%;
  display: inline-block;
  margin-right: 10px; }

.detail-send-document .list-action .icon-gui_bo_sung:before {
  font-size: 13px !important; }

.detail-send-document .list-action .icon-Xem_thuc_hien:before {
  font-size: 25px !important;
  position: relative;
  top: 3px; }

.detail-send-document .list-action .icon-thu_hoi_cong_van:before {
  font-size: 12px !important; }

.list-receivingunit .heading-box-bottom .name {
  padding-left: 13px;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 17px;
  color: #000000;
  letter-spacing: 0;
  text-align: left; }

.list-receivingunit .heading-box-bottom .icon-heading:before {
  font-size: 21px;
  position: relative;
  top: 3px; }

.list-receivingunit .name-heading {
  padding-bottom: 15px; }

.table-list-receivingunit {
  padding-bottom: 0; }
  .table-list-receivingunit .table-scroll .pt-table {
    width: 100%;
    min-width: auto; }
  .table-list-receivingunit .icon-refreshing {
    font-size: 18px; }
  .table-list-receivingunit .table-center-td td,
  .table-list-receivingunit .table-center-td th {
    padding: 8px 10px;
    text-align: center;
    vertical-align: middle; }
  .table-list-receivingunit th:first-child {
    padding-left: 0; }
  .table-list-receivingunit thead th {
    line-height: 1.5;
    min-height: 30px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: "Roboto-medium", sans-serif; }
  .table-list-receivingunit .pt-table thead tr {
    background: #fff; }
  .table-list-receivingunit .onclick-confirm {
    margin-top: 4px; }
    .table-list-receivingunit .onclick-confirm:hover .hover-icon:before {
      color: #195fff; }
  .table-list-receivingunit .colum-first {
    width: 100px; }
  .table-list-receivingunit .colum-second {
    min-width: 150px; }
  .table-list-receivingunit .colum-fixed-width {
    width: 150px; }
  .table-list-receivingunit .colmn-none-cursor {
    cursor: default !important; }

.history-receivingunit-container {
  padding: 12px; }

.executing-view-evn-item {
  position: relative; }

.dropdown-icon {
  position: absolute;
  left: -25px;
  top: 8px; }
  .dropdown-icon.pt-icon-chevron-down, .dropdown-icon.pt-icon-chevron-up {
    color: #195fff; }
  .dropdown-icon.icon-man-chutri, .dropdown-icon.icon-man-phoihop {
    font-size: 20px;
    left: 0; }
  .dropdown-icon.icon-man-chutri {
    left: 5px; }

.executing-view-evn {
  font-size: 14px;
  line-height: 35px;
  overflow: auto;
  max-height: 600px; }

.font-bold {
  font-family: "Roboto-medium", sans-serif; }

.font-regular {
  font-family: "Roboto-Regular", sans-serif; }

.text-blue {
  font-family: "Roboto-Regular", sans-serif;
  color: #195fff; }

.text-secondary {
  font-family: "Roboto-Regular", sans-serif;
  color: #7f7f7f; }

.min-width-400 {
  min-width: 400px; }

.listDecentralizationUser-container .select-inline .select-content {
  height: 46px; }

.listDecentralizationUser-container .seach-form .btn-reload {
  margin-right: 0; }

.listDecentralizationUser-container .form-search-basic {
  float: right; }

.listDecentralizationUser-container .search-keyword {
  float: left; }

.listDecentralizationUser-container .input-searchkey .search {
  margin-right: 14px; }

.listDecentralizationUser-container .select-group,
.listDecentralizationUser-container .input-searchkey {
  float: left; }

.listDecentralizationUser-container .form-container .pt-select {
  width: auto;
  padding-right: 10px; }

.listDecentralizationUser-container .pt-label-inline {
  display: inline;
  padding-right: 14px; }
  .listDecentralizationUser-container .pt-label-inline .text-label {
    font-family: "Roboto-medium", sans-serif;
    font-size: 12px;
    color: #81868e;
    letter-spacing: 0;
    text-align: left; }

.listDecentralizationUser-container .collapse-heading-content {
  background-color: #f8f9f9; }
  .listDecentralizationUser-container .collapse-heading-content .table-title {
    padding: 12px 0 12px 43px; }
  .listDecentralizationUser-container .collapse-heading-content .name-department {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 14px;
    color: #454b52;
    letter-spacing: 0;
    text-align: left;
    padding-right: 5px; }
  .listDecentralizationUser-container .collapse-heading-content .btn-icon-collapse {
    left: 10px;
    right: auto; }
  .listDecentralizationUser-container .collapse-heading-content .icon-Back:before {
    font-size: 10px;
    padding: 0 10px; }
  .listDecentralizationUser-container .collapse-heading-content .subtable-title {
    padding-left: 25px; }
  .listDecentralizationUser-container .collapse-heading-content .link {
    text-decoration: none; }

.listDecentralizationUser-container .subcollapse-block .collapse-heading-content {
  padding-left: 40px; }

.listDecentralizationUser-container .subcollapse-block .btn-icon-collapse {
  left: 30px; }

.listDecentralizationUser-container .pt-popover-target {
  float: right;
  top: 2px; }
  .listDecentralizationUser-container .pt-popover-target .icon-More:before {
    font-size: 20px;
    position: relative;
    top: -5px;
    text-align: right; }

.listDecentralizationUser-container .width-col-5 {
  width: 5%; }

.listrole-container .table-subcollap-container,
.custom-table-container .table-subcollap-container {
  padding-bottom: 0; }

.listrole-container .head-label,
.custom-table-container .head-label {
  min-width: 22px;
  display: inline-block; }

.listrole-container .table-cols-content,
.custom-table-container .table-cols-content {
  padding: 8px 10px 4px; }
  .listrole-container .table-cols-content:not(.width-col-5):first-child,
  .custom-table-container .table-cols-content:not(.width-col-5):first-child {
    width: 3%; }
  .listrole-container .table-cols-content:first-child,
  .custom-table-container .table-cols-content:first-child {
    padding: 8px 10px 6px; }
  .listrole-container .table-cols-content:last-child,
  .custom-table-container .table-cols-content:last-child {
    text-align: right; }
  .listrole-container .table-cols-content .checkbox-item,
  .custom-table-container .table-cols-content .checkbox-item {
    display: inline;
    margin-left: 15px; }
  .listrole-container .table-cols-content .icon-move-drap,
  .custom-table-container .table-cols-content .icon-move-drap {
    cursor: move;
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
    height: 22px;
    display: inline-block;
    line-height: 22px;
    width: 22px;
    text-align: center; }
  .listrole-container .table-cols-content .icon-Back,
  .custom-table-container .table-cols-content .icon-Back {
    vertical-align: middle; }

.listrole-container .table-head-container .table-cols-content,
.custom-table-container .table-head-container .table-cols-content {
  padding: 10px 10px 8px; }

.listrole-container .table-display .table-rows:hover,
.custom-table-container .table-display .table-rows:hover {
  background: none; }

.listrole-container .table-body-container .icon-Back,
.custom-table-container .table-body-container .icon-Back {
  text-decoration: none; }

.listrole-container .table-body-container .icon-forward,
.custom-table-container .table-body-container .icon-forward {
  margin-right: 10px;
  text-decoration: none; }
  .listrole-container .table-body-container .icon-forward:before,
  .custom-table-container .table-body-container .icon-forward:before {
    color: #185fff; }
  .listrole-container .table-body-container .icon-forward:hover,
  .custom-table-container .table-body-container .icon-forward:hover {
    font-weight: bold; }

.decentralization-container .table-container th {
  white-space: normal; }

.decentralization-container .table-container td,
.decentralization-container .table-container th {
  vertical-align: middle; }
  .decentralization-container .table-container td:first-child,
  .decentralization-container .table-container th:first-child {
    padding-left: 40px; }

.decentralization-container .table-container td {
  box-shadow: none !important;
  padding: 10px; }
  .decentralization-container .table-container td:nth-child(2) {
    text-align: left; }
  .decentralization-container .table-container td .checkbox-item {
    display: inline;
    margin-left: 0; }

.decentralization-container .table-container tr:nth-child(even) {
  background: #f0f4f8; }

.decentralization-container .table-container .head-label {
  font-family: "Roboto-medium", sans-serif;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0;
  text-align: left;
  font-weight: 500 !important;
  text-transform: uppercase; }

.decentralization-container .table-container .colum-role {
  width: 150px;
  text-align: center; }

.decentralization-container .table-container .colum-stt {
  width: 100px; }

.SortableHelper {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
  border: 1px solid rgba(102, 102, 102, 0.2);
  z-index: 20; }
  .SortableHelper .table-cols-content {
    color: #454a52;
    display: table-cell;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 1.4;
    padding: 8px 10px 4px;
    text-align: left;
    vertical-align: top; }
    .SortableHelper .table-cols-content:first-child {
      padding-left: 10px;
      width: 2%; }
    .SortableHelper .table-cols-content:last-child {
      text-align: right; }
    .SortableHelper .table-cols-content .checkbox-item {
      display: inline;
      margin-left: 15px; }
    .SortableHelper .table-cols-content .icon-More:before {
      font-size: 20px; }
    .SortableHelper .table-cols-content .icon-move-drap {
      cursor: move;
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
      height: 22px;
      display: inline-block;
      line-height: 22px;
      width: 22px;
      text-align: center; }
    .SortableHelper .table-cols-content .icon-Back {
      vertical-align: middle; }
  .SortableHelper .width-col-10 {
    width: 10%; }
  .SortableHelper .width-col-5 {
    width: 5%; }
  .SortableHelper .width-col-20 {
    width: 20%; }
  .SortableHelper .width-col-50 {
    width: 50%; }
  .SortableHelper .table-cols-content {
    color: #454a52;
    font-family: "Roboto-medium", sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 31px;
    min-height: 54px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap; }
    .SortableHelper .table-cols-content:last-child {
      padding-right: 20px; }
    .SortableHelper .table-cols-content .pt-icon {
      color: #9fa2a5;
      padding-left: 7px;
      cursor: pointer; }
    .SortableHelper .table-cols-content .pt-checkbox {
      margin-top: 6px; }

.page-statistic-signnumber {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  color: #454b52;
  letter-spacing: 0;
  text-align: left; }
  .page-statistic-signnumber .seach-form-container {
    padding: 10px; }
  .page-statistic-signnumber .checkbox-active-work {
    display: inline;
    position: relative;
    top: 35px;
    left: 20px; }
    .page-statistic-signnumber .checkbox-active-work .checkbox-content {
      display: inline; }
  .page-statistic-signnumber .group-datetime {
    width: 40%; }
  .page-statistic-signnumber .name-radio {
    font-family: "Roboto-medium", sans-serif;
    font-size: 14px; }
    .page-statistic-signnumber .name-radio:last-child {
      margin-bottom: 0; }
  .page-statistic-signnumber .search-group-container .list-radio-group {
    padding-top: 14px; }
  .page-statistic-signnumber .search-group-container .search-header-group {
    display: inline-block;
    text-align: right; }
  .page-statistic-signnumber .search-header-radio-group-content {
    margin-top: 20px;
    padding-top: 0;
    margin-left: 15px; }
  .page-statistic-signnumber .button-search-statistic {
    padding-top: 25px; }
    .page-statistic-signnumber .button-search-statistic .btn-success {
      margin-right: 0; }
  .page-statistic-signnumber .main-heading {
    padding: 20px; }
  .page-statistic-signnumber .table-container {
    margin-bottom: 20px; }
    .page-statistic-signnumber .table-container .pt-table thead th:first-child,
    .page-statistic-signnumber .table-container .pt-table tbody td:first-child {
      text-align: left; }
    .page-statistic-signnumber .table-container .button-heading-right {
      position: absolute;
      top: 10px;
      right: 10px; }

.has-event {
  cursor: pointer !important; }

.no-event {
  cursor: default !important; }
  .no-event .icon-More::before {
    cursor: pointer; }

.sign-location {
  clear: both; }
  .sign-location .form-cols:first-child {
    padding-left: 0; }
  .sign-location .form-cols:last-child {
    padding-right: 0; }

.menu-popover .pt-icon::before {
  color: #5f5f5f;
  font-size: 16px; }

.menu-popover .icon-Ket_Qua_Bao_Cao {
  transform: translateY(2px); }

.form-sub-step .form-sub-title {
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #eff3f5; }
  .form-sub-step .form-sub-title .pt-label {
    text-transform: uppercase; }

.form-sub-step .sort-block-step {
  padding-top: 15px; }
  .form-sub-step .sort-block-step > .form-cols {
    width: 100%; }
    .form-sub-step .sort-block-step > .form-cols:not(:empty) {
      margin-bottom: 15px; }

.form-sub-step input[type='number']::-webkit-outer-spin-button,
.form-sub-step input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.form-sub-step input[type='number'] {
  -moz-appearance: textfield; }

.form-sub-step .btn-add-more span,
.form-sub-step .btn-add-more span::before {
  color: #2057d5; }

@media only screen and (max-width: 600px) {
  .form-sub-step .form-sub-title {
    padding: 15px 20px; } }

.form-container.procedure-form {
  border: 1px solid #d2d2d2;
  background: #fff; }
  .form-container.procedure-form .form-header {
    padding: 15px 10px;
    border-bottom: 1px solid #d2d2d2;
    margin-bottom: 10px;
    background-color: #f8f9f9; }
    .form-container.procedure-form .form-header .pt-label {
      text-transform: uppercase; }
      .form-container.procedure-form .form-header .pt-label .icon-drag {
        cursor: move;
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
        height: 22px;
        display: inline-block;
        line-height: 22px;
        width: 22px;
        text-align: center; }
    .form-container.procedure-form .form-header .close-cols {
      float: right; }
    .form-container.procedure-form .form-header .icon-close-light {
      cursor: pointer; }
      .form-container.procedure-form .form-header .icon-close-light::before {
        color: #626262; }

.pt-form-group .pt-form-helper-text {
  color: #cc1d1d; }

.pt-form-group .has-file-input {
  color: #454a52; }

.center-text {
  text-align: center !important; }

.pt-select.loading-input > select {
  color: #454a52; }

.pt-select.loading-input::after {
  display: none; }

.no-max-width {
  max-width: none !important; }

.procedure-select-item {
  background: #fff;
  border: 1px solid #dfe6ea;
  border-top: none; }
  .procedure-select-item .table-cols-content:first-child {
    padding-left: 12px; }
  .procedure-select-item.category {
    font-weight: 600;
    background: #f8f9f9; }
    .procedure-select-item.category .table-cols-content:first-child {
      text-align: center; }
      .procedure-select-item.category .table-cols-content:first-child span::before {
        font-size: 16px;
        color: #2667fc;
        cursor: pointer; }
  .procedure-select-item.procedure-group .procedure {
    cursor: pointer; }
    .procedure-select-item.procedure-group .procedure:hover {
      background: #f8f9f9; }
    .procedure-select-item.procedure-group .procedure .default, .procedure-select-item.procedure-group .procedure .cpc-button.default-back {
      text-decoration: none;
      color: inherit;
      display: block; }
  .procedure-select-item.procedure-group .has-underline {
    border-bottom: 1px solid #dfe6ea; }

.step-new-style .header-new-style {
  background: #1551da !important;
  padding: 0 !important;
  display: -webkit-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }
  .step-new-style .header-new-style .drag {
    padding: 12px 15px;
    background: #0f5bfa; }
    .step-new-style .header-new-style .drag > .icon-move-drap::before {
      color: #fff;
      cursor: grab; }
  .step-new-style .header-new-style .name {
    flex: 1; }
  .step-new-style .header-new-style > .form-cols .pt-label {
    color: #fff; }
  .step-new-style .header-new-style > .form-cols .icon-close-light::before {
    color: #fff; }

.form-sub-new-style .form-sub-title {
  border: none;
  padding: 0;
  padding-top: 10px;
  background: none; }
  .form-sub-new-style .form-sub-title .pt-label {
    text-transform: uppercase;
    color: #1551da; }

.procedure-form-content {
  background: none !important;
  border: none !important; }
  .procedure-form-content .form-block {
    margin-bottom: 5px;
    background: #fff;
    border: 1px solid #e4ecf0; }

.sign-step-new-style .form-header {
  text-transform: uppercase;
  margin-bottom: 10px; }
  .sign-step-new-style .form-header .pt-label {
    color: #195fff; }
    .sign-step-new-style .form-header .pt-label .pt-checkbox {
      color: #454a52; }
    .sign-step-new-style .form-header .pt-label .text-regular-label {
      color: #454a52;
      font-family: 'Roboto-Regular';
      text-transform: none; }
    .sign-step-new-style .form-header .pt-label .text-bold-label {
      font-family: 'Roboto-Bold'; }
  .sign-step-new-style .form-header .inline-label {
    display: inline-block; }

.sign-step-new-style:not(:last-child) {
  margin-bottom: 20px; }

.procedure-table-checkbox {
  display: inline;
  margin: 0;
  padding: 0; }

.procedure-step-define-table {
  width: 100%; }
  .procedure-step-define-table .header-row {
    color: #fff;
    background: #195fff; }
  .procedure-step-define-table .data-row {
    border: 1px solid #e3e4e8;
    border-top: 0; }
    .procedure-step-define-table .data-row .cell {
      border: 0 !important; }

.procedure-form .form-subblock {
  margin: 0 !important;
  max-width: none !important; }

.define-step-input {
  font-size: 13px;
  color: #454a52;
  font: Roboto-Regular; }
  .define-step-input .Select-control .Select-value {
    padding-right: 40px; }

.icon-trinh-quy-trinh {
  margin-right: 5px; }
  .icon-trinh-quy-trinh::before {
    color: #2667fc;
    font-size: 16px; }

.menu-popover-quy-trinh {
  padding: 5px 10px;
  cursor: default; }

.select-group-container {
  padding: 6px 18px;
  border: 1px solid #e2e2e2;
  border-radius: 3px; }
  .select-group-container .pt-checkbox {
    font-size: 13px;
    margin: 5px 0; }
  .select-group-container .role-checkbox {
    width: 33.33%;
    display: inline-block; }

.applied-unit .icon-trash:before {
  color: #6a7489; }

.applied-unit .table-cols-content:last-child {
  padding-right: 20px; }

.department-tree__child {
  padding-left: 53px; }
  .department-tree__child .department-tree__item {
    margin: 5px 0; }
  .department-tree__child .department-tree__child {
    padding-left: 23px;
    padding-top: 5px; }

.department-tree__item {
  display: flex;
  align-items: center;
  margin: 10px 30px; }
  .department-tree__item .department-tree__checkbox-form {
    margin-bottom: 0; }
  .department-tree__item .department-tree__icon {
    margin-right: 7px; }
    .department-tree__item .department-tree__icon.no-icon {
      width: 16px;
      height: 16px; }

.dialog-footer-unit .btn-save-unit {
  width: 100% !important;
  margin-left: 0; }

.title-font, .group-page-container .container-title, .group-page-container .container-body .group-header .group-name {
  font: 14px/18px 'Roboto-Bold';
  color: #333; }

.normal-font, .default-button, .group-page-container .container-body .add-group-btn, .group-page-container .container-footer .btn-cancel, .group-page-container .container-footer .save-btn, .group-input-button .group-file + label, .group-page-container .container-body .group-header .action-button-group .action-button.add-btn, .group-page-container .container-body .group-header .action-button-group .action-button.delete-btn {
  font: 12px/16px 'Roboto-Medium'; }

.only-text-button, .group-page-container .container-body .group-header .action-button-group .action-button, .group-page-container .container-body .group-table .table-display .delete-member-btn {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer; }

.default-button, .group-page-container .container-body .add-group-btn, .group-page-container .container-footer .btn-cancel, .group-page-container .container-footer .save-btn, .group-input-button .group-file + label {
  background: #fff;
  padding: 10px 15px;
  border-radius: 3px; }
  .default-button:hover, .group-page-container .container-body .add-group-btn:hover, .group-page-container .container-footer .btn-cancel:hover, .group-page-container .container-footer .save-btn:hover, .group-input-button .group-file + label:hover {
    background: #ebf1f5; }

.group-page-container {
  background: #fff;
  border: 1px solid #dfe6ea;
  border-radius: 0 0 0 4px 4px; }
  .group-page-container .container-title {
    padding: 17px 20px;
    border-bottom: 1px solid #e2e2e2; }
  .group-page-container .container-body {
    padding: 20px;
    border-bottom: 1px solid #e2e2e2; }
    .group-page-container .container-body .group-header {
      display: -webkit-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -moz-flex;
      display: flex;
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      -webkit-box-direction: normal;
      -moz-box-direction: normal;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      margin-bottom: 10px; }
      .group-page-container .container-body .group-header .group-name .collapse-btn {
        cursor: pointer;
        margin-left: 10px; }
      .group-page-container .container-body .group-header .action-button-group .action-button:last-child {
        margin-left: 20px; }
      .group-page-container .container-body .group-header .action-button-group .action-button.add-btn {
        color: #195fff; }
        .group-page-container .container-body .group-header .action-button-group .action-button.add-btn .icon-Plus {
          margin-right: 6px; }
          .group-page-container .container-body .group-header .action-button-group .action-button.add-btn .icon-Plus::before {
            color: #195fff;
            font-size: 10px; }
      .group-page-container .container-body .group-header .action-button-group .action-button.delete-btn {
        color: #d0021b; }
    .group-page-container .container-body .group-table {
      margin-bottom: 15px; }
      .group-page-container .container-body .group-table .table-display .table-rows {
        border: 1px solid #e2e2e2;
        border-top: none; }
      .group-page-container .container-body .group-table .table-display .table-cols-content:first-child {
        padding-left: 12px; }
      .group-page-container .container-body .group-table .table-display .delete-member-btn .pt-icon-trash::before {
        color: #6a7489; }
    .group-page-container .container-body .add-group-btn {
      color: #195fff;
      text-transform: uppercase; }
      .group-page-container .container-body .add-group-btn .icon-Plus::before {
        margin-left: 12px;
        color: #195fff; }
  .group-page-container .container-footer {
    display: -webkit-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 15px 0; }
    .group-page-container .container-footer .pt-button {
      padding: 10px 20px;
      text-transform: uppercase; }
      .group-page-container .container-footer .pt-button:first-child {
        margin-right: 10px; }
    .group-page-container .container-footer .btn-cancel {
      color: #6a7489; }
    .group-page-container .container-footer .save-btn {
      background: #195fff;
      border: 1px solid #195fff;
      color: #fff; }
      .group-page-container .container-footer .save-btn:hover {
        background: #0344d7; }
      .group-page-container .container-footer .save-btn .icon-save {
        margin-left: 10px; }
        .group-page-container .container-footer .save-btn .icon-save::before {
          color: #fff; }

.primary-color {
  color: #195fff !important; }

.group-end-line {
  margin-top: 0;
  margin-bottom: 15px; }

.no-justify {
  -webkit-box-pack: flex-start !important;
  -moz-box-pack: flex-start !important;
  -ms-flex-pack: flex-start !important;
  -webkit-justify-content: flex-start !important;
  justify-content: flex-start !important; }

.group-input-button {
  min-width: 140px; }
  .group-input-button .group-file {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }
    .group-input-button .group-file + label {
      display: inline-block;
      margin-right: 12px;
      border: 1px solid #195fff;
      cursor: pointer; }
      .group-input-button .group-file + label i {
        margin-right: 12px; }

.group-file-display {
  position: relative;
  padding-right: 24px; }
  .group-file-display i {
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
    cursor: pointer; }
    .group-file-display i::before {
      color: #195fff; }

.validate-error-text {
  font-size: 13px;
  color: red; }

.signature-document-form {
  background: transparent;
  border: none; }
  .signature-document-form .error-page {
    background: #fff;
    border: 1px solid #d2d2d2; }
  .signature-document-form .form-block {
    background-color: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 2px;
    padding-top: 10px;
    margin-bottom: 20px; }
    .signature-document-form .form-block .procedure-block {
      display: -webkit-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -moz-flex;
      display: flex; }
      .signature-document-form .form-block .procedure-block .procedure-left {
        padding-left: 0;
        flex: 1; }
      .signature-document-form .form-block .procedure-block .procedure-right {
        display: -webkit-flex;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: flex;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        padding: 0; }
        .signature-document-form .form-block .procedure-block .procedure-right button {
          box-sizing: border-box;
          width: 155px;
          height: 40px;
          padding: 0 15px;
          background: none;
          outline: none;
          border-radius: 3px;
          font-size: 12px; }
          .signature-document-form .form-block .procedure-block .procedure-right button.help-document {
            border: 1px solid #f3853a;
            background-color: #fff4ed;
            margin-right: 20px; }
            .signature-document-form .form-block .procedure-block .procedure-right button.help-document .icon-document::before {
              color: #f3853a; }
            .signature-document-form .form-block .procedure-block .procedure-right button.help-document:hover {
              background-color: #ffdeca; }
          .signature-document-form .form-block .procedure-block .procedure-right button.template {
            color: #195fff;
            border: 1px solid #195fff; }
            .signature-document-form .form-block .procedure-block .procedure-right button.template .icon-cloud::before {
              color: #195fff; }
            .signature-document-form .form-block .procedure-block .procedure-right button.template:hover {
              background-color: #ebf1f5; }
    .signature-document-form .form-block .input-area {
      resize: none;
      height: 68px; }
    .signature-document-form .form-block .pt-input-file {
      margin-bottom: 6px;
      position: relative; }
    .signature-document-form .form-block .pt-control {
      line-height: 18px; }
    .signature-document-form .form-block .select-all-checkbox {
      float: right;
      margin-bottom: 0;
      font: 13px "Roboto-Regular", sans-serif; }
      .signature-document-form .form-block .select-all-checkbox .pt-checkbox {
        margin: 0;
        display: inline; }
    .signature-document-form .form-block .form-sub-step .form-sub-body {
      padding: 20px 0; }
      .signature-document-form .form-block .form-sub-step .form-sub-body .procedure-form {
        margin-bottom: 20px; }
        .signature-document-form .form-block .form-sub-step .form-sub-body .procedure-form:last-child {
          margin-bottom: 0; }
        .signature-document-form .form-block .form-sub-step .form-sub-body .procedure-form .form-body {
          padding: 10px 0; }
      .signature-document-form .form-block .form-sub-step .form-sub-body .select-group-container {
        padding: 6px 18px;
        border: 1px solid #e2e2e2;
        border-radius: 3px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; }
        .signature-document-form .form-block .form-sub-step .form-sub-body .select-group-container .member-checkbox {
          flex: 1 1 33%;
          max-width: 33%; }
        .signature-document-form .form-block .form-sub-step .form-sub-body .select-group-container .pt-checkbox {
          font-size: 13px;
          margin: 5px 0; }
      .signature-document-form .form-block .form-sub-step .form-sub-body .select-fieldset {
        margin-bottom: 10px; }
        .signature-document-form .form-block .form-sub-step .form-sub-body .select-fieldset legend {
          font-size: 0.9em;
          font-weight: bold;
          padding: 0 5px; }
    .signature-document-form .form-block .form-col-inline {
      display: inline-block; }
    .signature-document-form .form-block .buttton-action-footer {
      position: relative;
      padding: 20px; }
      .signature-document-form .form-block .buttton-action-footer .save-draft-btn {
        position: absolute;
        left: 20px;
        border: 1px solid #195fff;
        color: #195fff;
        width: 120px;
        background-color: #fff; }
        .signature-document-form .form-block .buttton-action-footer .save-draft-btn .icon-save::before {
          color: #195fff; }
        .signature-document-form .form-block .buttton-action-footer .save-draft-btn:hover {
          background-color: #ebf1f5; }
    .signature-document-form .form-block .save-draft-col {
      display: -webkit-flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -moz-flex;
      display: flex; }
      .signature-document-form .form-block .save-draft-col .pt-label {
        padding-top: 10px;
        font: 14px 'Roboto-Bold';
        width: 100px;
        height: 39px;
        margin-right: 20px; }

.signature-document-custom-list form table::before {
  content: none !important; }

.signature-document-custom-list .footer-list {
  padding: 0; }

.procedure-details-content .block {
  margin-bottom: 8px;
  background: #fff;
  border: 1px solid #e4ecf0; }
  .procedure-details-content .block .sub-block {
    padding: 20px; }
    .procedure-details-content .block .sub-block:not(:last-child) {
      border-bottom: 1px solid #e4ecf0; }
    .procedure-details-content .block .sub-block .row {
      margin: 0;
      display: flex; }
      .procedure-details-content .block .sub-block .row:not(:last-child) {
        margin-bottom: 10px; }
      .procedure-details-content .block .sub-block .row .head {
        width: 30%;
        font-weight: 500; }
      .procedure-details-content .block .sub-block .row .content {
        width: 70%; }
      .procedure-details-content .block .sub-block .row.title {
        font: 16px 'Roboto-bold';
        color: #0f5bfa; }
    .procedure-details-content .block .sub-block .title {
      text-transform: uppercase;
      font-weight: 500; }
    .procedure-details-content .block .sub-block.title {
      padding: 16px 20px;
      background: #0f5bfa;
      color: #fff; }

.procedure-details-content .buttton-action-footer {
  border: none;
  padding: 0; }

.procedure-details-content .file-type {
  vertical-align: middle;
  background-position: center left;
  background-size: 18px 24px;
  background-repeat: no-repeat;
  padding: 4px 0 4px 24px; }

.status-metting {
  padding-left: 5px;
  position: relative; }
  .status-metting:before {
    border-radius: 2px;
    content: '';
    display: inline-block;
    height: 10px;
    left: 0;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 2px; }
  .status-metting.yellow {
    color: #ed8526; }
    .status-metting.yellow:before {
      background-color: #ed8526; }
  .status-metting.blue {
    color: #195fff; }
    .status-metting.blue:before {
      background-color: #195fff; }
  .status-metting.green {
    color: #08b424; }
    .status-metting.green:before {
      background-color: #08b424; }
  .status-metting.red {
    color: #d60000; }
    .status-metting.red:before {
      background-color: #d60000; }
  .status-metting.orange {
    color: #ed8525; }
    .status-metting.orange:before {
      background-color: #ed8525; }

.panel-meeting-detail {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 8px 21px rgba(199, 199, 199, 0.209975);
  padding: 15px 15px 0; }
  .panel-meeting-detail ul {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px; }
    .panel-meeting-detail ul li {
      align-items: flex-start;
      display: flex;
      flex: 1 50%;
      padding: 0 15px; }
      .panel-meeting-detail ul li.align-items-center {
        align-items: center; }
    .panel-meeting-detail ul .label {
      display: block;
      min-width: 120px;
      width: 120px; }
    .panel-meeting-detail ul .user-info span {
      color: #9a9a9a; }

.click-avatar-work-flow {
  align-items: center;
  cursor: pointer;
  display: flex;
  min-height: 30px;
  position: relative; }
  .click-avatar-work-flow .avatar-user {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    float: left;
    height: 37px;
    left: 0;
    margin-left: 20px;
    min-width: 37px;
    overflow: hidden;
    position: absolute;
    width: 37px; }
  .click-avatar-work-flow .name-user {
    color: #212121;
    font-family: 'Roboto-Bold';
    font-size: 13px;
    padding-left: 50px;
    white-space: pre-line;
    width: 100%; }
    .click-avatar-work-flow .name-user .role {
      color: #195fff;
      font-family: 'Roboto-Medium';
      margin-left: 5px; }
  .click-avatar-work-flow .pt-icon-chevron-up {
    color: #195fff; }

.item-flow .info-user {
  font-family: 'Roboto-Regular';
  font-size: 13px;
  padding-left: 65px; }
  .item-flow .info-user .click-report {
    color: #195fff;
    cursor: pointer;
    display: inline-block; }

.avatar-lanhdao {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  height: 37px;
  width: 37px; }

.main-flowwork {
  background: #f7f8fa; }
  .main-flowwork .flow-work-box {
    background: #fff;
    border: 1px solid #dfe6ea;
    margin: 10px; }
  .main-flowwork .box-info {
    border-bottom: 1px solid #dfe6ea;
    margin-bottom: 30px;
    padding: 15px; }

.align-items-center {
  align-items: center; }

.lanhdao-info {
  margin-left: 8px; }
  .lanhdao-info .ten-lanhdao {
    font-weight: 600; }
  .lanhdao-info .chuc-vu {
    color: #9a9a9a;
    font-size: 10px; }

.icon-pdf-flow {
  height: 19px;
  width: 16px; }

.report-flow {
  background: #f4f4f4; }
  .report-flow .item-report-flow {
    border-bottom: 1px solid #0000002b;
    margin: 0 17px;
    padding: 12px 0; }
    .report-flow .item-report-flow:last-child {
      border-bottom: none; }
    .report-flow .item-report-flow .info-report-flow {
      align-items: flex-start;
      flex-direction: column;
      gap: 5px;
      padding-left: 28px; }
      .report-flow .item-report-flow .info-report-flow.link {
        color: #195fff;
        cursor: pointer; }

.block-thongke {
  display: flex;
  gap: 15px;
  width: 100%; }
  .block-thongke .info-thongke {
    background: #fff;
    border-radius: 4px;
    height: fit-content;
    max-height: 600px;
    overflow: auto;
    padding: 15px;
    width: 350px; }
    .block-thongke .info-thongke .info-thongke-item {
      border-bottom: 1px solid #0000001c;
      font-family: 'Roboto-Regular';
      font-size: 13px;
      padding-bottom: 10px;
      padding-top: 10px; }
      .block-thongke .info-thongke .info-thongke-item:last-child {
        border-bottom: unset;
        padding-bottom: 0; }
      .block-thongke .info-thongke .info-thongke-item:first-child {
        padding-top: 0; }
  .block-thongke .table-thongke {
    flex: 1; }
  @media only screen and (max-width: 992px) {
    .block-thongke {
      flex-direction: column; }
      .block-thongke .info-thongke {
        min-width: 100%;
        width: 100%; } }

.wh-16 {
  height: 16px;
  width: 16px; }

.item-flow-department {
  display: flex;
  flex-wrap: wrap; }
  .item-flow-department .name-user {
    color: #212121;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 600; }
  .item-flow-department .role {
    color: #195fff;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    margin-left: 5px; }

.systerm-form, .SortableHelper-step {
  text-align: left; }
  .systerm-form .pt-label-inline, .SortableHelper-step .pt-label-inline {
    display: block;
    float: left;
    padding-right: 10px;
    padding-top: 8px;
    width: 33%; }
  .systerm-form .item-signer, .SortableHelper-step .item-signer {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    color: #212121;
    letter-spacing: 0;
    line-height: 18px;
    margin: 13px 0px;
    display: block; }
  .systerm-form .input-form-inline, .SortableHelper-step .input-form-inline {
    width: 67%;
    float: right; }
  .systerm-form .form-group-item, .SortableHelper-step .form-group-item {
    padding: 10px; }
    .systerm-form .form-group-item:after, .SortableHelper-step .form-group-item:after, .systerm-form .form-group-item:before, .SortableHelper-step .form-group-item:before {
      content: '';
      display: table; }
    .systerm-form .form-group-item:after, .SortableHelper-step .form-group-item:after {
      clear: both; }
  .systerm-form .form-group-inline .pt-checkbox, .SortableHelper-step .form-group-inline .pt-checkbox {
    display: inline;
    margin-bottom: 0;
    margin-top: 2px; }
  .systerm-form .form-group-inline .pt-label-inline, .SortableHelper-step .form-group-inline .pt-label-inline {
    padding-top: 0; }
  .systerm-form .form-cols, .SortableHelper-step .form-cols {
    float: left;
    padding-left: 20px;
    padding-right: 20px; }
  .systerm-form .form-col5, .SortableHelper-step .form-col5 {
    width: 50%; }
  .systerm-form .form-col8, .SortableHelper-step .form-col8 {
    width: 80%; }
  .systerm-form .form-col3, .SortableHelper-step .form-col3 {
    width: 25%;
    display: flex; }
    .systerm-form .form-col3 .checkbox-container-users, .SortableHelper-step .form-col3 .checkbox-container-users {
      width: 100%; }
      .systerm-form .form-col3 .checkbox-container-users .checkbox-users, .SortableHelper-step .form-col3 .checkbox-container-users .checkbox-users {
        width: 15%;
        float: left;
        display: flex;
        padding-top: 5px; }
      .systerm-form .form-col3 .checkbox-container-users .lable-users, .SortableHelper-step .form-col3 .checkbox-container-users .lable-users {
        width: 85%;
        float: left; }
  .systerm-form .form-col4, .SortableHelper-step .form-col4 {
    width: 33.33333333%; }
  .systerm-form .form-col-full, .SortableHelper-step .form-col-full {
    width: 100%; }
  .systerm-form .center-top-bottom, .SortableHelper-step .center-top-bottom {
    margin-top: 10px; }
  .systerm-form .form-row.justify-center, .SortableHelper-step .form-row.justify-center {
    display: flex;
    justify-content: center; }
  .systerm-form .form-row:after, .SortableHelper-step .form-row:after, .systerm-form .form-row:before, .SortableHelper-step .form-row:before {
    content: '';
    display: table; }
  .systerm-form .form-row:after, .SortableHelper-step .form-row:after {
    clear: both; }
  .systerm-form .form-row .heading-block-primary, .SortableHelper-step .form-row .heading-block-primary {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 14px;
    color: #454b52;
    letter-spacing: 0;
    line-height: 19px;
    text-transform: uppercase;
    margin-left: 12px;
    margin-right: 12px;
    border-top: 1px solid #d2d2d2;
    padding: 20px 0px 0px 0px; }
  .systerm-form .form-subblock, .SortableHelper-step .form-subblock {
    margin: 0 auto;
    max-width: 1274px;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%; }
    .systerm-form .form-subblock.has-uline, .SortableHelper-step .form-subblock.has-uline {
      border-bottom: 1px solid #e4ecf0; }
  .systerm-form .bg-grey, .SortableHelper-step .bg-grey {
    background-color: #f8f9f9;
    position: relative; }
    .systerm-form .bg-grey .close-block-grey, .SortableHelper-step .bg-grey .close-block-grey {
      position: absolute;
      right: 5px;
      top: 5px;
      font-size: 16px;
      cursor: pointer; }
      .systerm-form .bg-grey .close-block-grey:before, .SortableHelper-step .bg-grey .close-block-grey:before {
        color: #195fff; }
      .systerm-form .bg-grey .close-block-grey:hover:before, .SortableHelper-step .bg-grey .close-block-grey:hover:before {
        color: red; }
  .systerm-form .form-block .title-content, .SortableHelper-step .form-block .title-content {
    font-family: "Roboto-medium", sans-serif;
    font-size: 14px;
    color: #3f555f;
    text-transform: uppercase;
    margin-top: 20px;
    text-align: left;
    padding-left: 20px; }
  .systerm-form .form-block .dropdown-tree-filter, .SortableHelper-step .form-block .dropdown-tree-filter {
    min-width: 100%; }
  .systerm-form .btn-add-more, .SortableHelper-step .btn-add-more {
    background: 0 none #fff;
    color: #6a7489;
    font-family: "Roboto-medium", sans-serif;
    font-size: 12px;
    height: 40px;
    line-height: 1.4;
    padding: 10px 15px 10px 20px;
    text-transform: uppercase; }
    .systerm-form .btn-add-more .icon-bc, .SortableHelper-step .btn-add-more .icon-bc {
      display: inline-block;
      font-size: 10px;
      margin-left: 12px;
      margin-top: 3px;
      vertical-align: top; }
  .systerm-form .bottom-form .buttton-action-footer, .SortableHelper-step .bottom-form .buttton-action-footer {
    margin-top: 0; }
  .systerm-form .selectbox-group, .SortableHelper-step .selectbox-group {
    padding: 20px;
    margin-bottom: 0; }
    .systerm-form .selectbox-group .heading-group-select, .SortableHelper-step .selectbox-group .heading-group-select {
      margin-bottom: 13px; }
  .systerm-form .pt-select select, .SortableHelper-step .pt-select select {
    border: 1px solid #d2d2d2; }

.systerm-main-content {
  background-color: #fff;
  border: 1px solid #e4ecf0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }
  .systerm-main-content .pt-file-upload input[type='file'] {
    width: 500px; }
  .systerm-main-content .pt-file-upload .pt-input {
    color: transparent;
    direction: rtl; }
  .systerm-main-content .file-box-margin {
    margin-top: 10px; }
  .systerm-main-content .file-avatar-box {
    width: 150px;
    float: left; }
    .systerm-main-content .file-avatar-box .input-hidden {
      display: none; }
  .systerm-main-content .box-dialog-image {
    width: 100%; }
  .systerm-main-content .hover-upload {
    -webkit-transition: 0.3s all ease;
    -moz-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 150px;
    height: 200px;
    position: absolute;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    border-radius: 4px; }
    .systerm-main-content .hover-upload span.pt-icon {
      font-size: 60px; }
    .systerm-main-content .hover-upload p {
      font-size: 14px;
      padding: 0 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
  .systerm-main-content .box-cropped-image {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
    width: 150px;
    height: 200px;
    margin: 10px 0;
    cursor: pointer;
    border-radius: 4px; }
    .systerm-main-content .box-cropped-image:hover {
      background-color: rgba(0, 0, 0, 0.4); }
      .systerm-main-content .box-cropped-image:hover .hover-upload {
        opacity: 1; }

/* Phan Quyen Nguoi Dung */
.cols-group-content.decentralized-users-content {
  background-color: #fff; }
  .cols-group-content.decentralized-users-content .aside-col-content {
    border: 1px solid #dfe6ea; }
    .cols-group-content.decentralized-users-content .aside-col-content .head-content-aside {
      border-bottom: 1px solid #dfe6ea;
      padding: 11px 120px 8px 14px;
      text-transform: uppercase; }
    .cols-group-content.decentralized-users-content .aside-col-content .aside-main-content {
      padding-bottom: 10px;
      padding-top: 10px; }
  .cols-group-content.decentralized-users-content .decentralized-users-main {
    border: 1px solid #dfe6ea;
    border-left: 0 none transparent;
    margin-left: -1px;
    overflow-x: auto;
    overflow-y: hidden; }
    .cols-group-content.decentralized-users-content .decentralized-users-main .table-container {
      color: #384f5a;
      border: 0 none transparent;
      padding-bottom: 0; }
      .cols-group-content.decentralized-users-content .decentralized-users-main .table-container .table-display .width-col-5 {
        width: 5%; }

.form-cols-content {
  margin: 20px;
  border-bottom: 2px solid #d2d2d2;
  padding-bottom: 10px; }
  .form-cols-content .form-cols {
    padding-left: 0; }
    .form-cols-content .form-cols:nth-child(3) {
      padding-right: 0; }
  .form-cols-content .form-group-item {
    padding-left: 0;
    padding-right: 0; }
  .form-cols-content .pt-form-content {
    margin-top: 10px; }
  .form-cols-content .heading-step {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 14px;
    color: #454b52;
    letter-spacing: 0;
    line-height: 19px;
    text-transform: uppercase; }
  .form-cols-content .icon-drag {
    color: #b5b5b5;
    font-weight: bold;
    cursor: -webkit-grabbing; }
  .form-cols-content .text {
    margin-left: 12px; }

.SortableHelper-step .bg-grey {
  margin-top: 0; }

.SortableHelper-step .selectbox-group {
  padding: 20px;
  margin-bottom: 0; }
  .SortableHelper-step .selectbox-group .heading-group-select {
    margin-bottom: 13px; }

.SortableHelper-step .pt-form-group {
  margin: 0 0 12px; }

.SortableHelper-step .step-block-heading {
  position: relative; }

.SortableHelper-step .required-input {
  margin-left: 3px;
  color: #f00; }

.SortableHelper-step .pt-select {
  width: 100%; }
  .SortableHelper-step .pt-select::after {
    content: '\E697';
    color: #9fa2a5;
    font-size: 14px;
    padding-left: 11px;
    font-family: 'Icons20', sans-serif;
    position: relative;
    line-height: 0;
    right: 10px;
    float: right;
    top: -19px; }

.SortableHelper-step .pt-select select {
  width: 100%;
  background: #fff;
  border-radius: 3px;
  min-height: 39px;
  font-family: 'Roboto-Regular', sans-serif;
  font-size: 13px;
  color: #454a52;
  letter-spacing: 0;
  text-align: left;
  border: 1px solid #d2d2d2;
  box-shadow: none; }

.SortableHelper-step .pt-label {
  font-family: 'Roboto-Bold';
  font-size: 14px;
  color: #454a52;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 0; }

.close-block-step {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer; }
  .close-block-step:before {
    color: #195fff; }
  .close-block-step:hover:before {
    color: red; }

.button-add-step-sign-number {
  padding: 20px 0 !important; }

.drag-signature-block .title-progress-sign .box-border-title-sign {
  border-top: 2px solid #d2d2d2;
  padding-top: 30px; }
  .drag-signature-block .title-progress-sign .box-border-title-sign .pt-label {
    text-transform: uppercase; }

.drag-signature-block .title-progress-sign .form-cols {
  padding-top: 0;
  padding-bottom: 0; }

.sectionbox-group-employees .pt-label-inline {
  width: 15.7%; }

.sectionbox-group-employees .pt-form-content {
  width: 84.3%; }

.sectionbox-group-employees .selectbox-group {
  background: #fff;
  padding: 4px 10px;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  margin-bottom: 0; }
  .sectionbox-group-employees .selectbox-group .container-messenger-not-data {
    text-align: center; }

.box-dialog-image-body {
  /*hide the outline behind the border*/ }
  .box-dialog-image-body .box-custom-avatar {
    padding: 5px 0;
    width: 202px;
    margin: auto; }
  .box-dialog-image-body input[type='range'] {
    /*removes default webkit styles*/
    -webkit-appearance: none;
    /*fix for FF unable to apply focus style bug */
    border: 1px solid white;
    /*required for proper track sizing in FF*/
    width: 200px; }
  .box-dialog-image-body input[type='range']::-webkit-slider-runnable-track {
    width: 200px;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px; }
  .box-dialog-image-body input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #195fff;
    margin-top: -4px; }
  .box-dialog-image-body input[type='range']:focus {
    outline: none; }
  .box-dialog-image-body input[type='range']:focus::-webkit-slider-runnable-track {
    background: #ccc; }
  .box-dialog-image-body input[type='range']::-moz-range-track {
    width: 200px;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px; }
  .box-dialog-image-body input[type='range']::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #195fff; }
  .box-dialog-image-body input[type='range']:-moz-focusring {
    outline: 1px solid white;
    outline-offset: -1px; }
  .box-dialog-image-body input[type='range']::-ms-track {
    width: 200px;
    height: 5px;
    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;
    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;
    /*remove default tick marks*/
    color: transparent; }
  .box-dialog-image-body input[type='range']::-ms-fill-lower {
    background: #777;
    border-radius: 10px; }
  .box-dialog-image-body input[type='range']::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px; }
  .box-dialog-image-body input[type='range']::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #195fff; }
  .box-dialog-image-body input[type='range']:focus::-ms-fill-lower {
    background: #888; }
  .box-dialog-image-body input[type='range']:focus::-ms-fill-upper {
    background: #ccc; }

.btn-group-choose-file {
  padding: 5px; }
  .btn-group-choose-file .btn-choose-file {
    display: block;
    margin-bottom: 7px;
    width: 130px;
    background-color: #195fff;
    border-color: #195fff;
    color: #fff;
    background-image: none; }
    .btn-group-choose-file .btn-choose-file:last-child {
      margin-bottom: 0; }
    .btn-group-choose-file .btn-choose-file:hover {
      background-color: #0344d7; }

.pt-transition-container {
  z-index: 20 !important; }

.upload-group-btn {
  border-radius: 3px;
  padding: 8px 0;
  position: absolute;
  top: 165px;
  width: 100%;
  left: 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
  .upload-group-btn .pt-button:not([class*='pt-intent-']) {
    text-align: left;
    padding: 0 15px;
    width: 100%;
    background: white;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0; }
    .upload-group-btn .pt-button:not([class*='pt-intent-']).btn-choose-file:hover {
      background: #195fff;
      color: #fff; }
    .upload-group-btn .pt-button:not([class*='pt-intent-']).btn-delete-file {
      color: #d24848; }
    .upload-group-btn .pt-button:not([class*='pt-intent-']).btn-delete-file:hover {
      background: #d24848;
      color: #fff; }

.d-none {
  display: none; }

.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.profile-file-info {
  background-color: rgba(206, 217, 224, 0.5);
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  position: absolute;
  padding: 10px 15px; }
  .profile-file-info .content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 95%; }
  .profile-file-info .file-name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
    font-family: "Roboto-medium", sans-serif; }
    .profile-file-info .file-name .name {
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis; }
    .profile-file-info .file-name .extention {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto; }
  .profile-file-info .file-size {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto; }

.profile-close {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 0;
  height: 39px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .profile-close.icon-close:hover::before {
    color: #d24848 !important; }

.warning-size-avatar {
  font-style: italic;
  font-size: 14px; }
  .warning-size-avatar::before {
    content: '*';
    color: #d24848; }

.utility-media-block {
  background-color: #fff;
  border: 1px solid #e4ecf0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  height: 100%;
  min-height: 100%;
  padding: 16px 10px; }
  .utility-media-block .image-content {
    float: left;
    text-align: center;
    width: 35%; }
    .utility-media-block .image-content .image {
      height: auto;
      margin: 0 auto;
      max-width: 100%;
      min-width: 154px; }
  .utility-media-block .text-link {
    color: #000; }
    @media (min-width: 1023px) {
      .utility-media-block .text-link:hover {
        color: #195fff; } }
  .utility-media-block .text-link-highline {
    color: #195fff; }
    @media (min-width: 1023px) {
      .utility-media-block .text-link-highline:hover {
        text-decoration: underline !important; } }
  .utility-media-block .desc {
    color: #000;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    line-height: 1.4;
    padding-left: 26px;
    position: relative; }
    .utility-media-block .desc:before {
      background-color: #195fff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      content: '';
      height: 6px;
      left: 5px;
      position: absolute;
      top: 4px;
      width: 6px; }
    .utility-media-block .desc.desc-video:before {
      background-color: transparent;
      color: #195fff;
      content: '\e9ae';
      font-family: 'icomoon' !important;
      font-size: 15px;
      height: 15px;
      line-height: 1;
      left: 0;
      top: 0;
      width: 15px; }
  .utility-media-block .main-heading {
    color: #000;
    font-family: "Roboto-medium", sans-serif;
    font-size: 17px;
    padding-bottom: 10px; }
  .utility-media-block .utility-info-list .item {
    padding-bottom: 3px;
    padding-top: 3px; }
  .utility-media-block .utility-main-content {
    overflow: hidden;
    padding-left: 12px; }
  .utility-media-block:before, .utility-media-block:after {
    content: '';
    display: table; }
  .utility-media-block:after {
    clear: both; }

.list-general-utility .item-list {
  padding-bottom: 10px;
  padding-top: 10px; }

.general-notification-utility-container {
  padding-bottom: 10px; }

.utility-media-block {
  background-color: #fff;
  border: 1px solid #e4ecf0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  height: 100%;
  min-height: 100%;
  padding: 16px 10px; }
  .utility-media-block .image-content {
    float: left;
    text-align: center;
    width: 35%; }
    .utility-media-block .image-content .image {
      height: auto;
      margin: 0 auto;
      max-width: 100%;
      min-width: 154px; }
  .utility-media-block .text-link {
    color: #000; }
    @media (min-width: 1023px) {
      .utility-media-block .text-link:hover {
        color: #195fff; } }
  .utility-media-block .text-link-highline {
    color: #195fff; }
    @media (min-width: 1023px) {
      .utility-media-block .text-link-highline:hover {
        text-decoration: underline !important; } }
  .utility-media-block .desc {
    color: #000;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px;
    line-height: 1.4;
    padding-left: 26px;
    position: relative; }
    .utility-media-block .desc:before {
      background-color: #195fff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      content: '';
      height: 6px;
      left: 5px;
      position: absolute;
      top: 4px;
      width: 6px; }
    .utility-media-block .desc.desc-video:before {
      background-color: transparent;
      color: #195fff;
      content: '\e9ae';
      font-family: 'icomoon' !important;
      font-size: 15px;
      height: 15px;
      line-height: 1;
      left: 0;
      top: 0;
      width: 15px; }
  .utility-media-block .main-heading {
    color: #000;
    font-family: "Roboto-medium", sans-serif;
    font-size: 17px;
    padding-bottom: 10px; }
  .utility-media-block .utility-info-list .item {
    padding-bottom: 3px;
    padding-top: 3px; }
  .utility-media-block .utility-main-content {
    overflow: hidden;
    padding-left: 12px; }
  .utility-media-block:before, .utility-media-block:after {
    content: '';
    display: table; }
  .utility-media-block:after {
    clear: both; }

.list-general-utility .item-list {
  padding-bottom: 10px;
  padding-top: 10px; }

.general-notification-utility-container {
  padding-bottom: 10px; }

.page-utilities-container .search-padding0 {
  padding-left: 0;
  padding-right: 0; }

.page-utilities-container .btn-action:last-child {
  margin-right: 0; }

.page-utilities-container .btn-top-heading {
  background: #195fff;
  border: 1px solid #154ed1;
  box-shadow: 0 2px 1px 0 #b0c8ff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #fff;
  min-width: 100px;
  min-height: 38px;
  margin-right: 10px;
  text-transform: uppercase;
  font-family: "Roboto-medium", sans-serif;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0;
  text-align: center; }
  .page-utilities-container .btn-top-heading .icon-In_So_Cong_Van:before,
  .page-utilities-container .btn-top-heading .icon-Tu_Tai_Lieu:before {
    font-size: 12px;
    color: #fff;
    padding-left: 10px; }
  .page-utilities-container .btn-top-heading:hover {
    background-color: #0344d7; }
  .page-utilities-container .btn-top-heading:disabled {
    color: #fff; }

.page-utilities-container .documentCabinet-overTime {
  margin-right: 20px; }

.page-utilities-container .search-list-utilities .search-advanced {
  padding: 10px;
  padding-bottom: 0; }

.page-utilities-container .search-list-utilities .group-btn-search {
  margin-top: 25px; }
  .page-utilities-container .search-list-utilities .group-btn-search .btn-item {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    min-height: 38px;
    margin-left: 10px; }

.page-utilities-container .search-list-utilities .btn-search {
  background: #6bc779;
  border: 1px solid #45a754;
  box-shadow: 0 2px 1px 0 rgba(213, 255, 220, 0.84);
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: background-color 0.3s;
  -o-transition: background-color 0.3s; }
  .page-utilities-container .search-list-utilities .btn-search .pt-icon-search {
    color: #fff; }
    .page-utilities-container .search-list-utilities .btn-search .pt-icon-search:before {
      font-size: 13px;
      padding-left: 30px; }
  .page-utilities-container .search-list-utilities .btn-search:hover {
    background: #1f9431; }

.page-utilities-container .search-list-utilities .btn-reload {
  width: 38px;
  height: 38px;
  -webkit-transition: background-color 0.3s;
  -o-transition: background-color 0.3s; }
  .page-utilities-container .search-list-utilities .btn-reload:hover {
    background: #1f9431; }
    .page-utilities-container .search-list-utilities .btn-reload:hover .icon-Loading:before {
      color: #fff; }

.page-utilities-container .search-list-utilities .checkbox-dealine-statistics {
  display: inline;
  position: relative;
  top: 35px;
  left: 20px; }
  .page-utilities-container .search-list-utilities .checkbox-dealine-statistics .checkbox-content {
    display: inline; }

.page-utilities-container .search-DocumentarySearch.search-documentary-content {
  display: none; }

.page-utilities-container .search-DocumentarySearch .search-advanced .group-select,
.page-utilities-container .search-DocumentarySearch .search-advanced .group-datetime,
.page-utilities-container .search-DocumentarySearch .search-advanced .group-input-filter {
  width: 25%; }
  .page-utilities-container .search-DocumentarySearch .search-advanced .group-select .input-filter,
  .page-utilities-container .search-DocumentarySearch .search-advanced .group-datetime .input-filter,
  .page-utilities-container .search-DocumentarySearch .search-advanced .group-input-filter .input-filter {
    min-width: 100%; }

.page-utilities-container .search-PrintSendDocument .search-advanced .group-select,
.page-utilities-container .search-PrintSendDocument .search-advanced .group-datetime {
  width: 33.333%; }

.page-utilities-container .search-width-25 .item-with-box {
  width: 25%; }

.page-utilities-container .search-statistic-documentCabinet {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  color: #454b52;
  letter-spacing: 0;
  text-align: left; }
  .page-utilities-container .search-statistic-documentCabinet .checkbox-active-work {
    display: inline;
    position: relative;
    top: 35px;
    left: 20px; }
    .page-utilities-container .search-statistic-documentCabinet .checkbox-active-work .checkbox-content {
      display: inline; }
  .page-utilities-container .search-statistic-documentCabinet .group-btn-search {
    text-align: right;
    margin-top: 25px; }
  .page-utilities-container .search-statistic-documentCabinet .group-datetime {
    width: 40%; }

.search-label {
  font-family: "Roboto-medium", sans-serif;
  font-size: 12px;
  display: block; }

.form-input-filter {
  position: relative;
  border: 1px solid #d2d2d2;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff; }

.date-picker-inline > div {
  display: inline-block;
  width: 50%; }

.date-picker-inline > div:first-child {
  border-right: 1px solid #d2d2d2; }

.border-transparent {
  border-color: transparent !important; }

.input {
  position: relative;
  height: 38px;
  border: 1px solid #d2d2d2;
  padding-left: 10px;
  box-sizing: border-box;
  width: 100%;
  font-size: 13px;
  font-family: "Roboto-Regular", sans-serif;
  background: transparent; }

input[placeholder] {
  text-overflow: ellipsis; }

::-moz-placeholder {
  text-overflow: ellipsis; }

/* firefox 19+ */
input:-moz-placeholder {
  text-overflow: ellipsis; }

.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.select-arrow {
  position: relative; }
  .select-arrow::before {
    content: '\E697';
    color: #9fa2a5;
    font-size: 14px;
    font-family: 'Icons20', sans-serif;
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }

.align-items-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.btn-icon {
  width: 40px;
  height: 40px;
  background: #fff; }

.form-input-btn {
  position: absolute;
  right: 0;
  right: -1px;
  top: -1px; }

.border-radius-lelf-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important; }

.border-radius-righ-0 {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.pr-40 {
  padding-right: 40px; }

.pt-intent-btn-green-shadow {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
  border: 1px solid #51c062;
  background: #6bc779;
  box-shadow: 0 2px 2px 0 rgba(107, 199, 121, 0.3); }
  .pt-intent-btn-green-shadow:hover {
    background-color: #60b56d; }

.pt-intent-btn-white {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 1px solid #d2d2d2;
  background-color: #fff; }
  .pt-intent-btn-white:hover {
    background-color: #60b56d;
    color: #fff; }
    .pt-intent-btn-white:hover .icon-Loading:before {
      color: #fff; }

.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.flex-solid {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }

.justify-content-end {
  justify-content: flex-end; }

.border-radius-3 {
  border-radius: 3px !important; }

.border-radius-lelf-3 {
  border-bottom-left-radius: 3px !important;
  border-top-left-radius: 3px !important; }

.border-radius-right-3 {
  border-bottom-right-radius: 3px !important;
  border-top-right-radius: 3px !important; }

@media only screen and (min-width: 768px) {
  .px-sm-15 {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .pl-sm-0 {
    padding-left: 0px !important; }
  .pr-sm-0 {
    padding-right: 0px !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .border-left-sm-transparent {
    border-left-color: transparent !important; } }

@media only screen and (min-width: 64em) {
  .mt-md-0 {
    margin-top: 0 !important; } }

@media only screen and (max-width: 575px) {
  .col-575-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .mt-575-20 {
    margin-top: 20px !important; } }

@media only screen and (min-width: 576px) {
  .mt-576-0 {
    margin-top: 0px !important; } }

.check-label {
  font-size: 14px;
  font-family: "Roboto-medium", sans-serif; }

.no-pointer td {
  cursor: default !important; }

.cpc-tag {
  display: inline-block;
  padding: 0 18px;
  height: 20px;
  line-height: 20px;
  background: #e5f5df;
  border: 1px solid #9ee782;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 30px;
  color: #5ca73f;
  white-space: nowrap; }
  .cpc-tag.cpc-tag-yellow {
    background: #fff2d6;
    border-color: #f1d393;
    color: #d19330; }

.popover-item {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .popover-item .icon {
    margin-right: 8px;
    font-size: 12px;
    color: #6a7489; }
    .popover-item .icon:before {
      color: #6a7489; }
  .popover-item .name {
    font-size: 13.1px;
    margin: 0px; }
  .popover-item:hover .icon {
    color: #fff; }
    .popover-item:hover .icon:before {
      color: #fff; }

.box-action-wrapper {
  background: #f3f3f3; }
  .box-action-wrapper .box-action-block {
    background: #fff;
    display: block;
    margin-bottom: 5px;
    padding: 15px 15px 0px 15px; }
    .box-action-wrapper .box-action-block .list-items,
    .box-action-wrapper .box-action-block .box-signnumber {
      padding: 0 !important; }
  .box-action-wrapper .title {
    color: #9498a2;
    font-size: 13px;
    font-family: 'Roboto-medium';
    margin: 0px;
    margin-bottom: 10px;
    text-transform: uppercase;
    padding: 0 !important; }

.table-thanhvien {
  padding: 15px; }
  .table-thanhvien .table-detail > tbody > tr > td {
    padding: 10px; }
    .table-thanhvien .table-detail > tbody > tr > td .file-item {
      font-size: 16px;
      padding-left: 10px; }

.data-column {
  font-size: 14px;
  margin: 0px;
  padding: 0px; }
  .data-column li {
    display: block;
    list-style: none;
    padding: 0px;
    padding-bottom: 15px; }
    .data-column li .data-column-items {
      align-items: flex-start;
      display: flex;
      flex-wrap: nowrap; }
      .data-column li .data-column-items label {
        color: #454b52;
        flex: 0 0 116px;
        font-size: 14px;
        font-weight: bold;
        margin: 0px;
        margin-right: 10px; }
      .data-column li .data-column-items .content {
        width: 100%; }
        .data-column li .data-column-items .content .file-items:first-child .link-file {
          padding-top: 0px; }
        .data-column li .data-column-items .content .file-items:last-child .link-file {
          padding-bottom: 0px; }
  .data-column .data-rows-items {
    display: block !important; }
    .data-column .data-rows-items label {
      display: inline-block !important;
      margin: 0 !important;
      margin-bottom: 10px !important;
      position: relative; }
      .data-column .data-rows-items label.request:after {
        color: #f00;
        content: '*';
        display: inline-block;
        font-weight: 100;
        height: 10px;
        line-height: 10px;
        position: absolute;
        right: -12px;
        text-align: center;
        top: 4px;
        width: 10px; }

.kyso-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .kyso-block .kyso-radio > div {
    margin-bottom: 5px; }
  .kyso-block .kyso-radio .name_checkbox {
    font-size: 12px;
    text-transform: uppercase; }
    .kyso-block .kyso-radio .name_checkbox.blue {
      color: #195fff; }
    .kyso-block .kyso-radio .name_checkbox.red {
      color: #f00; }
    .kyso-block .kyso-radio .name_checkbox.default, .kyso-block .kyso-radio .name_checkbox.cpc-button.default-back {
      color: #212121;
      border: #c3c3c3;
      background-color: #f9f9f9; }
  .kyso-block .kyso-button {
    align-items: flex-end;
    display: flex;
    flex-direction: column; }
    .kyso-block .kyso-button .button-item {
      flex-basis: auto;
      flex-grow: 1;
      flex-shrink: 1; }
    .kyso-block .kyso-button .button-item:last-child .pt-button.btn-default {
      margin-bottom: 0px; }
    .kyso-block .kyso-button .width-165 {
      width: 165px; }
    .kyso-block .kyso-button .custom-icon {
      display: flex;
      align-items: center;
      justify-content: space-between; }

.pt-button.btn-default {
  align-items: center;
  background: #fff;
  border: 1px solid #195fff;
  border-radius: 3px;
  box-shadow: none;
  color: #195fff;
  cursor: pointer;
  display: flex;
  font-family: "Roboto-medium", sans-serif;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  text-transform: uppercase;
  white-space: nowrap; }
  .pt-button.btn-default .icon {
    color: #195fff;
    font-size: 18px;
    margin-left: 15px; }

.table-detail {
  width: 100%;
  border: 1px solid #dfe6ea;
  color: #4c4f53;
  font-size: 13px; }
  .table-detail thead tr {
    background: #f8f9f9;
    border-bottom: 1px solid #dfe6ea; }
    .table-detail thead tr th {
      font-family: "Roboto-medium", sans-serif;
      font-size: 12px;
      padding: 12px 10px;
      text-align: left;
      text-transform: uppercase;
      white-space: nowrap; }
  .table-detail tbody tr {
    border-top: 1px solid #dfe6ea; }
    .table-detail tbody tr:first-child {
      border-top: 0px; }
    .table-detail tbody tr.sub {
      border-top: 0px; }
      .table-detail tbody tr.sub td {
        padding-top: 0px; }
    .table-detail tbody tr td {
      padding: 12px 10px; }
      .table-detail tbody tr td .view-more {
        color: #195fff;
        font-family: 'Roboto-Bold';
        font-size: 13px;
        text-align: right;
        display: block;
        background: #fff;
        padding-left: 10px; }
  .table-detail tbody h4 {
    font-family: "Roboto-medium", sans-serif; }
  .table-detail tbody .status {
    background: #fed8da;
    border: 1px solid #ffacb1;
    border-radius: 100px;
    color: #f60a18;
    display: inline-block;
    font-family: "Roboto-medium", sans-serif;
    font-size: 11px;
    height: 20px;
    line-height: 20px;
    min-width: 110px;
    padding: 0 12px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap; }
    .table-detail tbody .status.blue {
      background: #eef6ff;
      border-color: #adc6ff;
      color: #326ef6; }
    .table-detail tbody .status.yellow {
      background: #fff2d6;
      border-color: #f1d393;
      color: #d19330; }
    .table-detail tbody .status.black {
      background: #f9f9f9;
      border-color: #c3c3c3;
      color: #212121; }
  .table-detail tbody .content {
    overflow: hidden;
    padding-left: 28px;
    position: relative; }
    .table-detail tbody .content:before {
      content: '\e9db';
      display: inline-block;
      font-family: 'icomoon';
      font-size: 18px;
      height: 20px;
      left: 0px;
      position: absolute;
      top: 0px;
      width: 20px; }

.file-list-box-detail .file-items {
  margin-bottom: 5px; }

button[type='submit'].action-popup-red {
  background-color: #d0021b !important;
  box-shadow: none !important; }

.inline-block {
  display: inline-block; }

.text-medium {
  font-family: "Roboto-medium", sans-serif; }

.text-decoration-underline {
  text-decoration: underline; }

.border-color-blue {
  border-color: #195fff !important; }

.text-color.normal {
  color: #007bff; }

.text-color.medium {
  color: #195fff; }

.text-color.warning {
  color: #d19330; }

.text-color.danger {
  color: #c23030; }

.text-color.urgent {
  color: #e20000; }

.text-color-333 {
  color: #333; }

.file-synthesis {
  align-items: center;
  display: flex; }
  .file-synthesis > span {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 14px;
    margin-right: 10px; }
  .file-synthesis .file-item {
    align-items: center;
    display: inline-flex; }
    .file-synthesis .file-item .file {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      height: 30px;
      width: 24px; }
    .file-synthesis .file-item .file-name {
      font-family: "Roboto-Regular", sans-serif;
      font-size: 13px; }
      .file-synthesis .file-item .file-name a {
        color: #454a52;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 13px; }

.color-white span {
  color: white;
  vertical-align: middle; }
  .color-white span:before {
    color: white; }

.place-block {
  padding: 20px 20px 2px 20px; }

.form-group {
  margin-bottom: 18px;
  text-align: left; }
  .form-group.form-group-inline {
    align-items: center;
    display: flex; }
    .form-group.form-group-inline .label {
      margin-bottom: 0px; }
    .form-group.form-group-inline .pt-checkbox {
      margin-bottom: 0px !important; }
  .form-group .label-instruction {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap; }
    .form-group .label-instruction .instruction {
      font-size: 14px;
      font-style: italic;
      line-height: 100%; }
  .form-group label {
    color: #454a52;
    display: block;
    font-family: 'Roboto-Bold';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 100%;
    margin-bottom: 10px;
    text-align: left; }
    .form-group label.required:after {
      color: #d0021b;
      content: '*';
      display: inline-block;
      height: 12px;
      line-height: 100%;
      margin-left: 2px;
      text-align: center;
      width: 12px; }
    .form-group label.label-inline {
      display: inline-block; }
  .form-group .input-control {
    background: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 3px;
    box-shadow: none;
    color: #454a52;
    font-family: 'Roboto-Regular';
    font-size: 13px;
    font-weight: 300;
    min-height: 40px;
    padding: 0 10px;
    text-align: left;
    vertical-align: middle;
    width: 100%; }
    .form-group .input-control[type='text'] {
      line-height: 40px; }
  .form-group textarea.input-control {
    padding: 10px; }
  .form-group .form-helper-text {
    color: #d0021b;
    font-size: 12px;
    margin: 5px 0 0; }
  .form-group .pt-file-upload {
    height: auto;
    min-height: 40px; }
  .form-group .pt-file-upload-input {
    min-height: 40px; }
    .form-group .pt-file-upload-input :after {
      min-height: 40px; }
  .form-group .helper-text {
    color: #d24848;
    font-size: 12px; }
    .form-group .helper-text.helper-infomation {
      color: #6bc779; }
  .form-group .xoa-file {
    background: transparent;
    color: #555;
    font-size: 14px;
    margin-top: -7px;
    position: absolute;
    right: 78px;
    top: 50%;
    z-index: 999; }

.dropdown-tree-custom {
  width: 100%; }

.rc-tree-select-dropdown-search {
  border-bottom: 1px solid #e2e2e2;
  padding: 10px; }
  .rc-tree-select-dropdown-search .rc-tree-select-search__field {
    height: 30px;
    line-height: 30px;
    padding: 0 10px; }

.heading-block {
  margin-bottom: 10px;
  padding-top: 7px; }
  .heading-block .headding-inline {
    color: #195fff;
    display: inline-block;
    font-family: 'Roboto-Regular';
    font-size: 20px;
    letter-spacing: 0;
    text-align: left;
    white-space: nowrap; }
    .heading-block .headding-inline:hover {
      color: #195fff; }
    .heading-block .headding-inline .icon-headding {
      display: inline-block;
      font-size: 25px;
      margin-right: 15px;
      vertical-align: middle; }
    .heading-block .headding-inline .text-headding {
      display: inline-block;
      font-family: 'Roboto-Regular';
      vertical-align: middle; }

.statistics-block {
  margin-bottom: 10px; }
  .statistics-block .statistics-box {
    align-items: center;
    background: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    display: inline-flex;
    padding: 20px 30px; }
    .statistics-block .statistics-box .icon {
      color: #fff;
      display: block;
      height: 56px;
      text-align: center;
      width: 56px; }
    .statistics-block .statistics-box .info {
      padding-left: 30px; }
      .statistics-block .statistics-box .info span {
        display: block;
        font-size: 13px;
        margin-bottom: 7px;
        text-transform: uppercase; }
      .statistics-block .statistics-box .info i {
        font-size: 33px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; }

.statistics-list--block {
  background: #fff;
  border: 1px solid #e2e2e2;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 20px 4px; }
  .statistics-list--block .statistics-list--scroll {
    padding: 0 14px; }

.statistics-list {
  margin: 0px;
  padding: 0px; }
  .statistics-list li {
    border-bottom: 1px solid #e2e2e2;
    list-style: none;
    margin-bottom: 15px; }
    .statistics-list li:last-child {
      border-bottom: 0px;
      margin-bottom: 0px; }
    .statistics-list li .statistics-heading-block .send {
      color: #333333;
      display: block;
      font-size: 13px;
      font-weight: 500; }
    .statistics-list li .heading {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      font-size: 13px;
      justify-content: space-between;
      margin-bottom: 5px; }
      .statistics-list li .heading h3 {
        color: #195fff;
        font-size: 15px;
        font-weight: 500;
        margin: 0px; }
      .statistics-list li .heading .date {
        color: #999999; }
    .statistics-list li .content {
      font-size: 13px;
      overflow: hidden;
      overflow-wrap: break-word;
      padding: 10px 0px 15px; }
      .statistics-list li .content .emojione {
        width: 25px; }

.statistics-box-chuyentiep {
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  position: relative; }
  .statistics-box-chuyentiep .quote-icon {
    left: 0px;
    position: absolute;
    top: -2px;
    transform: rotate(180deg); }
    .statistics-box-chuyentiep .quote-icon:before {
      color: #182026;
      font-size: 20px; }

.service-file-block .file-document {
  align-items: center;
  display: flex;
  padding: 5px 0;
  text-decoration: none; }
  .service-file-block .file-document .file-kieufile {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 36px;
    width: 27px; }
  .service-file-block .file-document .file-info {
    padding: 2px 10px; }
    .service-file-block .file-document .file-info p {
      color: #555;
      font-weight: 600; }
    .service-file-block .file-document .file-info span {
      color: #a9a9a9;
      font-size: 12px; }

.service-file-block .file-images {
  padding: 5px 0; }
  .service-file-block .file-images img {
    max-height: 138px; }

.statistic-to {
  display: inline-block;
  margin-left: 5px; }

.icon-to-down {
  font-size: 11px;
  margin-left: 5px; }

.menu-popover-box {
  padding: 10px; }
  .menu-popover-box .popover-chat-item {
    display: block;
    font-size: 12px; }

.chon-nguoiduoccap-block {
  position: relative; }
  .chon-nguoiduoccap-block .arrow-dropdown {
    font-size: 6px;
    position: absolute;
    right: 10px;
    top: 18px; }

.popup-phongban-canhan {
  background: #fff;
  border: 1px solid #e2e2e2;
  display: none;
  opacity: 0;
  position: relative;
  width: 100%; }
  .popup-phongban-canhan.show {
    display: block;
    opacity: 1;
    position: absolute; }
  .popup-phongban-canhan .popup-phongban-canhan-select {
    border-bottom: 1px solid #e2e2e2;
    padding: 10px 20px; }
    .popup-phongban-canhan .popup-phongban-canhan-select .select-tree {
      width: 200px; }

.radio-css .pt-control-indicator {
  border-radius: 50% !important;
  overflow: hidden; }

.form-show-file {
  align-items: center;
  border: 1px solid #dedede;
  border-radius: 3px;
  display: inline-flex;
  justify-content: space-between;
  padding: 8px 0 8px 10px;
  text-decoration: none !important; }
  .form-show-file .name {
    font-size: 13px;
    font-weight: 500;
    padding-right: 16px; }
    .form-show-file .name span {
      background-position: center center;
      background-size: contain;
      display: inline-block;
      height: 20px;
      margin-right: 10px;
      vertical-align: middle;
      width: 15px; }
  .form-show-file .xoa {
    color: #bcbcbc;
    cursor: pointer;
    display: initial;
    font-size: 13px;
    height: 20px;
    line-height: 18px;
    text-align: center;
    width: 26px; }

.filter-checkbox {
  font-size: 13px;
  font-weight: 600; }

.btn-action.btn-min-width-130px {
  min-width: 130px; }

.thong-tin-thu-hoi .user-box {
  display: inline-block; }
  .thong-tin-thu-hoi .user-box .line {
    display: inline-block;
    padding: 0 2px; }

.thong-tin-thu-hoi .date {
  text-transform: uppercase; }

.thong-tin-thu-hoi .content {
  display: block;
  margin: 0px; }

.mauXam:before {
  color: #929292; }

.font-9 {
  font-size: 9px !important; }

.font-11 {
  font-size: 11px !important; }

.font-12 {
  font-size: 13px !important; }

.data-column-items .detail-file .file-items {
  margin-bottom: 10px; }

.cpc-table-scroll {
  overflow-x: auto;
  overflow-y: hidden; }
  .cpc-table-scroll.ml--1 {
    margin-left: -1px; }
  .cpc-table-scroll.mr--1 {
    margin-right: -1px; }
  .cpc-table-scroll.mb--1 {
    margin-bottom: -1px; }

.cpc-table-scroll-content {
  background: #eff3f5;
  margin-bottom: -1px;
  min-height: 420px;
  overflow-y: hidden; }

.cpc-sortable {
  min-width: 1000px; }

.cpc-sortable-body--row-loading {
  background-color: #fff; }

.cpc-sortable-head .cpc-sortable-body--cell {
  background-color: #195fff;
  border-top: 0;
  color: #fff;
  font: 500 12px/1.6 "Roboto-medium", sans-serif;
  padding: 12px 10px; }
  .cpc-sortable-head .cpc-sortable-body--cell .head-label {
    vertical-align: middle; }
  .cpc-sortable-head .cpc-sortable-body--cell .pt-icon {
    margin-left: 5px; }

.cpc-sortable-head.uppercase {
  text-transform: uppercase; }

.cpc-sortable-body--rows {
  background-color: #fff; }
  .cpc-sortable-body--rows.cpc-sortable-body--rows-hidden .cpc-sortable-body--rows {
    display: none; }
  .cpc-sortable-body--rows.cpc-sortable-body--rows-show .cpc-sortable-body--rows {
    display: block; }

.cpc-sortable-body--row {
  background-color: #fff;
  display: flex;
  flex-wrap: nowrap;
  width: 100%; }
  .cpc-sortable-body--row.cpc-sortable-body--row-parent {
    background-color: #f8f9f9 !important; }
  .cpc-sortable-body--row.cpc-sortable-body--row-parent-department {
    background-color: #dbe6ff !important; }
    .cpc-sortable-body--row.cpc-sortable-body--row-parent-department .name-department {
      color: #212121;
      font-size: 15px;
      font-weight: 500; }
  .cpc-sortable-body--row.cpc-sortable-body--row-striped:nth-child(2n + 1) {
    background-color: #f8f9f9; }

.cpc-sortable-body .cpc-sortable-body--cell.cell-border-right {
  border-right: 1px solid #dfe6ea; }

.cpc-sortable-body--cell {
  border-top: 1px solid #dfe6ea;
  color: #454b52;
  flex: 1;
  font-size: 13px;
  padding: 8px 10px; }
  .cpc-sortable-body--cell .icon-More {
    color: #818181;
    font-size: 25px; }
  .cpc-sortable-body--cell .icon-move-drap {
    color: #818181;
    cursor: -webkit-grabbing;
    cursor: grabbing;
    font-size: 14px; }
  .cpc-sortable-body--cell .pt-popover-target {
    font-size: 0.0001px; }
  .cpc-sortable-body--cell .row-arrow-box {
    display: inline-block;
    width: 23px; }

.no-gutters {
  margin-left: 0;
  margin-right: 0; }
  .no-gutters > .col,
  .no-gutters > [class*='col-'] {
    padding-left: 0;
    padding-right: 0; }

.cpc-sortable-filter--box {
  position: relative; }
  .cpc-sortable-filter--box input,
  .cpc-sortable-filter--box select {
    background-color: #fff;
    border: 1px solid #dfe6ea;
    border-radius: 2px;
    box-sizing: border-box;
    color: #313131;
    height: 30px;
    line-height: 30px;
    padding: 0 12px;
    width: 100%; }
  .cpc-sortable-filter--box input {
    padding: 0 25px 0 12px; }
  .cpc-sortable-filter--box select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 0 20px 0 12px; }
  .cpc-sortable-filter--box .table-row-select-tree {
    min-width: 100% !important;
    width: 100%; }
    .cpc-sortable-filter--box .table-row-select-tree.dropdown-tree .rc-tree-select-selection {
      min-height: 30px;
      padding: 0px; }
    .cpc-sortable-filter--box .table-row-select-tree.dropdown-tree .rc-tree-select-arrow {
      top: 0px; }
  .cpc-sortable-filter--box .icon-select-down {
    height: 20px;
    line-height: 20px;
    margin-top: -10px;
    position: absolute;
    right: 2px;
    text-align: center;
    top: 50%;
    width: 20px; }
    .cpc-sortable-filter--box .icon-select-down::after {
      color: #9fa2a5;
      content: '\E697';
      font-family: 'Icons20', sans-serif;
      font-size: 12px;
      line-height: 0; }
  .cpc-sortable-filter--box .react-datepicker__input-container .react-datepicker__close-icon {
    top: 11px; }
  .cpc-sortable-filter--box .icon-close {
    cursor: pointer;
    height: 20px;
    line-height: 20px;
    margin-top: -10px;
    position: absolute;
    right: 5px;
    text-align: center;
    top: 50%;
    width: 20px; }

.cpc-sortable-footer {
  background-color: #fff;
  border-top: 1px solid #dfe6ea;
  padding: 12px 12px 7px 12px; }

.group-button {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px; }
  .group-button.center {
    justify-content: center; }
  .group-button .group-button--content {
    margin-bottom: 5px;
    padding: 0 5px; }
    .group-button .group-button--content button {
      background: #fff;
      border-radius: 3px;
      font-family: 'Roboto-Medium';
      padding: 5px 15px; }
      .group-button .group-button--content button.btn-blue {
        background: #195fff; }
        .group-button .group-button--content button.btn-blue span {
          color: #fff; }
          .group-button .group-button--content button.btn-blue span:before {
            color: #fff; }
      .group-button .group-button--content button.btn-red-bg-while {
        background: #fff;
        box-shadow: inset 0 0 0 1px #d0021b, inset 0 -1px 0 #d0021b; }
        .group-button .group-button--content button.btn-red-bg-while span {
          color: #d0021b; }
          .group-button .group-button--content button.btn-red-bg-while span:before {
            color: #d0021b; }
      .group-button .group-button--content button span {
        color: #6a7489;
        font-size: 12px; }
        .group-button .group-button--content button span:before {
          color: #6a7489; }
        .group-button .group-button--content button span.icon {
          font-family: 'Roboto-Regular';
          font-size: 12px;
          font-weight: normal; }
        .group-button .group-button--content button span.ml8 {
          margin-left: 8px; }
        .group-button .group-button--content button span.mr8 {
          margin-right: 8px; }
    .group-button .group-button--content .pt-button:not([class*='pt-intent-']):disabled span:before,
    .group-button .group-button--content .pt-button:not([class*='pt-intent-']).pt-disabled span:before {
      color: #6a7489; }

.panel-wrapper {
  background-color: #fff; }
  .panel-wrapper.border {
    border: 1px solid #dfe6ea; }
  .panel-wrapper.border-top {
    border-top: 1px solid #dfe6ea; }
  .panel-wrapper .panel-heading {
    align-items: center;
    border-bottom: 1px solid #dfe6ea;
    display: flex;
    flex-wrap: wrap;
    font-family: 'Roboto-Medium';
    font-size: 15px;
    justify-content: space-between;
    padding: 12px 20px 7px 20px; }

.panel-body-content .col-content label {
  color: #212121;
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 8px; }

.panel-body-content .col-content .content p {
  color: #333333;
  font-size: 13px;
  font-weight: 600; }

.panel-body-content .col-content .content span {
  color: #212121;
  font-size: 13px; }

.checkbox-item.inline {
  display: inline;
  margin-left: 0; }

.icon-color-blue {
  color: #185fff; }
  .icon-color-blue:after, .icon-color-blue:before {
    color: #185fff; }

.time-line-block {
  margin: 0px;
  padding: 0px;
  padding-left: 5px;
  padding-right: 10px;
  position: relative; }
  .time-line-block:before {
    background-color: #fff;
    content: '';
    display: inline-block;
    height: 10px;
    left: 5px;
    position: absolute;
    top: 0px;
    width: 1px;
    z-index: 1; }
  .time-line-block li {
    font-size: 13px;
    position: relative; }
    .time-line-block li:last-child {
      padding-bottom: 0px !important; }
    .time-line-block li:before {
      background-color: #195fff;
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: 10px;
      left: -5px;
      position: absolute;
      top: 5px;
      width: 10px;
      z-index: 2; }
    .time-line-block li .name {
      align-items: flex-start;
      display: flex;
      justify-content: space-between; }
      .time-line-block li .name h2 {
        color: #333;
        font-weight: bold;
        margin: 0px; }
      .time-line-block li .name span {
        color: #333;
        font-size: 12px;
        opacity: 0.65; }
    .time-line-block li .sumary {
      margin: 0px; }
      .time-line-block li .sumary span {
        color: #333;
        font-weight: bold;
        margin-right: 5px; }
      .time-line-block li .sumary.red {
        color: #f00; }

.task-manager-wrapper {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  padding-top: 55px;
  word-break: break-word; }
  @media only screen and (max-width: 1200px) {
    .task-manager-wrapper {
      padding-top: 52px; } }

.footer-pagination {
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  left: 0px;
  padding: 0 40px;
  position: absolute;
  width: 100%; }
  @media only screen and (max-width: 1024px) {
    .footer-pagination {
      bottom: 20px;
      padding: 0 20px; } }

.popover-background-list {
  border: none;
  border-radius: 4px !important;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2) !important; }

.config-background-title:hover, .config-background-title.active {
  background: #e8efff;
  cursor: pointer; }

.background-block {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  visibility: inherit;
  z-index: -9; }
  .background-block.active {
    left: auto;
    opacity: 1;
    right: 100%;
    visibility: visible;
    z-index: 1; }

.background-list-block {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.2);
  padding: 12px; }

.background-list {
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 300px; }
  .background-list .background-list-item {
    box-sizing: border-box;
    flex-grow: 1;
    max-width: calc((100% - 20px) / 3);
    width: calc((100% - 20px) / 3); }
    .background-list .background-list-item span {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border: 2px solid transparent;
      border-radius: 3px;
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      overflow: hidden;
      padding-top: 100%;
      width: 100%; }
      .background-list .background-list-item span.active, .background-list .background-list-item span:hover {
        border-color: #2bcf77; }

.background-home-icon {
  background: #fff !important;
  border: none !important;
  border-radius: 50% !important;
  color: #1552dc !important;
  cursor: pointer;
  height: 47px;
  width: 47px; }
  .background-home-icon .icon-settings::before {
    font-size: 16px !important; }
  .background-home-icon .icon2-digital::before {
    font-size: 14px !important; }
  .background-home-icon .icon-chat::before {
    color: #1552dc !important;
    font-size: 20px !important; }
  .background-home-icon .icon2-digital-vertical {
    font-size: 28px; }
  .background-home-icon.digital {
    border: 1px solid #ededed;
    height: 45px;
    outline: 2px solid #fff;
    width: 45px; }
  .background-home-icon.setting.pt-tether-enabled {
    background: #1552dc !important;
    color: #fff !important; }

.pagination-change-page {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0 10px; }
  .pagination-change-page .pagination {
    margin: 0px;
    margin-bottom: 10px;
    width: auto; }
  .pagination-change-page .pagination-input .pagination-text {
    font-size: 13px;
    padding: 0 10px 0 0; }
  .pagination-change-page .pagination-input input {
    border: 1px solid #cecece;
    border-radius: 3px;
    height: 28px;
    line-height: 28px;
    margin-bottom: 10px;
    padding: 0 10px;
    width: 60px; }
    .pagination-change-page .pagination-input input:focus {
      border-color: #195fff; }

.color-light {
  color: #fff; }

.avatar-small {
  background-position: top center;
  background-size: cover;
  border-radius: 50%;
  height: 32px;
  width: 32px; }
  .avatar-small img {
    display: none; }

.tabs-dragdrop {
  display: flex; }
  .tabs-dragdrop .tabs-dragdrop-box {
    margin: 0 2px;
    position: relative; }

.relative {
  position: relative; }

.bg-hover-grey:hover {
  background-color: rgba(148, 163, 184, 0.12); }

.line-height-reset {
  line-height: 100%; }

.reset-margin label {
  margin: 0; }

.tabs-custom .pt-tab[aria-selected='true'] {
  color: #195fff; }

.tabs-custom .pt-tab-indicator-wrapper .pt-tab-indicator {
  background-color: #195fff; }

.tabs-custom .pt-tab:not([aria-disabled='true']):hover {
  color: #195fff; }

.tabs-custom .pt-tab-panel {
  margin-top: 15px; }

.tick-success-icon {
  background-color: #00b051;
  border-radius: 50%;
  color: #fff;
  font-size: 8px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: 20px; }
  .tick-success-icon::before {
    color: #fff; }

.task-manager-block-all-layout .task-manager-filter-type {
  position: absolute;
  right: 5px;
  top: 3px;
  width: 150px; }
  .task-manager-block-all-layout .task-manager-filter-type input {
    background-color: transparent;
    border: none;
    color: #195fff;
    cursor: pointer !important; }

.task-manager-block {
  border: 1px solid #dfe6ea;
  margin-top: 10px; }
  .task-manager-block__tabs header {
    background: #fafafa;
    border-bottom: 1px solid #dfe6ea;
    justify-content: flex-start; }
    .task-manager-block__tabs header .tabs-dragdrop-box {
      margin: 0; }
    .task-manager-block__tabs header .cpc-button {
      background-color: transparent;
      border: none;
      color: #777777;
      font-size: 13px;
      font-weight: 600;
      height: 43px;
      position: relative;
      text-transform: uppercase; }
      .task-manager-block__tabs header .cpc-button::after {
        background-color: transparent;
        content: '';
        display: inline-block;
        height: 3px;
        left: 0;
        position: absolute;
        top: -1.5px;
        width: 100%; }
      .task-manager-block__tabs header .cpc-button.pt-intent-primary, .task-manager-block__tabs header .cpc-button:hover, .task-manager-block__tabs header .cpc-button:active {
        background-color: transparent !important;
        color: #195fff; }
        .task-manager-block__tabs header .cpc-button.pt-intent-primary::after, .task-manager-block__tabs header .cpc-button:hover::after, .task-manager-block__tabs header .cpc-button:active::after {
          background: #195fff; }

.task-manager-title {
  align-items: center;
  display: flex;
  justify-content: space-between; }
  .task-manager-title .name {
    color: #195fff;
    font-size: 20px;
    font-weight: 300;
    margin: 0; }

.task-manager-filter {
  background: #f9faff;
  padding: 14px; }

.table-collapse.pt-table td {
  line-height: 1.9; }

.table-collapse .accordion-toggle {
  background: #ebebeb; }
  .table-collapse .accordion-toggle .icon {
    display: inline-flex;
    font-size: 8px;
    margin: 0 10px;
    transition: all 0.35s; }
    .table-collapse .accordion-toggle .icon.show {
      transform: rotate(180deg); }

.table-collapse .custom-collapse {
  display: none; }
  .table-collapse .custom-collapse.show {
    display: contents; }

.cpc-tag {
  display: inline-block;
  padding: 0 18px;
  height: 20px;
  line-height: 20px;
  background: #e5f5df;
  border: 1px solid #9ee782;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 30px;
  color: #5ca73f;
  white-space: nowrap; }
  .cpc-tag.cpc-tag-yellow {
    background: #fff2d6;
    border-color: #f1d393;
    color: #d19330; }

.popover-item {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .popover-item .icon {
    margin-right: 8px;
    font-size: 12px;
    color: #6a7489; }
    .popover-item .icon:before {
      color: #6a7489; }
  .popover-item .name {
    font-size: 13.1px;
    margin: 0px; }
  .popover-item:hover .icon {
    color: #fff; }
    .popover-item:hover .icon:before {
      color: #fff; }

.tab-items-radio--group {
  display: flex;
  padding: 15px; }
  .tab-items-radio--group .pt-control.pt-radio {
    margin-bottom: 0;
    margin-left: 48px; }
    .tab-items-radio--group .pt-control.pt-radio:first-child {
      margin-left: 0px; }

.row-padding-multilevel--1 {
  padding-left: 0px; }

.row-padding-multilevel-1 {
  padding-left: 10px; }

.row-padding-multilevel-2 {
  padding-left: 40px; }

.row-padding-multilevel-3 {
  padding-left: 60px; }

.row-padding-multilevel-4 {
  padding-left: 80px; }

.row-padding-multilevel-5 {
  padding-left: 100px; }

.row-padding-multilevel-6 {
  padding-left: 120px; }

.row-padding-multilevel-7 {
  padding-left: 140px; }

.row-padding-multilevel-8 {
  padding-left: 160px; }

.row-padding-multilevel-9 {
  padding-left: 180px; }

.row-padding-multilevel-10 {
  padding-left: 200px; }

.row-padding-multilevel-11 {
  padding-left: 220px; }

.row-padding-multilevel-12 {
  padding-left: 240px; }

.row-padding-multilevel-13 {
  padding-left: 260px; }

.row-padding-multilevel-14 {
  padding-left: 280px; }

.row-padding-multilevel-15 {
  padding-left: 300px; }

.row-bold-multilevel {
  color: #212121;
  font-family: 'Roboto-Medium';
  font-size: 14px;
  font-weight: 500; }

.row-arrow-down-up {
  color: #212121;
  cursor: pointer;
  display: inline-block;
  font-size: 5px;
  font-weight: 600;
  height: 20px;
  line-height: 20px;
  margin-right: 0px;
  vertical-align: middle;
  width: 20px;
  padding-left: 4px; }
  .row-arrow-down-up:before {
    color: #212121; }

.hscv-action {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 20px 5px 20px; }

.select-box-multiple.Select--multi .Select-value {
  background-color: #fff;
  border: 1px solid #d2d2d2;
  color: #000;
  display: flex; }

.select-box-multiple.Select--multi .Select-value-icon {
  border-right: 1px solid #d2d2d2; }

.select-box-multiple .Select-arrow {
  border: none;
  height: 27px;
  width: 12px; }
  .select-box-multiple .Select-arrow::after {
    color: #a1a3a6;
    content: '\E999';
    font-family: 'icomoon';
    font-size: 6px; }

.select-box-multiple.is-open .Select-arrow::after {
  content: '\e9b2'; }

.content-loaitailieu {
  position: relative; }
  .content-loaitailieu .txt-content {
    display: block;
    overflow: hidden; }
  .content-loaitailieu .icon {
    background-color: #fff;
    bottom: 0px;
    color: #195fff;
    padding-left: 5px;
    position: absolute;
    right: 0px; }

.hscv-icon {
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 16px;
  vertical-align: middle;
  width: 16px; }
  .hscv-icon.mr10 {
    margin-right: 10px; }

.row-radio-checkbox {
  align-items: center;
  display: flex;
  justify-content: space-between; }

.select-box-item select {
  border: 1px solid #dfe6ea;
  padding: 8px 10px; }

.move-hscv-list li .dropdown-left {
  height: 17px;
  line-height: 14px;
  margin-left: 3px;
  text-align: center;
  width: 17px; }
  .move-hscv-list li .dropdown-left .pt-icon {
    font-size: 13px; }

.move-hscv-list li .main-content {
  align-items: center;
  border-top: 1px solid #dfe6ea;
  display: flex;
  padding: 10px 0; }
  .move-hscv-list li .main-content .pt-control {
    margin-bottom: 0px;
    padding-left: 18px; }
  .move-hscv-list li .main-content .text {
    font-size: 14px;
    margin-left: 5px; }

.move-hscv-list .subitem {
  padding-left: 16px; }

.border-top-none {
  border-top-color: transparent !important; }

.border-bottom-none {
  border-bottom-color: transparent !important; }

.mw-150 {
  min-width: 150px; }

.mw-180 {
  min-width: 180px; }

.pl-150 {
  padding-left: 150px; }

.pl-180 {
  padding-left: 180px; }

.ttlpb__header {
  display: flex;
  align-items: center;
  padding: 10px 20px; }
  .ttlpb__header > * {
    flex: 1; }

.ttlpb__more-wrap {
  padding-left: 36%; }

.ttlpb__more-button {
  background: #fff;
  color: #6a7489;
  min-width: 105px;
  padding: 4px 12px 4px 10px !important;
  text-align: center;
  white-space: nowrap; }
  .ttlpb__more-button .pt-icon {
    color: #6a7489; }
  .ttlpb__more-button .text-content {
    text-transform: uppercase;
    color: #6a7489;
    font-size: 12px; }
  .ttlpb__more-button > *:last-child {
    margin-left: 6px; }

.table-phongban-donvi {
  border: 1px solid #dfe6ea;
  overflow: hidden; }
  .table-phongban-donvi__tabs {
    align-items: center;
    display: flex;
    margin: 0 -10px;
    user-select: none; }
    .table-phongban-donvi__tabs li {
      list-style: none;
      padding: 0 10px; }
      .table-phongban-donvi__tabs li span {
        color: #738a95;
        display: block;
        position: relative; }
        .table-phongban-donvi__tabs li span:after {
          background-color: transparent;
          bottom: 0;
          content: '';
          display: inline-block;
          height: 2px;
          left: 0;
          position: absolute;
          width: 100%; }
        .table-phongban-donvi__tabs li span.active {
          color: #195fff; }
          .table-phongban-donvi__tabs li span.active:after {
            background-color: #195fff; }
  .table-phongban-donvi__table {
    width: 100%; }
    .table-phongban-donvi__table tr th,
    .table-phongban-donvi__table tr td {
      color: #31434b;
      font-size: 12px;
      line-height: 100%;
      padding: 10px;
      text-align: left;
      vertical-align: middle; }
    .table-phongban-donvi__table thead tr th {
      background: #e8e8e8;
      font-weight: 500;
      position: sticky;
      top: 0;
      z-index: 2; }
    .table-phongban-donvi__table tbody tr {
      background-color: #fff; }
      .table-phongban-donvi__table tbody tr:nth-child(2n) {
        background-color: #fafafa; }
    .table-phongban-donvi__table .cel {
      align-items: center;
      display: inline-flex; }
      .table-phongban-donvi__table .cel .cel-value .pt-control {
        margin: 0;
        padding: 0; }
  .table-phongban-donvi .row-level-0 .cel-value-first {
    padding-left: 0 !important; }
  .table-phongban-donvi .row-level-1 .cel-value-first {
    padding-left: 10px !important; }
  .table-phongban-donvi .row-level-2 .cel-value-first {
    padding-left: 20px !important; }
  .table-phongban-donvi .row-level-3 .cel-value-first {
    padding-left: 30px !important; }
  .table-phongban-donvi .row-level-4 .cel-value-first {
    padding-left: 40px !important; }
  .table-phongban-donvi .row-level-5 .cel-value-first {
    padding-left: 50px !important; }

.row-detail {
  display: flex;
  flex-wrap: nowrap; }
  @media screen and (max-width: 1023px) {
    .row-detail {
      flex-wrap: wrap; } }
  .row-detail__col {
    flex: 1 auto; }
  .row-detail__col-right {
    flex: 0 auto;
    max-width: 542px;
    min-width: 442px;
    width: 30%; }
    @media screen and (max-width: 1023px) {
      .row-detail__col-right {
        min-width: 100%;
        width: 100%; } }
  .row-detail .menu-detail__item {
    padding: 10px 0; }

.tree-organization-task {
  position: relative; }
  .tree-organization-task .save-favorite-group {
    position: absolute;
    right: 0;
    top: 0; }

.task-tabs {
  align-items: stretch;
  display: inline-flex; }
  .task-tabs .selected .item,
  .task-tabs li:hover .item {
    color: #195fff; }
    .task-tabs .selected .item::before,
    .task-tabs li:hover .item::before {
      background-color: #195fff; }
  .task-tabs .item {
    align-items: center;
    background-color: #fafafa;
    color: #777777;
    cursor: pointer;
    display: flex;
    gap: 5px;
    padding: 13px 15px;
    position: relative; }
    .task-tabs .item .name {
      font-family: "Roboto-medium", sans-serif;
      font-size: 13px;
      line-height: 15.23px;
      text-transform: uppercase; }
    .task-tabs .item::before {
      background-color: transparent;
      content: '';
      display: block;
      height: 3px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }

.task-manager-filter-layout {
  align-items: flex-end;
  background: #f9faff;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 14px; }
  .task-manager-filter-layout > * {
    flex-grow: 0; }
  .task-manager-filter-layout .grow-0 {
    flex-grow: 0; }
  .task-manager-filter-layout .grow-1 {
    flex-grow: 1; }
  .task-manager-filter-layout .label {
    color: #262626;
    font-family: "Roboto-medium", sans-serif;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 4px; }
  .task-manager-filter-layout .height-38 .cpc-input > input {
    height: 37px;
    line-height: 37px; }
  .task-manager-filter-layout .height-38 .input-comp-v2 {
    border-radius: 3px; }
    .task-manager-filter-layout .height-38 .input-comp-v2 > input {
      height: 36px;
      line-height: 36px; }
  .task-manager-filter-layout .date-block {
    display: flex; }
    .task-manager-filter-layout .date-block > *:nth-child(2) {
      margin-left: -2px; }
  .task-manager-filter-layout .search-submit {
    align-items: center;
    display: flex;
    gap: 10px; }
    .task-manager-filter-layout .search-submit > * {
      flex-grow: 1; }
    .task-manager-filter-layout .search-submit .cpc-input-search {
      border: 1px solid #dfe6ea; }
      .task-manager-filter-layout .search-submit .cpc-input-search input {
        border: none;
        box-shadow: none;
        height: 35px;
        line-height: 35px;
        outline: none; }
      .task-manager-filter-layout .search-submit .cpc-input-search .pt-input-action {
        align-items: center;
        display: inline-flex; }
        .task-manager-filter-layout .search-submit .cpc-input-search .pt-input-action button {
          height: 35px;
          line-height: 35px;
          margin: 0; }
          .task-manager-filter-layout .search-submit .cpc-input-search .pt-input-action button > * {
            color: #fff; }
    .task-manager-filter-layout .search-submit .pt-popover-target {
      max-width: 37px;
      width: 37px; }

.type-icon-block {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  line-height: 0; }
  .type-icon-block .type-icon-box {
    align-items: center;
    display: inline-flex;
    gap: 5px; }

.task-manager-detail-layout {
  font-family: "Roboto-Regular", sans-serif; }
  .task-manager-detail-layout .task-manager-detail-block {
    align-items: flex-start;
    display: flex; }
    @media only screen and (max-width: 1199px) {
      .task-manager-detail-layout .task-manager-detail-block {
        flex-wrap: wrap;
        gap: 10px; } }
    .task-manager-detail-layout .task-manager-detail-block > * {
      flex-grow: 1; }
      @media only screen and (max-width: 1199px) {
        .task-manager-detail-layout .task-manager-detail-block > * {
          width: 100% !important; } }
    .task-manager-detail-layout .task-manager-detail-block .left {
      background-color: #fff;
      border: 1px solid #eff3f5;
      border-top-left-radius: 4px;
      box-shadow: 0px 8px 21px 0px rgba(199, 199, 199, 0.21); }
    .task-manager-detail-layout .task-manager-detail-block .right {
      background-color: #fff;
      border: 1px solid #eff3f5;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      box-shadow: 0px 8px 21px 0px rgba(199, 199, 199, 0.21);
      flex-grow: 0;
      min-width: 500px;
      width: 500px; }
  .task-manager-detail-layout .task-manager-menu {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0; }
    .task-manager-detail-layout .task-manager-menu li {
      border-bottom: 1px solid rgba(151, 151, 151, 0.1);
      flex-grow: 1;
      list-style: none;
      max-width: calc(100% / 3);
      width: calc(100% / 3); }
      .task-manager-detail-layout .task-manager-menu li button {
        align-items: center;
        background-color: transparent;
        border: none;
        box-shadow: none;
        box-sizing: border-box;
        color: #424242;
        cursor: pointer;
        display: flex;
        gap: 5px;
        margin: 0;
        outline: none;
        padding: 14px 10px 14px 15px; }
        .task-manager-detail-layout .task-manager-menu li button .icon {
          color: #424242;
          min-width: 20px;
          width: 20px; }
        .task-manager-detail-layout .task-manager-menu li button .name {
          color: #424242;
          font-family: "Roboto-Regular", sans-serif;
          font-size: 13px;
          letter-spacing: -0.3px;
          text-align: left; }

.remind-select-datetime .react-datepicker-popper {
  min-width: 315px; }

.select-box-checkbox {
  position: relative; }

.select-box-dropdown {
  background-color: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 2px;
  display: none;
  left: 0px;
  margin-top: 4px;
  padding: 5px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 9; }
  .select-box-dropdown.show {
    display: block; }
  .select-box-dropdown input[type='checkbox'] {
    margin-right: 5px; }
  .select-box-dropdown li {
    padding: 2px 0; }
    .select-box-dropdown li .not-data {
      font-size: 14px;
      opacity: 0.5; }
    .select-box-dropdown li .name.disabled {
      color: #717171; }

.select-box-input {
  border: 1px solid #d2d2d2;
  border-radius: 2px;
  cursor: default;
  overflow: hidden;
  padding-right: 15px;
  position: relative; }
  .select-box-input:after {
    content: '\E999';
    display: inline-block;
    font-family: 'icomoon';
    font-size: 6px;
    height: 100%;
    line-height: 34px;
    position: absolute;
    right: 0px;
    text-align: center;
    width: 30px; }
  .select-box-input input {
    border: none !important;
    box-sizing: border-box;
    cursor: default;
    height: 36px;
    line-height: 36px;
    outline: none;
    overflow: hidden;
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }

@media only screen and (min-width: 600px) and (max-height: 750px) {
  .cpc-login-page .cpc-logo-full-text {
    margin-bottom: 30px;
    margin-top: 30px; } }

/*recapcha*/
@media screen and (max-height: 575px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

@media only screen and (width: 1366px) {
  .search-work-other .group-datetime {
    width: 50% !important; }
  .search-work-other .group-select,
  .search-work-other .input-element-other {
    width: 25% !important; } }

@media only screen and (min-width: 1600px) {
  .update-DecentralizationUser {
    margin: 0 auto;
    width: 70%; } }

@media only screen and (min-width: 1366px) and (max-width: 1400px) {
  .search-ReceiveDocument .search-filter-date,
  .search-DocumentarySearch .search-filter-date {
    display: inline-block;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 40%; }
    .search-ReceiveDocument .search-filter-date .group-datetime:last-child,
    .search-DocumentarySearch .search-filter-date .group-datetime:last-child {
      padding-right: 0; }
  .search-ReceiveDocument .search-filter-select {
    width: 60%; }
    .search-ReceiveDocument .search-filter-select .search {
      min-width: 260px; }
  .search-DocumentarySearch .search-filter-select {
    width: 60%; }
    .search-DocumentarySearch .search-filter-select .search {
      min-width: 230px; }
  .statistic-work .item-status .number {
    font-size: 25px;
    padding-right: 5px;
    padding-top: 5px; }
  /*set menu*/
  .header .block-menu .list-menu .item {
    min-width: 95px; } }

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .search-work-processing .search-filter-date {
    margin-bottom: 0 !important; }
  .search-work-unfulfilled .search-filter-date {
    margin-bottom: 10px; }
    .search-work-unfulfilled .search-filter-date .group-datetime {
      padding-right: 5px; }
      .search-work-unfulfilled .search-filter-date .group-datetime .pt-label-inline .text-label {
        margin-right: 5px; }
  .search-work-unfulfilled .search-filter-select .search-keyword {
    margin-right: 5px; }
  .search-work-unfulfilled .search-filter-select .btn-reload-group .pt-button {
    margin-right: 5px; } }

@media only screen and (min-width: 1366px) {
  .fixtopMenuRightHasSideBar {
    max-width: calc(30% - 65px); } }

@media only screen and (min-width: 1201px) and (max-width: 1365px) {
  .box-left-addSignNumber {
    width: 50% !important; }
  .box-right-addSignNumber {
    width: 50% !important; }
  /*set menu*/
  .header .block-menu .list-menu .item {
    min-width: 90px; }
  .header-container .button-link-chat {
    margin-right: 8px; }
  .header .info-user {
    padding: 10px 5px; }
  .header .info-user .dropdown .name-user {
    display: inline-block;
    max-width: 140px;
    text-align: left;
    vertical-align: middle;
    white-space: normal; }
  .search-work-processing .search-filter-date {
    margin-bottom: 0px !important; }
  .search-work-unfulfilled .search-filter-date {
    margin-bottom: 10px; }
    .search-work-unfulfilled .search-filter-date .group-datetime:last-child {
      padding-right: 0; } }

@media only screen and (min-width: 1201px) {
  .action-mobile-document,
  .icon-close-menu,
  .logout-mobile,
  .menu-header-mobile,
  .menu-sidebar-mobile,
  .user-mobile,
  .userinfo-mobile {
    display: none !important; } }

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .detail-receive-document .form-assign-work {
    padding: 0; }
  .action-mobile-document {
    display: none; } }

@media only screen and (min-width: 768px) {
  .seach-form .search-keyword {
    min-width: calc(100% - 170px); }
  .page-container .box-action .close-mobile-menu-detail {
    display: none; }
  .history-steering-mobile {
    display: none; }
  .box-right .list-action .show-th-mobile {
    display: none; }
  .detail-receive-document .info-top .heading {
    display: none; } }

@media only screen and (max-width: 1490px) {
  .detail-receive-document .detail-container .box-right .group-button-action {
    margin-left: -3px;
    margin-right: -3px; }
    .detail-receive-document .detail-container .box-right .group-button-action .btn-action-sign-number {
      padding: 0 4px; }
    .detail-receive-document .detail-container .box-right .group-button-action .button-content {
      padding-left: 3px;
      padding-right: 3px; }
  .detail-receive-document .view-job-done .info-job {
    margin-top: 10px; }
  .detail-receive-document .container-detail {
    padding: 20px 10px; } }

@media only screen and (max-width: 1400px) {
  .fixed-viewfile .action-top {
    width: calc(70% - 68px); }
  .fixed-viewfile-hasSidebar .action-top {
    width: calc(70% - 175px); }
  .fixed-button-hasSidebar {
    left: 245px; }
  .fixed-button-back {
    left: 79px; }
  .content-wrapper {
    padding: 65px 15px 15px; }
  /*resize menu sidebar*/
  .left-content {
    width: -moz-calc(100% - var(--sidebar));
    width: -webkit-calc(100% - var(--sidebar));
    width: calc(100% - var(--sidebar)); }
  .page-container.sidebar-collapsed-back .left-content {
    width: -moz-calc(100% - var(--sidebar));
    width: -webkit-calc(100% - var(--sidebar));
    width: calc(100% - var(--sidebar)); }
  .page-container.sidebar-collapsed-back .sidebar-menu {
    width: 240px; }
  /*end resize menu sidebar*/ }

@media only screen and (max-width: 1440px) {
  .detail-receive-document .detail-container .box-left .action-top .button-list {
    padding-left: 0;
    padding-right: 0; }
    .detail-receive-document .detail-container .box-left .action-top .button-list .item-btn {
      margin-right: 6px;
      text-align: center; }
    .detail-receive-document .detail-container .box-left .action-top .button-list .migrated-file-buttons {
      margin-right: 6px;
      vertical-align: middle; }
  .header .info-user .user-menu .user-avatar-content {
    margin-right: 5px; }
  .detail-receive-document .view-document .button-list .item-btn {
    margin-right: 8px;
    min-width: 20px;
    overflow: hidden; }
    .detail-receive-document .view-document .button-list .item-btn a {
      left: auto;
      top: auto; }
    .detail-receive-document .view-document .button-list .item-btn:last-child {
      margin-right: 0; }
  .kyso-block .kyso-button {
    margin: 0 -5px; }
    .kyso-block .kyso-button > div {
      padding: 0 5px; } }

@media only screen and (max-width: 1365px) {
  .fixed-viewfile .action-top {
    top: 55px;
    width: calc(60% - 57px); }
  .fixed-viewfile-hasSidebar .action-top {
    top: 55px;
    width: calc(60% - 157px); }
  .search-ReceiveDocument .search-advanced .group-datetime {
    width: 33.333%; }
  .detail-receive-document .detail-container .box-left {
    width: 60%; }
  .detail-receive-document .detail-container .box-right {
    width: 40%; }
  .statistic-work .item-status .number {
    font-size: 25px;
    padding-right: 5px;
    padding-top: 5px; }
  .search-processing .search-filter-date {
    margin-bottom: 10px; }
  .search-processing .search-filter-date .group-datetime,
  .search-work-unfulfilled .search-filter-date .group-datetime {
    padding-left: 10px; }
    .search-processing .search-filter-date .group-datetime:last-child,
    .search-work-unfulfilled .search-filter-date .group-datetime:last-child {
      padding-right: 0 !important; }
  .search-DocumentarySearch .search-filter-date {
    margin-bottom: 10px; }
    .search-DocumentarySearch .search-filter-date .group-datetime:last-child {
      padding-right: 0; }
  .search-work-processing .search-filter-date {
    margin-right: 10px; }
    .search-work-processing .search-filter-date .group-datetime {
      margin-bottom: 0; }
      .search-work-processing .search-filter-date .group-datetime:last-child {
        padding-right: 0 !important; }
  .search-work-processing .search-header-right-content {
    display: block !important;
    width: 100% !important; }
  .search-work-processing .seach-form .search {
    min-width: 100%; }
  .search-work-processing .search-filter-select {
    text-align: right !important; }
  .search-DocumentarySearch .group-datetime {
    width: 50%; } }

@media only screen and (max-width: 1280px) {
  .detail-container-sign-number .list-action-sign-number .table-status .cell-table {
    white-space: nowrap; }
  .detail-container-sign-number .list-action-sign-number .table-departments .cell-table {
    white-space: normal; }
  .systerm-form .form-cols, .SortableHelper-step .form-cols {
    padding-left: 10px;
    padding-right: 10px; }
  .addDocumentsCabinet .form-inline-group .label-inline-input {
    display: block; }
  .addDocumentsCabinet .form-inline-group .pt-input,
  .addDocumentsCabinet .form-inline-group .radio-group,
  .addDocumentsCabinet .form-inline-group .pt-select,
  .addDocumentsCabinet .form-inline-group .dateinput-inline,
  .addDocumentsCabinet .form-inline-group .pt-textarea {
    float: left;
    width: 100% !important; } }

@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .group-table-DepartmentPerform .item-box {
    min-width: 50% !important; }
  .fixtopMenuRight, .fixtopMenuRightHasSideBar {
    padding-left: 23px !important;
    padding-right: 15px !important; }
  .fixtopMenuRightHasSideBar {
    max-width: calc(40% - 65px); }
  .size-width-AdminNotification {
    flex-basis: 70%;
    -ms-flex-preferred-size: 70%;
    max-width: 70%; }
  .seach-form .search-keyword {
    min-width: calc(100% - 180px); }
  .size-width-ListAdminNotification {
    flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    max-width: 100%; }
  .search-work-other .group-datetime,
  .search-work-other .group-select,
  .search-work-other .input-element-other {
    width: 50% !important; }
  .search-ReceiveDocument .select-filter-header {
    margin-right: 15px; }
  .form-width-90 {
    flex-basis: 90%;
    max-width: 90%; }
  .detail-receive-document .box-right .box-action .item {
    flex-basis: 50%;
    max-width: 50%; }
  .detail-receive-document .label.pt-label {
    display: block;
    margin: 0 0 10px; }
  .document-cabinet-content .search-advanced .group-datetime,
  .document-cabinet-content .search-advanced .group-select,
  .document-cabinet-content .search-advanced .input-element-other {
    width: 50% !important; }
  .search-ReceiveDocument-other .search-advanced .group-select,
  .search-ReceiveDocument-other .search-advanced .input-element-other,
  .search-ReceiveDocument-other .search-advanced .group-datetime {
    width: 33.333% !important; }
  .search-PrintSendDocument .group-select {
    width: 30% !important; }
  .search-PrintSendDocument .group-datetime {
    width: 40% !important; }
  .search-statistic-documentCabinet .group-datetime {
    width: 60%; }
  .systerm-form .form-row, .SortableHelper-step .form-row {
    margin-left: 0;
    margin-right: 0; } }

@media only screen and (max-width: 1200px) {
  .page-utilities-container .table-scroll .pt-table {
    min-width: 900px;
    width: 100% !important; }
  .page-utilities-container .table-container .colum-default-width {
    width: 180px; }
  .fixed-viewfile .action-top,
  .fixed-viewfile-hasSidebar .action-top {
    top: 50px; }
  .search-work .search-filter-select .search {
    min-width: 230px; }
  .search-work-unfulfilled .search-filter-date {
    margin-bottom: 10px; }
  .search-work-processing .search-filter-date {
    margin-bottom: 0; }
  .container-list-permission-user .cols-group-content {
    display: inherit;
    table-layout: inherit; }
  .container-list-permission-user .decentralized-users-content {
    overflow-x: scroll;
    table-layout: fixed;
    width: 100%; }
  .button-gv .btn-action {
    height: 38px;
    line-height: 38px;
    margin-right: 10px;
    min-width: 145px; }
    .button-gv .btn-action:last-child {
      margin-right: 0; }
    .button-gv .btn-action .pt-icon {
      line-height: 34px;
      padding-right: 0; }
    .button-gv .btn-action .name-btn {
      margin-left: 0;
      padding-left: 0;
      text-align: center; }
  .search-system-module {
    display: block !important;
    text-align: right !important; }
  .listDecentralizationUser-container .end-xs {
    -ms-flex-pack: initial;
    justify-content: normal;
    padding-right: 10px !important;
    padding-top: 0 !important;
    text-align: initial; }
  .page-list-document-cabinet-container .head-action-group {
    padding: 10px; }
  .page-list-document-cabinet-container .search-advanced {
    margin: 0; }
  .search-header-right-content {
    padding-left: 0;
    padding-right: 0; }
  .form-search-basic .seach-form {
    padding-left: 10px;
    padding-right: 10px; }
  /* menu header*/
  .sidebar-menu .icon-close-menu {
    display: none; }
  .hidden-sm {
    display: none; }
  .visible-sm {
    display: block; }
  .user-mobile .info-user {
    margin-bottom: 13px;
    padding-bottom: 0;
    padding-left: 15px;
    padding-top: 15px; }
  .user-mobile .user-avatar-content {
    border: none !important; }
  .user-mobile .dropdown span:after {
    display: none; }
  .user-mobile li {
    width: auto !important; }
  .menu-header-mobile {
    display: block !important;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%); }
    .menu-header-mobile .pt-icon::before {
      color: #fff;
      font-size: 25px;
      position: relative; }
  .header .block-menu {
    background: #fff;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    margin-top: 0;
    padding-left: 0; }
    .header .block-menu .list-menu .menu-link {
      padding-bottom: 10px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 10px;
      position: relative;
      text-align: left; }
    .header .block-menu .list-menu .active {
      background: #f5f5f5; }
    .header .block-menu .list-menu .item {
      padding-left: 0;
      text-align: left;
      width: 100%; }
    .header .block-menu .list-menu br {
      display: none; }
    .header .block-menu .list-menu .top-icon {
      display: inline-block;
      height: 30px;
      margin: 0;
      position: absolute;
      text-align: center;
      top: 9px;
      vertical-align: middle;
      width: 30px; }
      .header .block-menu .list-menu .top-icon:before {
        color: #195fff; }
    .header .block-menu .list-menu .icon-Tu_Tai_Lieu:before {
      font-size: 15px !important; }
    .header .block-menu .text-content {
      color: #212121;
      display: inline-block;
      font-family: "Roboto-Regular", sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      margin-left: 35px;
      text-align: left; }
    .header .block-menu .icon-close-menu {
      display: none; }
    .header .block-menu .logout-mobile {
      display: block !important; }
  .header .info-user {
    float: none;
    padding-left: 0; }
    .header .info-user .name-user {
      color: #212121;
      display: block !important;
      font-family: "Roboto-Regular", sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      padding-left: 20px;
      padding-top: 6px;
      text-align: left; }
    .header .info-user .dropdown {
      padding-left: 15px; }
      .header .info-user .dropdown:after {
        display: none; }
  .header .user-mobile {
    display: block; }
  .header .userinfo-mobile {
    border-bottom: 1px solid #e8edf0;
    margin-top: 15px; }
    .header .userinfo-mobile .user-titles .active .text-link {
      color: #0344d7;
      cursor: default;
      font-family: "Roboto-Bold", sans-serif;
      text-decoration: none; }
  .cpc-menu .user-submenu-content {
    display: none; }
  /*custom scroll daterangeinput*/
  .user-mobile {
    display: inline-block; }
  .custom-daterangeinput .DayPicker {
    min-width: 100% !important;
    overflow: auto; }
  .custom-daterangeinput .DayPicker-NavButton {
    position: fixed;
    top: 5px; }
  /*page-container*/
  .page-container .list-info-document ul li {
    width: 48%; }
  /*table*/
  .table-scroll .pt-table {
    min-width: 1000px;
    width: 100vw; }
    .table-scroll .pt-table thead th {
      white-space: nowrap; }
  .table-subcollap-container .table-display-container {
    min-width: 1000px;
    width: 100vw !important; }
  .menu-sidebar-mobile {
    display: none; }
  /*detail-receive-document*/
  .page-DetailDocumentsCabinet .action-name {
    display: inline !important; }
  .listDecentralizationUser-container .input-searchkey {
    display: block;
    float: none;
    margin-bottom: 0px;
    margin-top: 10px; }
  .listDecentralizationUser-container .form-search-basic {
    margin-top: 0 !important; }
  .listDecentralizationUser-container .select-group {
    margin-bottom: 10px; }
  .listDecentralizationUser-container .form-search-basic {
    float: left; }
  .listDecentralizationUser-container .form-container .pt-select {
    width: 100%; }
  .listDecentralizationUser-container .form-search-basic {
    width: 100%; }
  .listDecentralizationUser-container .seach-form .search {
    min-width: 290px !important; }
  .listDecentralizationUser-container .search-keyword {
    float: right;
    margin-right: 0; }
  .search-ReceiveDocument-other .input-element-other,
  .search-ReceiveDocument-other .group-datetime {
    width: 50%; }
  .search-work-other .search-advanced .group-datetime,
  .search-work-other .search-advanced .group-select,
  .search-work-other .search-advanced .input-element-other {
    width: 50% !important; }
  .search-work-other .input-element-other .emty-nbsp {
    display: none; }
  /*form add document cabinet*/
  .addDocumentsCabinet .form-inline-group .pt-input,
  .addDocumentsCabinet .form-inline-group .pt-textarea,
  .addDocumentsCabinet .form-inline-group .radio-group {
    float: none;
    width: 100% !important; }
  .addDocumentsCabinet .pt-form-content {
    display: block; }
  .addDocumentsCabinet .dateinput-inline {
    float: none;
    width: 100% !important; }
  .addDocumentsCabinet .detail-pdf-item {
    float: right;
    padding-left: 0;
    width: 82%; }
  .list-task-content .item:nth-child(2n + 1):before {
    background-color: transparent; }
  .box-right {
    border-radius: 0px !important; }
    .box-right .box-action .item {
      flex-basis: 50%;
      -ms-flex-preferred-size: 50%;
      max-width: 50%; } }

@media only screen and (max-width: 1162px) {
  .search-advanced-listSignNumberProjects .item {
    width: 50% !important; }
  .search-work-processing .search-filter-date {
    margin-right: 0; }
  .search-work-processing .group-datetime {
    float: right !important;
    margin-bottom: 10px;
    width: initial !important; }
  .search-work-processing .search-header-group {
    width: 100%; }
  .search-work .search-filter-select .search {
    min-width: 255px; } }

@media only screen and (max-width: 1126px) {
  .addSignNumber .form-container {
    padding: 10px; }
  .table-container .pt-table th:first-child {
    padding-left: 12px; }
  .search-work .form-search-basic {
    text-align: left;
    width: 100%; }
  .control-header-open .block-menu .list-menu a:hover {
    text-decoration: none; } }

@media only screen and (max-width: 1023px) {
  .dispatch-code {
    margin-left: 0;
    margin-right: 0; }
  /*show full width editInputReceiveDocumentPage*/
  .detail-receive-document .full-form-editInputReceiveDocumentPage {
    margin-left: 10px;
    margin-right: 10px;
    min-width: 100% !important; }
  .search-processing .search-filter-date,
  .search-work-unfulfilled .search-filter-date,
  .search-work-processing .search-filter-date {
    margin-bottom: 10px; }
  .row-detail-container {
    margin-left: 0;
    margin-right: 0; }
  .systerm-form .form-row, .SortableHelper-step .form-row {
    margin-left: 0;
    margin-right: 0; }
  .list-sign-number .checkbox-sign-number {
    margin-left: 0; }
  .sidebar-menu .treeview-item li:hover {
    background: none; }
  .form-assign-work .pt-form-group .pt-checkbox {
    display: block; }
  .search-GeneralWork .group-btn-search {
    margin-bottom: 10px; }
  .search-GeneralWork .group-datetime,
  .search-GeneralWork .group-select {
    width: 34.4% !important; }
  .page-utilities-container .search-statistic-documentCabinet .group-datetime {
    width: 60%; }
  .search-ReceiveDocument .search-filter-select {
    text-align: right; }
  .search-ReceiveDocument .search-header-group {
    text-align: right; }
  .search-ReceiveDocument-other .group-datetime,
  .search-ReceiveDocument-other .group-select,
  .search-ReceiveDocument-other .input-element-other {
    width: 33.33% !important; }
  .sidebar-menu .treeview-item li:hover {
    background: none; }
  .listDecentralizationUser-container .seach-form .btn-reload-group {
    top: 0; }
  .listDecentralizationUser-container .seach-form .search-keyword {
    padding-right: 10px; }
  .listDecentralizationUser-container .dropdown-tree .rc-tree-select-selection {
    min-width: 200px; }
  .listDecentralizationUser-container .input-searchkey .search {
    margin-right: 0; }
  .listDecentralizationUser-container .search-keyword {
    float: right; }
  .buttton-action-footer.buttton-actionfooter-fixtop {
    padding: 0; }
  .detail-receive-document .box-left,
  .detail-receive-document .box-right {
    width: 100% !important; }
  .detail-receive-document .detail-container .box-left {
    min-height: auto; }
  .detail-receive-document .btn-view-file-detail {
    background: #fff;
    display: block;
    margin-bottom: 15px;
    padding: 20px 10px; }
  .detail-receive-document .info-top {
    margin-bottom: 0; }
  .list-task-content .item:before {
    background-color: transparent !important;
    height: 0; }
  .statistical-container .statistical-item-left {
    border-right: 0 none transparent; }
  .home-section-container-last .section-block {
    display: table;
    height: 100%;
    width: 100%; }
  .home-section-container-last .col-right-content {
    padding-top: 24px; }
  .search-work .form-search-basic {
    padding-left: 0; }
  .seach-form .search-keyword {
    margin-right: 0; }
  .seach-form .btn-reload-group {
    margin-left: 10px; }
  .public-infor-section-block {
    display: block !important; }
  /*document-cabinet-content*/
  .cols-group-content.document-cabinet-content {
    display: block; }
    .cols-group-content.document-cabinet-content .aside-col-content {
      display: none; }
      .cols-group-content.document-cabinet-content .aside-col-content .btn-close {
        display: block;
        z-index: 20; }
      .cols-group-content.document-cabinet-content .aside-col-content .main-heading {
        border-bottom: 1px solid #e4ecf0;
        text-align: center; }
      .cols-group-content.document-cabinet-content .aside-col-content .head-content-aside {
        padding: 10px 0px; }
      .cols-group-content.document-cabinet-content .aside-col-content .aside-nav .main-heading {
        position: relative; }
    .cols-group-content.document-cabinet-content .col-main-content {
      display: block;
      width: 100%; }
  .show-sidebarcabinet-mobile .aside-col-content {
    display: block !important; }
  .page-list-document-cabinet-container .show-asidenav-moblie {
    display: block; }
  .page-list-document-cabinet-container .aside-col-content {
    background-color: #fff;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 20; }
    .page-list-document-cabinet-container .aside-col-content .right-action-group .icon-action {
      display: none; }
    .page-list-document-cabinet-container .aside-col-content .aside-nav {
      height: 100%;
      padding-top: 48px;
      position: relative; }
      .page-list-document-cabinet-container .aside-col-content .aside-nav .main-heading {
        height: 48px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%; }
      .page-list-document-cabinet-container .aside-col-content .aside-nav .aside-main-content {
        height: 100%;
        max-height: 100%;
        overflow-y: auto; }
  /*end document-cabinet-content*/
  .systerm-form .pt-label-inline, .SortableHelper-step .pt-label-inline,
  .systerm-form .pt-form-content,
  .SortableHelper-step .pt-form-content {
    float: none;
    width: 100%; }
  .list-sign-number .search-advanced .group-select,
  .list-sign-number .search-advanced .group-input {
    width: 50% !important; }
  .list-sign-number .search-advanced .group-datetime {
    width: 100% !important; } }

@media only screen and (max-width: 992px) {
  .group-table-DepartmentPerform .item-box {
    min-width: 100% !important; }
  /*hidden button view file editInputReceiveDocumentPage*/
  .form-edit-ReceiveDocument .action-mobile-document {
    display: none; }
  .list-sign-number .name_checkbox {
    font-family: "Roboto-medium", sans-serif;
    font-size: 13px; }
  .search-ReceiveDocument .search-advanced .group-datetime {
    width: 50%; }
  .search-DocumentarySearch .group-select,
  .search-DocumentarySearch .group-datetime {
    width: 50%; }
  .search-PrintSendDocument .btn-reload-group {
    top: 15px; }
  .listDecentralizationUser-container .seach-form .search {
    min-width: 200px !important; }
  .page-printReceiveDocument .search-keyword {
    margin-top: 5px; }
  .page-printReceiveDocument .group-btn-search {
    margin-top: 0 !important; }
  .page-printReceiveDocument .group-btn-search .btn-search {
    margin-left: 0 !important; }
  .search-filterDocument .item-with-box {
    width: 50% !important; }
  .search-filterDocument .group-select,
  .search-filterDocument .group-datetime {
    margin-bottom: 0 !important; }
  .search-filterDocument .group-btn-search {
    margin-top: 0 !important; }
  .search-filterDocument .group-btn-search .btn-search {
    margin-left: 0 !important; }
  .addDocumentsCabinet .col-left-form {
    width: 100%; }
  .addDocumentsCabinet .col-right-form {
    display: none; }
  .seach-form-utilities .list-button .btn-action {
    margin-bottom: 10px; }
  .page-list-document-cabinet-container .head-action-group .list-button {
    text-align: left; }
  .page-list-document-cabinet-container .head-action-group .select-tab {
    float: left;
    padding-left: 5px; }
  .page-list-document-cabinet-container .head-action-group .btn-action .pt-icon {
    margin-left: 5px !important;
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .page-list-document-cabinet-container .head-action-group .name-btn {
    padding-left: 0; }
  .page-list-document-cabinet-container .head-action-group .btn-action:last-child {
    padding-right: 0px !important; }
  .page-list-document-cabinet-container .head-action-group .dropdown-tree-filter {
    min-width: 245px; }
  .dashboard-container .action-right-content {
    display: block;
    float: left;
    margin: auto;
    padding: 10px 0;
    width: 100%; }
  .action-mobile-document .info-icon {
    display: none; }
  .action-mobile-document .list-info-document ul li {
    width: 48%; }
  .search-advanced .group-datetime,
  .search-advanced .group-select {
    width: 50% !important; }
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .logout-mobile,
  .menu-header-mobile,
  .menu-sidebar-mobile {
    display: none; }
  .sidebar-menu .icon-close-menu {
    display: none; }
  .hidden-sm {
    display: none; }
  .visible-sm {
    display: block !important; }
  .header .block-menu .list-menu .item:hover {
    background: transparent !important; }
    .header .block-menu .list-menu .item:hover .top-icon:before {
      color: #195fff; }
  .header .icon-close-menu {
    display: none; }
  .icon-Hethong {
    padding-left: 5px; }
  .hidden-xs {
    display: none; }
  .visible-xs {
    display: block; }
  .box-left {
    height: auto !important;
    min-height: inherit !important; }
    .box-left .view-document .button-list {
      display: none; }
    .box-left .action-mobile-document {
      line-height: 40px;
      min-height: 40px; }
      .box-left .action-mobile-document .migrated-file-buttons {
        margin-left: 15px;
        vertical-align: top; }
        .box-left .action-mobile-document .migrated-file-buttons .item-btn {
          display: inline-block;
          margin-right: 10px; }
      .box-left .action-mobile-document .count-page,
      .box-left .action-mobile-document .info-icon {
        display: none; }
      .box-left .action-mobile-document .info-icon:before {
        color: #9a9a9a;
        font-size: 23px;
        position: relative;
        top: 5px; }
      .box-left .action-mobile-document .view-full-icon:before {
        color: #52555c;
        font-size: 23px;
        vertical-align: middle; }
    .box-left .ad-digitizing {
      margin-left: 10px;
      margin-right: 10px; }
  .form-container .pt-form-group .margin-bt-form {
    margin-bottom: 13px; }
  .not-found-content {
    padding: 60px 15px; }
  .search-ReceiveDocument .seach-form .search {
    min-width: 240px; } }

@media only screen and (max-width: 991px) {
  .list-sign-number .checkbox-sign-number {
    margin-bottom: 10px; }
  .list-sign-number .search-header-group .search {
    min-width: 100%; }
  .search-SendDocument .search-header-group .search-filter-date {
    margin-bottom: 10px !important; }
  .search-SendDocument .search-filter-select {
    padding: 0 !important; }
  .search-SendDocument .search-filter-date {
    padding-right: 0 !important; }
  .search-work .search-filter-select {
    padding-right: 0 !important; }
  .search-work-unfulfilled .search-header-group .search-filter-date {
    margin-bottom: 10px !important;
    padding-right: 0 !important; }
  .list-sign-number .checkbox-sign-number .name_checkbox {
    font-size: 12px; }
  /*full width search*/
  .search-work .search-filter-date,
  .list-sign-number .search-filter-date,
  .search-SendDocument .search-filter-date,
  .search-DocumentarySearch .search-filter-date {
    float: right !important;
    margin-bottom: 0 !important;
    padding-right: 10px; }
    .search-work .search-filter-date .group-datetime,
    .list-sign-number .search-filter-date .group-datetime,
    .search-SendDocument .search-filter-date .group-datetime,
    .search-DocumentarySearch .search-filter-date .group-datetime {
      padding-left: 10px !important;
      padding-right: 0 !important; }
  .search-work .search-filter-select,
  .list-sign-number .search-filter-select,
  .search-SendDocument .search-filter-select,
  .search-DocumentarySearch .search-filter-select {
    float: right !important;
    text-align: right !important;
    width: 100% !important; }
  .search-work .search-header-group,
  .search-work .filter-search-list,
  .list-sign-number .search-header-group,
  .list-sign-number .filter-search-list,
  .search-SendDocument .search-header-group,
  .search-SendDocument .filter-search-list,
  .search-DocumentarySearch .search-header-group,
  .search-DocumentarySearch .filter-search-list {
    width: 100% !important; }
  .search-work .search-keyword,
  .list-sign-number .search-keyword,
  .search-SendDocument .search-keyword,
  .search-DocumentarySearch .search-keyword {
    float: left;
    width: calc(100% - 170px); }
    .search-work .search-keyword .search,
    .list-sign-number .search-keyword .search,
    .search-SendDocument .search-keyword .search,
    .search-DocumentarySearch .search-keyword .search {
      min-width: 100% !important; }
  .search-work .search-filter-select,
  .list-sign-number .search-filter-select,
  .search-SendDocument .search-filter-select,
  .search-DocumentarySearch .search-filter-select {
    float: none !important;
    padding: 0px 10px; }
    .search-work .search-filter-select .search,
    .list-sign-number .search-filter-select .search,
    .search-SendDocument .search-filter-select .search,
    .search-DocumentarySearch .search-filter-select .search {
      min-width: 100% !important; }
  .search-work .select-filter,
  .list-sign-number .select-filter,
  .search-SendDocument .select-filter,
  .search-DocumentarySearch .select-filter {
    margin-bottom: 10px;
    width: 100%; }
    .search-work .select-filter .pt-label-inline,
    .list-sign-number .select-filter .pt-label-inline,
    .search-SendDocument .select-filter .pt-label-inline,
    .search-DocumentarySearch .select-filter .pt-label-inline {
      display: block;
      float: left; }
  .search-work .group-select-inline .pt-select-inline,
  .list-sign-number .group-select-inline .pt-select-inline,
  .search-SendDocument .group-select-inline .pt-select-inline,
  .search-DocumentarySearch .group-select-inline .pt-select-inline {
    width: 100% !important; } }

@media only screen and (max-width: 989px) {
  .search-SendDocument .filter-search-list {
    width: 100%; }
  .search-SendDocument .search-header-group .search-filter-date {
    float: right !important;
    margin-bottom: 10px; }
  .search-SendDocument .search-filter-select {
    display: block !important;
    float: none !important;
    width: 100% !important; }
    .search-SendDocument .search-filter-select .search {
      min-width: 100% !important; }
    .search-SendDocument .search-filter-select .search-keyword {
      margin-left: 0; }
  .search-SendDocument .search-filter-date .group-datetime {
    padding-right: 0 !important; } }

@media only screen and (max-width: 873px) {
  .search-work-unfulfilled .filter-search-list {
    width: 100%; }
  .search-work-unfulfilled .search-header-group .search-filter-date {
    float: right !important;
    margin-bottom: 10px; }
  .search-work-unfulfilled .search-filter-select {
    padding-left: 10px;
    width: 100%; }
    .search-work-unfulfilled .search-filter-select .search {
      min-width: 100% !important; }
    .search-work-unfulfilled .search-filter-select .search-keyword {
      margin-left: 0; }
  .search-work-unfulfilled .search-filter-date .group-datetime {
    padding-right: 0 !important; } }

@media only screen and (max-width: 815px) {
  .detail-receive-document .detail-container .box-left,
  .detail-receive-document .detail-container .box-right {
    width: 100% !important; }
  .box-left .ad-digitizing {
    margin-left: 0;
    margin-right: 0; }
  .page-list-document-cabinet-container .head-action-group .dropdown-tree-filter {
    min-width: 30%; }
  .page-list-document-cabinet-container .list-button {
    width: 70%; }
  .search-processing .search-filter-date {
    float: right !important;
    margin-bottom: 10px; }
  .search-processing .search-header-group {
    margin-right: 0 !important; }
  .search-processing .btn-reload-group .pt-button {
    margin-right: 10px; }
    .search-processing .btn-reload-group .pt-button:last-child {
      margin-right: 0; }
  /*search-DocumentarySearch*/
  .search-DocumentarySearch .form-search-basic .search-header-group {
    padding-left: 0;
    padding-right: 0; } }

@media only screen and (max-width: 767px) {
  .select-project {
    padding-right: 0; }
    .select-project .dropdown-tree-filter {
      margin-top: 3px; }
    .select-project .group-select-inline,
    .select-project .search-system-tree,
    .select-project .pt-label-inline {
      display: block !important;
      text-align: left; }
    .select-project .search-filter-select,
    .select-project .pt-select-inline,
    .select-project .dropdown-tree-filter {
      min-width: 100%; }
    .select-project .select-filter {
      display: block;
      margin: 0;
      padding: 0; }
    .select-project .search-system-tree {
      margin-left: 0; }
    .select-project .search-system-module {
      padding-left: 0; }
  .page-statistic-signnumber .name-radio {
    display: inline-block;
    margin-right: 20px; }
  .page-statistic-signnumber .button-search-statistic {
    padding-top: 10px; }
  .detail-receive-document .container-form-action-sub .box-item:last-child {
    margin-bottom: 80px; }
  /*show full-form-editInputReceiveDocumentPage*/
  .full-form-editInputReceiveDocumentPage {
    margin-top: 10px; }
  .form-edit-ReceiveDocument {
    position: relative !important; }
  .react-quill-custom {
    width: 100%; }
  /*full search List KySo*/
  .list-sign-number .seach-form .search {
    min-width: 100%; }
  .list-sign-number .checkbox-sign-number {
    margin-bottom: 10px; }
  .list-sign-number .search-header-group {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .list-sign-number .seach-form .search-keyword {
    margin-right: 0;
    width: calc(100% - 158px); }
  .search-SendDocument .seach-form .search-header-group {
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0; }
  .search-work .search-keyword {
    margin-left: 0 !important; }
  .search-not-processing {
    width: 100%; }
  .search-ReceiveDocument .search-header-group {
    padding-right: 0 !important; }
  .search-ReceiveDocument .group-select .group-datetime {
    width: 50%; }
  .form-padding-box {
    padding: 10px; }
  .form-AddAdminNotificationPage .form-inline-group .pt-input,
  .form-AddAdminNotificationPage .form-inline-group .radio-group,
  .form-AddAdminNotificationPage .form-inline-group .pt-select,
  .form-AddAdminNotificationPage .react-quill-custom,
  .editor-custom-wrapper {
    width: 100%; }
  .addDocumentsCabinet .container-box-addfile {
    margin-top: -10px; }
  .detail-container .box-right .history-receivingunit-container {
    position: relative;
    top: 0; }
  .department-perform-table tbody td:first-child {
    padding-left: 10px; }
  .selectbox-group .item-checkbox {
    padding: 5px; }
  .viewfile-digitizing-container .heading-goback {
    margin-left: 10px; }
  .resolve-date-padding0,
  .selectbox-group .col-form-item {
    padding-left: 0; }
  .page-list-document-cabinet-container .list-button,
  .page-list-document-cabinet-container .dropdown-tree-filter {
    float: left;
    text-align: left;
    width: 100%; }
  .page-list-document-cabinet-container .dropdown-tree-filter {
    margin-left: 5px;
    margin-top: 10px; }
  .search-system-module {
    padding-right: 0 !important; }
  .addDocumentsCabinet .hide-label {
    display: none !important; }
  .addDocumentsCabinet .form-inline-group {
    margin-bottom: 10px !important; }
  .addDocumentsCabinet .section-content {
    padding: 10px; }
  .breadcrumb-path {
    margin: 0px 10px; }
  .job-information-form {
    margin-left: 10px;
    margin-right: 10px; }
  .search-GeneralWork .group-datetime,
  .search-GeneralWork .group-select {
    width: 50% !important; }
  .search-GeneralWork .checkbox-dealine-statistics {
    margin-top: 35px;
    position: inherit !important; }
  .search-statistic-documentCabinet .group-btn-search {
    margin-bottom: 10px;
    margin-top: 0px !important; }
  .search-statistic-documentCabinet .checkbox-active-work {
    float: right;
    margin-top: 35px;
    position: inherit !important; }
  .listDecentralizationUser-container .seach-form {
    padding: 0; }
    .listDecentralizationUser-container .seach-form .search-keyword {
      display: block;
      float: left;
      margin-bottom: 10px;
      padding-right: 0; }
    .listDecentralizationUser-container .seach-form .search {
      margin-top: 10px;
      min-width: 100% !important; }
  .listDecentralizationUser-container .form-search-basic {
    margin-bottom: 10px;
    padding: 0; }
  .listDecentralizationUser-container .rc-tree-select {
    width: 100%; }
  .listDecentralizationUser-container .pt-label-inline {
    display: block;
    float: left; }
  .listDecentralizationUser-container .btn-reload-group {
    float: left;
    margin-left: 0;
    margin-top: 10px;
    position: initial;
    top: 0; }
  .listDecentralizationUser-container .search-header-group {
    padding-right: 0; }
  .page-ListAdminNotification .seach-form {
    padding-bottom: 10px !important; }
  .row {
    margin: 0; }
  .page-utilities-container .list-DocumentarySearch .group-select,
  .page-utilities-container .list-DocumentarySearch .group-input-filter {
    width: 50% !important; }
  .page-list-contact-container .select-filter-header {
    margin-bottom: 10px;
    padding-right: 0; }
  .detail-receive-document .box-action {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .detail-receive-document .dispatch-code {
    margin-left: 10px;
    padding-bottom: 5px;
    position: relative;
    top: 10px; }
    .detail-receive-document .dispatch-code:after {
      clear: both;
      content: '';
      display: table; }
  .detail-receive-document .btn-view-file-detail {
    display: block;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase; }
  .detail-receive-document .form-numberDocument {
    margin-bottom: 10px; }
  .detail-receive-document .btn-edit-blue {
    margin-right: 10px; }
  .ad-digitizing-container {
    border-top: 1px solid #ddd;
    padding: 10px; }
    .ad-digitizing-container .heading {
      margin-bottom: 10px; }
  .search-ReceiveDocument-other .group-select,
  .search-ReceiveDocument-other .group-datetime,
  .search-ReceiveDocument-other .input-element-other {
    width: 50% !important; }
  .error-page-container {
    padding: 60px 20px; }
    .error-page-container .img-thumb-error {
      margin-bottom: 10px; }
    .error-page-container .description {
      padding-top: 10px; }
  .history-steering-showmobile .container-form-action {
    background: #fff;
    bottom: 0;
    left: 0;
    min-height: 100%;
    overflow-y: scroll;
    position: fixed;
    right: 0;
    top: 50px;
    width: 100%; }
  .table-list-receivingunit {
    padding: 0; }
  .addDocumentsCabinet .container-form-action {
    position: static; }
  .addDocumentsCabinet .form-inline-group {
    margin-bottom: 13px;
    padding-left: 10px;
    padding-right: 10px; }
  .addDocumentsCabinet .label-inline-input,
  .addDocumentsCabinet .section-content {
    display: block; }
  .addDocumentsCabinet .detail-pdf-item {
    float: left;
    padding-left: 0;
    width: 100%; }
  .page-DetailDocumentsCabinet .pt-form-content {
    padding-left: 0; }
  .detail-container-sign-number .box-right .list-action .show-th-mobile {
    display: none; }
  .detail-container-sign-number .show-note-box,
  .detail-container-sign-number .history-edit-document {
    background: #fff;
    display: block;
    margin-top: 0;
    padding-bottom: 10px; }
    .detail-container-sign-number .show-note-box .time-create,
    .detail-container-sign-number .history-edit-document .time-create {
      line-height: 1; }
  .detail-container-sign-number .list-action-sign-number .box-action {
    background: #fff;
    height: auto;
    line-height: 0;
    margin: 0;
    margin-bottom: 10px;
    opacity: initial;
    padding: 0 !important; }
    .detail-container-sign-number .list-action-sign-number .box-action:last-child {
      margin-bottom: 0; }
  .detail-container-sign-number .list-action-sign-number .button-content {
    margin-bottom: 5px; }
  .detail-container-sign-number .list-action-sign-number .table-status {
    padding: 0px 20px; }
    .detail-container-sign-number .list-action-sign-number .table-status .cell-table {
      padding: 0 5px; }
  .search-container {
    border-left: 0 none transparent;
    border-radius: 0;
    border-right: 0 none transparent; }
    .search-container .search-header-left-content,
    .search-container .search-header-right-content {
      padding-left: 0;
      padding-right: 0; }
  .table-container {
    border-bottom: 0 none transparent;
    border-left: 0 none transparent;
    border-radius: 0;
    border-right: 0 none transparent; }
  .addSignNumber .buttton-action-footer {
    padding: 10px; }
  .buttton-action-footer {
    margin: 0 !important;
    padding: 15px 0px; }
    .buttton-action-footer.buttton-actionfooter-fixtop {
      display: none;
      padding: 0;
      top: 140px; }
  .form-report-work .pt-form-content {
    padding-left: 0;
    padding-right: 0; }
    .form-report-work .pt-form-content .pt-control {
      margin-left: 20px !important;
      width: 100% !important; }
  .form-assign-work .pt-form-content,
  .form-assign-work .text-padding-ct {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .form-assign-work .note-deadline .text {
    line-height: 19px;
    padding-left: 15px;
    padding-top: 12px; }
  .form-send-mail {
    padding: 15px !important; }
    .form-send-mail .pt-form-content {
      padding: 0; }
    .form-send-mail .item-info-file .label-item {
      padding-right: 35px !important; }
  .content-wrapper {
    margin-top: 62px;
    padding: 0; }
  .history-steering-Information,
  .content-AssignmentHistory {
    display: none; }
  .history-steering-showmobile {
    display: block;
    overflow: auto;
    /*heading-mobile-add*/ }
    .history-steering-showmobile .heading .click-go-back {
      text-decoration: none; }
    .history-steering-showmobile .heading .text-back {
      color: #454a52;
      font-family: "Roboto-Regular", sans-serif;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 21px;
      padding-left: 5px;
      text-align: left; }
    .history-steering-showmobile .heading .name-heading {
      color: #000;
      display: inline-block;
      font-family: "Roboto-Regular", sans-serif;
      font-size: 17px;
      letter-spacing: 0; }
  .container-form-action {
    background: #fff;
    left: 0;
    min-height: 100%;
    position: absolute;
    right: 0;
    top: 50px;
    width: 100%; }
    .container-form-action .responsive-relative {
      padding-top: 0px !important;
      position: relative;
      top: 0px; }
    .container-form-action .detail-digitizing {
      background: #fff; }
    .container-form-action .pt-form-group {
      margin-bottom: 15px !important; }
      .container-form-action .pt-form-group .full-row {
        margin-left: 0;
        margin-right: -0.5rem; }
    .container-form-action .pt-label {
      padding: 0 !important; }
    .container-form-action .container-detail {
      background: #fff;
      padding: 10px !important; }
      .container-form-action .container-detail .resolve-date {
        display: block; }
        .container-form-action .container-detail .resolve-date .pt-input-group {
          width: 100%; }
      .container-form-action .container-detail .note-deadline {
        padding-left: 0 !important; }
    .container-form-action .resolve-date .pt-label {
      margin-bottom: 12px !important;
      width: 100%; }
    .container-form-action .click-go-back .pt-icon-arrow-left {
      margin-left: 10px !important;
      padding-top: 5px; }
    .container-form-action .pt-form-group .col-form-item {
      padding-left: 0; }
  .header .block-menu {
    margin-top: 7px;
    width: 100%; }
  .header .logo {
    margin-left: 20px; }
  .page-container .left-content {
    width: 100% !important; }
  .page-container .box-action .item {
    height: 58px;
    line-height: 25px; }
  /*menu sidebar and header*/
  .sidebar-collapsed .treeview-item .link-url {
    padding-left: 20px !important;
    text-align: left !important; }
  .sidebar-menu {
    left: -336px;
    margin-top: 80px;
    position: absolute;
    position: fixed;
    top: 8px;
    top: 0;
    width: 336px;
    z-index: 1010;
    -webkit-transition: left 0.3s ease-in-out !important;
    -moz-transition: left 0.3s ease-in-out !important;
    -o-transition: left 0.3s ease-in-out !important;
    transition: left 0.3s ease-in-out !important; }
    .sidebar-menu .arrow-sidebar {
      display: none; }
    .sidebar-menu .pt-icon-cross::before {
      color: #d2d2d2;
      cursor: pointer;
      font-size: 30px; }
    .sidebar-menu .icon-close-menu {
      diplay: block !important;
      padding-right: 15px;
      padding-top: 15px;
      position: absolute;
      right: 0;
      top: 0; }
  .sidebar-mobile-open {
    left: 0; }
    .sidebar-mobile-open .treeview-item .name-menu {
      margin-left: 15px; }
  .menu-sidebar-mobile {
    display: block !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .menu-sidebar-mobile .pt-icon::before {
      color: #fff;
      font-size: 30px; }
  .sidebar-menu {
    margin-top: 54px; }
    .sidebar-menu .menu {
      margin-top: 50px;
      padding-bottom: 20px; }
    .sidebar-menu .title {
      display: none !important;
      width: 100%; }
    .sidebar-menu .hidden-element {
      display: block !important; }
    .sidebar-menu .icon-sidebar:before {
      text-align: left; }
    .sidebar-menu .name-menu {
      display: inline-block !important; }
  .header .block-menu .icon-close-menu,
  .sidebar-menu .icon-close-menu {
    display: block; }
  .sidebar-mobile-open {
    margin: 0 !important;
    position: fixed !important;
    width: 100% !important; }
    .sidebar-mobile-open .icon-close-menu {
      padding-bottom: 10px;
      position: fixed;
      right: 0;
      text-align: right;
      width: 50px;
      z-index: 1; }
  /*end menu sidebar and header*/
  .left-content {
    position: relative; }
  .search-work .search {
    min-width: 39%; }
  .search-work .form-search-basic {
    padding-left: 0; }
  .search-work .statistic-work .item:last-child {
    padding-right: 0; }
  .search-work .statistic-work .item:first-child {
    padding-left: 0; }
  .search-work .statistic-work-left-content {
    padding-left: 0;
    padding-right: 0; }
  .box-right {
    margin-top: 15px; }
    .box-right .list-action {
      line-height: 44px;
      min-height: 44px;
      position: relative; }
      .box-right .list-action .title {
        display: inline;
        font-size: 12px !important;
        padding: 10px 0; }
      .box-right .list-action .show-th-mobile {
        cursor: pointer;
        display: none;
        float: right; }
        .box-right .list-action .show-th-mobile:before {
          line-height: 44px;
          margin-right: 10px; }
    .box-right .box-action {
      background: #fff;
      transition: all 0.3s ease 0.15s; }
      .box-right .box-action .close-mobile-menu-detail {
        background: #fff;
        display: none !important;
        display: block;
        font-size: 30px;
        left: 0;
        padding-right: 15px;
        position: fixed;
        text-align: right;
        width: 100%;
        z-index: 1; }
        .box-right .box-action .close-mobile-menu-detail .pt-icon-cross {
          color: #d7d7d7; }
        .box-right .box-action .close-mobile-menu-detail .history-steering-mobile {
          display: block; }
      .box-right .box-action .item {
        flex-basis: 100%;
        max-width: 100%;
        padding: 16px 10px !important; }
    .box-right .box-action-open {
      background: #fff;
      bottom: 0;
      height: 100%;
      left: 0;
      margin: 0;
      opacity: inherit;
      overflow: auto !important;
      position: fixed;
      right: 0;
      top: 50px;
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -ms-transition: all 1s ease;
      -o-transition: all 1s ease;
      transition: all 1s ease;
      width: 100%;
      z-index: 100; }
      .box-right .box-action-open .item:first-child {
        margin-top: 40px; }
  .detail-receive-document .desc,
  .detail-receive-document .number-code {
    margin-bottom: 10px; }
  .detail-receive-document .desc,
  .detail-receive-document .number-code,
  .detail-receive-document .work-desc {
    font-size: 18px;
    font-size: 14px;
    line-height: 20px;
    padding: 0 15px; }
  .detail-receive-document .list-info-document {
    padding: 0 15px; }
    .detail-receive-document .list-info-document ul li {
      width: 100% !important; }
  .detail-receive-document .buttton-action-footer {
    background: #fff;
    margin: 0;
    padding: 15px 0; }
  .detail-receive-document .info-top {
    margin-bottom: 15px;
    max-height: 0;
    opacity: 0;
    overflow-y: hidden;
    -moz-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    -webkit-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out; }
  .detail-receive-document .info-top-open {
    background: #fff;
    height: 100%;
    left: 0;
    max-height: 100%;
    opacity: 1;
    overflow: auto;
    padding-top: 70px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    /*heading-mobile-add*/ }
    .detail-receive-document .info-top-open .heading {
      border-bottom: 1px solid rgba(151, 151, 151, 0.23);
      line-height: 50px;
      margin: 0 0 13px;
      min-height: 50px;
      padding: 0 15px;
      text-align: center; }
      .detail-receive-document .info-top-open .heading a {
        text-decoration: none; }
      .detail-receive-document .info-top-open .heading .pt-icon {
        color: #000;
        line-height: 3.2; }
      .detail-receive-document .info-top-open .heading .text-back {
        color: #454a52;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 21px;
        padding-left: 5px;
        text-align: left; }
      .detail-receive-document .info-top-open .heading h4 {
        color: #000;
        display: inline;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 17px;
        letter-spacing: 0; }
  .documents-cabinet .checkbox-document .row {
    display: initial !important; }
  .documents-cabinet .name_checkbox {
    padding-left: 20px; }
  .documents-cabinet .type-document-list {
    padding-left: 10px !important; }
  .documents-cabinet .group-save-document {
    border-left: 0 !important;
    min-height: 100% !important;
    padding: 23px 0 !important; }
  .documents-cabinet .checkbox-document-right {
    padding-left: 0;
    padding-right: 0; }
  .documents-cabinet .form-input {
    background: #fff; }
  .documents-cabinet .pt-form-content {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .section-block .heading-content {
    padding: 16px 15px; }
  .dashboard-container .section-block-heading {
    padding: 0 15px; }
  .dashboard-container .list-task-content .item {
    padding-left: 0;
    padding-right: 0; }
  .dashboard-container .section-block {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border-left: 0 none transparent;
    border-right: 0 none transparent; }
  .dashboard-container .statistical-container .col-item {
    padding-left: 15px;
    padding-right: 15px; }
  .dashboard-container .statistical-container .statistical-item-right {
    padding-top: 20px; }
  .dashboard-container .section-container {
    padding-bottom: 5px;
    padding-top: 5px; }
  .dashboard-container .home-section-container-last {
    padding-bottom: 0; }
    .dashboard-container .home-section-container-last .col-left-content,
    .dashboard-container .home-section-container-last .col-right-content {
      padding-left: 0;
      padding-right: 0; }
  .add-receive-document .form-container .pt-form-group {
    margin-left: -8px;
    margin-right: -8px; }
  .row-input-numberdocument .padding-left-none {
    padding-left: 0; }
  .row-input-numberdocument .padding-right-none {
    padding-right: 0; }
  .dashboard-page-wapper .dashboard-container {
    width: 100%; }
  .search-GeneralWork .group-btn-search {
    margin-top: 0px !important; }
  /*system*/
  .search-system-module .search-system-tree {
    padding-right: 0; } }

@media only screen and (max-width: 640px) {
  .search-processing .search-header-group .pt-label-inline {
    display: block; }
  .search-processing .search-filter-date {
    margin-bottom: 0 !important; }
  .search-processing .select-filter {
    margin-right: 0 !important;
    text-align: left !important; }
  .search-processing .search-filter-select {
    margin-top: 10px; }
    .search-processing .search-filter-select .search {
      min-width: 253px !important; }
  .search-not-processing .search-header-group .pt-label-inline {
    display: block; }
  .search-not-processing .search-filter-date {
    margin-bottom: 0 !important; }
  .search-not-processing .select-filter {
    margin-right: 0 !important;
    text-align: left !important; } }

@media only screen and (max-width: 693px) {
  .search-DocumentarySearch .search-filter-select {
    display: inline-flex; } }

@media only screen and (max-width: 600px) {
  .form-cols-content .form-cols {
    padding-left: 0;
    padding-right: 0; }
  .systerm-form .form-col5, .SortableHelper-step .form-col5 {
    float: none;
    width: 100%; }
  .systerm-form .form-col3, .SortableHelper-step .form-col3 {
    float: none;
    width: 100%; }
  .systerm-form .form-subblock, .SortableHelper-step .form-subblock, .systerm-form .form-cols, .SortableHelper-step .form-cols {
    padding-left: 0;
    padding-right: 0; }
  .list-sign-number .search-advanced .group-select,
  .list-sign-number .search-advanced .group-input {
    width: 100% !important; }
  .systerm-form .form-col4, .SortableHelper-step .form-col4 {
    width: 100%; }
  .form-AddAdminNotificationPage .form-container-AddAdminNotificationPage .dropdown-tree-filter {
    margin-left: 0; }
  .cols-group-content.document-cabinet-content .aside-col-content .main-heading {
    padding-left: 10px;
    text-align: left; }
  /* search-system-module */
  .search-system-module {
    padding-left: 0 !important; }
  /*search-ReceiveDocument*/
  .search-ReceiveDocument .search-header-group,
  .search-DocumentarySearch .search-header-group {
    padding-left: 0; }
  .search-ReceiveDocument .filter-search-list,
  .search-DocumentarySearch .filter-search-list {
    width: 100% !important; }
  .search-ReceiveDocument .search-keyword,
  .search-DocumentarySearch .search-keyword {
    float: left;
    width: calc(100% - 150px) !important; }
  .search-ReceiveDocument .search-advanced .group-select,
  .search-DocumentarySearch .search-advanced .group-select {
    width: 100%; }
  .search-ReceiveDocument .search-filter-select,
  .search-DocumentarySearch .search-filter-select {
    display: block;
    min-width: 100%; }
    .search-ReceiveDocument .search-filter-select .search,
    .search-DocumentarySearch .search-filter-select .search {
      min-width: 100% !important; }
  .search-ReceiveDocument .select-filter,
  .search-DocumentarySearch .select-filter {
    margin-bottom: 10px;
    width: 100%; }
    .search-ReceiveDocument .select-filter .pt-label-inline,
    .search-DocumentarySearch .select-filter .pt-label-inline {
      display: block;
      text-align: left; }
  .search-ReceiveDocument .group-select-inline .pt-select-inline,
  .search-DocumentarySearch .group-select-inline .pt-select-inline {
    width: 100% !important; }
  .search-ReceiveDocument .seach-form .btn-reload-group,
  .search-DocumentarySearch .seach-form .btn-reload-group {
    margin-left: 0px; }
  .documents-cabinet .name_checkbox {
    padding-left: 10px; }
  .documents-cabinet .type-document-list {
    padding-left: 0 !important; }
  .documents-cabinet .pt-radio {
    display: inline-block;
    margin-right: 15px; }
  .detail-receive-document .btn-view-file-detail {
    margin-bottom: 0; }
  .department-perform-table {
    overflow-x: scroll; }
  .dropdown-tree-filter {
    width: 100%; }
  .user-mobile {
    margin-top: 30px; }
    .user-mobile .info-user {
      padding-top: 0; }
  .page-list-GeneralDocumentary .group-btn-search {
    margin-bottom: 10px; }
  .page-list-document-cabinet-container .head-action-group {
    padding: 10px; }
    .page-list-document-cabinet-container .head-action-group .list-button {
      margin: 0 !important; }
  .page-list-document-cabinet-container .btn-action .pt-icon {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .page-list-document-cabinet-container .btn-action .name-btn {
    padding-left: 10px !important; }
  .search-statistic-documentCabinet .checkbox-active-work {
    margin-bottom: 10px;
    margin-top: 0; }
  .search-GeneralWork .checkbox-dealine-statistics {
    margin-bottom: 10px;
    margin-top: 0; }
  .page-utilities-container .search-filter-date .group-datetime {
    padding-right: 0px; }
  .page-utilities-container .filter-search-list .search {
    min-width: 100%; }
  .page-utilities-container .group-btn-search {
    margin-top: 0 !important; }
    .page-utilities-container .group-btn-search .btn-search {
      margin-left: 0 !important; }
  .page-utilities-container .item-with-box {
    width: 100% !important; }
  .page-utilities-container .button-heading-right .pt-button {
    margin-bottom: 0; }
    .page-utilities-container .button-heading-right .pt-button:last-child {
      margin-bottom: 0; }
  .page-utilities-container .search-advanced .group-select,
  .page-utilities-container .group-input-filter {
    min-width: 100% !important; }
  .page-utilities-container .search-header-group {
    padding-left: 0;
    padding-right: 0; }
  .page-utilities-container .filter-search-list,
  .page-utilities-container .search-filter-date {
    width: 100%; }
    .page-utilities-container .filter-search-list .group-datetime,
    .page-utilities-container .search-filter-date .group-datetime {
      float: right; }
  .listDecentralizationUser-container .search-keyword {
    width: 100%; }
  .search-ReceiveDocument-other .search-advanced .input-element-other,
  .search-ReceiveDocument-other .search-advanced .group-datetime,
  .search-ReceiveDocument-other .search-advanced .group-select {
    width: 100% !important; }
  .search-ReceiveDocument-other .search-advanced .emty-nbsp {
    display: none; }
  .search-work-other .emty-nbsp {
    display: block; }
  .search-work-other .group-datetime {
    min-width: 100% !important; }
  .search-work-other .group-select {
    min-width: 50% !important; }
  .search-work-other .input-element-other {
    width: 100% !important; }
  body {
    -webkit-text-size-adjust: none; }
  .button-gv .btn-action {
    margin-bottom: 10px;
    margin-right: 10px; }
  .detail-container-sign-number .show-note-box .buttton-action-footer {
    padding: 5px 0; }
  .hidden-btn,
  .note-issues {
    display: none; }
  .seach-form .search {
    min-width: 220px;
    text-overflow: ellipsis; }
  .search-advanced {
    padding: 0 10px; }
    .search-advanced .group-datetime {
      width: 100% !important; }
  .buttton-action-footer .btn-view,
  .buttton-action-footer .full-mobile {
    margin-bottom: 13px;
    width: 100%; }
  .buttton-action-footer .full-btn-left,
  .buttton-action-footer .full-btn-right {
    text-align: center;
    width: 46%; }
  .buttton-action-footer .full-btn-left {
    float: left; }
  .buttton-action-footer .full-btn-right {
    float: right; }
  .table-container {
    padding-bottom: 5px; }
    .table-container .footer-list {
      margin-top: 5px;
      padding: 0; }
    .table-container .pt-table td:first-child {
      padding-left: 12px; }
    .table-container .check-star::before {
      font-size: 16px !important; }
    .table-container.table-subcollap-container .pt-table thead th:first-child {
      padding-left: 20px; }
    .table-container.table-subcollap-container .pt-table td.collapse-heading-content {
      padding-left: 10px; }
    .table-container.table-subcollap-container .subtable-content .pt-table td:first-child {
      padding-left: 20px; }
  .cpc-login-page {
    padding-bottom: 70px; }
    .cpc-login-page .cpc-logo-full-text {
      margin-bottom: 30px;
      margin-top: 30px; }
    .cpc-login-page .cpc-footer {
      bottom: 20px; }
  .head-action-group .select-tab {
    margin-top: 10px;
    width: 100% !important; }
  .systerm-form .pt-label-inline, .SortableHelper-step .pt-label-inline {
    float: none;
    padding-bottom: 5px;
    padding-top: 0;
    width: 100%; }
  .systerm-form .input-form-inline, .SortableHelper-step .input-form-inline {
    float: none;
    width: 100%; }
  .systerm-form .form-group-inline .pt-label-inline, .SortableHelper-step .form-group-inline .pt-label-inline {
    float: left;
    padding-bottom: 0;
    padding-right: 20px;
    width: auto; }
  .systerm-form .form-group-inline .input-form-inline, .SortableHelper-step .form-group-inline .input-form-inline {
    float: left;
    width: auto; }
  .systerm-form .form-subblock, .SortableHelper-step .form-subblock,
  .systerm-form .title-progress-sign,
  .SortableHelper-step .title-progress-sign {
    padding-left: 20px;
    padding-right: 20px; }
  .systerm-form .form-block, .SortableHelper-step .form-block {
    padding-left: 0;
    padding-right: 0; }
  .ad-digitizing .form-container .pt-form-group .form-item,
  .add-receive-document .form-container .pt-form-group .form-item {
    flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    max-width: 100%; }
    .ad-digitizing .form-container .pt-form-group .form-item:last-child,
    .add-receive-document .form-container .pt-form-group .form-item:last-child {
      margin-top: 10px; }
  .ad-digitizing .row-input-numberdocument,
  .add-receive-document .row-input-numberdocument {
    margin-bottom: 12px; }
  .bottom-add-receive-document .group-button-head .btn-digitizing-cvd {
    min-width: 200px; }
    .bottom-add-receive-document .group-button-head .btn-digitizing-cvd:first-child {
      margin-left: 0; }
  .detail-receive-document .view-job-done .name_user {
    padding-top: 10px; }
  .full-input {
    flex-basis: 100%;
    max-width: 100%; }
  .search-system-tree {
    padding-right: 0 !important; }
    .search-system-tree .pt-label-inline {
      display: block !important;
      text-align: left !important; } }

@media only screen and (max-width: 530px) {
  .page-statistic-signnumber .table-container .button-heading-right {
    position: static; }
  /* search-system-module */
  .search-system-module {
    margin-bottom: 10px; }
  .listDecentralizationUser-container .form-search-basic {
    margin-bottom: 0 !important; }
  .page-list-document-cabinet-container .search-header-group {
    padding-left: 0;
    padding-right: 0; }
  .seach-form .search {
    min-width: 100% !important; }
  .search-work-unfulfilled .search-filter-select {
    padding-left: 0; }
  .dispatch-code {
    margin-bottom: 0; }
  .heading-page .text-heading-page {
    font-size: 18px;
    margin-top: 0px; }
    .heading-page .text-heading-page .icon-heading {
      margin-right: 10px; }
  .page-list-document-cabinet-container .search-container {
    padding-bottom: 10px; }
  .list-sign-number .search-header-group {
    padding-bottom: 10px;
    padding-left: 0; }
  .search-processing .group-datetime {
    padding-left: 0; }
  .search-container .filter-search-list,
  .search-work-processing .filter-search-list {
    width: 100%; }
  .search-container .react-datepicker__close-icon::after,
  .search-work-processing .react-datepicker__close-icon::after {
    display: none; }
  .search-container .search-filter-date,
  .search-work-processing .search-filter-date {
    padding-left: 0 !important;
    width: 100% !important; }
    .search-container .search-filter-date .group-datetime,
    .search-work-processing .search-filter-date .group-datetime {
      padding-left: 0 !important;
      text-align: left;
      width: 50% !important; }
    .search-container .search-filter-date .daterangepicker-group,
    .search-work-processing .search-filter-date .daterangepicker-group {
      display: inline-block;
      width: 100% !important; }
  .search-container .search-filter-select,
  .search-work-processing .search-filter-select {
    width: 100%; }
    .search-container .search-filter-select .search-keyword,
    .search-work-processing .search-filter-select .search-keyword {
      margin-left: 0 !important; }
  .page-DocumentarySearch .btn-top-heading {
    margin-bottom: 10px; }
  .size-container-dialog {
    min-width: auto; }
  .content-wrapper .authorize-addform {
    padding: 10px; }
  .content-wrapper .dispatch-code .number-code {
    display: block;
    padding: 10px 0px;
    position: inherit; }
  .content-wrapper .ad-digitizing .form-item {
    padding-left: 0;
    padding-right: 0; }
  .content-wrapper .button-heading-right {
    display: block;
    margin-top: 10px;
    width: 100%; }
  .content-wrapper .page-DocumentarySearch .search-DocumentarySearch .search-header-group {
    padding-bottom: 10px; }
  .content-wrapper .page-DocumentarySearch .btn-top-heading {
    margin-bottom: 10px;
    width: 48%; }
    .content-wrapper .page-DocumentarySearch .btn-top-heading:nth-child(2) {
      margin-right: 0; }
  .padding-0 {
    padding-left: 0;
    padding-right: 0; }
  .page-list-document-cabinet-container .head-action-group .dropdown-tree-filter {
    margin-left: 0; }
  .search-GeneralWork .group-btn-search {
    margin-top: 0 !important; }
  .search-GeneralWork .group-select {
    width: 100% !important; }
  .listDecentralizationUser-container .search-keyword .select-inline {
    display: block;
    width: auto; }
  .listDecentralizationUser-container .btn-reload-group {
    margin-bottom: 10px; }
  .error-page-container .description .text-link {
    display: block; }
  /*DetailDocumentsCabinet*/
  .addDocumentsCabinet .section-content {
    padding: 15px 0; }
  .padding-mobile-default {
    padding: 0; }
  .page-DetailDocumentsCabinet .heading-page,
  .page-DetailDocumentsCabinet .tabs-detail-page {
    display: none; }
  .cpc-login-form {
    padding: 15px;
    width: 90%; }
    .cpc-login-form .cpc-form-control {
      padding-left: 10px !important; }
  .bottom-add-receive-document .btn-digitizing-cvd {
    width: 100%; }
    .bottom-add-receive-document .btn-digitizing-cvd:first-child {
      margin-top: 15px; }
  .detail-receive-document .detail-container .box-right .box-action {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .left-content {
    /*sohoa*/ }
    .left-content .addSignNumber {
      border-radius: 0px; }
    .left-content .btn-digitizing, .left-content .bottom-add-receive-document .btn-digitizing-cvd, .bottom-add-receive-document .left-content .btn-digitizing-cvd {
      margin-left: 5px !important; }
    .left-content .group-button-head {
      float: none;
      padding: 0px 10px; }
    .left-content .ad-digitizing-container {
      left: 0;
      overflow-y: scroll;
      position: absolute;
      width: 100%; }
      .left-content .ad-digitizing-container .btn-close::before {
        display: none;
        font-size: 30px; }
      .left-content .ad-digitizing-container .list-digitizing .item:last-child {
        margin-bottom: 30px; }
    .left-content .content-wrapper .add-receive-document {
      margin-top: 0; }
  /*history*/
  .history-steering-showmobile .buttton-action-footer .pt-button {
    margin-bottom: 10px; }
  /*search*/
  .search-container .seach-form {
    padding-bottom: 0; }
  .page-ListAdminNotification .seach-form {
    padding-bottom: 10px !important; }
  .search-work .statistic-work .item:first-child {
    padding-left: 0; }
  .search-work .statistic-work-left-content {
    padding-left: 0; }
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .statistical-list .item {
    flex-basis: 50%;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    max-width: 50%; }
  .form-container .pt-form-group .colcus-mb-50 {
    flex-basis: 100%;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    max-width: 100%; }
    .form-container .pt-form-group .colcus-mb-50:first-child {
      padding-bottom: 12px; }
  .page-list-document-cabinet-container .head-action-group {
    text-align: left; }
    .page-list-document-cabinet-container .head-action-group .btn-action {
      margin: 5px; }
  .utility-media-block .image-content {
    float: none;
    padding-bottom: 15px;
    width: 100%; }
  .utility-media-block .utility-main-content {
    padding-left: 0; }
  .bottom-add-receive-document .group-button-head .btn-digitizing-cvd {
    margin-left: 0 !important;
    width: 100%; }
    .bottom-add-receive-document .group-button-head .btn-digitizing-cvd:last-child {
      margin-top: 10px; }
  .page-list-document-cabinet-container .head-action-group .list-button {
    margin-bottom: 0;
    width: 100%; }
    .page-list-document-cabinet-container .head-action-group .list-button .btn-action {
      margin: 4px 0;
      width: 100%; }
  .search-ReceiveDocument .select-filter-header {
    width: 100% !important; }
  .search-ReceiveDocument .seach-form .search-keyword {
    margin-bottom: 10px; }
  .addSignNumber .selectbox-group {
    margin: 0px 10px;
    margin-bottom: 12px; }
  .addSignNumber .form-container {
    padding: 10px 0px; }
  .wrapper-panel-collapse {
    margin-left: 10px;
    margin-right: 10px; } }

@media only screen and (max-width: 430px) {
  .search-filter-date .group-datetime {
    min-width: 100% !important;
    padding-right: 0; }
  .detail-receive-document .view-job-done .box-level-sub {
    margin-left: 15px; }
  .detail-receive-document .view-job-done .avatar {
    margin-left: 15px !important; }
  .history-steering .buttton-action-footer {
    text-align: center; }
  .filter-chart {
    display: block !important; }
    .filter-chart .item-filter {
      display: block;
      margin-right: 0;
      width: 100%; }
  .tab-cus-container .pt-tab-list .pt-tab {
    min-width: 106px;
    padding: 15px 0px; }
  .listDecentralizationUser-container .seach-form .search {
    margin-bottom: 10px;
    min-width: 100%; }
    .listDecentralizationUser-container .seach-form .search .seach-form {
      padding: 0px 10px; }
  .listDecentralizationUser-container .seach-form .search {
    width: 100%; }
  .listDecentralizationUser-container .seach-form .btn-reload-group {
    width: 100%; }
  .listDecentralizationUser-container .form-container .pt-select {
    padding-right: 0; }
  .listDecentralizationUser-container .search-keyword {
    padding-right: 0 !important; }
  .wapper-checkbox-group-two-columns {
    overflow-x: scroll; }
    .wapper-checkbox-group-two-columns > .box-panel-heading,
    .wapper-checkbox-group-two-columns > .panel {
      min-width: 370px; }
    .wapper-checkbox-group-two-columns .panel-heading {
      padding: 10px; }
    .wapper-checkbox-group-two-columns .panel-title {
      min-width: 160px; } }

@media only screen and (max-width: 375px) {
  .listDecentralizationUser-container .btn-reload-group {
    margin-bottom: 0; }
  .listDecentralizationUser-container .search-keyword {
    min-width: 100%; }
  .listDecentralizationUser-container .form-search-basic {
    padding-bottom: 0; }
  .list-sign-number .seach-form .search-keyword {
    margin-right: 0;
    width: calc(100% - 156px); }
  .header-container .button-link-chat .icon-chat {
    font-size: 18px; }
  .form-assign-work .buttton-action-footer .btn-cancel-steering {
    margin-left: 10px !important; }
  .form-assign-work .pt-button:first-child {
    margin-left: 0 !important; }
  .form-assign-work .pt-button:last-child {
    margin-right: 0 !important; }
  .buttton-action-footer .btn-cancel-steering {
    margin-left: 15px; }
  .tab-cus-container .pt-tab-list .pt-tab {
    font-size: 13px; }
  .search-work .search-header-right-content {
    padding-top: 10px; }
  .search-work-other .emty-nbsp {
    display: none; }
  .search-work-other .input-element-other {
    min-width: 100% !important; }
  .page-list-document-cabinet-container .search-container .seach-form {
    padding-bottom: 0; }
  .search-container .search-advanced input {
    min-width: auto !important; }
  .search-container .seach-form .btn-reload,
  .search-container .seach-form .btn-toogle {
    margin-right: 10px; }
  .search-container .btn-reload-group {
    margin-left: 10px; }
    .search-container .btn-reload-group .pt-button:last-child {
      margin-right: 0; }
  .search-ReceiveDocument-other .input-element-other .pt-input {
    min-width: 100% !important; }
  .search-work .form-search-basic,
  .statistic-work {
    margin-left: 0 !important;
    text-align: center; }
  .form-assign-work .note-deadline {
    padding-top: 0 !important; }
  .left-content .button-gv .btn-action {
    margin-right: 10px !important;
    min-width: auto !important;
    text-align: center;
    width: 46.8%; }
    .left-content .button-gv .btn-action .name-btn {
      font-size: 11.4px;
      padding-left: 0; }
    .left-content .button-gv .btn-action .pt-icon {
      padding-right: 4px; }
  .left-content .button-gv .btn-action {
    width: 100% !important; }
  .add-receive-document .pt-button:last-child,
  .box-right .pt-button:last-child {
    margin-right: 0; }
  .add-receive-document .buttton-action-footer,
  .box-right .buttton-action-footer {
    padding: 15px 0;
    text-align: center; }
    .add-receive-document .buttton-action-footer .btn-blue-color,
    .add-receive-document .buttton-action-footer .btn-cancel,
    .add-receive-document .buttton-action-footer .btn-save,
    .box-right .buttton-action-footer .btn-blue-color,
    .box-right .buttton-action-footer .btn-cancel,
    .box-right .buttton-action-footer .btn-save {
      text-align: center !important; }
  .add-receive-document .margin-375,
  .box-right .margin-375 {
    margin-bottom: 13px; }
  .search-work .statistic-work .item {
    padding-left: 0;
    padding-right: 0; }
    .search-work .statistic-work .item:not(:last-child) {
      padding-bottom: 10px; }
  .seach-form .search-keyword {
    width: -moz-calc(100% - 156px);
    width: -webkit-calc(100% - 156px);
    width: calc(100% - 156px);
    margin-right: 0; }
  .buttton-action-footer .full-btn-left,
  .buttton-action-footer .full-mobile {
    margin-bottom: 10px !important; }
  .buttton-action-footer .full-btn-left,
  .buttton-action-footer .full-mobile .full-btn-right {
    width: 100%; }
  .container-form-action .container-detail {
    padding: 5px 10px; }
  .heading-page .text-heading-page {
    font-size: 15px; }
  .cpc-login-form {
    padding: 15px 15px 20px;
    width: 100%; }
    .cpc-login-form .main-title {
      font-size: 24px; }
    .cpc-login-form .cpc-form-control {
      padding-left: 10px !important; }
  .cpc-login-page {
    padding-left: 15px;
    padding-right: 15px; }
    .cpc-login-page h1 {
      width: 100%; }
  .buttton-action-footer .pt-button,
  .buttton-action-footer .seach-form .btn-reload,
  .buttton-action-footer .seach-form .btn-search,
  .buttton-action-footer .seach-form .btn-toogle,
  .seach-form .buttton-action-footer .btn-reload,
  .seach-form .buttton-action-footer .btn-search,
  .seach-form .buttton-action-footer .btn-toogle {
    margin-right: 10px; }
  .detail-receive-document .box-level-sub {
    margin-left: 10px !important; }
  .search-ReceiveDocument .seach-form .search-keyword {
    width: -moz-calc(100% - 150px);
    width: -webkit-calc(100% - 150px);
    width: calc(100% - 150px); }
    .search-ReceiveDocument .seach-form .search-keyword .search {
      min-width: 100% !important; }
  .page-utilities-container .button-heading-right .pt-button {
    margin-bottom: 10px; } }

@media only screen and (max-width: 360px) {
  .header .logo {
    margin-left: 15px;
    width: 120px; }
  .header .menu-header-mobile .pt-icon-menu:before {
    font-size: 20px; }
  .header .menu-sidebar-mobile .pt-icon-chevron-right:before {
    font-size: 25px; } }

@media only screen and (max-width: 320px) {
  .content-wrapper {
    margin-top: 67px; }
  .header-container .button-link-chat .name-link {
    display: none; }
  .header-container .button-link-chat {
    min-width: 50px; }
  .documents-cabinet .type-document-list .pt-radio {
    display: block; }
  .menu-sidebar-mobile .pt-icon-chevron-right::before {
    font-size: 20px; }
  .search-ReceiveDocument .group-select {
    width: 100%; }
  .search-ReceiveDocument .search-keyword {
    margin-bottom: 0 !important;
    margin-top: 10px !important;
    width: 100% !important; }
  .search-ReceiveDocument .search-header-group {
    margin-bottom: 0;
    padding: 0 !important; }
  .button-heading-right .pt-button {
    margin-bottom: 10px; }
  .detail-receive-document .pt-checkbox {
    margin-bottom: 10px; }
  .search-advanced .group-datetime .pt-input,
  .search-advanced .group-select .pt-input {
    min-width: auto !important; }
  .search-container .search-header-group {
    padding-left: 0;
    padding-right: 0; }
  .search-container .btn-reload,
  .search-container .btn-toogle,
  .search-container .btn-search {
    margin-top: 10px; }
  .search-container .search-keyword {
    padding-right: 0;
    width: 100%; }
  .search-container .search-advanced input {
    min-width: auto !important; }
  .search-container .btn-reload-group {
    margin-bottom: 10px;
    margin-left: 0;
    position: inherit !important; }
  .search-container .search-keyword .search {
    width: 100%; }
  .search-work-other .search-advanced .group-select,
  .search-work-other .search-advanced .group-datetime {
    width: 100%; }
  .search-work-other .search-advanced .input-element-other .pt-input {
    min-width: 100% !important; }
  .list-sign-number .search-header-group {
    padding-bottom: 0; } }

.cpc-card {
  background-color: #fff; }
  .cpc-card.bordered {
    border: 1px solid #e4ecf0; }
  .cpc-card.border-top {
    border-top: 1px solid #e4ecf0; }
  .cpc-card.rounded {
    border-radius: 5px; }
  .cpc-card > .padding {
    padding: 10px 20px; }
    .cpc-card > .padding-vertical {
      padding-left: 0;
      padding-right: 0; }
    .cpc-card > .padding-horizontal {
      padding-bottom: 0;
      padding-top: 0; }
  .cpc-card.shadow {
    box-shadow: 0 8px 20px 0 rgba(199, 199, 199, 0.25); }
  .cpc-card-title {
    font-size: 14px;
    font-weight: bold; }
    .cpc-card-title.bordered {
      border-bottom: 1px solid #e4ecf0; }

.cpc-notification-card main {
  align-items: center;
  display: flex;
  flex-direction: row; }
  .cpc-notification-card main .time {
    color: rgba(51, 51, 51, 0.65);
    font-size: 13px;
    min-width: 75px;
    text-align: right; }

.cpc-notification-card.unread {
  background-color: #e4f7fe; }

.cpc-user-card {
  background-color: transparent; }
  .cpc-user-card .cpc-user-title {
    color: #31434b;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; }
  .cpc-user-card .cpc-user-sub {
    color: #738a95;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 15px; }
  .cpc-user-card .cpc-user-action {
    font-weight: 300; }
  .cpc-user-card main {
    align-items: center;
    display: flex;
    flex-direction: row; }
  .cpc-user-card .avatar-name {
    align-items: center;
    background: #195fff;
    border-radius: 50%;
    color: #fff;
    display: inline-flex;
    height: 30px;
    justify-content: center;
    margin: 0 2px;
    padding: 5px;
    text-align: center;
    width: 30px; }
    .cpc-user-card .avatar-name span {
      display: block;
      font-size: 8px;
      font-weight: 500;
      letter-spacing: 1px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }

.card-empty__round {
  border-radius: 50%;
  border: 1px solid #dce4ef;
  background: #f5f5f5;
  width: 139px;
  height: 139px;
  box-sizing: border-box;
  line-height: 139px;
  margin: 0 auto; }

.card-empty-small img {
  margin-bottom: 5px;
  max-height: 50px; }

.card-empty-small__text {
  opacity: 0.6; }

.cpc-badge {
  border-radius: 50%;
  font-size: 9px;
  height: 20px;
  line-height: 20px;
  min-width: 20px;
  padding: 0px 2px;
  text-align: center;
  text-indent: -1px; }
  .cpc-badge.cpc-badge-small {
    height: 18px;
    line-height: 18px;
    min-width: 18px;
    width: 18px; }
  .cpc-badge.cpc-badge-sidebar {
    background-color: #cd4343;
    border-radius: 20px;
    font-size: 13px;
    height: 20px;
    line-height: 20px;
    min-width: 31px;
    position: absolute;
    right: 15px;
    width: 31px; }
  .cpc-badge.cpc-badge-status-tab {
    font-size: 12px; }
  .cpc-badge.dot {
    min-width: unset;
    padding: 0;
    width: 10px;
    height: 10px;
    border-radius: 50% !important; }
  .cpc-badge.small {
    height: 18px;
    line-height: 18px;
    min-width: 18px;
    width: 18px; }

.cpc-button.gray .cpc-badge-status-tab {
  background-color: #fff;
  color: #384f5a; }

.cpc-button.cpc-button-orange .cpc-badge-status-tab {
  background-color: #fff;
  color: #ed8525; }

.cpc-button.pt-intent-primary:not([class*='cpc-button-orange']) .cpc-badge-status-tab {
  background-color: #fff;
  color: #195fff; }

.sidebar-collapsed .cpc-badge-statistics {
  border-radius: 50%;
  height: 8px;
  min-width: 8px;
  position: absolute;
  right: 4px;
  text-indent: -9999px;
  top: 4px;
  width: 8px; }
  @media only screen and (max-width: 1024px) {
    .sidebar-collapsed .cpc-badge-statistics {
      border-radius: 20px;
      height: 20px;
      line-height: 20px;
      min-width: 31px;
      position: absolute;
      right: 15px;
      text-indent: 0;
      width: 31px; } }

@media only screen and (max-width: 1024px) {
  .sidebar-collapsed-back .cpc-badge-statistics {
    border-radius: 50%;
    height: 8px;
    min-width: 8px;
    position: absolute;
    right: 4px;
    text-indent: -9999px;
    top: 4px;
    width: 8px; } }

.cpc-tabs {
  z-index: 1; }
  .cpc-tabs > .pt-tab-list {
    background-color: #fff;
    border: 1px solid #e4ecf0;
    align-items: center; }
    .cpc-tabs > .pt-tab-list > .pt-tab {
      color: #999;
      padding: 8px 30px;
      margin: 0;
      font-size: 15px;
      user-select: none;
      z-index: 1; }
      .cpc-tabs > .pt-tab-list > .pt-tab > * {
        vertical-align: middle; }
      .cpc-tabs > .pt-tab-list > .pt-tab[aria-selected='true'] {
        color: #000; }
        .cpc-tabs > .pt-tab-list > .pt-tab[aria-selected='true'] > i::before,
        .cpc-tabs > .pt-tab-list > .pt-tab[aria-selected='true'] > .tab-icon::before {
          color: #195fff; }
      .cpc-tabs > .pt-tab-list > .pt-tab:hover {
        background-color: #e4ecf0; }
    .cpc-tabs > .pt-tab-list > .pt-tab-indicator-wrapper {
      z-index: 2; }
      .cpc-tabs > .pt-tab-list > .pt-tab-indicator-wrapper > .pt-tab-indicator {
        background-color: #195fff; }
  .cpc-tabs > .pt-tab-panel {
    margin: 0; }
  .cpc-tabs.content-border > .pt-tab-panel {
    border: 1px solid #e4ecf0; }
  .cpc-tabs.scrollable > .pt-tab-panel {
    max-height: 365px;
    overflow-y: auto; }

.cpc-row {
  margin: 0; }
  .cpc-row-gutter {
    margin: -0.5rem; }
    .cpc-row-gutter > [class^='col-'] {
      padding: 0.5rem; }
    .cpc-row-gutter-vertical {
      margin: 0 -0.5rem; }

.cpc-button-filter-clear {
  height: 37px;
  padding: 0;
  width: 37px; }
  .cpc-button-filter-clear-old {
    height: 38px;
    width: 38px; }

.cpc-button {
  font-family: "Roboto", sans-serif; }
  .cpc-button.green {
    background: #00b051 !important;
    border-color: transparent;
    box-shadow: none; }
    .cpc-button.green:hover {
      background: #6bc779 !important; }
  .cpc-button-create-speed {
    background-color: rgba(26, 27, 35, 0.7) !important;
    color: #fff !important;
    height: 40px;
    line-height: 40px;
    padding: 0 15px; }
    .cpc-button-create-speed .icon {
      color: #fff;
      margin-right: 10px;
      vertical-align: middle; }
      .cpc-button-create-speed .icon:before {
        color: #fff !important; }
    .cpc-button-create-speed:hover {
      background-color: rgba(26, 27, 35, 0.8) !important; }
  .cpc-button.red-border {
    background-color: transparent !important;
    border-color: #d0021b;
    color: #d0021b; }
    .cpc-button.red-border:hover {
      background-color: transparent !important; }
  .cpc-button-link {
    min-height: auto;
    padding: 0; }
    .cpc-button-link:hover {
      background-color: transparent !important;
      color: #195fff !important; }
    .cpc-button-link:disabled {
      background-color: transparent !important; }
      .cpc-button-link:disabled:hover {
        background-color: transparent !important; }
  .cpc-button.no-background {
    background-color: transparent;
    border: none;
    color: #a4b3ba;
    outline: none; }
    .cpc-button.no-background:hover {
      background-color: #f6f7f9;
      color: #31434b; }
  .cpc-button.theme-sm {
    align-items: center;
    display: flex;
    gap: 5px;
    height: 35px;
    padding: 0 10px; }
  .cpc-button.theme-md {
    align-items: center;
    display: flex;
    gap: 5px;
    height: 37px;
    padding: 0 10px; }

.cpc-button {
  align-items: center;
  background-image: unset !important;
  border: 1px solid rgba(16, 22, 26, 0.4);
  box-shadow: unset !important;
  display: inline-flex;
  font-size: 13px;
  justify-content: center;
  line-height: 100%; }
  .cpc-button::before {
    color: inherit;
    font-size: 13px !important; }
  .cpc-button [class^='pt-icon'],
  .cpc-button [class^='icon-'] {
    align-items: center;
    display: flex;
    justify-content: center; }
    .cpc-button [class^='pt-icon']::before,
    .cpc-button [class^='icon-']::before {
      color: inherit;
      font-size: 13px; }
  .cpc-button.pt-minimal, .cpc-button.pt-small {
    border-color: transparent !important; }
  .cpc-button.pt-intent-primary {
    background-color: #195fff;
    border-color: #195fff;
    color: #fff; }
    .cpc-button.pt-intent-primary:not(.pt-disabled):not(.pt-minimal):hover {
      background-color: #0344d7; }
    .cpc-button.pt-intent-primary.pt-minimal {
      background-color: unset;
      border-color: transparent;
      color: #195fff; }
      .cpc-button.pt-intent-primary.pt-minimal:not(.pt-disabled):hover {
        color: #195fff; }
  .cpc-button.pt-intent-danger {
    background-color: #d0021b;
    border-color: #d0021b;
    color: #fff; }
    .cpc-button.pt-intent-danger:not(.pt-disabled):not(.pt-minimal):hover {
      background-color: #d24848; }
    .cpc-button.pt-intent-danger.pt-minimal {
      background-color: unset;
      border-color: transparent;
      color: #d0021b; }
      .cpc-button.pt-intent-danger.pt-minimal:not(.pt-disabled):hover {
        color: #d0021b; }
  .cpc-button.default, .cpc-button.default-back {
    background: #fff;
    color: #6a7489; }
    .cpc-button.default:hover, .cpc-button.default-back:hover {
      background-color: #eff3f5; }
  .cpc-button.default-back {
    border-color: #d2d2d2; }
  .cpc-button.transparent {
    background-color: transparent; }
    .cpc-button.transparent.pt-intent-primary {
      color: #195fff; }
      .cpc-button.transparent.pt-intent-primary:hover {
        background-color: #eff3f5 !important; }
    .cpc-button.transparent.pt-intent-danger {
      color: #d0021b; }
      .cpc-button.transparent.pt-intent-danger:hover {
        background-color: #eff3f5 !important; }
  .cpc-button.no-border {
    border-color: transparent; }
  .cpc-button.rounded {
    border-radius: 20px; }
    .cpc-button.rounded:disabled {
      background: #bebebe !important;
      border-color: #bebebe !important;
      color: #fff !important; }
  .cpc-button.gray {
    background-color: #f1f1f1;
    border-color: #f1f1f1;
    color: rgba(56, 79, 90, 0.5); }
  .cpc-button.no-hover:hover {
    background-color: transparent !important; }

.cpc-button-collapse-more {
  background-color: transparent !important;
  background-image: none !important;
  border-radius: 3px;
  box-shadow: none !important;
  color: #426ee1 !important;
  font-family: "Roboto", sans-serif;
  height: 40px;
  min-height: 40px; }
  .cpc-button-collapse-more .icon-arrow-dropdown,
  .cpc-button-collapse-more .icon-arrow-dropup {
    display: inline-block;
    height: 20px;
    line-height: 20px !important;
    text-align: center;
    width: 20px; }
    .cpc-button-collapse-more .icon-arrow-dropdown::before,
    .cpc-button-collapse-more .icon-arrow-dropup::before {
      color: #426ee1 !important; }

.cpc-footer-button-group {
  text-align: center; }
  .cpc-footer-button-group button {
    font-size: 12px;
    padding: 4px 12px; }
    .cpc-footer-button-group button:not(:first-child) {
      margin-left: 10px; }

.cpc-button.qlcv-green-color {
  color: #0aaa84; }
  .cpc-button.qlcv-green-color [class^='pt-icon']::before,
  .cpc-button.qlcv-green-color [class^='icon-']::before {
    color: #0aaa84; }
  .cpc-button.qlcv-green-color:hover {
    color: #0aaa84; }

.cpc-button.color-light {
  color: #fff; }
  .cpc-button.color-light:not(.pt-intent-primary):hover {
    color: #182026; }

.cpc-button-orange {
  background-color: #ed8526 !important;
  border-color: #ed8526 !important; }

.cpc-button-green {
  background-color: #08b424 !important;
  border-color: #08b424 !important;
  color: #fff !important; }
  .cpc-button-green * {
    color: #fff !important; }
    .cpc-button-green *::before {
      color: inherit; }

.cpc-button.blue {
  background-color: #195fff;
  border-color: #195fff;
  color: #fff; }
  .cpc-button.blue:hover {
    background-color: #0e4ee4;
    border-color: #195fff;
    color: #fff; }

.cpc-button.blue-text {
  color: #195fff; }
  .cpc-button.blue-text:hover {
    color: #195fff; }

.cpc-button.white {
  background-color: #fff;
  border-color: #fff;
  color: #000; }
  .cpc-button.white:hover {
    background-color: #f9f9f9;
    border-color: #f9f9f9;
    color: #000; }

.cpc-button.white-border-gray {
  background-color: #fff;
  border-color: #b3b8c4;
  color: #6a7489; }
  .cpc-button.white-border-gray:hover {
    background-color: #f9f9f9;
    border-color: #b3b8c4;
    color: #6a7489; }

.cpc-button.border-gray {
  background-color: transparent;
  border-color: #b3b8c4;
  color: #6a7489; }
  .cpc-button.border-gray:hover {
    border-color: #b3b8c4;
    color: #6a7489; }

.cpc-button.green-no-bg {
  background-color: transparent !important;
  border-color: #08b424 !important;
  color: #41c656 !important; }
  .cpc-button.green-no-bg * {
    color: #41c656 !important; }
    .cpc-button.green-no-bg *::before {
      color: inherit; }

.checkbox-button {
  background-color: transparent !important;
  border: none;
  border-radius: 50%;
  box-shadow: none !important;
  line-height: 100%;
  min-height: auto;
  min-width: auto;
  padding: 0px; }
  .checkbox-button:hover [class^='icon-']:before {
    color: #6bc779; }
  .checkbox-button:disabled [class^='icon-']:before {
    color: #d0d0d0; }

textarea.cpc-input {
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 300;
  height: 36px;
  width: 100%; }
  textarea.cpc-input:focus {
    box-shadow: none; }
  textarea.cpc-input[readonly] {
    box-shadow: none; }

.cpc-input {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  width: 100%; }
  .cpc-input > input {
    border: 1px solid #e2e2e2;
    border-radius: 3px;
    box-shadow: none;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 300;
    height: 36px;
    width: inherit; }
    .cpc-input > input:focus {
      box-shadow: none; }
    .cpc-input > input[readonly] {
      box-shadow: none;
      cursor: default; }
  .cpc-input.no-border > input {
    border: 0;
    padding-left: 0; }
  .cpc-input .pt-input-action {
    align-items: center;
    display: inline-flex;
    height: 100%;
    top: 50%;
    transform: translateY(-50%); }
    .cpc-input .pt-input-action i[class^='icon-arrow'] {
      display: flex;
      height: 30px;
      line-height: 30px;
      margin-top: 0px; }
    .cpc-input .pt-input-action .full-button {
      border-radius: 0 3px 3px 0;
      height: inherit;
      margin: 0; }
    .cpc-input .pt-input-action > button {
      align-items: center;
      display: flex;
      height: 30px;
      justify-content: center;
      min-width: 30px;
      padding: 0; }
  .cpc-input-icon .pt-input-action {
    right: auto; }
  .cpc-input-icon input {
    padding-left: 36px; }
  .cpc-input-icon .absolute-left-icon {
    color: #8a9da7;
    font-size: 12px;
    left: 5px !important;
    line-height: 100%;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }

.cpc-input-inline {
  align-items: center;
  display: flex; }

.cpc-input-search > input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d2d2d2;
  box-shadow: none;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 300; }

.cpc-input-search > .pt-icon-search {
  align-items: center;
  display: flex;
  height: 100%; }
  .cpc-input-search > .pt-icon-search::before {
    font-size: 13px; }

.cpc-input-search-old {
  flex: 1; }
  .cpc-input-search-old > input {
    border: 3px;
    border: 1px solid #e2e2e2;
    box-shadow: none;
    font: 400 13px/38px "Roboto-Regular", sans-serif;
    height: 38px; }
    .cpc-input-search-old > input:focus {
      box-shadow: none; }
  .cpc-input-search-old .pt-input-action {
    align-items: center;
    display: flex;
    height: 100%; }
    .cpc-input-search-old .pt-input-action .cpc-button.green {
      background: #6bc779 !important;
      border: 1px solid #51c062;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      height: 100%;
      margin: 0;
      width: 38px; }
      .cpc-input-search-old .pt-input-action .cpc-button.green > span {
        color: #fff; }
      .cpc-input-search-old .pt-input-action .cpc-button.green:hover {
        background: #00b051 !important; }

.cpc-input-v2-filter input {
  border-radius: 0px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  height: 37px !important;
  line-height: 37px; }

.cpc-input-v2-filter-button-calendar {
  width: 100%; }
  .cpc-input-v2-filter-button-calendar .pt-input-group {
    border: 1px solid #d2d2d2; }
    .cpc-input-v2-filter-button-calendar .pt-input-group input {
      border: none !important; }

.cpc-input-v2-filter-button {
  align-items: center;
  display: flex;
  justify-content: space-between; }
  .cpc-input-v2-filter-button .cpc-input-search {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    overflow: hidden; }
  .cpc-input-v2-filter-button .pt-input-group {
    position: relative;
    width: calc(100% - 55px); }
    .cpc-input-v2-filter-button .pt-input-group input {
      border: 1px solid #dfe6ea;
      border-radius: 0px;
      box-shadow: none;
      box-sizing: border-box;
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      height: 37px;
      line-height: 37px; }
    .cpc-input-v2-filter-button .pt-input-group .pt-input-action {
      align-items: center;
      display: flex; }
      .cpc-input-v2-filter-button .pt-input-group .pt-input-action button {
        border: none;
        border-radius: 0px;
        box-shadow: none;
        color: #fff;
        height: 100%;
        height: 37px;
        margin: 0px;
        text-align: center;
        width: 37px; }
        .cpc-input-v2-filter-button .pt-input-group .pt-input-action button span {
          color: #fff;
          font-size: 15px;
          margin: 0px; }

.cpc-input-v2-filter-button.justify-content-end {
  justify-content: flex-end; }

.icon-arrow-dropup2 {
  font-size: 0px;
  line-height: 100%;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0%, -50%); }

.input-not-ph .input-comp-v2 {
  padding: 0 8px !important; }

.cpc-textarea {
  width: 100%;
  border: 1px solid #e2e2e2;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px;
  box-shadow: none;
  resize: none; }
  .cpc-textarea:focus {
    box-shadow: none; }

.cpc-dropdown-checked-select {
  align-items: center;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  height: 40px;
  padding: 0 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05); }
  .cpc-dropdown-checked-select .checkbox-arrow::before {
    color: #9fa2a5; }

.cpc-dropdown-item {
  cursor: pointer;
  font-size: 13px;
  padding: 5px 20px; }
  .cpc-dropdown-item:first-child.border {
    border-top: 0 !important; }
  .cpc-dropdown-item.border {
    border-top: 1px solid #e4e9f0; }
  .cpc-dropdown-item:hover {
    background: rgba(25, 95, 255, 0.1);
    color: #195fff; }

.cpc-dropdown-color {
  display: flex;
  flex-wrap: wrap;
  padding: 10px; }
  .cpc-dropdown-color-item {
    cursor: pointer;
    flex: 1 0 33%;
    padding: 5px; }
    .cpc-dropdown-color-item:hover, .cpc-dropdown-color-item.active {
      box-shadow: 0 0 0 1px #195fff inset; }
  .cpc-dropdown-color-text {
    font-size: 12px;
    overflow: hidden;
    padding: 5px 10px;
    text-overflow: ellipsis;
    white-space: nowrap; }

.cpc-checkbox {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-size: 13px; }
  .cpc-checkbox > .pt-control-indicator,
  .cpc-checkbox > input {
    top: 50%;
    transform: translateY(-50%); }
  .cpc-checkbox:checked ~ .pt-control-indicator {
    box-shadow: none !important; }
  .cpc-checkbox.pt-disabled {
    color: unset; }
  .cpc-checkbox-task > .pt-control-indicator {
    border-radius: 50% !important; }
    .cpc-checkbox-task > .pt-control-indicator::before {
      border-radius: 50% !important;
      background-color: #37c700 !important; }

.cpc-progress {
  height: 10px; }
  .cpc-progress.large {
    height: 14px; }
  .cpc-progress-bar-wrapper {
    display: flex;
    align-items: center; }
    .cpc-progress-bar-wrapper > div {
      margin-right: 3px; }
    .cpc-progress-bar-wrapper > span {
      min-width: 30px;
      text-align: right;
      color: #53bfa9; }
  .cpc-progress.pt-intent-success > .pt-progress-meter {
    background-color: #53bfa9; }

.cpc-context-menu {
  border: 1px solid #e2e2e2;
  display: inline-block; }
  .cpc-context-menu-item {
    display: flex;
    align-items: center;
    font-size: 13px; }
    .cpc-context-menu-item > i {
      min-width: 30px;
      display: inline-block;
      margin-right: 5px;
      text-align: center;
      list-style: 50%; }
      .cpc-context-menu-item > i::before {
        color: #7f7f7f; }
  .cpc-context-menu .pt-menu-item::after {
    top: 50%;
    transform: translateY(-50%); }

.cpc-label {
  background-color: #f1f1f1;
  border-radius: 0px 12px 12px 0px;
  color: #0f3753;
  display: block;
  font: 10px/14px 'Roboto';
  font-size: 11px;
  padding: 3px 12px; }
  .cpc-label-group {
    align-items: center;
    display: flex;
    flex-wrap: wrap; }
  .cpc-label.primary {
    background-color: #195fff;
    border-color: #195fff;
    color: #fff; }

.cpc-progress-label {
  background: none;
  border: 0;
  font: 500 10px 'Roboto';
  font-size: 11px;
  position: relative;
  margin: 0; }
  .cpc-progress-label.round::after {
    content: unset; }
  .cpc-progress-label.round.done {
    background: #f5fff7;
    border: unset;
    border: 1px solid #7efb93;
    border-radius: 100px;
    font-family: 'Roboto-Bold';
    padding: 4px 17px; }
  .cpc-progress-label.round.new {
    background: #fff2d6;
    border: unset;
    border: 1px solid #ed8526;
    border-radius: 100px;
    font-family: 'Roboto-Bold';
    padding: 4px 17px; }
  .cpc-progress-label.round.process {
    background: #fff2d6;
    border: unset;
    border: 1px solid #ed8526;
    border-radius: 100px;
    color: #d19330;
    font-family: 'Roboto-Bold';
    padding: 4px 17px; }
  .cpc-progress-label.round.slow {
    background: #fff6f6;
    border: unset;
    border: 1px solid #d60000;
    border-radius: 100px;
    color: #d60000;
    font-family: 'Roboto-Bold';
    padding: 4px 17px; }
  .cpc-progress-label.round.orange {
    background: #fff3e9;
    border: unset;
    border: 1px solid #ed8525;
    border-radius: 100px;
    color: #ed8525;
    font-family: 'Roboto-Bold';
    padding: 4px 17px; }
  .cpc-progress-label.round.blue {
    background: #f5f8ff;
    border: unset;
    border: 1px solid #335fb3;
    border-radius: 100px;
    color: #335fb3;
    font-family: 'Roboto-Bold';
    padding: 4px 17px; }
  .cpc-progress-label::after {
    content: '';
    height: 12px;
    left: -10px;
    position: absolute;
    top: -1px;
    width: 2px; }
  .cpc-progress-label.red {
    color: #d60000; }
    .cpc-progress-label.red::after {
      background-color: #d60000; }
  .cpc-progress-label.new {
    color: #ed8526; }
    .cpc-progress-label.new::after {
      background-color: #ed8526; }
  .cpc-progress-label.process {
    color: #195fff; }
    .cpc-progress-label.process::after {
      background-color: #195fff; }
  .cpc-progress-label.done {
    border: unset;
    color: #08b424; }
    .cpc-progress-label.done::after {
      background-color: #08b424; }
  .cpc-progress-label.orange {
    border: unset;
    color: #ed8525; }
    .cpc-progress-label.orange::after {
      background-color: #ed8525; }

.cpc-overlay {
  top: 0;
  z-index: 1;
  position: fixed;
  width: 100%; }
  .cpc-overlay-popup {
    background-color: #fff;
    box-shadow: 0px 8px 21px rgba(74, 74, 74, 0.4);
    position: absolute;
    top: 126px;
    right: 20px;
    width: 600px;
    transition: transform 0.3s; }
    .cpc-overlay-popup.stretch {
      transform: translateY(-71px); }
    .cpc-overlay-popup .popup-title {
      font-weight: bold;
      line-height: 22px;
      font-size: 15px;
      text-transform: uppercase;
      text-align: center;
      position: relative; }
      .cpc-overlay-popup .popup-title .back-button,
      .cpc-overlay-popup .popup-title .menu-button {
        position: absolute;
        top: 13px;
        left: 20px;
        cursor: pointer; }
      .cpc-overlay-popup .popup-title .menu-button {
        top: 12px;
        left: auto;
        right: 20px;
        transform: rotate(0.25turn); }
      .cpc-overlay-popup .popup-title .pt-popover-target {
        position: inherit !important; }
    .cpc-overlay-popup .popup-content {
      overflow-y: auto;
      max-height: calc(100vh - 130px); }
    .cpc-overlay-popup .cpc-overlay-button-left {
      margin-left: -10px; }
    .cpc-overlay-popup .cpc-overlay-button-right {
      float: right;
      margin-right: -10px; }
  .cpc-overlay-divider {
    display: block;
    margin: 15px 0;
    width: 100%;
    height: 2px;
    background-color: #e4ecf0; }

@media only screen and (max-width: 1400px) {
  .cpc-overlay-popup {
    right: 15px; } }

@media only screen and (max-width: 767px) {
  .cpc-overlay-popup {
    right: 0; } }

.cpc-scrollbar {
  transition: max-height 0.3s; }
  .cpc-scrollbar > div {
    transition: inherit; }
  .cpc-scrollbar .scroll-thumb {
    width: 5px;
    border-radius: inherit;
    background-color: #c5cdd2;
    border-radius: inherit;
    left: 1px;
    transition: height 0.3s;
    width: 4px; }
  .cpc-scrollbar .scroll-inline {
    padding-right: 10px; }

.cpc-kanban-wrapper {
  display: flex;
  overflow-x: auto;
  background-color: #fff;
  padding: 15px; }
  .cpc-kanban-wrapper::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 16px;
    height: 8px; }
  .cpc-kanban-wrapper::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    border-radius: 4px; }
  .cpc-kanban-wrapper::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 16px; }
  .cpc-kanban-wrapper .cpc-kanban-dropzone {
    padding-bottom: 1px; }
  .cpc-kanban-wrapper .cpc-kanban-column-scroll {
    overflow: hidden auto; }
  .cpc-kanban-wrapper .cpc-kanban-column {
    background-color: #edf0f4;
    border-radius: 4px;
    padding: 0 10px;
    max-width: 320px;
    min-width: 300px;
    align-self: baseline; }
    .cpc-kanban-wrapper .cpc-kanban-column.cpc-kanban-column-add {
      padding: 0; }
      .cpc-kanban-wrapper .cpc-kanban-column.cpc-kanban-column-add .cpc-kanban-form .input-form {
        height: 40px;
        width: 100%;
        border: none;
        outline: none;
        background: #ffffff;
        padding: 0 10px;
        border-radius: 4px; }
      .cpc-kanban-wrapper .cpc-kanban-column.cpc-kanban-column-add .cpc-kanban-footer {
        padding: 5px; }
        .cpc-kanban-wrapper .cpc-kanban-column.cpc-kanban-column-add .cpc-kanban-footer .button {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: normal;
          background-color: #5ba83e;
          box-shadow: none;
          border: none;
          color: #ffffff;
          height: 32px;
          border-radius: 4px;
          padding: 2px 10px; }
          .cpc-kanban-wrapper .cpc-kanban-column.cpc-kanban-column-add .cpc-kanban-footer .button:hover {
            cursor: pointer; }
          .cpc-kanban-wrapper .cpc-kanban-column.cpc-kanban-column-add .cpc-kanban-footer .button.button-close {
            background-color: transparent;
            margin-left: 10px; }
    .cpc-kanban-wrapper .cpc-kanban-column:not(:last-child) {
      margin-right: 20px; }
    .cpc-kanban-wrapper .cpc-kanban-column.open-column-dragover {
      background-color: #fff2d6; }
    .cpc-kanban-wrapper .cpc-kanban-column.progress-column-dragover {
      background-color: #eef6ff; }
    .cpc-kanban-wrapper .cpc-kanban-column.done-column-dragover {
      background-color: #e5f5df; }
    .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card-children {
      margin-bottom: 10px; }
    .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card {
      user-select: none;
      background-color: #ffffff;
      padding: 10px;
      border-radius: 4px; }
      .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card.cpc-kanban-add {
        background-color: transparent; }
        .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card.cpc-kanban-add:hover {
          cursor: pointer; }
        .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card.cpc-kanban-add .title-name {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 18px;
          color: #426ee1; }
        .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card.cpc-kanban-add span[class*='icon-'] {
          color: #426ee1;
          font-size: 13px;
          padding-right: 6px; }
      .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card .padding {
        padding: 10px 12px; }
      .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card .title-content {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: #31434b;
        padding-left: 0; }
      .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card.cpc-kanban-title {
        position: relative;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-color: transparent; }
        .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card.cpc-kanban-title .title-name {
          font-weight: bold;
          text-transform: uppercase;
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          color: #31434b; }
        .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card.cpc-kanban-title .title-size {
          font-weight: bold;
          float: right;
          font-size: 13px; }
        .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card.cpc-kanban-title::before {
          content: '';
          display: block;
          width: 2px;
          height: 14px;
          border-radius: 2px;
          background: black;
          position: absolute;
          left: 0;
          top: 15px; }
        .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card.cpc-kanban-title.title-open {
          background-color: transparent;
          border: unset;
          margin-bottom: 0; }
          .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card.cpc-kanban-title.title-open::before {
            background-color: #d19330; }
        .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card.cpc-kanban-title.title-progress {
          background-color: transparent;
          border: unset;
          margin-bottom: 0; }
          .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card.cpc-kanban-title.title-progress::before {
            background-color: #195fff; }
        .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card.cpc-kanban-title.title-done {
          background-color: transparent;
          border: unset;
          margin-bottom: 0; }
          .cpc-kanban-wrapper .cpc-kanban-column .cpc-kanban-card.cpc-kanban-title.title-done::before {
            background-color: #5ba83e; }
  .cpc-kanban-wrapper .wrapper-icon {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    background: #eceff4;
    border-radius: 100%;
    margin-right: 5px; }
    .cpc-kanban-wrapper .wrapper-icon i[class*='icon2-'] {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #31434b !important;
      font-size: 10px; }

.cpc-detail {
  font-family: 'Roboto';
  font-weight: 300;
  word-break: break-word; }
  .cpc-detail .menu-button {
    color: #426ee1;
    cursor: pointer;
    transform: rotate(0.25turn); }
  .cpc-detail-block {
    background-color: #fff;
    border: 1px solid #dfe6ea; }
  .cpc-detail-left {
    margin-right: auto; }
  .cpc-detail-right {
    margin-left: auto; }
  .cpc-detail-label, .cpc-detail-text {
    font-size: 10px !important;
    line-height: 15px;
    text-transform: uppercase; }
  .cpc-detail-label {
    color: #738a95;
    margin-right: 5px; }
  .cpc-detail-text {
    color: #31434b;
    font-weight: 500; }
  .cpc-detail-norm {
    color: #31434b;
    font-size: 13px;
    line-height: 18px; }

.cpc-avatar {
  display: inline-block;
  min-width: 38px;
  overflow: hidden;
  vertical-align: middle; }
  .cpc-avatar .image {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    padding-top: 100%;
    width: 100%; }
    .cpc-avatar .image.contain {
      background-size: contain; }
    .cpc-avatar .image.not-radius {
      border-radius: 0%; }
    .cpc-avatar .image img {
      display: none; }
  .cpc-avatar.small {
    min-width: 30px; }
  .cpc-avatar.tiny {
    min-width: 26px; }
  .cpc-avatar.tinytan {
    min-width: 24px; }
  .cpc-avatar > img {
    width: 100%; }
  .cpc-avatar-group {
    align-items: center;
    display: flex; }
    .cpc-avatar-group .cpc-avatar {
      margin: 0 2px; }
    .cpc-avatar-group .avatar-name {
      align-items: center;
      background: #195fff;
      border-radius: 50%;
      color: #fff;
      display: inline-flex;
      height: 30px;
      justify-content: center;
      margin: 0 2px;
      padding: 5px;
      text-align: center;
      width: 30px; }
      .cpc-avatar-group .avatar-name span {
        display: block;
        font-size: 8px;
        font-weight: 500;
        letter-spacing: 1px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .cpc-avatar-group .label-counter {
      background: rgba(25, 95, 255, 0.1);
      border-radius: 50%;
      color: #195fff;
      display: block;
      font-size: 10px;
      font-weight: 600;
      height: 30px;
      line-height: 30px;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 30px; }

.cpc-timeline {
  font-family: 'Roboto';
  font-weight: 300; }
  .cpc-timeline-item {
    position: relative;
    padding: 10px;
    padding-left: 30px; }
    .cpc-timeline-item::after {
      content: '\e904';
      font-family: 'icon2';
      position: absolute;
      top: 10px;
      left: 0px;
      color: #8a9da7; }
  .cpc-timeline-time, .cpc-timeline-description {
    font-size: 13px;
    line-height: 18px;
    color: #31434b; }

.cpc-confirm {
  border-radius: 0;
  padding-bottom: 0;
  font-size: 15px; }
  .cpc-confirm .confirm-buttons {
    padding: 0;
    display: flex;
    justify-content: center; }
    .cpc-confirm .confirm-buttons > main > button:not(:first-child) {
      margin-left: 10px; }

.cpc-popover {
  background: #fff; }
  .cpc-popover.no-arrow .pt-popover-arrow {
    display: none; }

.label-group-popover {
  box-shadow: 0px 8px 20px rgba(200, 200, 200, 0.65); }

.cpc-form {
  font-family: 'Roboto';
  font-weight: 300; }
  .cpc-form label {
    font-size: 13px;
    font-weight: 600;
    color: #31434b; }
  .cpc-form .cpc-form-button-left {
    margin-left: -10px;
    margin-right: auto; }
  .cpc-form .cpc-form-button-right {
    margin-left: auto;
    margin-right: -10px; }
  .cpc-form .pt-form-group {
    position: relative; }
    .cpc-form .pt-form-group .pt-label {
      font: 14px "Roboto-Bold", sans-serif;
      margin-bottom: 8px; }
      .cpc-form .pt-form-group .pt-label .pt-text-muted {
        display: inline-block;
        position: relative;
        visibility: hidden;
        width: 0; }
        .cpc-form .pt-form-group .pt-label .pt-text-muted::after {
          content: '*';
          color: #c23030;
          visibility: visible;
          position: absolute;
          top: 0;
          left: 2px; }
    .cpc-form .pt-form-group .button-float-right {
      position: absolute;
      top: -6px;
      right: -10px; }

.cpc-slider {
  min-width: auto;
  margin-right: 3px; }
  .cpc-slider > .pt-slider-track {
    height: 8px; }
  .cpc-slider > .pt-slider-progress {
    height: 8px;
    background-color: #35b49a; }
  .cpc-slider > .pt-slider-handle {
    background-image: unset;
    background-color: #35b49a;
    border-radius: 50%;
    border: 3px solid #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    width: 20px;
    height: 20px;
    top: -2px; }
  .cpc-slider.pt-disabled {
    opacity: 1; }
    .cpc-slider.pt-disabled .pt-slider-handle {
      display: none; }
  .cpc-slider-wrapper {
    display: flex;
    align-items: center; }
    .cpc-slider-wrapper > span {
      min-width: 45px;
      text-align: right;
      color: #35b49a; }

.cpc-list-item .action {
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  cursor: pointer; }
  .cpc-list-item .action.show {
    opacity: 1;
    visibility: visible; }

.cpc-list-item.active {
  background-color: #e4e7e9; }

.cpc-list-item:not(.cpc-list):hover .action {
  opacity: 1;
  visibility: visible; }

.cpc-list-item:not(:first-child) .option-border-top {
  border-top: 1px solid #f9f9f9; }

.cpc-radio {
  margin-bottom: 0;
  display: flex;
  align-items: center; }
  .cpc-radio > input,
  .cpc-radio > .pt-control-indicator {
    top: 50%;
    transform: translateY(-50%); }
  .cpc-radio-group .cpc-radio {
    padding: 5px 0 5px 25px;
    margin: 0; }
    .cpc-radio-group .cpc-radio:not(:last-child) {
      border-bottom: 1px solid #e4ecf0; }

.cpc-upload {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center; }
  .cpc-upload-wrapper {
    align-items: center;
    background-color: #ffffff;
    border: 1px dashed #dfe6ea;
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-height: 48px;
    padding: 10px 0; }
    .cpc-upload-wrapper.drag-hover {
      border: 1px dashed #195fff; }
    .cpc-upload-wrapper.warning {
      border: 1px dashed #c02e2e; }
  .cpc-upload__infos {
    color: #738a95;
    font-weight: 300; }

.cpc-table-wrapper {
  overflow-x: auto; }

.cpc-table-row {
  display: flex;
  flex-wrap: nowrap;
  max-width: 100%; }
  .cpc-table-row-head {
    background-color: #1b60ff;
    color: #fff;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 13px; }
  .cpc-table-row-filter {
    background-color: #f8f9f9;
    border-bottom: 1px solid #e2e2e2; }
    .cpc-table-row-filter .cpc-table-cell {
      padding: 10px; }
    .cpc-table-row-filter .cpc-table-cell:nth-child(2n) .pt-input-group input {
      margin-left: -1px;
      width: calc(100% + 2px); }
  .cpc-table-row.active {
    background-color: #e4f7ff !important; }
  .cpc-table-row .cpc-table-cell--split {
    align-items: stretch;
    display: flex;
    line-height: 100%;
    width: 100%; }
    .cpc-table-row .cpc-table-cell--split > div {
      flex: 1; }

.cpc-table-cell {
  flex-grow: 1;
  flex-shrink: 1; }
  .cpc-table-cell.width-0 {
    flex-basis: 0% !important; }
  .cpc-table-cell.width-1 {
    flex-basis: 1% !important; }
  .cpc-table-cell.width-2 {
    flex-basis: 2% !important; }
  .cpc-table-cell.width-3 {
    flex-basis: 3% !important; }
  .cpc-table-cell.width-4 {
    flex-basis: 4% !important; }
  .cpc-table-cell.width-5 {
    flex-basis: 5% !important; }
  .cpc-table-cell.width-6 {
    flex-basis: 6% !important; }
  .cpc-table-cell.width-7 {
    flex-basis: 7% !important; }
  .cpc-table-cell.width-8 {
    flex-basis: 8% !important; }
  .cpc-table-cell.width-9 {
    flex-basis: 9% !important; }
  .cpc-table-cell.width-10 {
    flex-basis: 10% !important; }
  .cpc-table-cell.width-11 {
    flex-basis: 11% !important; }
  .cpc-table-cell.width-12 {
    flex-basis: 12% !important; }
  .cpc-table-cell.width-13 {
    flex-basis: 13% !important; }
  .cpc-table-cell.width-14 {
    flex-basis: 14% !important; }
  .cpc-table-cell.width-15 {
    flex-basis: 15% !important; }
  .cpc-table-cell.width-16 {
    flex-basis: 16% !important; }
  .cpc-table-cell.width-17 {
    flex-basis: 17% !important; }
  .cpc-table-cell.width-18 {
    flex-basis: 18% !important; }
  .cpc-table-cell.width-19 {
    flex-basis: 19% !important; }
  .cpc-table-cell.width-20 {
    flex-basis: 20% !important; }
  .cpc-table-cell.width-21 {
    flex-basis: 21% !important; }
  .cpc-table-cell.width-22 {
    flex-basis: 22% !important; }
  .cpc-table-cell.width-23 {
    flex-basis: 23% !important; }
  .cpc-table-cell.width-24 {
    flex-basis: 24% !important; }
  .cpc-table-cell.width-25 {
    flex-basis: 25% !important; }
  .cpc-table-cell.width-26 {
    flex-basis: 26% !important; }
  .cpc-table-cell.width-27 {
    flex-basis: 27% !important; }
  .cpc-table-cell.width-28 {
    flex-basis: 28% !important; }
  .cpc-table-cell.width-29 {
    flex-basis: 29% !important; }
  .cpc-table-cell.width-30 {
    flex-basis: 30% !important; }
  .cpc-table-cell.width-31 {
    flex-basis: 31% !important; }
  .cpc-table-cell.width-32 {
    flex-basis: 32% !important; }
  .cpc-table-cell.width-33 {
    flex-basis: 33% !important; }
  .cpc-table-cell.width-34 {
    flex-basis: 34% !important; }
  .cpc-table-cell.width-35 {
    flex-basis: 35% !important; }
  .cpc-table-cell.width-36 {
    flex-basis: 36% !important; }
  .cpc-table-cell.width-37 {
    flex-basis: 37% !important; }
  .cpc-table-cell.width-38 {
    flex-basis: 38% !important; }
  .cpc-table-cell.width-39 {
    flex-basis: 39% !important; }
  .cpc-table-cell.width-40 {
    flex-basis: 40% !important; }
  .cpc-table-cell.width-41 {
    flex-basis: 41% !important; }
  .cpc-table-cell.width-42 {
    flex-basis: 42% !important; }
  .cpc-table-cell.width-43 {
    flex-basis: 43% !important; }
  .cpc-table-cell.width-44 {
    flex-basis: 44% !important; }
  .cpc-table-cell.width-45 {
    flex-basis: 45% !important; }
  .cpc-table-cell.width-46 {
    flex-basis: 46% !important; }
  .cpc-table-cell.width-47 {
    flex-basis: 47% !important; }
  .cpc-table-cell.width-48 {
    flex-basis: 48% !important; }
  .cpc-table-cell.width-49 {
    flex-basis: 49% !important; }
  .cpc-table-cell.width-50 {
    flex-basis: 50% !important; }
  .cpc-table-cell.width-51 {
    flex-basis: 51% !important; }
  .cpc-table-cell.width-52 {
    flex-basis: 52% !important; }
  .cpc-table-cell.width-53 {
    flex-basis: 53% !important; }
  .cpc-table-cell.width-54 {
    flex-basis: 54% !important; }
  .cpc-table-cell.width-55 {
    flex-basis: 55% !important; }
  .cpc-table-cell.width-56 {
    flex-basis: 56% !important; }
  .cpc-table-cell.width-57 {
    flex-basis: 57% !important; }
  .cpc-table-cell.width-58 {
    flex-basis: 58% !important; }
  .cpc-table-cell.width-59 {
    flex-basis: 59% !important; }
  .cpc-table-cell.width-60 {
    flex-basis: 60% !important; }
  .cpc-table-cell.width-61 {
    flex-basis: 61% !important; }
  .cpc-table-cell.width-62 {
    flex-basis: 62% !important; }
  .cpc-table-cell.width-63 {
    flex-basis: 63% !important; }
  .cpc-table-cell.width-64 {
    flex-basis: 64% !important; }
  .cpc-table-cell.width-65 {
    flex-basis: 65% !important; }
  .cpc-table-cell.width-66 {
    flex-basis: 66% !important; }
  .cpc-table-cell.width-67 {
    flex-basis: 67% !important; }
  .cpc-table-cell.width-68 {
    flex-basis: 68% !important; }
  .cpc-table-cell.width-69 {
    flex-basis: 69% !important; }
  .cpc-table-cell.width-70 {
    flex-basis: 70% !important; }
  .cpc-table-cell.width-71 {
    flex-basis: 71% !important; }
  .cpc-table-cell.width-72 {
    flex-basis: 72% !important; }
  .cpc-table-cell.width-73 {
    flex-basis: 73% !important; }
  .cpc-table-cell.width-74 {
    flex-basis: 74% !important; }
  .cpc-table-cell.width-75 {
    flex-basis: 75% !important; }
  .cpc-table-cell.width-76 {
    flex-basis: 76% !important; }
  .cpc-table-cell.width-77 {
    flex-basis: 77% !important; }
  .cpc-table-cell.width-78 {
    flex-basis: 78% !important; }
  .cpc-table-cell.width-79 {
    flex-basis: 79% !important; }
  .cpc-table-cell.width-80 {
    flex-basis: 80% !important; }
  .cpc-table-cell.width-81 {
    flex-basis: 81% !important; }
  .cpc-table-cell.width-82 {
    flex-basis: 82% !important; }
  .cpc-table-cell.width-83 {
    flex-basis: 83% !important; }
  .cpc-table-cell.width-84 {
    flex-basis: 84% !important; }
  .cpc-table-cell.width-85 {
    flex-basis: 85% !important; }
  .cpc-table-cell.width-86 {
    flex-basis: 86% !important; }
  .cpc-table-cell.width-87 {
    flex-basis: 87% !important; }
  .cpc-table-cell.width-88 {
    flex-basis: 88% !important; }
  .cpc-table-cell.width-89 {
    flex-basis: 89% !important; }
  .cpc-table-cell.width-90 {
    flex-basis: 90% !important; }
  .cpc-table-cell.width-91 {
    flex-basis: 91% !important; }
  .cpc-table-cell.width-92 {
    flex-basis: 92% !important; }
  .cpc-table-cell.width-93 {
    flex-basis: 93% !important; }
  .cpc-table-cell.width-94 {
    flex-basis: 94% !important; }
  .cpc-table-cell.width-95 {
    flex-basis: 95% !important; }
  .cpc-table-cell.width-96 {
    flex-basis: 96% !important; }
  .cpc-table-cell.width-97 {
    flex-basis: 97% !important; }
  .cpc-table-cell.width-98 {
    flex-basis: 98% !important; }
  .cpc-table-cell.width-99 {
    flex-basis: 99% !important; }
  .cpc-table-cell.width-100 {
    flex-basis: 100% !important; }

.cpc-table-tbody {
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px; }
  .cpc-table-tbody .cpc-table-row {
    background-color: white;
    border-top: 1px solid #e2e2e2; }
    .cpc-table-tbody .cpc-table-row:nth-child(2n) {
      background-color: #f8f9f9; }
  .cpc-table-tbody .special-last-row > div:first-child {
    text-indent: -99999px; }
  .cpc-table-tbody .special-last-row div {
    font-weight: 600; }
    .cpc-table-tbody .special-last-row div span {
      color: #454a52; }

.cpc-table .cpc-table-thead .cpc-table-cell--split > div {
  border-left: 1px solid #dfe6ea;
  border-right: 1px solid #dfe6ea;
  margin-right: -1px;
  padding: 8px; }

.cpc-table-tbody-total {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 600; }
  .cpc-table-tbody-total .cpc-table-row {
    background-color: rgba(255, 255, 255, 0.97);
    margin-top: 2px; }

.cpc-table-old {
  min-width: 1000px;
  width: inherit; }
  .cpc-table-old-wrapper {
    background: #fff;
    border: 1px solid #d2d2d2;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%; }
  .cpc-table-old tr {
    border-bottom: 1px solid rgba(210, 210, 210, 0.5); }
    .cpc-table-old tr > *:first-child {
      padding-left: 20px; }
  .cpc-table-old thead tr:first-child {
    background: #195fff; }
  .cpc-table-old thead tr:last-child:not(:first-child) {
    background: #f8f9f9; }
  .cpc-table-old thead tr .react-datepicker {
    font-weight: 300; }
  .cpc-table-old thead .header-cell {
    color: #fff;
    font: 500 12px/1.6 "Roboto-medium", sans-serif;
    text-transform: uppercase;
    white-space: nowrap; }
  .cpc-table-old tbody .table-placeholder {
    padding: 100px 0;
    text-align: center; }
    .cpc-table-old tbody .table-placeholder > .thumb {
      margin-bottom: 20px; }
    .cpc-table-old tbody .table-placeholder > .description {
      color: #8a9da7;
      font: 16px/1.6 "Roboto-Regular", sans-serif; }
  .cpc-table-old tbody tr:last-child {
    border: 0; }
  .cpc-table-old tbody td {
    color: #333;
    font-size: 13px; }
  .cpc-table-old-footer {
    background: #fff;
    border: 1px solid #d2d2d2;
    border-top: 0;
    padding: 10px; }
    .cpc-table-old-footer.no-border {
      border: none !important; }

.cpc-pagination {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
  gap: 10px; }
  .cpc-pagination li {
    list-style: none;
    position: relative; }
    .cpc-pagination li.rc-pagination-jump-prev, .cpc-pagination li.rc-pagination-jump-next {
      min-width: 20px; }
    .cpc-pagination li span:not(.jumpIcon) {
      background: #ffffff;
      border: 1px solid #cecece;
      border-radius: 5px;
      box-sizing: border-box;
      color: #738a95;
      cursor: pointer;
      display: block;
      font-size: 10px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      width: 30px; }
      .cpc-pagination li span:not(.jumpIcon):hover {
        background-color: #195fff;
        border-color: #195fff;
        color: #fff; }
    .cpc-pagination li span.jumpIcon {
      color: #dcdcdc;
      display: block;
      font-size: 18px;
      height: 30px;
      left: -5px;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      width: 30px; }
      .cpc-pagination li span.jumpIcon:before {
        color: #fff;
        content: '...';
        font-family: 'icon2' !important;
        height: 100%;
        left: 0px;
        line-height: 16px;
        position: absolute;
        text-align: center;
        top: 0px;
        width: 100%; }
      .cpc-pagination li span.jumpIcon:hover {
        border-radius: 5px;
        cursor: pointer; }
        .cpc-pagination li span.jumpIcon:hover:before {
          content: '\e903';
          font-size: 8px;
          line-height: 30px; }
    .cpc-pagination li.rc-pagination-jump-prev span.jumpIcon:hover:before {
      content: '\e902'; }
    .cpc-pagination li a {
      background: #ffffff;
      border: 1px solid #cecece;
      border-radius: 5px;
      box-sizing: border-box;
      color: #738a95;
      display: block;
      font-size: 10px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      text-decoration: none;
      width: 30px; }
      .cpc-pagination li a:hover {
        background-color: #195fff;
        border-color: #195fff;
        color: #fff; }
    .cpc-pagination li.rc-pagination-item-active a {
      background-color: #195fff;
      border-color: #195fff;
      color: #fff; }

.cpc-pagination-old {
  display: block;
  text-align: center; }
  .cpc-pagination-old li {
    display: inline;
    margin: 0 5px; }
    .cpc-pagination-old li > a {
      border: 1px solid #cecece;
      border-radius: 3px;
      font: 13px "Roboto-Regular", sans-serif;
      padding: 5px 8px;
      color: #454a52;
      text-decoration: none;
      user-select: none; }
      .cpc-pagination-old li > a:hover {
        background: #195fff;
        border-color: #195fff;
        color: #fff; }
    .cpc-pagination-old li.selected > a {
      background: #195fff;
      border-color: #195fff;
      color: #fff; }
    .cpc-pagination-old li.disabled > a {
      opacity: 0.5; }
    .cpc-pagination-old li.break {
      border: 1px solid #cecece;
      border-radius: 3px;
      font: 13px "Roboto-Regular", sans-serif;
      padding: 5px 8px;
      cursor: default; }

.cpc-loading-wrapper {
  display: flex;
  flex-direction: row;
  padding: 10px 0; }
  .cpc-loading-wrapper .cpc-loading-block {
    flex: 1;
    margin: 0 10px; }
    .cpc-loading-wrapper .cpc-loading-block .cpc-loading-bar {
      background: #ddd;
      display: block;
      height: 10px;
      overflow: hidden;
      position: relative; }
      .cpc-loading-wrapper .cpc-loading-block .cpc-loading-bar::after {
        animation: loading 1s linear infinite;
        background: linear-gradient(to right, #ddd, #f5f5f5, #e5e5e5, #ddd);
        content: '';
        display: block;
        height: 100%; }
      .cpc-loading-wrapper .cpc-loading-block .cpc-loading-bar:not(:last-child) {
        margin-bottom: 10px; }
      .cpc-loading-wrapper .cpc-loading-block .cpc-loading-bar:nth-child(2) {
        margin-bottom: 20px; }
      .cpc-loading-wrapper .cpc-loading-block .cpc-loading-bar.item-0 {
        width: 60%; }
      .cpc-loading-wrapper .cpc-loading-block .cpc-loading-bar.item-1 {
        width: 65%; }
      .cpc-loading-wrapper .cpc-loading-block .cpc-loading-bar.item-2 {
        width: 85%; }
      .cpc-loading-wrapper .cpc-loading-block .cpc-loading-bar.item-3 {
        width: 90%; }
      .cpc-loading-wrapper .cpc-loading-block .cpc-loading-bar.item-4 {
        width: 80%; }

@keyframes loading {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(100%); } }

.cpc-link {
  color: #195fff; }
  .cpc-link:hover {
    color: #0344d7; }

.cpc-box-shadow {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05); }

.layout-filter {
  border-bottom-left-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow: hidden; }
  @media only screen and (max-width: 768px) {
    .layout-filter {
      flex-wrap: wrap; } }
  .layout-filter__align-end {
    align-items: flex-end; }
  .layout-filter--bg {
    background-color: #f9faff;
    gap: 10px; }
  .layout-filter-col {
    flex-grow: 1; }
  .layout-filter__col {
    flex-grow: 1; }
  .layout-filter .box-filter {
    display: none; }
    .layout-filter .box-filter.open {
      display: block; }

.layout-filter2 {
  justify-content: space-between; }
  .layout-filter2-col-flex {
    display: flex;
    flex: 1;
    flex-wrap: wrap; }
  .layout-filter2__item {
    flex: 1 0 auto; }

.cpc-file {
  margin-left: 15px; }
  .cpc-file-group .link-file {
    word-break: break-word; }

.cpc-side-action {
  background-color: #f8f9fb;
  bottom: 0;
  box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.2);
  height: calc(100vh - 55px);
  overflow: hidden;
  position: fixed;
  right: 0px;
  width: 0;
  z-index: 9; }
  @media only screen and (max-width: 1200px) {
    .cpc-side-action {
      height: calc(100vh - 52px); } }
  .cpc-side-action > * {
    opacity: 0; }
  .cpc-side-action.open {
    width: 700px; }
    @media (max-width: 1200px) {
      .cpc-side-action.open {
        width: 600px; } }
    @media (max-width: 500px) {
      .cpc-side-action.open {
        width: 375px; } }
    .cpc-side-action.open > * {
      opacity: 1; }
  .cpc-side-action.large-size {
    width: 700px; }
    @media (max-width: 1200px) {
      .cpc-side-action.large-size {
        width: 600px; } }
    @media (max-width: 500px) {
      .cpc-side-action.large-size {
        width: 375px; } }

.cpc-datepicker-range.DateRangePicker {
  display: inline-block;
  margin-bottom: 10px;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative; }

.cpc-datepicker-range .DateRangePicker__Legend {
  color: #52575e;
  font-size: 14px;
  line-height: 16px;
  list-style-type: none;
  margin: 20px 0;
  padding: 0; }

.cpc-datepicker-range .DateRangePicker__LegendItem {
  display: inline-block;
  margin: 0 20px; }

.cpc-datepicker-range .DateRangePicker__LegendItemColor {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  display: inline-block;
  height: 14px;
  margin-right: 6px;
  vertical-align: text-bottom;
  width: 14px; }

.cpc-datepicker-range .DateRangePicker__LegendItemColor--selection {
  background-color: #426ee1; }

.cpc-datepicker-range .DateRangePicker__PaginationArrow {
  border: 0;
  cursor: pointer;
  display: block;
  height: 35px;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
  width: 35px;
  line-height: 35px;
  z-index: 1; }

.cpc-datepicker-range .DateRangePicker__PaginationArrow--previous {
  left: 20px; }

.cpc-datepicker-range .DateRangePicker__PaginationArrow--next {
  right: 20px; }

.cpc-datepicker-range .DateRangePicker__PaginationArrow:hover {
  background-color: #ccc; }

.cpc-datepicker-range .DateRangePicker__PaginationArrowIcon:before {
  content: '\e907';
  font-family: 'icon2' !important;
  color: #426ee1;
  font-size: 11px;
  font-weight: 600; }

.cpc-datepicker-range .DateRangePicker__PaginationArrowIcon--is-disabled {
  opacity: 0.25; }

.cpc-datepicker-range .DateRangePicker__PaginationArrowIcon--previous {
  right: 11px; }
  .cpc-datepicker-range .DateRangePicker__PaginationArrowIcon--previous:before {
    content: '\e907'; }

.cpc-datepicker-range .DateRangePicker__PaginationArrowIcon--next {
  left: 11px; }
  .cpc-datepicker-range .DateRangePicker__PaginationArrowIcon--next:before {
    content: '\e908'; }

.cpc-datepicker-range .DateRangePicker__Month {
  color: #333;
  display: inline-block;
  margin: 0 20px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 275px; }

.cpc-datepicker-range .DateRangePicker__MonthHeader {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  height: 35px;
  line-height: 35px;
  position: relative;
  text-align: center; }

.cpc-datepicker-range .DateRangePicker__MonthHeaderLabel {
  display: inline-block;
  position: relative; }

.cpc-datepicker-range .DateRangePicker__MonthHeaderSelect {
  background: #e4e4e4;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5; }

.cpc-datepicker-range .DateRangePicker__MonthDates {
  border-bottom: 1px solid #f4f5f6;
  border-collapse: separate;
  border-spacing: 0 1px;
  margin: 0;
  width: 100%; }

.cpc-datepicker-range .DateRangePicker__WeekdayHeading,
.cpc-datepicker-range .DateRangePicker__Date {
  font-size: 12px;
  line-height: 1;
  padding: 10px 0;
  text-align: center;
  width: 14.285714285714286%; }

.cpc-datepicker-range .DateRangePicker__WeekdayHeading {
  color: #426ee1;
  font-weight: bold; }

.cpc-datepicker-range .DateRangePicker__WeekdayHeading abbr[title] {
  border-bottom-width: 0;
  color: #426ee1;
  cursor: pointer;
  font-size: inherit;
  text-decoration: none; }

.cpc-datepicker-range .DateRangePicker__Date {
  border: 0 solid #f4f5f6;
  border-right-width: 0px;
  cursor: pointer;
  overflow: hidden;
  position: relative; }

.cpc-datepicker-range .DateRangePicker__Date:first-child {
  border-left-width: 1px; }

.cpc-datepicker-range .DateRangePicker__Date--weekend {
  background-color: #f6f7f9; }

.cpc-datepicker-range .DateRangePicker__Date--otherMonth {
  opacity: 0.25; }

.cpc-datepicker-range .DateRangePicker__Date--is-disabled {
  color: #cdcdd1;
  cursor: default; }

.cpc-datepicker-range .DateRangePicker__Date--is-selected {
  color: #fff; }

.cpc-datepicker-range .DateRangePicker__Date--is-highlighted {
  color: #333; }

.cpc-datepicker-range .DateRangePicker__CalendarDatePeriod {
  bottom: 0;
  position: absolute;
  top: 0; }

.cpc-datepicker-range .DateRangePicker__CalendarDatePeriod--am {
  left: 0;
  right: 50%; }

.cpc-datepicker-range .DateRangePicker__CalendarDatePeriod--pm {
  left: 50%;
  right: 0; }

.cpc-datepicker-range .DateRangePicker__CalendarSelection {
  background-color: #426ee1;
  border: 1px solid #426ee1;
  bottom: 5px;
  left: 0;
  position: absolute;
  right: 0;
  top: 5px; }

.cpc-datepicker-range .DateRangePicker__CalendarSelection--inOtherMonth {
  opacity: 0.5; }

.cpc-datepicker-range .DateRangePicker__CalendarSelection--start {
  border-bottom-left-radius: 10px;
  border-right-width: 0;
  border-top-left-radius: 10px;
  left: 5px; }

.cpc-datepicker-range .DateRangePicker__CalendarSelection--end {
  border-bottom-right-radius: 10px;
  border-left-width: 0;
  border-top-right-radius: 10px;
  right: 5px; }

.cpc-datepicker-range .DateRangePicker__CalendarSelection--segment {
  border-left-width: 0;
  border-right-width: 0; }

.cpc-datepicker-range .DateRangePicker__CalendarSelection--single {
  border-radius: 10px;
  left: 5px;
  right: 5px; }

.cpc-datepicker-range .DateRangePicker__CalendarSelection--is-pending {
  background-color: #426ee1;
  border-width: 0; }

.cpc-datepicker-range .DateRangePicker__CalendarHighlight {
  background-color: rgba(255, 255, 255, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.25);
  bottom: 5px;
  left: 0;
  position: absolute;
  right: 0;
  top: 5px; }

.cpc-datepicker-range .DateRangePicker__CalendarHighlight--inOtherMonth {
  opacity: 0.5; }

.cpc-datepicker-range .DateRangePicker__CalendarHighlight--start {
  border-bottom-left-radius: 5px;
  border-right-width: 0;
  border-top-left-radius: 5px;
  left: 5px; }

.cpc-datepicker-range .DateRangePicker__CalendarHighlight--end {
  border-bottom-right-radius: 10px;
  border-left-width: 0;
  border-top-right-radius: 10px;
  right: 5px; }

.cpc-datepicker-range .DateRangePicker__CalendarHighlight--segment {
  border-left-width: 0;
  border-right-width: 0; }

.cpc-datepicker-range .DateRangePicker__CalendarHighlight--single {
  background-color: #fff;
  border: 1px solid #426ee1;
  border-radius: 10px;
  left: 5px;
  right: 5px; }

.cpc-datepicker-range .DateRangePicker__HalfDateStates {
  bottom: -50px;
  left: -50px;
  position: absolute;
  right: -50px;
  top: -50px;
  transform: rotate(30deg); }

.cpc-datepicker-range .DateRangePicker__FullDateStates {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.cpc-datepicker-range .DateRangePicker__DateLabel {
  display: block;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1; }

.cpc-calendar {
  color: #31434b;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 13px; }
  .cpc-calendar .fc a {
    color: #31434b; }
  .cpc-calendar .fc .fc-col-header-cell-cushion {
    font-family: "Roboto-medium", sans-serif; }
  .cpc-calendar .fc .fc-header-toolbar {
    background-color: #f9f8f7;
    height: 42px;
    padding: 6px 11px;
    vertical-align: middle; }
    .cpc-calendar .fc .fc-header-toolbar.fc-toolbar {
      margin-bottom: 0; }
    .cpc-calendar .fc .fc-header-toolbar .fc-button {
      border: 1px solid #dfe6ea;
      color: #31434b;
      font-size: 12px;
      line-height: unset;
      outline: unset; }
      .cpc-calendar .fc .fc-header-toolbar .fc-button:focus {
        box-shadow: unset;
        outline: none; }
      .cpc-calendar .fc .fc-header-toolbar .fc-button.fc-prev-button.fc-button-primary, .cpc-calendar .fc .fc-header-toolbar .fc-button.fc-next-button.fc-button-primary {
        background-color: #f9f8f7;
        color: #8a9da7;
        padding: 5px 8px; }
      .cpc-calendar .fc .fc-header-toolbar .fc-button.fc-next-button {
        margin-right: 24px; }
      .cpc-calendar .fc .fc-header-toolbar .fc-button.fc-today-button {
        background-color: white;
        padding: 6px 18px; }
      .cpc-calendar .fc .fc-header-toolbar .fc-button.fc-dayGridWeek-button, .cpc-calendar .fc .fc-header-toolbar .fc-button.fc-dayGridMonth-button {
        background-color: white;
        padding: 6px 18px; }
        .cpc-calendar .fc .fc-header-toolbar .fc-button.fc-dayGridWeek-button.fc-button-active, .cpc-calendar .fc .fc-header-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active {
          background-color: #426ee1;
          border-color: #426ee1;
          color: white;
          font-weight: 500; }
    .cpc-calendar .fc .fc-header-toolbar h2.fc-toolbar-title {
      color: #31434b;
      font-size: 16px;
      font-style: normal;
      line-height: 30px; }
  .cpc-calendar .fc .fc-daygrid-day-number {
    padding: 0 4px; }
  .cpc-calendar .fc .fc-daygrid-dot-event .fc-event-title {
    color: #31434b;
    font-size: 12px;
    font-weight: normal; }
  .cpc-calendar .fc .fc-daygrid-day-bottom {
    cursor: pointer; }
    .cpc-calendar .fc .fc-daygrid-day-bottom .fc-daygrid-more-link {
      color: #31434b;
      font-size: 11px; }
  .cpc-calendar .fc .fc-popover .fc-popover-header {
    display: none; }
  .cpc-calendar .fc .fc-popover .fc-popover-body .fc-daygrid-dot-event {
    align-items: flex-start; }
    .cpc-calendar .fc .fc-popover .fc-popover-body .fc-daygrid-dot-event:not(:last-child) {
      margin-bottom: 4px; }
    .cpc-calendar .fc .fc-popover .fc-popover-body .fc-daygrid-dot-event .fc-daygrid-event-dot {
      margin: 4px 6px 4px 4px; }
    .cpc-calendar .fc .fc-popover .fc-popover-body .fc-daygrid-dot-event .fc-event-title {
      max-width: 300px;
      white-space: normal; }

.task-mention {
  font-family: 'Roboto', sans-serif;
  font-weight: 300; }
  .task-mention__input {
    font-family: 'Roboto', sans-serif;
    font-weight: 300; }

.task-mention__suggestions {
  bottom: calc(100% + 7px) !important;
  left: -6px !important; }
  .task-mention__suggestions .task-mention__suggestions__list {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto; }

.task-mention__suggestions__item:last-child {
  border-bottom: 0px !important; }

.task-mention__highlighter.task-mention__highlighter--singleLine strong {
  background: #fff;
  color: #195fff;
  position: relative;
  z-index: 9;
  font-weight: 300 !important; }

.task-mention--multiLine .task-mention__highlighter strong {
  background: #fff;
  color: #195fff;
  position: relative;
  z-index: 9;
  font-weight: 300 !important; }

.layout-chart {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px; }
  .layout-chart--col {
    display: flex;
    flex: 1 33.33%;
    padding: 0 16px; }
    @media screen and (max-width: 768px) {
      .layout-chart--col {
        flex: 1 100% !important; } }
  .layout-chart--box {
    background-color: white;
    border-radius: 5px;
    padding: 20px 30px 15px 30px;
    width: 100%; }

.chart-gauge-layout {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px; }
  .chart-gauge-layout--col {
    flex: 1 33.33%;
    margin-bottom: 10px;
    padding: 0 20px; }
    @media screen and (max-width: 768px) {
      .chart-gauge-layout--col {
        flex: 1 100%; } }
  .chart-gauge-layout .title-gauge-chart {
    color: #31434b;
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
    margin-bottom: 45px;
    text-align: center;
    text-transform: uppercase; }
  .chart-gauge-layout .chart-gauge {
    position: relative; }
    .chart-gauge-layout .chart-gauge:after {
      border-bottom: 1px dashed #dadada;
      bottom: 9px;
      content: '';
      display: inline-block;
      height: 1px;
      left: 50%;
      margin-left: -35%;
      position: absolute;
      width: 70%; }
    .chart-gauge-layout .chart-gauge--info {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 10px 0 0; }
      .chart-gauge-layout .chart-gauge--info .total {
        color: #ed8525;
        font-size: 20px;
        font-weight: 600; }
      .chart-gauge-layout .chart-gauge--info .name {
        color: #31434b;
        display: block;
        font-size: 12px;
        margin-bottom: 10px; }
      .chart-gauge-layout .chart-gauge--info .detail {
        font-size: 12px;
        font-weight: 600; }
        .chart-gauge-layout .chart-gauge--info .detail span {
          padding: 0 8px; }
          .chart-gauge-layout .chart-gauge--info .detail span:first-child {
            position: relative; }
            .chart-gauge-layout .chart-gauge--info .detail span:first-child:after {
              background-color: #31434b;
              content: '';
              display: inline-block;
              height: 10px;
              margin-top: -5px;
              position: absolute;
              right: 0px;
              top: 50%;
              transform: rotate(20deg);
              width: 1px; }

.title-chart {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase; }

.chart-doughnut-block {
  margin: 0 auto;
  max-width: 160px;
  position: relative; }
  .chart-doughnut-block .chart-doughnut-round {
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 20px;
    max-width: 150px; }
  .chart-doughnut-block canvas.chartjs-render-monitor {
    position: relative;
    z-index: 1; }
  .chart-doughnut-block .chart-doughnut-inner {
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%); }
    .chart-doughnut-block .chart-doughnut-inner p {
      font-size: 18px;
      font-weight: 600; }
    .chart-doughnut-block .chart-doughnut-inner span {
      font-size: 12px; }
  .chart-doughnut-block .chart-doughnut-parameter span {
    bottom: calc(16% - 15px);
    font-size: 12px;
    font-size: 18px;
    font-weight: 600;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0%);
    white-space: nowrap; }
    .chart-doughnut-block .chart-doughnut-parameter span i {
      color: #454a52;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      margin-left: 5px; }
  .chart-doughnut-block .chart-doughnut-percent span {
    font-size: 16px;
    font-weight: 600;
    position: absolute; }

.chart-legends {
  bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px;
  margin: 0 -10px;
  padding: 0px; }
  .chart-legends li {
    align-items: center;
    display: flex;
    font-size: 13px;
    margin-bottom: 10px;
    padding: 0 10px; }
    .chart-legends li span {
      border-radius: 2px;
      display: block;
      height: 15px;
      margin-right: 5px;
      width: 20px; }

.layout-big-desktop .layout-chart {
  margin: 0 -40px; }

.layout-big-desktop .layout-chart--col {
  padding: 0 40px; }

.iframe-pdf-block .react-pdf__message {
  padding: 20px; }

.iframe-pdf-box canvas {
  height: auto !important;
  max-width: 100% !important; }

.iframe-pdf-box .react-pdf__Page {
  border-top: 4px solid #ddd; }
  .iframe-pdf-box .react-pdf__Page:first-child {
    border-top: 0px; }

.heading-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }
  .heading-panel__left {
    display: inline-flex;
    align-items: center; }
    .heading-panel__left .go-back {
      color: #6a7489;
      background-color: #f9f9f9;
      border-color: #d7d7d7; }
      .heading-panel__left .go-back .icon:before {
        font-size: 10px; }
    .heading-panel__left__title {
      display: inline-flex;
      align-items: center; }
      .heading-panel__left__title .title-icon {
        font-size: 24px; }
        .heading-panel__left__title .title-icon:before {
          color: #195fff; }
      .heading-panel__left__title .title-name {
        color: #195fff;
        font-size: 20px; }

.viewer-panel {
  background-color: #fff;
  width: 100%; }
  .viewer-panel__fullscreen {
    left: 0;
    min-height: 100vh;
    position: fixed;
    top: 0;
    z-index: 92; }
    .viewer-panel__fullscreen.is-mobile {
      display: block !important; }
  .viewer-panel .control-block {
    align-items: center;
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.02);
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 0 15px; }
    .viewer-panel .control-block ul {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      margin: 0 -10px; }
      .viewer-panel .control-block ul li {
        list-style: none;
        margin-bottom: 15px;
        padding: 0 10px;
        position: relative; }
        .viewer-panel .control-block ul li::after {
          background-color: rgba(61, 61, 61, 0.15);
          content: '';
          display: block;
          height: 10px;
          margin-top: -5px;
          position: absolute;
          right: 0;
          top: 50%;
          width: 1px; }
        .viewer-panel .control-block ul li:last-child::after {
          display: none; }
        .viewer-panel .control-block ul li > div {
          align-items: center;
          display: flex;
          font-size: 13px; }
  .viewer-panel.is-mobile {
    display: none; }

.viewer-panel-document {
  border: 1px solid #eff3f5; }
  .viewer-panel-document .react-pdf__Page {
    border-top: 4px solid #eff3f5; }
    .viewer-panel-document .react-pdf__Page:first-child {
      border-top: 0px; }
  .viewer-panel-document .react-pdf__Page__textContent {
    display: none; }
  .viewer-panel-document .react-pdf__Page__annotations {
    position: absolute;
    top: 0px; }

.button-view-file-mobile {
  background-color: #fff;
  color: #195fff;
  cursor: pointer;
  display: none;
  font-family: "Roboto-medium", sans-serif;
  font-size: 15px;
  padding: 15px 10px; }
  .button-view-file-mobile.is-mobile {
    align-items: center;
    display: flex; }
  .button-view-file-mobile .left-icon {
    margin-top: -7px; }
    .button-view-file-mobile .left-icon:before {
      color: #195fff; }

.iframe-document {
  position: relative;
  width: 100%; }
  .iframe-document iframe {
    height: 100%;
    left: 0;
    min-width: 100%;
    position: absolute;
    top: 0;
    width: 10px; }

.truncate-text-tooltip {
  max-width: 400px; }
  .truncate-text-tooltip .pt-popover-content {
    background-color: #fff;
    color: #343434;
    font-size: 12px; }
  .truncate-text-tooltip .pt-popover-arrow-fill {
    fill: #fff; }
  .truncate-text-tooltip__break .pt-popover-content {
    word-break: break-all; }

.hidden-full-text {
  display: block;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.view-more-dots__total {
  color: #195fff;
  font-weight: 600;
  font-size: 13px;
  white-space: nowrap; }

.view-more-dots__icon {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  display: inline-block;
  font-size: 12px;
  height: 15px;
  line-height: 6px;
  text-align: center;
  vertical-align: text-bottom;
  width: 15px; }

.view-more-dots__content ul {
  min-width: 100px; }
  .view-more-dots__content ul li {
    border-bottom: 1px solid #efefef;
    padding: 5px 0; }
    .view-more-dots__content ul li:last-child {
      border-bottom: 0; }

.cpc-datepicker-advanced__head {
  border-bottom: 1px solid #dfe6ea;
  padding: 10px 15px; }
  .cpc-datepicker-advanced__head__list {
    align-items: center;
    margin: 0 -4px;
    margin: 0;
    max-width: 100%;
    padding: 0; }
    .cpc-datepicker-advanced__head__list li {
      color: #31434b;
      flex: auto;
      font-size: 13px;
      list-style: none;
      padding: 0 4px; }

.cpc-datepicker-advanced__body {
  max-width: 320px; }
  .cpc-datepicker-advanced__body__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin: 0 -5px;
    padding: 0; }
    .cpc-datepicker-advanced__body__list li {
      flex: 1 25%;
      list-style: none;
      margin-bottom: 10px;
      padding: 0 5px; }
    .cpc-datepicker-advanced__body__list .item {
      background: #ffffff;
      border: 1px solid #dfe6ea;
      cursor: pointer;
      font-size: 12px;
      height: 36px;
      line-height: 36px;
      padding: 0 15px; }
      .cpc-datepicker-advanced__body__list .item:hover, .cpc-datepicker-advanced__body__list .item.active {
        background-color: #195fff;
        color: #fff; }
      .cpc-datepicker-advanced__body__list .item:disabled {
        background-color: inherit;
        color: #ccc;
        cursor: not-allowed; }

.cpc-datepicker-advanced__footer {
  border-top: 1px solid #dfe6ea;
  overflow: hidden;
  padding: 10px; }

.cpc-datepicker-advanced__footer-action {
  margin: 0 -5px;
  max-width: 100%; }
  .cpc-datepicker-advanced__footer-action li {
    list-style: none;
    padding: 0 5px; }

.cpc-datepicker-advanced__picker {
  border: none !important; }

.select-tree-box ul {
  padding: 10px; }
  .select-tree-box ul li {
    font-size: 13px;
    margin-bottom: 10px; }
    .select-tree-box ul li .pt-control {
      margin-bottom: 0; }

.select-tree-box ul > li:last-child {
  margin-bottom: 0; }

.list-tooltip-show-more {
  align-items: center;
  display: inline-flex;
  font-size: 13px; }
  .list-tooltip-show-more__content {
    padding: 10px; }
    .list-tooltip-show-more__content p {
      font-size: 13px; }
  .list-tooltip-show-more .pt-popover-target {
    font-size: initial !important;
    line-height: initial !important; }

.table-phongban-donvi {
  border: 1px solid #dfe6ea;
  overflow: hidden; }
  .table-phongban-donvi__tabs {
    align-items: center;
    display: flex;
    margin: 0 -10px;
    user-select: none; }
    .table-phongban-donvi__tabs li {
      list-style: none;
      padding: 0 10px; }
      .table-phongban-donvi__tabs li span {
        color: #738a95;
        display: block;
        position: relative; }
        .table-phongban-donvi__tabs li span:after {
          background-color: transparent;
          bottom: 0;
          content: '';
          display: inline-block;
          height: 2px;
          left: 0;
          position: absolute;
          width: 100%; }
        .table-phongban-donvi__tabs li span.active {
          color: #195fff; }
          .table-phongban-donvi__tabs li span.active:after {
            background-color: #195fff; }
  .table-phongban-donvi__table {
    width: 100%; }
    .table-phongban-donvi__table tr th,
    .table-phongban-donvi__table tr td {
      color: #31434b;
      font-size: 12px;
      line-height: 100%;
      padding: 10px;
      text-align: left;
      vertical-align: middle; }
    .table-phongban-donvi__table thead tr th {
      background: #e8e8e8;
      font-weight: 500;
      position: sticky;
      top: 0;
      z-index: 2; }
    .table-phongban-donvi__table tbody tr {
      background-color: #fff; }
      .table-phongban-donvi__table tbody tr:nth-child(2n) {
        background-color: #fafafa; }
    .table-phongban-donvi__table .cel {
      align-items: center;
      display: inline-flex; }
      .table-phongban-donvi__table .cel .cel-value .pt-control {
        margin: 0;
        padding: 0; }
  .table-phongban-donvi .row-level-0 .cel-value-first {
    padding-left: 0 !important; }
  .table-phongban-donvi .row-level-1 .cel-value-first {
    padding-left: 10px !important; }
  .table-phongban-donvi .row-level-2 .cel-value-first {
    padding-left: 20px !important; }
  .table-phongban-donvi .row-level-3 .cel-value-first {
    padding-left: 30px !important; }
  .table-phongban-donvi .row-level-4 .cel-value-first {
    padding-left: 40px !important; }
  .table-phongban-donvi .row-level-5 .cel-value-first {
    padding-left: 50px !important; }

.table-tree {
  border: 1px solid #dfe6ea;
  overflow: auto; }
  .table-tree__tabs {
    align-items: center;
    display: flex;
    margin: 0 -10px;
    user-select: none; }
    .table-tree__tabs li {
      list-style: none;
      padding: 0 10px; }
      .table-tree__tabs li span {
        color: #738a95;
        display: block;
        position: relative; }
        .table-tree__tabs li span:after {
          background-color: transparent;
          bottom: 0;
          content: '';
          display: inline-block;
          height: 2px;
          left: 0;
          position: absolute;
          width: 100%; }
        .table-tree__tabs li span.active {
          color: #195fff; }
          .table-tree__tabs li span.active:after {
            background-color: #195fff; }
  .table-tree__table {
    width: 100%; }
    .table-tree__table tr th,
    .table-tree__table tr td {
      color: #31434b;
      font-size: 12px;
      line-height: 100%;
      padding: 10px;
      text-align: left;
      vertical-align: middle; }
    .table-tree__table thead tr th {
      background: #e8e8e8;
      font-weight: 500;
      position: sticky;
      top: 0;
      z-index: 2; }
    .table-tree__table tbody tr {
      background-color: #fff; }
      .table-tree__table tbody tr:nth-child(2n) {
        background-color: #fafafa; }
    .table-tree__table .cel {
      align-items: center;
      display: inline-flex; }
      .table-tree__table .cel .cel-value .pt-control {
        margin: 0;
        padding: 0; }
  .table-tree .row-level-0 td:first-child {
    padding-left: 0 !important; }
  .table-tree .row-level-1 td:first-child {
    padding-left: 20px !important; }
  .table-tree .row-level-2 td:first-child {
    padding-left: 40px !important; }
  .table-tree .row-level-3 td:first-child {
    padding-left: 60px !important; }
  .table-tree .row-level-4 td:first-child {
    padding-left: 80px !important; }
  .table-tree .row-level-5 td:first-child {
    padding-left: 100px !important; }
  .table-tree .row-level-6 td:first-child {
    padding-left: 120px !important; }
  .table-tree .row-level-7 td:first-child {
    padding-left: 140px !important; }
  .table-tree .row-level-8 td:first-child {
    padding-left: 160px !important; }
  .table-tree .row-level-9 td:first-child {
    padding-left: 180px !important; }
  .table-tree .row-level-10 td:first-child {
    padding-left: 200px !important; }

.user-role {
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  font-family: "Roboto", sans-serif;
  font-size: 13px; }
  .user-role__name {
    margin-bottom: 8px; }
  .user-role__roles li {
    list-style: none;
    margin-top: 4px;
    padding-left: 17px;
    position: relative; }
    .user-role__roles li .text-link {
      cursor: pointer; }
      .user-role__roles li .text-link:hover {
        color: #195fff;
        text-decoration: underline; }
  .user-role__logout:hover {
    background-color: rgba(167, 182, 194, 0.3);
    cursor: pointer; }

.tag {
  background-color: #ddd;
  border-radius: 40px;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  line-height: 100%;
  padding: 5px 8px;
  white-space: nowrap; }
  .tag.light-blue {
    background-color: #2fafd0; }
  .tag.green {
    background-color: #00aa48; }
  .tag.blue {
    background-color: #1552dc; }
  .tag.yellow {
    background-color: #ff951a; }

.table-basic {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 100%; }
  .table-basic.table-responsive {
    display: inline-block;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden; }
  .table-basic.table-border {
    border: 1px solid #dfe6ea; }
  .table-basic.table-striped tbody tr {
    background-color: #fff; }
    .table-basic.table-striped tbody tr:nth-child(2n) {
      background-color: #fafafa; }
  .table-basic.table-scroll {
    overflow-x: auto;
    overflow-y: auto !important; }
    .table-basic.table-scroll thead {
      position: sticky;
      top: 0; }
  .table-basic table {
    max-width: 100%; }
  .table-basic thead td,
  .table-basic thead th {
    background-color: #e8e8e8; }

.popup-modal__panel {
  width: auto; }
  .popup-modal__panel.full {
    width: 100% !important; }
  .popup-modal__panel.full-max {
    max-width: 1400px;
    width: 100% !important; }

.popup-modal__content {
  max-width: 100%; }

.popup-modal__panel.theme-grey,
.popup-modal__panel .pt-dialog-header {
  background-color: #f7f8fa; }

.cpc-title {
  align-items: center;
  display: inline-flex;
  gap: 20px; }
  .cpc-title .title {
    align-items: center;
    display: inline-flex;
    gap: 12px; }
    .cpc-title .title .icon {
      color: #195fff;
      font-size: 24px; }
      .cpc-title .title .icon::before {
        color: #195fff; }
    .cpc-title .title .name {
      color: #195fff;
      font-family: "Roboto-Regular", sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 23.44px; }

.comp-upload-file {
  overflow: hidden;
  position: relative;
  width: 100%;
  font-family: "Roboto", sans-serif; }
  .comp-upload-file input[type='text'] {
    border: 1px solid #e4e8ed !important;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    box-sizing: border-box;
    height: 37px;
    line-height: 37px;
    outline: none;
    padding: 0 10px;
    padding-right: 30px;
    width: calc(100% - 75px);
    font-family: "Roboto", sans-serif; }
  .comp-upload-file input[type='file'] {
    bottom: 0px;
    cursor: pointer;
    left: 0px;
    opacity: 0;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 100%;
    z-index: 7;
    font-family: "Roboto", sans-serif; }
  .comp-upload-file button {
    background: #105aff;
    border: none;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    height: 37px;
    line-height: 37px;
    position: absolute;
    right: 0px;
    text-align: center;
    top: 0px;
    width: 75px;
    font-family: "Roboto", sans-serif; }
  .comp-upload-file .close {
    cursor: pointer;
    font-size: 12px;
    position: absolute;
    right: 85px;
    top: 12px;
    z-index: 10; }

.comp-upload-file-add {
  align-items: center;
  background: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  box-shadow: none;
  color: #333333;
  cursor: pointer;
  display: inline-flex;
  gap: 5px;
  justify-content: center;
  min-height: 37px;
  padding: 0 15px;
  font-family: "Roboto", sans-serif; }
  .comp-upload-file-add [class^='icon-'] {
    color: #818181;
    font-size: 12px; }
  .comp-upload-file-add .name {
    font-size: 13.1px;
    font-weight: 500;
    white-space: nowrap;
    font-family: "Roboto", sans-serif; }
  .comp-upload-file-add:disabled {
    background-color: #f7f7f7;
    cursor: not-allowed; }

.comp-action-fixed {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  opacity: 0;
  position: fixed;
  right: -50px;
  row-gap: 10px;
  top: 100px;
  transition: margin-top 0.3s;
  z-index: -9999; }
  .comp-action-fixed.fixed {
    margin-top: 0;
    opacity: 1;
    right: 5px;
    transition-timing-function: linear;
    z-index: 9; }
  .comp-action-fixed button {
    background-image: none !important;
    height: 35px;
    width: 35px; }
    .comp-action-fixed button.blue {
      background-color: #195fff;
      border-color: #195fff;
      color: #fff; }
      .comp-action-fixed button.blue:hover {
        background-color: #195fff;
        border-color: #195fff;
        color: #fff; }

.comp-action-fixed-tooltip .pt-popover-content {
  font-size: 12px;
  padding: 8px 12px; }

.task-document-cabinet {
  max-width: 843px; }
  .task-document-cabinet__row {
    display: flex;
    flex: 1;
    flex-wrap: nowrap; }
    .task-document-cabinet__row__left {
      border-right: 1px solid #dfe6ea;
      min-width: 180px; }
    .task-document-cabinet__row__right {
      width: 100%; }
  .task-document-cabinet__card {
    border: 1px solid #dfe6ea; }
  .task-document-cabinet__radio--group label {
    color: #31434b;
    font-size: 12px; }
  .task-document-cabinet__title {
    color: #31434b;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.5em;
    margin-bottom: 15px; }
  .task-document-cabinet__filter {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between; }
    .task-document-cabinet__filter > div:nth-child(1) {
      flex: 1; }
  .task-document-cabinet__tree__group .task-document-cabinet__tree__item--toggle {
    margin-right: 10px; }
    .task-document-cabinet__tree__group .task-document-cabinet__tree__item--toggle .pt-icon {
      color: #9fa1a5;
      cursor: pointer;
      font-size: 12px;
      font-weight: 100; }
      .task-document-cabinet__tree__group .task-document-cabinet__tree__item--toggle .pt-icon:before {
        color: #9fa1a5; }
  .task-document-cabinet__tree__item {
    align-items: center;
    display: flex;
    line-height: 100%;
    margin-bottom: 10px; }
  .task-document-cabinet__tree__subitem {
    margin-left: 25px; }
    .task-document-cabinet__tree__subitem--row {
      align-items: center;
      display: flex;
      line-height: 100%;
      margin-bottom: 10px; }
  .task-document-cabinet__tree__name {
    color: #31434b;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5em; }
    .task-document-cabinet__tree__name .keycode {
      color: #31434b;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      vertical-align: middle;
      white-space: wrap; }
      .task-document-cabinet__tree__name .keycode:after {
        color: #31434b;
        content: '-';
        margin: 0 5px; }
    .task-document-cabinet__tree__name--nomal {
      font-weight: 300; }
